import { FC, useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import useCollectionForeign from 'hooks/collectionforeign/useCollectionForeign';
import useCollection from 'hooks/collection/useCollection';
import { toast } from 'react-toastify';
import React from 'react';
import { H3, Paragraph } from 'components/atoms/text';
import { Card, CardBody, CardHeader } from 'components/atoms/card';

interface CollectionFileDownloadProps extends RouteComponentProps {
    isForeign?: boolean;
    collectionId?: string;
    overviewFileId?: string;
}

const CollectionFileDownload: FC<CollectionFileDownloadProps> = ({ isForeign = false, collectionId = '', overviewFileId = '' }) => {
    useHeader('Downloaden');

    const { downloadCollectionForeignFile } = useCollectionForeign();
    const { downloadCollectionFile } = useCollection();


    const downloadFile = async () => {

        let response = null;
        if (isForeign) {
            response = await downloadCollectionForeignFile(collectionId, overviewFileId);
        } else {
            response = await downloadCollectionFile(collectionId, overviewFileId);
        }

        if (response.ok) {
            // Get filename
            const contentDisposition = response.headers.get('content-disposition');
            const match = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
            const fileName = match != null && match.length > 1 ? match[1] : 'download';

            saveAs(await response.blob(), fileName);

            setTimeout(() => navigate('/'), 5000);
        } else {
            toast.error('Oops, er ging iets mis bij het ophalen van het bestand');
        }
    };

    useEffect(() => {
        const download = async () => {
            await downloadFile();
        };

        download();
    }, []);

    return (
        <Card>
            <CardBody>
                <CardHeader borderBottom>
                    <H3>Downloaden</H3>
                </CardHeader>
                <Paragraph>
                    Het bestand wordt op dit moment gedownload. Hierna wordt u automatisch doorgestuurd.
                </Paragraph>
            </CardBody>
        </Card>
    );
};

export default CollectionFileDownload;
