import React, { FC } from 'react';
import { Form, Content } from './styles';
import { RouteComponentProps } from '@reach/router';
import Button, { ButtonProps } from 'components/atoms/button/Button';

interface AccountFormProps extends RouteComponentProps {
    button?: ButtonProps;
    isSubmitting: boolean;
    isValid: boolean;
}

const AccountForm: FC<AccountFormProps> = ({ button, children, isValid, isSubmitting }) => {
    return (
        <Form>
            <Content>{children}</Content>
            <Button type="submit" alignSelf="center" size="lg" disabled={!isValid} loading={isSubmitting} variant="white" rounded {...button} />
        </Form>
    );
};

export default AccountForm;
