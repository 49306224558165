import colors from './colors';
import borderRadius from './borderRadius';
import navigation from './navigation';
import header from './header';
import greys from './greys';

const theme = {
  colors,
  greys,
  borderRadius,
  navigation,
  header
};

export default theme;
