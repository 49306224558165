import React, { FC, Suspense } from 'react';
import { Wrapper, Circle, Circles, Logo, Text } from './styles';
import { RouteComponentProps } from '@reach/router';
import PartLeft from 'static/juresta-login-part-left.svg';
import PartRight from 'static/juresta-login-part-right.svg';
import Juresta from 'static/logo.svg';

interface AccountProps extends RouteComponentProps { }

const Account: FC<AccountProps> = ({ children }) => {
    return (
        <Wrapper>
            <Logo>
                <img src={Juresta} alt="Juresta" />
            </Logo>
            <Circles>
                <Circle isLeft>
                    <img src={PartLeft} alt="React Logo" />
                </Circle>
                <Circle>
                    <Suspense fallback="loading">{children}</Suspense>
                </Circle>
                <Circle isRight>
                    <img src={PartRight} alt="React Logo" />
                </Circle>
            </Circles>
            <Text>met recht deskundig</Text>
        </Wrapper>
    );
};

export default Account;
