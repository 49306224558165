import { navigate, RouteComponentProps } from '@reach/router';
import React, { FC, useState } from 'react';
import { Column } from 'react-table';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import useDeleteAdminTemplateLetter from 'hooks/templateLetter/useDeleteAdminTemplateLetter';
import { TemplateLetterPlainModel, DeleteTemplateLetterInputModel } from 'hooks/templateLetter/types';
import useAllTemplateLetters from 'hooks/templateLetter/useAllTemplateLetters';
import { toast } from 'react-toastify';
import Modal from 'components/organisms/Modal';
import useModal from 'hooks/modal';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { useHeader } from 'contexts/headerContext';
import { cdnString } from 'utils/cdnHelper';
import download from 'downloadjs';

export interface BannerProps extends RouteComponentProps {
}

const columns: Column<TemplateLetterPlainModel>[] = [
    {
        Header: 'Naam',
        accessor: (templateLetter) => templateLetter.name
    },
    {
        id: 'languageName',
        Header: 'Taal',
        accessor: (templateLetter) => templateLetter.languageName
    }
];

const ManageTemplateLetters: FC<BannerProps> = () => {
    useHeader('Voorbeeldbrieven');

    const { deleteTemplateLetterMutation } = useDeleteAdminTemplateLetter();
    const { data: templateLetters = [], isLoading: isLoadingTemplateLetters, } = useAllTemplateLetters();

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [templateLetterToDelete, setTemplateLetterToDelete] = useState<DeleteTemplateLetterInputModel>();

    const handleDelete = async () => {

        if (!templateLetterToDelete) {
            return;
        }

        setIsLoadingDelete(true);

        await deleteTemplateLetterMutation.mutateAsync(templateLetterToDelete)
            .then(() => {
                toast.success('Voorbeeldbrief succesvol verwijderd!');
            }).catch(() => {
                toast.error('Oeps, er ging iets fout tijdens het verwijderen van de voorbeeldbrief. Probeer het later opnieuw!');
            });

        setIsLoadingDelete(false);
        closeDeleteModal();
    };

    const openDeleteModal = (templateLetter: DeleteTemplateLetterInputModel) => {
        setTemplateLetterToDelete(templateLetter);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setTemplateLetterToDelete(undefined);
        hideDeleteModal();
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="large" title="Voorbeeld brief verwijderen" subtitle="Weet je zeker dat je deze voorbeeld brief wilt verwijderen">
                <Group spaceBetween>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [templateLetterToDelete]
    );

    const handleManage = (item: TemplateLetterPlainModel | undefined) => {
        navigate('templateletters/manage', { state: { item: item } });
    };

    const handleDownload = (item: TemplateLetterPlainModel) => {
        if (item == null || item.fileHash == null) {
            toast.error('Er is iets fout gegaan bij het downloaden van de brief. Probeer het later opnieuw');
            return;
        }

        const constructUrl = cdnString(item.fileHash);
        download(constructUrl);
    };

    return (
        <Card>
            <CardBody>
                <Table<TemplateLetterPlainModel>
                    isLoading={isLoadingTemplateLetters}
                    data={templateLetters}
                    noDataMessage="Geen voorbeeldbrieven beschikbaar"
                    columns={columns}
                    search
                    onAddClick={() => handleManage(undefined)}
                    sortBy={[
                        { id: 'languageName', desc: false },
                    ]}
                    actions={[
                        {
                            icon: 'edit',
                            onClick: (item, index) => handleManage(item),
                            title: 'Pas voorbeeldbrief aan'
                        },
                        {
                            icon: 'delete',
                            onClick: (item, index) => openDeleteModal(item),
                            title: 'Verwijder voorbeeldbrief'
                        },
                        {
                            icon: 'download',
                            onClick: (item) => handleDownload(item),
                            title: 'Download voorbeeldbrief'
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default ManageTemplateLetters;
