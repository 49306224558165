import React, { FC, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FormGroup } from 'components/atoms/form';
import { Button } from 'components/atoms/button';
import { RouteComponentProps } from '@reach/router';
import useAccount from 'hooks/account/useAccount';
import { toFormikErrors } from 'utils/errorhelper';
import { ResetPasswordInputModel } from 'hooks/account/types';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import queryString from 'query-string';
import { H2, Paragraph } from 'components/atoms/text';
import AccountForm from 'components/templates/AccountForm';

const ForgotPassword: FC<RouteComponentProps> = ({ navigate, location }) => {
    const params = queryString.parse(location?.search ?? '');
    const [created, setCreated] = useState(false);

    const { resetPassword } = useAccount();

    // ForgotPassword's methods.
    const onSubmit = async (
        values: ResetPasswordInputModel,
        { setSubmitting, setErrors }: FormikHelpers<ResetPasswordInputModel>
    ) => {
        const response = await resetPassword(values);

        if (response.ok) {
            setCreated(true);
        } else if (response.errors) {
            const errors = toFormikErrors<ResetPasswordInputModel>(response.errors);
            setErrors(errors);
        }

        setSubmitting(false);
    };

    // Formik.
    const initialValues: ResetPasswordInputModel = {
        password: '',
        confirmPassword: '',
        code: params.code as string ?? '',
        userName: params.userName as string ?? ''
    };

    if (!created) {
        return (
            <>
                <H2 center>Nieuw wachtwoord</H2>
                <Paragraph center variant="white">Maak een nieuw wachtwoord aan.</Paragraph>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, isValid }) => (
                        <AccountForm
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            button={{
                                title: 'aanmaken'
                            }}
                        >
                            <FormGroup label="Nieuw wachtwoord" required isWhite>
                                <FormControlFormik name="password" placeholder="Nieuw wachtwoord" type="password" />
                            </FormGroup>
                            <FormGroup label="Herhaal wachtwoord" required isWhite>
                                <FormControlFormik name="confirmPassword" placeholder="Herhaal wachtwoord" type="password" />
                            </FormGroup>
                        </AccountForm>
                    )}
                </Formik>
            </>
        );
    }

    return (
        <>
            <H2 center>Gelukt!</H2>
            <Paragraph center variant="white">U kunt nu met uw nieuwe wachtwoord inloggen in uw Juresta-omgeving.</Paragraph>
            <Button
                alignSelf="center"
                size="lg"
                variant="white"
                onClick={() => navigate && navigate('/account')}
            >
                Inloggen
            </Button>
        </>
    );
};

export default ForgotPassword;
