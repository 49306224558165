import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';

export const H3 = styled.h3<{ color: string; center?: boolean }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 1.375rem;
    line-height: 1.5rem;
    font-weight: 700;
    display: flex;
    word-break: break-word;
`;

export const Wrapper = styled.div<{ noMargin?: boolean }>`
    display: flex;
    margin-bottom: ${({ noMargin }) => noMargin ? 0 : 24}px;
`;

export const H3Icon = styled(Icon)`
    margin-right: 8px;
`;
