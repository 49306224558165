import { RouteComponentProps } from '@reach/router';
import React, { FC, useMemo, useState } from 'react';
import useAllTemplateLetters from 'hooks/templateLetter/useAllTemplateLetters';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { TemplateLetterPlainModel, TemplateLetterSendMailModel } from 'hooks/templateLetter/types';
import { Language } from 'hooks/language/types';
import { Column } from 'react-table';
import { useHeader } from 'contexts/headerContext';
import Accordion from 'components/organisms/accordion';
import { Paragraph } from 'components/atoms/text';
import download from 'downloadjs';
import { cdnString } from 'utils/cdnHelper';
import { toast } from 'react-toastify';
import { useModal } from 'react-modal-hook';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import { Button } from 'components/atoms/button';
import useSendTemplateLetterMail from 'hooks/templateLetter/useSendTemplateLetterMail';
import Modal from 'components/organisms/Modal';
import { toFormikErrors } from 'utils/errorhelper';

export interface TemplateLetterProps extends RouteComponentProps { }

const columns: Column<TemplateLetterPlainModel>[] = [
    {
        Header: 'Naam',
        accessor: (templateLetter) => templateLetter.name
    }
];

const TemplateLetters: FC<TemplateLetterProps> = () => {
    useHeader('Extra\'s');

    const { data: templateLetters = [], isLoading: isLoadingTemplateLetters } = useAllTemplateLetters();
    const [templateLetterToMail, setTemplateLetterToMail] = useState<TemplateLetterPlainModel | null>(null);

    const { sendTemplateLetterMail } = useSendTemplateLetterMail();

    const handleOnDownloadClick = (item: TemplateLetterPlainModel) => {
        if (item == null || item.fileHash == null) {
            toast.error('Er is iets fout gegaan bij het downloaden van de brief. Probeer het later opnieuw');
            return;
        }

        const constructUrl = cdnString(item.fileHash);
        download(constructUrl);
    };

    const handleOnSendMailClick = (item: TemplateLetterPlainModel) => {
        setTemplateLetterToMail(item);
        showLetterEmail();
    };

    const handleTemplateLetterOnSubmit = async (
        values: TemplateLetterSendMailModel,
        { setErrors, setSubmitting }: FormikHelpers<TemplateLetterSendMailModel>
    ) => {
        const response = await sendTemplateLetterMail(values);
        setSubmitting(true);

        if (response.ok) {
            toast.success('We hebben u deze voorbeeldbrief per mail gestuurd!');
            setTemplateLetterToMail(null);
            hideLetterEmail();
        } else if (response.errors) {
            const errors = toFormikErrors<TemplateLetterSendMailModel>(response.errors);
            setErrors(errors);
            toast.error('Niet alle velden zijn ingevuld');
        } else {
            toast.error('Oeps, er ging iets fout tijdens het versturen van de mail. Probeer het later opnieuw!');
        }

        setSubmitting(false);
    };

    const initialValues = {
        letterId: templateLetterToMail?.id ?? '',
        email: ''
    };

    const [showLetterEmail, hideLetterEmail] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideLetterEmail} size="small" title="Voorbeeldbrief versturen naar">
                <Formik<TemplateLetterSendMailModel> enableReinitialize initialValues={initialValues} onSubmit={handleTemplateLetterOnSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup label="E-mailadres">
                                <FormikFormControl name="email" placeholder="E-mail" />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button type="submit" loading={isSubmitting}>Verstuur</Button>
                            </FormGroup>

                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [initialValues]
    );

    const languages = Object.values(Language).filter(l => typeof l === 'number') as number[];

    const groupedTemplateLetters = useMemo(() => templateLetters
        .reduce((total, templateLetter) => {
            total[templateLetter.language] = [...(total[templateLetter.language] || []), templateLetter];
            return total;
        }, {} as { [keyof: number]: TemplateLetterPlainModel[] }), [templateLetters, languages]);

    return (
        <>
            <Card fill variant="primary">
                <CardBody>
                    <Paragraph variant="white">
                        In dit onderdeel vindt u voorbeeldteksten voor het versturen van een herinnering, aanmaning of laatste aanmaning in meerdere talen. U kunt de brieven eenvoudig downloaden of doorsturen naar uw e-mailadres. Het is niet mogelijk om de brief meteen door te sturen naar uw debiteur. In de voorbeeldteksten dient u namelijk nog de gegevens van uw debiteur en factuur of facturen op te nemen.
                    </Paragraph>
                    <Paragraph variant="white">
                        <b>Voorbeeldbrief laatste aanmaning consument</b><br />
                        Wanneer uw debiteur een particulier persoon c.q. consument betreft, gelden er sinds enkele jaren aangescherpte regels. Zo bent u, om uw recht op vergoeding van de buitengerechtelijke incassokosten te verzekeren, wettelijk verplicht tot het verzenden van een laatste schriftelijke aanmaning, ook wel de zogenaamde 14-dagen brief genoemd.
                    </Paragraph>
                    <Paragraph variant="white" noMargin>
                        <b>Doel en inhoud van de laatste aanmaning</b><br />
                        Aan de inhoud en wijze van verzenden van deze zogenaamde laatste aanmaning worden door de wetgever strenge eisen gesteld. U dient uw debiteur, als laatste wapenfeit, nog minimaal 14 dagen in de gelegenheid te stellen om tot betaling van uw factuur/ facturen over te gaan. Bovendien moet de debiteur er op worden geattendeerd dat wanneer hij niet binnen de aangegeven termijn alsnog betaalt, de buitengerechtelijke incassokosten en vertragingsrente verschuldigd worden.
                        Verstuur deze brief per e-mail met verzend- en leesbevestiging en/of per aangetekende post. Dan beschikt u in ieder geval over een verzendbewijs.
                    </Paragraph>
                </CardBody>
            </Card>
            {
                languages.map((language) => (
                    <Accordion key={language} title={`Voorbeeldbrieven - ${Language[language]}`}>
                        <Table<TemplateLetterPlainModel>
                            isLoading={isLoadingTemplateLetters}
                            data={Object.values(groupedTemplateLetters[language] ?? {})}
                            columns={columns}
                            noDataMessage="Geen voorbeeld brieven beschikbaar"
                            actions={[
                                {
                                    icon: 'download',
                                    onClick: (item, index) => handleOnDownloadClick(item)
                                },
                                {
                                    icon: 'mail',
                                    onClick: (item, index) => handleOnSendMailClick(item)
                                },
                            ]}
                            hideFooter
                        />
                    </Accordion>
                ))
            }
        </>
    );
};

export default TemplateLetters;
