import React, { FC } from 'react';
import greys from 'theme/greys';
import Icon from '../Icon';
import { Wrapper, Letter, Avatar } from './styles';

export interface ImageProps {
    image?: string;
    placeholderIcon?: string;
    placeholderLetter?: string;
    placeholderColor?: string;
    round?: boolean;
}

const Image: FC<ImageProps> = ({ image, placeholderIcon, placeholderLetter, ...props }) => {
    const icon = placeholderIcon || 'person';
    const letter = placeholderLetter?.charAt(0);

    return (
        <Wrapper {...props}>
            {image
                // eslint-disable-next-line jsx-a11y/alt-text
                ? <Avatar image={image} />
                : // render placeholder:
                letter
                    ? <Letter>{letter}</Letter>
                    : <Icon name={icon} color={greys[300]} />
            }
        </Wrapper>
    );
};

export default Image;
