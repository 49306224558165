export enum Claim {
    UserId = 'api.juresta.userId',
    UserName = 'api.juresta.userName',
    Roles = 'api.juresta.role',
    Rights = 'api.juresta.right',
    CustomerId = 'api.juresta.customerId',
    FullName = 'api.juresta.fullName',
    Insertion = 'api.juresta.insertion',
    LastName = 'api.juresta.lastName',
    Gender = 'api.juresta.gender'
}
