import { useField } from 'formik';
import React, { FC } from 'react';
import FormMultipleFile, { FormMultipleFileProps } from '..';

export interface FormikFormMultipleFileProps extends FormMultipleFileProps {
    name: string;
}

const FormikFormMultipleFile: FC<FormikFormMultipleFileProps> = ({ name, ...props }) => {
    const [field, meta, helpers] = useField<File[]>(name);

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormMultipleFile {...props} value={field.value} onChange={helpers.setValue} error={error} />;
};

export default FormikFormMultipleFile;
