import { FormikErrors, FormikTouched } from 'formik';
import { CustomError } from 'hooks/api/types';

export function toFormikErrors<T>(errors: CustomError[]): FormikErrors<T> {
    return errors.reduce((result: any, error) => {
        if (error.key.includes('.')) {
            let values = error.key.split('.');
            values = values.map((v, i) => {
                if (i === 0) {
                    return v;
                }

                return v.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
                    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
                    return index === 0 ? match.toLowerCase() : match.toUpperCase();
                });
            });

            values.forEach((v, i) => {
                if (i === 0 && result[values[0]] === undefined) {
                    result[values[0]] = {};
                } else if (i > 0 && i < values.length - 1 && result[values[i - 1]][values[i]] === undefined) {
                    result[values[i - 1]][values[i]] = {};
                } else if (i === values.length - 1) {
                    result[values[i - 1]][values[i]] = error.message;
                }
            });
        } else {
            result[error.key] = error.message;
        }
        return result;
    }, {});
}

export function isTouched(touched: FormikTouched<any>, name: string) {
    if (name.includes('[') && name.includes(']') && name.includes('.')) {
        // looking up array name
        const indexArrayOpen = name.indexOf('[');
        const arrayName = name.substr(0, indexArrayOpen);

        // looking up array index
        const indexArrayClose = name.indexOf(']');
        const indexArrayLength = indexArrayClose - indexArrayOpen - 1;
        const arrayIndex = Number(name.substr(indexArrayOpen + 1, indexArrayLength));

        // looking up variable name
        const indexDot = name.indexOf('.');
        const variableLength = name.length - (indexDot + 1);
        const variableName = name.substr(indexDot + 1, variableLength);

        const array = touched[arrayName] as Array<any>;

        if (!array) {
            return undefined;
        }

        if (!array[arrayIndex]) {
            return undefined;
        }

        if (!array[arrayIndex][variableName]) {
            return undefined;
        }

        return array[arrayIndex][variableName];
    } else {
        return touched[name];
    }
}
