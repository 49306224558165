import styled from '@emotion/styled';

const ButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -.75rem;

    > * {
        margin-right: 1rem;
        margin-bottom: .75rem;
    }
`;

export default ButtonWrapper;