import { useField } from 'formik';
import React, { FC } from 'react';
import FormCurrency, { FormCurrencyProps } from '..';

const FormCurrencyFormik: FC<FormCurrencyProps> = (props) => {
    const [field, meta] = useField(props);

    const error = !!(meta.touched && meta.error) ? meta.error : undefined;
    return <FormCurrency {...field} {...props} error={error} />;
};

export default FormCurrencyFormik;
