import { Card, CardBody } from 'components/atoms/card';
import Margin from 'components/atoms/Margin';
import { H3 } from 'components/atoms/text';
import { CustomerModel } from 'hooks/customer/types';
import useDocuments from 'hooks/document';
import { DocumentModel, SendDocumentMailInputModel } from 'hooks/document/types';
import React, { FC, useState } from 'react';
import { Column } from 'react-table';
import Table from 'components/molecules/table';
import { DocumentType, getDocumentTypeDisplay } from 'utils/constants/documentConstants';
import { toast } from 'react-toastify';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { toFormikErrors } from 'utils/errorhelper';
import { format, parseISO } from 'date-fns';

interface CustomerDetailDocumentsProps {
    customer: CustomerModel;
}

const CustomerDetailDocuments: FC<CustomerDetailDocumentsProps> = ({ customer }) => {

    const { useAllDocuments, getDocumentFile, sendDocumentMail } = useDocuments();
    const { data: documents = [], isLoading: isLoadingDocuments } = useAllDocuments({ customerId: customer.id, types: [DocumentType.Agreement, DocumentType.MaintenanceContract, DocumentType.TermsAndConditions] });

    const printRef = React.createRef<any>();
    const [fileToPrint, setFileToPrint] = useState<string | undefined>();
    const [documentToMail, setDocumentToMail] = useState<DocumentModel>();

    const documentColumns: Column<DocumentModel>[] = [
        {
            Header: 'Naam',
            accessor: (item) => item.fileName
        },
        {
            Header: 'Type',
            accessor: (item) => getDocumentTypeDisplay(item.type)
        },
        {
            Header: 'Versie',
            accessor: (item) => item.version
        },
        {
            id: 'date',
            Header: 'Datum',
            accessor: (item) => parseISO(item.date).getTime(),
            Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
        }
    ];

    const downloadDocument = async (document: DocumentModel) => {
        const response = await getDocumentFile(document.id);

        if (response.ok) {
            saveAs(await response.blob(), document.fileName);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de algemene voorwaarden');
        }
    };

    const printDocument = async (document: DocumentModel) => {
        const response = await getDocumentFile(document.id);

        if (response.ok) {
            const url = window.URL.createObjectURL(await response.blob());
            setFileToPrint(url);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de algemene voorwaarden');
        }
    };

    const handleMail = async (values: SendDocumentMailInputModel, { setErrors }: FormikHelpers<SendDocumentMailInputModel>) => {
        if (!documentToMail) {
            return;
        }

        const response = await sendDocumentMail(documentToMail.id, values);

        if (response.ok) {
            toast.success('Document is succesvol verstuurd');
            closeMailModal();
        } else if (response.errors) {
            const errors = toFormikErrors<SendDocumentMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van het document');
        }
    };

    const initialValuesSendMail: SendDocumentMailInputModel = {
        toEmail: '',
        toName: ''
    };

    const [showMailModal, hideMailModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideMailModal} size="large" title="Document versturen" subtitle="Vul de gegevens in om het document te versturen">
                <Formik<SendDocumentMailInputModel> initialValues={initialValuesSendMail} onSubmit={handleMail}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <FormGroup label="Naam">
                                <FormikFormControl name="toName" placeholder="Naam" />
                            </FormGroup>
                            <FormGroup label="E-mailadres" required>
                                <FormikFormControl name="toEmail" placeholder="E-mailadres" />
                            </FormGroup>
                            <Group right>
                                <Button type="button" onClick={closeMailModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Versturen</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [documentToMail]
    );

    const openMailModal = (document: DocumentModel) => {
        setDocumentToMail(document);
        showMailModal();
    };

    const closeMailModal = () => {
        setDocumentToMail(undefined);
        hideMailModal();
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Margin bottom={2}>
                        <H3>Documenten</H3>
                    </Margin>
                    <Card variant="light" noMargin>
                        <CardBody>
                            <Card noMargin>
                                <CardBody>
                                    <Table<DocumentModel>
                                        data={documents}
                                        columns={documentColumns}
                                        isLoading={isLoadingDocuments}
                                        sortBy={[{ id: 'date', desc: true }]}
                                        actions={[
                                            {
                                                icon: 'download',
                                                title: 'Document downloaden',
                                                onClick: downloadDocument
                                            },
                                            {
                                                icon: 'print',
                                                title: 'Document printen',
                                                onClick: printDocument
                                            },
                                            {
                                                icon: 'mail',
                                                title: 'Document mailen',
                                                onClick: openMailModal
                                            }
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </CardBody>
                    </Card>
                </CardBody>
            </Card>

            {
                fileToPrint &&
                <iframe ref={printRef} style={{ display: 'none' }} title="print" src={fileToPrint} onLoad={() => window.frames[0].print()}></iframe>
            }
        </>
    );
};

export default CustomerDetailDocuments;
