import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ImageProps } from './';

export const Wrapper = styled.div<ImageProps>`
  width: 2.5rem;
  height: 2.5rem;
  flex: 0 0 2.5rem;
  border-radius: ${({ round, theme }) =>
        round ? '1.25rem' : `${theme.borderRadius.small}px`};
  background-color: ${({ theme }) => theme.greys[50]};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Letter = styled.span`
  color: ${({ theme }) => theme.greys[300]};;
`;

export const Avatar = styled.div<{ image?: string }>`
    height: 100%;
    width: 100%;

    ${({ image }) =>
    image &&
        css`
            background-image: url('${image}');
            background-size: cover;
            background-position: center center;
        `}
`;
