import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Row, Col } from 'react-grid-system';
import HeaderAction from 'components/atoms/HeaderAction';
import { Button } from 'components/atoms/button';
import { Card, CardBody } from 'components/atoms/card';
import { H3 } from 'components/atoms/text';
import { FormGroup, FormikFormControl, FormikFormSelect, FormikFormYesNo } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import Margin from 'components/atoms/Margin';
import Group from 'components/molecules/Group';
import { CustomerModel, CustomerPlainModel, EditCustomerInputModel } from 'hooks/customer/types';
import useCustomer from 'hooks/customer/useCustomer';
import { Loading } from 'components/atoms/button/Button/styles';
import { cdnFileUrl } from 'utils/cdnHelper';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorhelper';
import useEditUserInfoRequests from 'hooks/edituserinforequest/useEditUserInfoRequest';
import { EditUserInfoRequestStatus } from 'utils/constants';
import { EditUserInfoRequestModel } from 'hooks/edituserinforequest/types';
import useRights from 'hooks/right';
import { RightModel } from 'hooks/right/types';
import CustomerDetailModules from './components/modules';
import CustomerDetailDocuments from './components/documents';
import ActionComponent from 'components/atoms/Action';
import { getLegalFormDisplay, legelFormOptions } from 'utils/constants/legalformconstants';
import { getGenderDisplay, genderOptions } from 'utils/constants/personconstants';
import { getPaymentMethodDisplay, paymentMethodOptions } from 'utils/constants/paymentmethodconstants';
import useUserInvitation from 'hooks/userInvitation/useUserInvitation';
import CustomerDetailFiles from './components/files';

interface CustomerDetailProps extends RouteComponentProps<{ location: { state: { customer: CustomerPlainModel } } }> { }

interface ChangeRequestModel {
    title: string;
    valueString: string | number | boolean;
    value?: any;
    field: string;
}

const CustomerDetail: FC<CustomerDetailProps> = ({ location }) => {
    useHeader(location?.state.customer.name);

    const { getAllCustomers, editCustomer, setCustomerBlocked } = useCustomer();
    const { getEditUserInfoRequests, completeEditUserInfoRequest } = useEditUserInfoRequests();
    const { getRights } = useRights();
    const { resendInvitation } = useUserInvitation();

    const [isLoading, setIsLoading] = useState(true);
    const [customer, setCustomer] = useState<CustomerModel>();
    const [editUserInfoRequest, setEditUserInfoRequest] = useState<EditUserInfoRequestModel>();
    const [rights, setRights] = useState<RightModel[]>([]);

    const [deniedChangeRequests, setDeniedChangeRequests] = useState<ChangeRequestModel[]>([]);
    const [acceptedChangeRequests, setAcceptedChangeRequests] = useState<ChangeRequestModel[]>([]);

    const fetchCustomer = async () => {
        const response = await getAllCustomers({ id: location?.state.customer.id });

        if (response.ok && response.data) {
            setCustomer(response.data[0]);
        } else {
            toast.error('Oeps, er ging iets fout bij het ophalen van jouw gegevens');
        }
    };

    const fetchEditUserInfoRequest = async () => {
        const response = await getEditUserInfoRequests({
            userId: location?.state.customer.userId,
            status: EditUserInfoRequestStatus.Requested
        });

        if (response.ok && response.data) {
            setEditUserInfoRequest(response.data[0]);
        } else {
            toast.error('Oeps, er ging iets fout bij het ophalen van jouw gegevens');
        }
    };

    const fetchRights = async () => {
        const response = await getRights();

        if (response.ok && response.data) {
            setRights(response.data);
        } else {
            toast.error('Oeps, er ging iets fout bij het ophalen van jouw gegevens');
        }
    };

    useEffect(() => {
        (async () => {
            const customerCall = fetchCustomer();
            const userInfoRequestCall = fetchEditUserInfoRequest();
            const rightsCall = fetchRights();

            await Promise.all([customerCall, userInfoRequestCall, rightsCall]);

            setIsLoading(false);
        })();
    }, [location?.state.customer]);

    const genderField = 'user.gender';
    const initialsField = 'user.initials';
    const firstNameField = 'user.firstName';
    const insertionField = 'user.insertion';
    const lastNameField = 'user.lastName';
    const functionField = 'user.function';
    const phoneNumberField = 'user.phoneNumber';
    const mobileNumberField = 'user.mobileNumber';
    const emailAddressField = 'user.email';
    const companyNameField = 'name';
    const kvkNumberField = 'kvkNumber';
    const legalFormField = 'legalForm';
    const addressVisitField = 'addressVisit';
    const postalCodeVisitField = 'postalCodeVisit';
    const cityVisitField = 'cityVisit';
    const isMailAddressDifferentField = 'isMailAddressDifferent';
    const addressMailField = 'addressMail';
    const postalCodeMailField = 'postalCodeMail';
    const cityMailField = 'cityMail';
    const bankAccountField = 'bankAccount';
    const paymentMethodField = 'paymentMethod';
    const emailInvoiceField = 'emailInvoice';
    const relationNumberField = 'relationNumber';
    const websiteField = 'website';

    const changeRequests = useMemo((): ChangeRequestModel[] => {
        if (editUserInfoRequest == null || customer == null) {
            return [];
        }

        const requests: ChangeRequestModel[] = [];

        if (editUserInfoRequest.gender !== customer.user.gender) {
            requests.push({
                field: genderField,
                title: 'Aanhef',
                valueString: getGenderDisplay(editUserInfoRequest.gender),
                value: editUserInfoRequest.gender
            });
        }
        if (editUserInfoRequest.initials !== customer.user.initials) {
            requests.push({
                field: initialsField,
                title: 'Initialen',
                valueString: editUserInfoRequest.initials,
                value: editUserInfoRequest.initials
            });
        }
        if (editUserInfoRequest.firstName !== customer.user.firstName) {
            requests.push({
                field: firstNameField,
                title: 'Voornaam',
                valueString: editUserInfoRequest.firstName,
                value: editUserInfoRequest.firstName
            });
        }
        if (editUserInfoRequest.insertion !== customer.user.insertion) {
            requests.push({
                field: insertionField,
                title: 'Tussenvoegsel',
                valueString: editUserInfoRequest.insertion ?? '',
                value: editUserInfoRequest.insertion
            });
        }
        if (editUserInfoRequest.lastName !== customer.user.lastName) {
            requests.push({
                field: lastNameField,
                title: 'Achternaam',
                valueString: editUserInfoRequest.lastName,
                value: editUserInfoRequest.lastName
            });
        }
        if (editUserInfoRequest.function !== customer.user.function) {
            requests.push({
                field: functionField,
                title: 'Functie',
                valueString: editUserInfoRequest.function,
                value: editUserInfoRequest.function
            });
        }
        if (editUserInfoRequest.phoneNumber !== customer.user.phoneNumber) {
            requests.push({
                field: phoneNumberField,
                title: 'Telefoonnummer',
                valueString: editUserInfoRequest.phoneNumber,
                value: editUserInfoRequest.phoneNumber
            });
        }
        if (editUserInfoRequest.mobileNumber !== customer.user.mobileNumber) {
            requests.push({
                field: mobileNumberField,
                title: 'Mobiele telefoonnummer',
                valueString: editUserInfoRequest.mobileNumber,
                value: editUserInfoRequest.mobileNumber
            });
        }
        if (editUserInfoRequest.companyName !== customer.name) {
            requests.push({
                field: companyNameField,
                title: 'Bedrijfsnaam',
                valueString: editUserInfoRequest.companyName,
                value: editUserInfoRequest.companyName
            });
        }
        if (editUserInfoRequest.kvkNumber !== customer.kvkNumber) {
            requests.push({
                field: kvkNumberField,
                title: 'KvK-nummer',
                valueString: editUserInfoRequest.kvkNumber,
                value: editUserInfoRequest.kvkNumber
            });
        }
        if (editUserInfoRequest.legalForm !== customer.legalForm) {
            requests.push({
                field: legalFormField,
                title: 'Rechtsvorm',
                valueString: getLegalFormDisplay(editUserInfoRequest.legalForm),
                value: editUserInfoRequest.legalForm
            });
        }
        if (editUserInfoRequest.addressVisit !== customer.addressVisit) {
            requests.push({
                field: addressVisitField,
                title: 'Bezoekadres',
                valueString: editUserInfoRequest.addressVisit,
                value: editUserInfoRequest.addressVisit
            });
        }
        if (editUserInfoRequest.postalCodeVisit !== customer.postalCodeVisit) {
            requests.push({
                field: postalCodeVisitField,
                title: 'Postcode',
                valueString: editUserInfoRequest.postalCodeVisit,
                value: editUserInfoRequest.postalCodeVisit
            });
        }
        if (editUserInfoRequest.cityVisit !== customer.cityVisit) {
            requests.push({
                field: cityVisitField,
                title: 'Plaats',
                valueString: editUserInfoRequest.cityVisit,
                value: editUserInfoRequest.cityVisit
            });
        }

        if (editUserInfoRequest.isMailAddressDifferent !== customer.isMailAddressDifferent) {
            requests.push({
                field: isMailAddressDifferentField,
                title: 'Postadres anders dan bezoekadres',
                valueString: editUserInfoRequest.isMailAddressDifferent ? 'Ja' : 'Nee',
                value: editUserInfoRequest.isMailAddressDifferent
            });
        }
        if (editUserInfoRequest.addressMail !== customer.addressMail) {
            requests.push({
                field: addressMailField,
                title: 'Postadres',
                valueString: editUserInfoRequest.addressMail,
                value: editUserInfoRequest.addressMail
            });
        }
        if (editUserInfoRequest.postalCodeMail !== customer.postalCodeMail) {
            requests.push({
                field: postalCodeMailField,
                title: 'Postadres postcode',
                valueString: editUserInfoRequest.postalCodeMail,
                value: editUserInfoRequest.postalCodeMail
            });
        }
        if (editUserInfoRequest.cityMail !== customer.cityMail) {
            requests.push({
                field: cityMailField,
                title: 'Postadres plaats',
                valueString: editUserInfoRequest.cityMail,
                value: editUserInfoRequest.cityMail
            });
        }
        if (editUserInfoRequest.bankAccount !== customer.bankAccount) {
            requests.push({
                field: bankAccountField,
                title: 'Rekeningnummer',
                valueString: editUserInfoRequest.bankAccount,
                value: editUserInfoRequest.bankAccount
            });
        }
        if (editUserInfoRequest.paymentMethod !== customer.paymentMethod) {
            requests.push({
                field: paymentMethodField,
                title: 'Betaalwijze',
                valueString: getPaymentMethodDisplay(editUserInfoRequest.paymentMethod),
                value: editUserInfoRequest.paymentMethod
            });
        }
        if (editUserInfoRequest.email !== customer.user.email) {
            requests.push({
                field: emailAddressField,
                title: 'E-mailadres',
                valueString: editUserInfoRequest.email,
                value: editUserInfoRequest.email
            });
        }
        if (editUserInfoRequest.emailInvoice !== customer.emailInvoice) {
            requests.push({
                field: emailInvoiceField,
                title: 'E-mailadres voor facturering',
                valueString: editUserInfoRequest.emailInvoice,
                value: editUserInfoRequest.emailInvoice
            });
        }
        if (editUserInfoRequest.website !== customer.website) {
            requests.push({
                field: websiteField,
                title: 'Website',
                valueString: editUserInfoRequest.website ?? '',
                value: editUserInfoRequest.website
            });
        }

        return requests;
    }, [editUserInfoRequest, customer, acceptedChangeRequests, deniedChangeRequests]);

    const showChangeRequests = useMemo(
        () =>
            changeRequests.filter(
                (c) => !deniedChangeRequests.some((d) => d.field === c.field) && !acceptedChangeRequests.some((a) => a.field === c.field)
            ),
        [changeRequests, acceptedChangeRequests, deniedChangeRequests]
    );

    const resendInvitationHandler = async () => {
        if (customer == null) {
            return;
        }

        const response = await resendInvitation(customer.user.id);

        if (response.ok) {
            toast.success('De uitnodiging is opnieuw verzonden');
        } else {
            toast.error('Oeps, er is iets fout gegaan');
        }
    };

    const onEditCustomer = useCallback(
        async (values: EditCustomerInputModel, { setErrors, setSubmitting }: FormikHelpers<EditCustomerInputModel>) => {
            const response = await editCustomer(values);

            if (response.ok) {
                toast.success('De wijzigingen zijn succesvol opgeslagen');
                if (editUserInfoRequest != null && changeRequests.length === acceptedChangeRequests.length + deniedChangeRequests.length) {
                    await completeEditUserInfoRequest(editUserInfoRequest.id);
                    setEditUserInfoRequest(undefined);
                }
            } else if (response.errors) {
                const errors = toFormikErrors<EditCustomerInputModel>(response.errors);
                setErrors(errors);
                toast.error('Niet alle velden zijn ingevuld');
            } else {
                toast.error('Oops, er ging iets fout bij het opslaan van de wijzigingen');
            }

            setSubmitting(false);
        },
        [changeRequests, acceptedChangeRequests, deniedChangeRequests, editUserInfoRequest]
    );

    const blockUserHandler = () => {
        if (customer == null) {
            return;
        }

        setCustomerBlocked(customer.id, !customer.blocked);
        setCustomer({ ...customer, blocked: !customer.blocked });
    };

    const isMailAddressDifferent = customer != null
        && (
            customer.addressMail != null ||
            customer.postalCodeMail != null ||
            customer.cityMail != null
        );


    const initialValues: EditCustomerInputModel = useMemo(
        () => ({
            id: customer?.id ?? '',
            name: customer?.name ?? '',
            kvkNumber: customer?.kvkNumber ?? '',
            legalForm: customer?.legalForm,
            addressVisit: customer?.addressVisit ?? '',
            postalCodeVisit: customer?.postalCodeVisit ?? '',
            cityVisit: customer?.cityVisit ?? '',
            isMailAddressDifferent: isMailAddressDifferent,
            addressMail: customer?.addressMail ?? '',
            postalCodeMail: customer?.postalCodeMail ?? '',
            cityMail: customer?.cityMail ?? '',
            bankAccount: customer?.bankAccount ?? '',
            paymentMethod: customer?.paymentMethod,
            emailInvoice: customer?.emailInvoice ?? '',
            relationNumber: customer?.relationNumber ?? '',
            website: customer?.website ?? '',
            user: {
                avatar: customer?.user.avatar != null ? cdnFileUrl(customer?.user.avatar.hash) : undefined,
                gender: customer?.user.gender,
                initials: customer?.user.initials ?? '',
                firstName: customer?.user.firstName ?? '',
                insertion: customer?.user.insertion,
                lastName: customer?.user.lastName ?? '',
                email: customer?.user.email ?? '',
                phoneNumber: customer?.user.phoneNumber ?? '',
                mobileNumber: customer?.user.mobileNumber ?? '',
                function: customer?.user.function ?? ''
            }
        }),
        [customer]
    );

    return (
        <>
            <Margin bottom={1.5}>
                <Group spaceBetween>
                    <Button variant="grey" type="button" onClick={() => navigate('/admin/customers')}>
                        Terug
                    </Button>
                    <Group>
                        {
                            customer?.user.registeredOn == null &&
                            <Button variant="primary" type="button" onClick={resendInvitationHandler}>
                                Uitnodiging opnieuw versturen
                            </Button>
                        }
                        <Button variant="error" type="button" onClick={blockUserHandler}>
                            {customer?.blocked ? 'Deblokkeer relatie' : 'Blokkeer relatie'}
                        </Button>
                    </Group>
                </Group>
            </Margin>
            {(isLoading || customer == null) && <Loading />}
            {!isLoading && customer != null && (
                <>
                    <Card>
                        <CardBody>
                            <H3>{customer.name}</H3>
                            <Formik initialValues={initialValues} onSubmit={onEditCustomer}>
                                {({ isSubmitting, setFieldValue, values }) => {
                                    return (
                                        <Form>
                                            <Card noMargin variant="light">
                                                <CardBody>
                                                    <HeaderAction title="Persoonsgegevens" />
                                                    <Row>
                                                        <Col sm={6}>
                                                            <FormGroup label="Aanhef" required>
                                                                <FormikFormSelect name={genderField} options={genderOptions} placeholder="Kies een aanhef" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <FormGroup label="Initialen">
                                                                <FormikFormControl name={initialsField} placeholder="Initialen" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <FormGroup label="Voornaam" required>
                                                                <FormikFormControl name={firstNameField} placeholder="Voornaam" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup label="Tussenvoegsel">
                                                                <FormikFormControl name={insertionField} placeholder="Tussenvoegsel" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={5}>
                                                            <FormGroup label="Achternaam" required>
                                                                <FormikFormControl name={lastNameField} placeholder="Achternaam" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup label="Functie" required>
                                                                <FormikFormControl name={functionField} placeholder="Functie" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup label="Mobiele telefoonnummer" required>
                                                                <FormikFormControl name={mobileNumberField} placeholder="Mobiele telefoonnummer" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup label="Telefoonnummer">
                                                                <FormikFormControl name={phoneNumberField} placeholder="Telefoonnummer" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <FormGroup label="E-mailadres" required>
                                                                <FormikFormControl name={emailAddressField} placeholder="E-mailadres" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <FormGroup label="E-mailadres voor facturatie">
                                                                <FormikFormControl name={emailInvoiceField} placeholder="E-mailadres voor facturatie" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <FormGroup label="Website">
                                                                <FormikFormControl name={websiteField} placeholder="Website" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Margin top={2}>
                                                        <HeaderAction title="Bedrijfsgegevens" />
                                                        <Row>
                                                            <Col xs={12}>
                                                                <FormGroup label="Bedrijfsnaam" required>
                                                                    <FormikFormControl name={companyNameField} placeholder="Bedrijfsnaam" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <FormGroup label="Relatienummer">
                                                                    <FormikFormControl name={relationNumberField} placeholder="Relatie nummer" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <FormGroup label="KvK-nummer" required>
                                                                    <FormikFormControl name={kvkNumberField} placeholder="KvK-nummer" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <FormGroup label="Rechtsvorm" required>
                                                                    <FormikFormSelect name={legalFormField} options={legelFormOptions} placeholder="Kies een rechtsvorm" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <FormGroup label="Bezoekadres" required>
                                                                    <FormikFormControl name={addressVisitField} placeholder="Bezoekadres" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <FormGroup label="Postcode" required>
                                                                    <FormikFormControl name={postalCodeVisitField} placeholder="Postcode" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <FormGroup label="Plaats" required>
                                                                    <FormikFormControl name={cityVisitField} placeholder="Plaats" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <FormGroup>
                                                                    <FormikFormYesNo label="Postadres wijkt af van bezoekadres" name={isMailAddressDifferentField} />
                                                                </FormGroup>
                                                            </Col>
                                                            {values.isMailAddressDifferent &&
                                                                <>
                                                                    <Col xs={12}>
                                                                        <FormGroup label="Postadres" required>
                                                                            <FormikFormControl name={addressMailField} placeholder="Postadres" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <FormGroup label="Postcode" required>
                                                                            <FormikFormControl name={postalCodeMailField} placeholder="Postcode" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <FormGroup label="Plaats" required>
                                                                            <FormikFormControl name={cityMailField} placeholder="Plaats" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </>
                                                            }
                                                        </Row>
                                                    </Margin>
                                                    <Margin top={2}>
                                                        <HeaderAction title="Betaalgegevens" />
                                                        <Row>
                                                            <Col xs={6}>
                                                                <FormGroup label="Rekeningnummer">
                                                                    <FormikFormControl name={bankAccountField} placeholder="Rekeningnummer" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <FormGroup label="Betaalwijze" required>
                                                                    <FormikFormSelect name={paymentMethodField} options={paymentMethodOptions} placeholder="Kies een betaalwijze" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Margin>
                                                    <Card>
                                                        <CardBody>
                                                            <H3>
                                                                {showChangeRequests.length} wijziging
                                                                {showChangeRequests.length !== 1 && 'en'} aangevraagd
                                                            </H3>
                                                            {showChangeRequests.length === 0 && editUserInfoRequest != null && (
                                                                <p>
                                                                    Alle aangevraagde wijzigingen zijn verwerkt. Vergeet niet op te
                                                                    slaan.
                                                                </p>
                                                            )}
                                                            {showChangeRequests.map((cr, index) => (
                                                                <div key={cr.field}>
                                                                    {index > 0 && <hr />}
                                                                    <Group spaceBetween>
                                                                        <span style={{ width: 200 }}>{cr.title}</span>
                                                                        <span>{cr.valueString}</span>
                                                                        <div>
                                                                            <ActionComponent
                                                                                icon="delete"
                                                                                item={cr}
                                                                                index={0}
                                                                                onClick={(item) => {
                                                                                    const array = [...deniedChangeRequests];
                                                                                    array.push(item);
                                                                                    setDeniedChangeRequests(array);
                                                                                }}
                                                                            />
                                                                            <ActionComponent
                                                                                icon="check"
                                                                                item={cr}
                                                                                index={1}
                                                                                onClick={(item) => {
                                                                                    setFieldValue(item.field, item.value);
                                                                                    const array = [...acceptedChangeRequests];
                                                                                    array.push(item);
                                                                                    setAcceptedChangeRequests(array);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Group>
                                                                </div>
                                                            ))}
                                                        </CardBody>
                                                    </Card>
                                                    <Group right>
                                                        <Button type="submit" loading={isSubmitting}>
                                                            Opslaan
                                                        </Button>
                                                    </Group>
                                                </CardBody>
                                            </Card>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </CardBody>
                    </Card>
                    <CustomerDetailDocuments customer={customer} />
                    <CustomerDetailFiles customer={customer} />
                    <CustomerDetailModules customer={customer} rights={rights} fetchCustomer={fetchCustomer} />
                </>
            )}
        </>
    );
};

export default CustomerDetail;
