export function appendObject(object: any, formData?: FormData, prefix?: string) {
    prefix = prefix ?? '';
    formData = formData ?? new FormData();
    for (const key in object) {
        if (object[key] !== undefined) {
            if (object[key] instanceof Date) {
                const x = object[key] as Date;

                const keyString = prefix + key;
                formData.append(keyString, x.toDateString());

                continue;
            }

            if (object[key] instanceof Array && (object[key] as Array<any>).length > 0) {
                //if ((object[key] as Array<any>)[0] instanceof File) {
                const array = object[key] as Array<any>;
                const keyString = prefix + key;
                array.forEach((f, i) => formData?.append(`${keyString}`, f));

                continue;
                //}
            }

            if (object[key] instanceof File) {
                const keyString = prefix + key;
                formData.append(keyString, object[key]);

                continue;
            }

            if (typeof object[key] === 'object') {
                appendObject(object[key], formData, `${key}.`);
                continue;
            }

            const keyString = prefix + key;
            switch (typeof (object[key])) {
                case 'number':
                    formData.append(keyString, object[key].toString());
                    break;
                case 'string':
                    formData.append(keyString, object[key]);
                    break;
                case 'boolean':
                    formData.append(keyString, object[key]);
                    break;
                case 'object':
                    break;
                default:
                    formData.append(keyString, object[key]);
                    break;
            }
        }
    }
}
