import styled from '@emotion/styled';
import Icon from '../Icon';
import { Link } from '@reach/router';
import { css } from '@emotion/react';

export const MenuItem = styled(Link)<{ active?: boolean }>`
    height: 3rem;
    list-style-type: none;
    transition: background 0.4s ease;
    display: flex;
    align-items: center;
    user-select: none;
    text-decoration: none;
    margin-left: 0;
    transition: margin 0.3s ease-out, padding 0.3s ease-out;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
    padding: 0 24px;
    position: relative;
    cursor: pointer;

    ${({ active }) =>
        active &&
        css`
            text-decoration: underline;
            font-weight: 600;
        `};
`;

export const Title = styled.span<{ active?: boolean }>`
    color: ${({ theme, active }) => (active ? theme.colors.primary : 'inherit')};
    line-height: 1;
    display: block;
    flex: 1;
    font-size: 1rem;
`;

export const MenuIcon = styled(Icon)`
    margin-right: 16px;
`;

export const Content = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    transition: padding 0.3s ease-out;
    padding-left: 2.5rem;
`;
