import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Col, Row } from 'react-grid-system';
import { Button } from 'components/atoms/button';
import { Card, CardBody } from 'components/atoms/card';
import HeaderAction from 'components/atoms/HeaderAction';
import Alert from 'components/atoms/Alert';
import { H3 } from 'components/atoms/text';
import Margin from 'components/atoms/Margin';
import KeyValue from 'components/atoms/KeyValue';
import Group from 'components/molecules/Group';
import { useHeader } from 'contexts/headerContext';
import { FormGroup, FormikFormControl, FormikFormImage, FormikFormSelect, FormikFormYesNo } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import { EditProfileInputModel, GetProfileModel } from 'hooks/profile/types';
import useProfile from 'hooks/profile/useProfile';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/button/Button/styles';
import { EditUserInfoRequestModel } from 'hooks/edituserinforequest/types';
import useEditUserInfoRequests from 'hooks/edituserinforequest/useEditUserInfoRequest';
import { useSession } from 'contexts/sessionContext';
import { EditUserInfoRequestStatus } from 'utils/constants';
import moment from 'moment';
import { toFormikErrors } from 'utils/errorhelper';
import { cdnFileUrl } from 'utils/cdnHelper';
import UserImage from 'components/atoms/UserImage';
import { Right } from 'utils/constants/rightconstants';
import { getLegalFormDisplay, legelFormOptions } from 'utils/constants/legalformconstants';
import { getPaymentMethodDisplay, paymentMethodOptions } from 'utils/constants/paymentmethodconstants';
import { getGenderDisplay, genderOptions } from 'utils/constants/personconstants';

const Profile: FC<RouteComponentProps> = () => {

    const { session } = useSession();
    const { edit, getProfile } = useProfile();
    const { getEditUserInfoRequests } = useEditUserInfoRequests();

    const [isLoading, setIsLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [profile, setProfile] = useState<GetProfileModel>();
    const { setHeaderProfile, setHeaderTitle } = useHeader();
    const [editUserInfoRequest, setEditUserInfoRequest] = useState<EditUserInfoRequestModel>();

    const loadProfile = async () => {
        const response = await getProfile();

        if (response.ok && response.data) {
            setProfile(response.data);
            setHeaderProfile(response.data);
        } else {
            toast.error('Oeps, er ging iets fout bij het ophalen van jouw gegevens');
        }
    };

    const loadEditUserInfoRequest = async () => {
        const response = await getEditUserInfoRequests({ userId: session?.userId, status: EditUserInfoRequestStatus.Requested });

        if (response.ok && response.data) {
            setEditUserInfoRequest(response.data[0]);
        } else {
            toast.error('Oeps, er ging iets fout bij het ophalen van jouw gegevens');
        }
    };

    useEffect(() => {
        (async () => {
            const profileCall = loadProfile();
            const requestCall = loadEditUserInfoRequest();

            await Promise.all([profileCall, requestCall]);

            setIsLoading(false);
        })();
        setHeaderTitle('Mijn gegevens');
    }, []);

    const handleSubmit = async (values: EditProfileInputModel, { setErrors, setSubmitting }: FormikHelpers<EditProfileInputModel>) => {
        const response = await edit(values);

        if (response.ok) {
            toast.success('Het wijzigingsversoek is verzonden');
            loadProfile();
            loadEditUserInfoRequest();
            setEditMode(false);
        } else if (response.errors) {
            const errors = toFormikErrors<EditProfileInputModel>(response.errors);
            toast.error('Oeps, er ontbreken wat gegevens. Vul ze in en probeer het opnieuw!');
            setErrors(errors);
        } else {
            toast.error('Oeps, er ging iets fout tijdens het versturen van het wijzigingsverzoek. Probeer het later opnieuw!');
        }

        setSubmitting(false);
    };

    const initialValues: EditProfileInputModel = useMemo(() => ({
        gender: editUserInfoRequest?.gender ?? profile?.gender,
        initials: editUserInfoRequest?.initials ?? profile?.initials ?? '',
        firstName: editUserInfoRequest?.firstName ?? profile?.firstName ?? '',
        lastName: editUserInfoRequest?.lastName ?? profile?.lastName ?? '',
        insertion: editUserInfoRequest?.insertion ?? profile?.insertion ?? '',
        avatar: profile?.avatar != null ? cdnFileUrl(profile?.avatar.hash) : undefined,
        function: editUserInfoRequest?.function ?? profile?.function ?? '',
        phoneNumber: editUserInfoRequest?.phoneNumber ?? profile?.phoneNumber ?? '',
        mobileNumber: editUserInfoRequest?.mobileNumber ?? profile?.mobileNumber,
        companyName: editUserInfoRequest?.companyName ?? profile?.customer.name ?? '',
        kvkNumber: editUserInfoRequest?.kvkNumber ?? profile?.customer.kvkNumber ?? '',
        legalForm: editUserInfoRequest?.legalForm ?? profile?.customer.legalForm,
        addressVisit: editUserInfoRequest?.addressVisit ?? profile?.customer.addressVisit ?? '',
        postalCodeVisit: editUserInfoRequest?.postalCodeVisit ?? profile?.customer.postalCodeVisit ?? '',
        cityVisit: editUserInfoRequest?.cityVisit ?? profile?.customer.cityVisit ?? '',
        isMailAddressDifferent: editUserInfoRequest?.isMailAddressDifferent ?? profile?.customer.isMailAddressDifferent ?? false,
        email: editUserInfoRequest?.email ?? profile?.email ?? '',
        emailInvoice: editUserInfoRequest?.emailInvoice ?? profile?.customer.emailInvoice ?? '',
        addressMail: editUserInfoRequest?.addressMail ?? profile?.customer.addressMail ?? '',
        postalCodeMail: editUserInfoRequest?.postalCodeMail ?? profile?.customer.postalCodeMail ?? '',
        cityMail: editUserInfoRequest?.cityMail ?? profile?.customer.cityMail ?? '',
        bankAccount: editUserInfoRequest?.bankAccount ?? profile?.customer.bankAccount ?? '',
        paymentMethod: editUserInfoRequest?.paymentMethod ?? profile?.customer.paymentMethod,
        website: editUserInfoRequest?.website ?? profile?.customer.website ?? ''
    }), [profile, editUserInfoRequest]);

    const userInfoItems = useMemo(() => ([
        {
            key: 'Aanhef',
            value: editUserInfoRequest?.gender != null ? getGenderDisplay(editUserInfoRequest.gender) : profile?.gender != null ? getGenderDisplay(profile.gender) : 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.gender !== profile?.gender,
            include: true
        },
        {
            key: 'Voorletters',
            value: editUserInfoRequest?.initials ?? profile?.initials ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.initials !== profile?.initials,
            include: true
        },
        {
            key: 'Voornaam',
            value: editUserInfoRequest?.firstName ?? profile?.firstName ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.firstName !== profile?.firstName,
            include: true
        },
        {
            key: 'Tussenvoegsel',
            value: editUserInfoRequest?.insertion ?? profile?.insertion ?? '',
            required: editUserInfoRequest != null && editUserInfoRequest.insertion !== profile?.insertion,
            include: true
        },
        {
            key: 'Achternaam',
            value: editUserInfoRequest?.lastName ?? profile?.lastName ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.lastName !== profile?.lastName,
            include: true
        },
        {
            key: 'Functie',
            value: editUserInfoRequest?.function ?? profile?.function ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.function !== profile?.function,
            include: true
        },
        {
            key: 'E-mailadres',
            value: editUserInfoRequest?.email ?? profile?.email ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.email !== profile?.email,
            include: true
        },
        {
            key: 'Telefoonnummer',
            value: editUserInfoRequest?.phoneNumber ?? profile?.phoneNumber ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.phoneNumber !== profile?.phoneNumber,
            include: true
        },
        {
            key: 'Mobiel telefoonnummer',
            value: editUserInfoRequest?.mobileNumber ?? profile?.mobileNumber ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.mobileNumber !== profile?.mobileNumber,
            include: true
        }
    ]), [editUserInfoRequest, profile]);

    const customerInfoItems = useMemo(() => ([
        {
            key: 'Bedrijfsnaam',
            value: profile?.customer.name ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.companyName !== profile?.customer.name,
            include: true
        },
        {
            key: 'KvK-nummer',
            value: profile?.customer.kvkNumber ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.kvkNumber !== profile?.customer.kvkNumber,
            include: true
        },
        {
            key: 'Rechtsvorm',
            value: profile?.customer.legalForm != null ? getLegalFormDisplay(profile.customer.legalForm) : 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.legalForm !== profile?.customer.legalForm,
            include: true
        },
        {
            key: 'Bezoekadres',
            value: profile?.customer.addressVisit ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.addressVisit !== profile?.customer.addressVisit,
            include: true
        },
        {
            key: 'Postcode',
            value: profile?.customer.postalCodeVisit ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.postalCodeVisit !== profile?.customer.postalCodeVisit,
            include: true
        },
        {
            key: 'Plaats',
            value: profile?.customer.cityVisit ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.cityVisit !== profile?.customer.cityVisit,
            include: true
        },
        {
            key: 'Afwijkend postadres',
            value: profile?.customer.isMailAddressDifferent ? 'Ja' : 'Nee',
            required: editUserInfoRequest != null && editUserInfoRequest.isMailAddressDifferent !== profile?.customer.isMailAddressDifferent,
            include: true
        },
        {
            key: 'Postadres',
            value: profile?.customer.addressMail ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.addressMail !== profile?.customer.addressMail,
            include: profile?.customer.isMailAddressDifferent
        },
        {
            key: 'Postadres postcode',
            value: profile?.customer.postalCodeMail ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.postalCodeMail !== profile?.customer.postalCodeMail,
            include: profile?.customer.isMailAddressDifferent
        },
        {
            key: 'Postadres plaats',
            value: profile?.customer.cityMail ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.cityMail !== profile?.customer.cityMail,
            include: profile?.customer.isMailAddressDifferent
        },
        {
            key: 'E-mailadres voor facturatie',
            value: editUserInfoRequest?.emailInvoice ?? profile?.customer.emailInvoice ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.emailInvoice !== profile?.customer.emailInvoice,
            include: true
        },
        {
            key: 'Website',
            value: editUserInfoRequest?.website ?? profile?.customer.website ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.website !== profile?.customer.website,
            include: true
        }
    ]), [profile, editUserInfoRequest]);

    const paymentItems = useMemo(() => ([
        {
            key: 'Rekeningnummer',
            value: profile?.customer.bankAccount ?? 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.bankAccount !== profile?.customer.bankAccount,
            include: true
        },
        {
            key: 'Betaalwijze',
            value: profile?.customer.paymentMethod != null ? getPaymentMethodDisplay(profile.customer.paymentMethod) : 'Onbekend',
            required: editUserInfoRequest != null && editUserInfoRequest.paymentMethod !== profile?.customer.paymentMethod,
            include: true
        }
    ]), [profile, editUserInfoRequest]);

    const modulesInfoItems = useMemo(() => ([
        {
            key: 'Incassomodule Nederland',
            value: profile?.rights.some((ur) => ur.name === Right.Collection) ? 'Actief' : 'Inactief',
            include: true
        },
        {
            key: 'Incassomodule Buitenland',
            value: profile?.rights.some((ur) => ur.name === Right.CollectionAbroad) ? 'Actief' : 'Inactief',
            include: true
        },
        {
            key: 'Module Rechtshulp',
            value: profile?.rights.some((ur) => ur.name === Right.LegalAid) ? 'Actief' : 'Inactief',
            include: true
        },
        {
            key: 'Algemene voorwaarden',
            value: profile?.rights.some((ur) => ur.name === Right.GeneralConditions) ? 'Actief' : 'Inactief',
            include: true
        }
    ]), [profile]);

    return isLoading ? <Loading /> : (
        <>
            <Card>
                <CardBody>
                    <H3>Mijn profiel</H3>
                    {!editMode ? (
                        <>
                            <Margin top={1} bottom={2}>
                                <UserImage value={profile?.avatar != null ? cdnFileUrl(profile?.avatar.hash) : undefined} />
                            </Margin>
                            <HeaderAction
                                title="Persoonsgegevens"
                                action={
                                    {
                                        title: 'Wijzigen',
                                        onClick: () => setEditMode(!editMode)
                                    }
                                }
                            />
                            <KeyValue
                                items={userInfoItems}
                            />
                            <Margin top={2}>
                                <HeaderAction title="Bedrijfsgegevens" />

                                <KeyValue
                                    items={customerInfoItems}
                                />
                            </Margin>
                            {
                                editUserInfoRequest != null &&
                                <Margin top={1}>
                                    <Alert text={`Uw verzoek tot het wijzigen van uw gegevens is door ons ontvangen op ${moment(editUserInfoRequest.created).format('DD-MM-YYYY HH:mm')}. Let op: uw wijzigingen zijn pas zichtbaar nadat deze door ons zijn verwerkt.`} />
                                </Margin>
                            }
                        </>
                    ) : (
                        <>
                            <Formik<EditProfileInputModel> initialValues={initialValues} onSubmit={handleSubmit}>
                                {({ isSubmitting, values }) => (
                                    <Form>
                                        <Margin top={1} bottom={2}>
                                            <FormikFormImage name="avatar" />
                                        </Margin>

                                        <Card variant="light">
                                            <CardBody>
                                                <HeaderAction title="Persoonsgegevens" />
                                                <Row>
                                                    <Col sm={4} xs={6}>
                                                        <FormGroup label="Aanhef" required>
                                                            <FormikFormSelect name="gender" options={genderOptions} placeholder="Kies een aanhef" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3} xs={6}>
                                                        <FormGroup label="Initialen">
                                                            <FormikFormControl name="initials" placeholder="Initialen" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={5} xs={12}>
                                                        <FormGroup label="Voornaam" required>
                                                            <FormikFormControl name="firstName" placeholder="Voornaam" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={4} xs={12}>
                                                        <FormGroup label="Tussenvoegsel">
                                                            <FormikFormControl name="insertion" placeholder="Tussenvoegsel" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={8} xs={12}>
                                                        <FormGroup label="Achternaam" required>
                                                            <FormikFormControl name="lastName" placeholder="Achternaam" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <FormGroup label="Functie" required>
                                                            <FormikFormControl name="function" placeholder="Functie" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <FormGroup label="E-mailadres" required>
                                                            <FormikFormControl name="email" placeholder="E-mailadres" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <FormGroup label="Telefoonnummer">
                                                            <FormikFormControl name="phoneNumber" placeholder="Telefoonnummer" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <FormGroup label="Mobiele telefoonnummer" required>
                                                            <FormikFormControl name="mobileNumber" placeholder="Mobiele telefoonnummer" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Margin top={2}>
                                                    <HeaderAction title="Bedrijfsgegevens" />

                                                    <Row>
                                                        <Col xs={6}>
                                                            <FormGroup label="Bedrijfsnaam" required>
                                                                <FormikFormControl name="companyName" placeholder="Bedrijfsnaam" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup label="KvK-nummer" required>
                                                                <FormikFormControl name="kvkNumber" placeholder="KvK-nummer" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup label="Rechtsvorm" required>
                                                                <FormikFormSelect name="legalForm" options={legelFormOptions} placeholder="Kies een rechtsvorm" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup label="Bezoekadres" required>
                                                                <FormikFormControl name="addressVisit" placeholder="Bezoekadres" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup label="Postcode" required>
                                                                <FormikFormControl name="postalCodeVisit" placeholder="Postcode" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup label="Plaats" required>
                                                                <FormikFormControl name="cityVisit" placeholder="Plaats" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <FormGroup>
                                                                <FormikFormYesNo label="Postadres wijkt af van bezoekadres" name="isMailAddressDifferent" />
                                                            </FormGroup>
                                                        </Col>
                                                        {values.isMailAddressDifferent &&
                                                            <>
                                                                <Col xs={6}>
                                                                    <FormGroup label="Postadres">
                                                                        <FormikFormControl name="addressMail" placeholder="Postadres" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <FormGroup label="Postcode">
                                                                        <FormikFormControl name="postalCodeMail" placeholder="Postcode" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <FormGroup label="Plaats">
                                                                        <FormikFormControl name="cityMail" placeholder="Plaats" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </>
                                                        }
                                                        <Col xs={12}>
                                                            <FormGroup label="E-mailadres voor facturatie" required>
                                                                <FormikFormControl name="emailInvoice" placeholder="E-mailadres voor facturatie" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <FormGroup label="Website" required>
                                                                <FormikFormControl name="website" placeholder="Website" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Margin>
                                                <Margin top={2}>
                                                    <HeaderAction title="Betaalgegevens" />
                                                    <Row>
                                                        <Col xs={6}>
                                                            <FormGroup label="Rekeningnummer" required>
                                                                <FormikFormControl name="bankAccount" placeholder="Rekeningnummer" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup label="Betaalwijze" required>
                                                                <FormikFormSelect name="paymentMethod" options={paymentMethodOptions} placeholder="Kies een betaalwijze" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Margin>
                                                <Group right>
                                                    <Button variant="light" type="button" onClick={() => setEditMode(false)}>Annuleren</Button>
                                                    <Button type="submit" loading={isSubmitting}>Wijziging indienen</Button>
                                                </Group>
                                            </CardBody>
                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                    <Margin top={2}>
                        <HeaderAction title="Betaalgegevens" tooltip="Wilt u uw rekeningnummer of betaalwijze wijzigen? Neem dan contact op met onze Financiële administratie via administratie@juresta.nl" />

                        <KeyValue
                            items={paymentItems}
                        />
                    </Margin>
                    <Margin top={2}>
                        <HeaderAction title="Modules" />

                        <KeyValue
                            items={modulesInfoItems}
                        />
                    </Margin>
                </CardBody>
            </Card>
        </>
    );
};

export default Profile;
