import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface CardHeaderProps {
    borderBottom?: boolean;
    withBody?: boolean;
    isDark?: boolean;
    flexDirection?: 'column' | 'row';
}

const CardHeader = styled.div<CardHeaderProps>`
    padding-bottom: 24px;
    display: flex;
    align-items: ${({ flexDirection }) => (flexDirection === 'row' || !flexDirection ? 'center' : 'flex-start')};
    flex-direction: ${({ flexDirection }) => flexDirection ?? 'row'};
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};

    h3 {
        margin-bottom: 0;
        overflow-wrap: break-word;
        hyphens: auto;
    }

    > * {
        margin-bottom: 0 !important;
    }

    ${({ theme, borderBottom }) => borderBottom && css``}

    ${({ isDark, theme }) =>
        isDark &&
        css`
            background-color: ${theme.colors.primary};
        `}

    ${({ withBody }) =>
        withBody &&
        css`
            padding: 24px;
        `}
`;

export default CardHeader;
