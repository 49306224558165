import { useApi } from 'hooks/api';
import { EditProfileInputModel, GetProfileModel } from './types';
import { appendObject } from 'utils/objectAppender';

const useProfile = () => {
    const { get, put } = useApi();

    const getProfile = async () => {
        const response = await get<GetProfileModel>('/profile');

        return response;
    };

    const edit = async (values: EditProfileInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await put('/profile', fd);

        return response;
    };

    return {
        edit,
        getProfile
    };
};

export default useProfile;
