import { useMutation, useQueryClient } from 'react-query';
import { CustomResponse, useApi } from 'hooks/api';
import { correspondenceTemplateQueryKey } from 'hooks/correspondenceTemplate/useAllCorrespondenceTemplates';
import { AddCorrespondenceTemplateInputModel, CorrespondenceTemplateMutationContext, CorrespondenceTemplatePlainModel } from 'hooks/correspondenceTemplate/types';

const useAddAdminCorrespondenceTemplate = () => {

    const { post } = useApi();

    const baseUrl = '/correspondencetemplate';

    const queryClient = useQueryClient();
    
    const createCorrespondenceTemplate = async (values: AddCorrespondenceTemplateInputModel) => {

        const response = await post(
            baseUrl, values
        );

        if(!response.ok) {
            throw response;
        }

        return response;
    };

    const addCorrespondenceTemplateMutation = useMutation<CustomResponse<unknown>, CustomResponse<unknown>, AddCorrespondenceTemplateInputModel, CorrespondenceTemplateMutationContext>((values: AddCorrespondenceTemplateInputModel) => createCorrespondenceTemplate(values), {

        onMutate: async (values: AddCorrespondenceTemplateInputModel) => {
            
            await queryClient.cancelQueries(correspondenceTemplateQueryKey);

            const previousData = queryClient.getQueryData<CorrespondenceTemplatePlainModel[]>(correspondenceTemplateQueryKey);
            
            const useAddAdminCorrespondenceTemplate: CorrespondenceTemplatePlainModel = {
                id: '',
                subject: values.subject,
                name: values.name,
                content: values.content,
                created: '',
                changed: ''
            };

            queryClient.setQueryData<CorrespondenceTemplatePlainModel[]>(correspondenceTemplateQueryKey,
                    (previousData) => [
                        useAddAdminCorrespondenceTemplate,
                        ...(previousData ?? [])
                    ]
            );

            return { previousData };
        },
        onError: (error, variables, context) => {
            if(context?.previousData) {
                queryClient.setQueryData<CorrespondenceTemplatePlainModel[]>(correspondenceTemplateQueryKey, context.previousData);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(correspondenceTemplateQueryKey);
        }

    });

    return { addCorrespondenceTemplateMutation };
};

export default useAddAdminCorrespondenceTemplate;
