import { useApi } from 'hooks/api';
import { CustomError } from 'hooks/api/types';
import { useQuery } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { buildUriWithParams } from 'utils/urihelper';
import { AddKnowledgeBaseItemInputModel, EditKnowledgeBaseItemInputModel, GetKnowledgeBaseItemInputModel, KnowledgeBaseItemModel } from './types';

const getKnowledgeBaseItemsQueryKey = 'knowledgebaseitem.get';

const useKnowledgeBaseItems = () => {

    const { get, post, put, deleteCall } = useApi();

    const baseUrl = '/knowledgebaseitem';

    const getAllKnowledgeBaseItem = async (params?: GetKnowledgeBaseItemInputModel) => {
        const url = buildUriWithParams(baseUrl, params);

        const response = await get<KnowledgeBaseItemModel[]>(url);

        return response;
    };

    const useAllKnowledgeBaseItems = (params?: GetKnowledgeBaseItemInputModel) => {
        const queryKey = [getKnowledgeBaseItemsQueryKey, params];

        return useQuery<KnowledgeBaseItemModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllKnowledgeBaseItem(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const addKnowledgeBaseItem = async (values: AddKnowledgeBaseItemInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(baseUrl, fd);

        return response;
    };

    const editKnowledgeBaseItem = async (values: EditKnowledgeBaseItemInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await put(baseUrl, fd);

        return response;
    };

    const deleteKnowledgeBaseItem = async (id: string) => {
        const response = await deleteCall(`${baseUrl}/${id}`);

        return response;
    };

    return {
        getAllKnowledgeBaseItem,
        useAllKnowledgeBaseItems,
        addKnowledgeBaseItem,
        editKnowledgeBaseItem,
        deleteKnowledgeBaseItem
    };
};

export default useKnowledgeBaseItems;
