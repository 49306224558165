export interface AddAgreementInputModel {
    customerId?: string;
    date: Date;
    version: string;
    files?: File[];
}

export interface AgreementPlainModel {
    id: string;
    customerRelationNumber: string;
    customerName: string;
    customerId: string;
    fileName: string;
    date: string;
    version: string;
}

export interface GetAgreementInputModel {
    Id?: string;
    customerId?: string;
}

export interface SendAgreementMailInputModel {
    toEmail?: string;
}

export interface SendAgreementTaskMailInputModel {
    file?: File;
    comments?: string;
    type: AgreementType;
    language?: string;
}

export enum AgreementType {
    Default = 0,
    Update = 1,
    Translation = 2,
    Review = 3,
    MaintenanceRequest = 4
}
