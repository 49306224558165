import { useTheme } from '@emotion/react';
import React, { FC } from 'react';
import { ActiveIcon, Wrapper } from './styles';

interface ActiveInactiveProps {
    isActive?: boolean;
    style?: React.CSSProperties;
}

const ActiveInactive: FC<ActiveInactiveProps> = ({ isActive, style }) => {
    const title = isActive ? 'Actief' : 'Inactief';
    const theme = useTheme();

    return (
        <Wrapper isActive={!!isActive} style={style}>
            {isActive && <ActiveIcon name="check" size={1} color={isActive ? theme.colors.green : theme.colors.grey} />}
            {title}
        </Wrapper>
    );
};

export default ActiveInactive;
