import { useMatch } from '@reach/router';
import React, { FC } from 'react';
import { MenuItem as Wrapper, Title, Content } from './styles';
import Submenu from '../../molecules/Submenu';
import { MenuItemProps } from '../MenuItem';

export interface SubmenuItemProps extends MenuItemProps { }

const MenuItem: FC<SubmenuItemProps> = ({ to, title, items }) => {
    const match = useMatch(to);

    // Render.
    return (
        <>
            <Wrapper to={to} active={!!match}>
                <Content active={!!match}>
                    <Title active={!!match}>{title}</Title>
                </Content>
            </Wrapper>
            {items && <Submenu items={items} />}
        </>
    );
};

export default MenuItem;
