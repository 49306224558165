import { useMutation, useQueryClient } from 'react-query';
import { CustomResponse, useApi } from 'hooks/api';
import { correspondenceQueryKey } from 'hooks/correspondence/useAllCorrespondence';
import { AddCorrespondenceInputModel, CorrespondenceMutationContext, CorrespondencePlainModel } from 'hooks/correspondence/types';
import { appendObject } from 'utils/objectAppender';
import { CustomerPlainModel } from 'hooks/customer/types';
import { allCustomersQueryKey } from 'hooks/customer/useAllCustomers';


const useAddAdminCorrespondence = () => {

    const { post } = useApi();

    const baseUrl = '/correspondence';

    const queryClient = useQueryClient();

    const createCorrespondence = async (values: AddCorrespondenceInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(
            baseUrl, fd
        );

        if (!response.ok) {
            throw response;
        }

        return response;
    };

    const addCorrespondenceMutation = useMutation<CustomResponse<unknown>, CustomResponse<unknown>, AddCorrespondenceInputModel, CorrespondenceMutationContext>((values: AddCorrespondenceInputModel) => createCorrespondence(values), {

        onMutate: async (values: AddCorrespondenceInputModel) => {

            await queryClient.cancelQueries(correspondenceQueryKey);

            const customersData = queryClient.getQueryData<CustomerPlainModel[]>(allCustomersQueryKey);
            const previousData = queryClient.getQueryData<CorrespondencePlainModel[]>(correspondenceQueryKey);

            const customers = customersData?.filter((c) => values.customerIds.some((id) => id === c.id)) ?? [];

            const useAddAdminCorrespondence: CorrespondencePlainModel = {
                id: '',
                subject: values.subject,
                content: values.content,
                customers: customers,
                files: values.files,
                created: new Date().toISOString(),
                changed: new Date().toISOString(),
                isQuestionnaire: values.isQuestionnaire,
                link: values.link
            };

            queryClient.setQueryData<CorrespondencePlainModel[]>(correspondenceQueryKey,
                (previousData) => [
                    ...(previousData ?? []),
                    useAddAdminCorrespondence
                ]
            );

            return { previousData };
        },
        onError: (error, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData<CorrespondencePlainModel[]>(correspondenceQueryKey, context.previousData);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(correspondenceQueryKey);
        }

    });

    return { addCorrespondenceMutation };
};

export default useAddAdminCorrespondence;
