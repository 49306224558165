import { useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { buildUriWithParams } from 'utils/urihelper';
import { GetTermsAndConditionsInputModel, TermsAndConditionsPlainModel } from './types';

export const allTermsAndConditionsKey = 'termsandconditions.all';


export default function useAllTermsAndConditions(params?: GetTermsAndConditionsInputModel) {

    const { get } = useApi();

    const getAllTermsAndConditions = async (params?: GetTermsAndConditionsInputModel) => {
        const url = buildUriWithParams('/termsandconditions', params);

        const { data } = await get<TermsAndConditionsPlainModel[]>(url);

        return data;
    };

    return useQuery<TermsAndConditionsPlainModel[] | undefined>(allTermsAndConditionsKey, () => getAllTermsAndConditions(params));
}
