import React from 'react';
import { SessionModel } from 'hooks/api/types';
import { useSession } from 'contexts/sessionContext';
import { navigate } from '@reach/router';
import { ForgotPasswordInputModel, LoginInputModel, ResetPasswordInputModel } from './types';
import { useApi } from 'hooks/api';

export interface VerifyTfaCodeInputModel {
    'code': string;
    'rememberVerification': boolean;
}

const useAccount = () => {
    const { setSession, clearSession } = useSession();
    const { post, put } = useApi();

    const login = async (values: LoginInputModel) => {
        const response = await post<SessionModel>('/account/cookielogin', values);
        return response;
    };

    const loginWithUsername = async (username: string) => {
        const response = await post<SessionModel>(`/account/cookieloginwithusername/${username}`);

        if (response.ok && response.data) {
            const session = response.data;
            setSession(session.claims);
            navigate('/');
        }
    };

    const logOut = async () => {
        const response = await post('/account/cookielogout');

        if (!response.ok) {
            return Promise.reject();
        }

        clearSession();
    };

    const resendTfaCode = async () => {
        const response = put('/account/cookieResendTfaCode');

        return response;

    };

    const verifyTfaCode = React.useCallback(async (values: VerifyTfaCodeInputModel, redirectUrl?: string) => {

        const response = await post<SessionModel>('/account/cookieVerifyTfaCode', values);

        if (response.ok && response.data) {
            const session = response.data;
            setSession(session.claims);
            if (redirectUrl) {
                navigate(redirectUrl);
            } else {
                navigate('/');
            }
        }

        return response;

    }, [setSession]);

    const forgotPassword = async (values: ForgotPasswordInputModel) => {
        const response = await post('/account/forgotpassword', values);

        return response;
    };

    const resetPassword = async (values: ResetPasswordInputModel) => {
        const response = await put('/account/resetpassword', values);

        return response;
    };

    return {
        login,
        loginWithUsername,
        logOut,
        resendTfaCode,
        verifyTfaCode,
        forgotPassword,
        resetPassword
    };
};

export default useAccount;
