import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/atoms/button';
import { Card, CardBody } from 'components/atoms/card';
import { FormGroup, FormikFormFile, FormikFormSelect } from 'components/atoms/form';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import { Form, Formik, FormikHelpers } from 'formik';
import { AddTemplateLetterInputModel, EditTemplateLetterInputModel, TemplateLetterInputModel, TemplateLetterPlainModel } from 'hooks/templateLetter/types';
import useAddAdminTemplateLetter from 'hooks/templateLetter/useAddAdminTemplateLetter';
import useEditAdminTemplateLetter from 'hooks/templateLetter/useEditAdminTemplateLetter';
import React, { FC, useMemo } from 'react';
import Group from 'components/molecules/Group';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorhelper';
import { useHeader } from 'contexts/headerContext';
import { useLanguage } from 'hooks/language';
import { Language } from 'hooks/language/types';
import usePurgeTemplateLetterCache from 'hooks/templateLetter/usePurgeTemplateLetterCache';

export interface ManageTemplateProps extends RouteComponentProps<{
    location: {
        state: {
            item: TemplateLetterPlainModel
        }
    }
}> {
}

const ManageTemplate: FC<ManageTemplateProps> = ({ location }: ManageTemplateProps) => {
    useHeader('Voorbeeldbrieven');

    const { createTemplateLetter } = useAddAdminTemplateLetter();
    const { editTemplateLetter } = useEditAdminTemplateLetter();
    const { purgeTemplateLetterCache } = usePurgeTemplateLetterCache();
    const { useAllLanguages } = useLanguage();
    const { data: languages = [] } = useAllLanguages();

    const languageOptions = useMemo(() => languages.map(({ language, name }) => ({ label: name, value: language })), [languages]);

    const initialValues: TemplateLetterInputModel = {
        id: location?.state?.item ? location.state.item.id : '',
        name: location?.state?.item ? location.state.item.name : '',
        file: null,
        language: location?.state?.item ? location.state.item.language : Language.Nederlands,
    };

    const handleSubmit = (values: TemplateLetterInputModel | EditTemplateLetterInputModel, { setErrors, setSubmitting }: FormikHelpers<TemplateLetterInputModel | EditTemplateLetterInputModel>) => {
        setSubmitting(true);

        if (values.id === '') {
            createTemplateLetter(values)
                .then(() => {
                    navigate('/admin/templateletters');
                    toast.success(`'${values.name}' succesvol opgeslagen`);
                }).catch((error) => {
                    if (error.errors) {
                        const errors = toFormikErrors<EditTemplateLetterInputModel>(error.errors);
                        setErrors(errors);
                        toast.error('Niet alle velden zijn ingevuld');
                    } else {
                        toast.error('Oeps, er ging iets fout tijdens het aanmaken van de voorbeeldbrief. Probeer het later opnieuw!');
                    }
                });
        } else {
            editTemplateLetter(values)
                .then(() => {
                    navigate('/admin/templateletters');
                    toast.success(`'${values.name}' succesvol opgeslagen`);
                }).catch((error) => {
                    if (error.errors) {
                        const errors = toFormikErrors<AddTemplateLetterInputModel>(error.errors);
                        setErrors(errors);
                        toast.error('Niet alle velden zijn ingevuld');
                    } else {
                        toast.error('Oeps, er ging iets fout tijdens het aanpassen van de voorbeeldbrief. Probeer het later opnieuw!');
                    }
                });
        }

        setSubmitting(false);
        purgeTemplateLetterCache();
    };

    return (
        <Card>
            <CardBody>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form style={{ paddingTop: '2em' }}>
                            <FormGroup label="Brief naam" required>
                                <FormControlFormik name="name" placeholder="Brief naam" />
                            </FormGroup>
                            <FormGroup label="Taal" required>
                                <FormikFormSelect name="language" options={languageOptions} placeholder="Kies een taal" />
                            </FormGroup>
                            <FormGroup label="Word-Bestand" required={initialValues.id === ''}>
                                <FormikFormFile accept=".doc, .docx" name="file" />
                                {
                                    initialValues.id !== '' &&
                                    <small>Het huidige bestand wordt overschreven als u een nieuw bestand upload. Als u geen bestand upload blijft het orginele bestand behouden.</small>
                                }
                            </FormGroup>
                            <Group spaceBetween>
                                <Button type="button" onClick={() => navigate('/admin/templateletters')} size="lg">Terug</Button>
                                <Button type="submit" size="lg" loading={isSubmitting}>Opslaan</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default ManageTemplate;
