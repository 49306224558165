import React, { FC, useMemo } from 'react';
import { H1 as Text } from './styles';
import { Colors } from 'theme/colors';
import { useTheme } from '@emotion/react';

export interface H1Props {
    variant?: keyof Colors;
    grey?: number;
    center?: boolean;
    className?: string;
}

const H1: FC<H1Props> = ({ children, variant, grey, center, className }) => {
    const theme = useTheme();
    const color = useMemo(() => (variant ? theme.colors[variant] : grey ? theme.greys[grey] : theme.colors.primary), [variant, grey]);

    return (
        <Text color={color} center={center} className={className}>
            {children}
        </Text>
    );
};

export default H1;
