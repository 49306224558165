import { useMatch } from '@reach/router';
import React, { FC, useState } from 'react';
import { Bullet, MenuItem as Wrapper, Notifications, Title, TitleIcon } from './styles';
import Submenu from '../../molecules/Submenu';
import { Collapse } from 'react-collapse';

export interface MenuItemProps {
    notifications?: number;
    to: string;
    title?: string;
    items?: MenuItemProps[];
    onClick?: () => void;
    active?: boolean;
}

const MenuItem: FC<MenuItemProps> = ({ notifications, to, title, items }) => {
    const match = useMatch(to);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Render.
    return (
        <>
            <Wrapper to={to} active={!!match} onClick={() => setIsOpen(!isOpen)}>
                <Bullet active={!!match} />
                <Title active={!!match}>
                    {title} {items && <TitleIcon isOpen={isOpen} name="collapse" size={1} />}
                </Title>

                {notifications && <Notifications>{notifications}</Notifications>}
            </Wrapper>

            {items && (
                <Collapse isOpened={isOpen}>
                    <Submenu items={items} />
                </Collapse>
            )}
        </>
    );
};

export default MenuItem;
