import { useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { CorrespondencePlainModel, GetCorrespondenceModel } from 'hooks/correspondence/types';
import { buildUriWithParams } from 'utils/urihelper';

export const correspondenceQueryKey = 'LetterKey';

export default function useAllCorrespondences(model?: GetCorrespondenceModel) {

    const { get } = useApi();

    const getAllCorrespondences = async (model?: GetCorrespondenceModel) => {
        const url = buildUriWithParams('/correspondence', model);

        const { data } = await get<CorrespondencePlainModel[]>(url);

        return data;
    };

    return useQuery<CorrespondencePlainModel[] | undefined>(correspondenceQueryKey, async () => {
        return await getAllCorrespondences(model);
    });
}
