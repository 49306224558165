import React, { FC } from 'react';
import { Date, Wrapper, Title } from './styles';

interface DateTitleCellProps {
    date?: Date;
    title: string;
}

const DateTitleCell: FC<DateTitleCellProps> = ({ date, title }) => {
    const formattedDate = date?.toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll('-', '.');
    return (
        <Wrapper>
            <Date>{date ? formattedDate : ''}</Date>
            <Title>{title}</Title>
        </Wrapper>
    );
};

export default DateTitleCell;
