import { useApi } from 'hooks/api';
import { AddTemplateLetterInputModel } from './types';
import { appendObject } from 'utils/objectAppender';

const useAddAdminTemplateLetter = () => {

    const { post } = useApi();

    const baseUrl = '/templateletter';

    const createTemplateLetter = async (values: AddTemplateLetterInputModel) => {

        const fd = new FormData();
        appendObject(values, fd);

        const response = await post(
            baseUrl, fd
        );

        if (!response.ok) {
            throw response;
        }

        return response;
    };

    return { createTemplateLetter };
};

export default useAddAdminTemplateLetter;
