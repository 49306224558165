import React, { FC, useMemo } from 'react';
import { H4 as Text, Wrapper, H4Icon as Icon } from './styles';
import { Colors } from 'theme/colors';
import { useTheme } from '@emotion/react';

export interface H4Props {
    variant?: keyof Colors;
    grey?: number;
    center?: boolean;
    className?: string;
    icon?: string;
}

const H4: FC<H4Props> = ({ children, variant, grey, center, className, icon }) => {
    const theme = useTheme();
    const color = useMemo(() => (variant ? theme.colors[variant] : grey ? theme.greys[grey] : theme.colors.primary), [variant, grey]);

    return (
        <Wrapper>
            {icon && <Icon name={icon} color={color} />}
            <Text color={color} center={center} className={className}>
                {children}
            </Text>
        </Wrapper>
    );
};

export default H4;
