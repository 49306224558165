import DropdownItem, { DropdownItemProps } from 'components/atoms/item/DropdownItem';
import NestedDropdownItem, { NestedDropdownItemProps } from 'components/atoms/item/NestedDropdownItem';
import React, { forwardRef, PropsWithChildren } from 'react';
import { DropdownMenu as Wrapper } from './styles';

export interface DropdownMenuProps {
    items: ((close?: () => void) => DropdownItemProps[]) | DropdownItemProps[];
    onSubItemClick?: (value: string) => void;
    access?: boolean;
    close?: () => void;
    style?: React.CSSProperties;
}

const DropdownMenu = forwardRef<HTMLUListElement, PropsWithChildren<DropdownMenuProps>>(
    ({ items: initialItems, children, onSubItemClick, close, style, ...props }, ref) => {
        // Render.
        const items = (typeof initialItems === 'function' ? initialItems(close) : initialItems).filter((item) => item.access !== false);
        return (
            <Wrapper ref={ref} {...props} style={{ ...style }}>
                {items.map((item) => {
                    const isNested = item.items ? item.items.length > 0 : false;

                    if (isNested) {
                        return (
                            <NestedDropdownItem
                                key={item.title}
                                {...(item as NestedDropdownItemProps)}
                                onSubItemClick={onSubItemClick}
                                onSubSubItemClick={item.onSubItemClick}
                            />
                        );
                    } else {
                        return <DropdownItem key={item.title} {...item} onSubItemClick={onSubItemClick} />;
                    }
                })}
                {children}
            </Wrapper>
        );
    }
);

export default DropdownMenu;
