import React, { FC } from 'react';
import Icon from '../Icon';
import { Wrapper } from './styles';
import { useTheme } from '@emotion/react';

export interface AddFieldButtonProps {
    title: string;
    tag: string;
    onClick?: (tag: string) => void;
}

const AddFieldButton: FC<AddFieldButtonProps> = ({ title, onClick, tag }) => {
    const theme = useTheme();

    const handleOnClick = () => {
        if (onClick) {
            onClick(tag);
        }
    };

    return (
        <Wrapper onClick={handleOnClick} type="button">
            {title}
            <Icon name="plus" size={1} color={theme.colors.primary} />
        </Wrapper>
    );
};

export default AddFieldButton;
