import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';

export const H5 = styled.h5<{ color: string; }>`
    color: ${({ color }) => color};
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
`;

export const Wrapper = styled.div<{ center?: boolean }>`
    display: flex;
    margin-bottom: 24px;
    justify-content: ${({ center }) => (center ? 'space-around' : 'flex-start')};
`;

export const H3Icon = styled(Icon)`
    margin-right: 8px;
`;
