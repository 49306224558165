import { CorrespondenceTemplatePlainModel, DeleteCorrespondenceTemplateInputModel } from 'hooks/correspondenceTemplate/types';
import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'hooks/api';
import { correspondenceTemplateQueryKey } from 'hooks/correspondenceTemplate/useAllCorrespondenceTemplates';

const useDeleteAdminCorrespondenceTemplate = () => {

    const { deleteCall } = useApi();

    const baseUrl = '/correspondencetemplate';

    const queryClient = useQueryClient();

    const deleteCorrespondenceTemplate = async (values: DeleteCorrespondenceTemplateInputModel) => {

        const response = await deleteCall(`${baseUrl}/${values.id}`);

        if(!response.ok) {
            throw response;
        }

        return response;
    };


    const deleteCorrespondenceTemplateMutation = useMutation((values: DeleteCorrespondenceTemplateInputModel) => deleteCorrespondenceTemplate(values), {

        onMutate: async (values: DeleteCorrespondenceTemplateInputModel) => {
            
            await queryClient.cancelQueries(correspondenceTemplateQueryKey);

            const previousData = queryClient.getQueryData<CorrespondenceTemplatePlainModel[]>(correspondenceTemplateQueryKey);

            const filteredData = previousData?.filter(item => item.id !== values.id);
            queryClient.setQueryData<CorrespondenceTemplatePlainModel[]>(correspondenceTemplateQueryKey, filteredData ?? []);

            return { previousData };
        },
        onError: (err, variables, context) => {
            if(context?.previousData) {
                queryClient.setQueryData(correspondenceTemplateQueryKey, context.previousData);
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries(correspondenceTemplateQueryKey);
        }

    });

    return { deleteCorrespondenceTemplateMutation };
};

export default useDeleteAdminCorrespondenceTemplate;
