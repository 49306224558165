import React, { FC } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import InvalidFeedback from '../InvalidFeedback';

export interface WysiwygEditorProps {
    error?: string;
    value?: string;
    onEditorChange?: (a: string, editor: TinyMCEEditor) => void;
    disabled?: boolean;
    onInit?: (a: any, editor: TinyMCEEditor) => void;
}

const WysiwygEditor: FC<WysiwygEditorProps> = ({ error, ...props }) => {

    return (
        <>
            <Editor
                {...props}
                initialValue={undefined}
                scriptLoading={{ async: true }}
                apiKey="g2ynapvk9iho0bs0krvwzua6q7bsq0uqnbld4he5sby9aje0"
                init={{
                    plugins: [
                        'print lists fullscreen preview autoresize'
                    ]
                }}
            />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );

};

export default WysiwygEditor;
