export enum ProspectModuleSignRequest {
    Collections, // Incasso
    CollectionsForeign, // Incasso buitenland 
    LegalAid, // Rechtshulp
    TermsAndConditions // Algemene voorwaarden
}

export const getProspectModuleSignRequestDisplay = (type: ProspectModuleSignRequest): string => {
    switch (type) {
        case ProspectModuleSignRequest.Collections:
            return 'Incasso Nederland';
        case ProspectModuleSignRequest.CollectionsForeign:
            return 'Incasso buitenland';
        case ProspectModuleSignRequest.LegalAid:
            return 'Rechtshulp';
        case ProspectModuleSignRequest.TermsAndConditions:
            return 'Algemene voorwaarden';
    }
};
