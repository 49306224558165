import { useApi } from 'hooks/api';
import { CustomError } from 'hooks/api/types';
import { useQuery } from 'react-query';
import { buildUriWithParams } from 'utils/urihelper';
import { GetUserInputModel, UserModel } from './types';

const getUsersQueryKey = 'user.get';

const useUsers = () => {
    const { get, deleteCall } = useApi();

    const baseUrl = '/user';

    const getAllUser = async (params?: GetUserInputModel) => {
        const url = buildUriWithParams(baseUrl, params);
        const response = await get<UserModel[]>(url);
        return response;
    };
    const useAllUsers = (params?: GetUserInputModel) => {
        const queryKey = [getUsersQueryKey, params];
        return useQuery<UserModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllUser(params);
            if (!response.ok && response.errors != null) {
                throw response.errors;
            }
            return response.data || [];
        });
    };
    const deleteUser = async (id: string) => {
        const response = await deleteCall(`${baseUrl}/${id}`);
        return response;
    };

    return {
        getAllUser,
        useAllUsers,
        deleteUser
    };
};

export default useUsers;
