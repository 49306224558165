import React, { FC } from 'react';
import { Tab as Wrapper, Title } from './styles';

export interface TabProps {
    title: string;
    active?: boolean;
    onClick?: () => void;
    to: string;
}

const Tab: FC<TabProps> = ({ title, active, onClick, to }) => {
    return (
        <Wrapper to={to} active={active} onClick={onClick}>
            <Title active={active}>{title}</Title>
        </Wrapper>
    );
};

export default Tab;
