export interface MaintenanceContractPlainModel {
    id: string;
    date: string;
    version: string;
    fileName: string;
    customerId: string;
    customerName: string;
    customerRelationNumber: string;
}

export interface AddMaintenanceContractInputModel {
    customerId: string;
    date: Date;
    version: string;
    file?: File;
    type: MaintenanceContractType;
}

export interface SendMaintenanceContractMailInputModel {
    toEmail: string;
}

export interface GetMaintenanceContractInputModel {
    id?: string;
    customerId?: string;
}

export enum MaintenanceContractType {
    BranchOriented = 0,
    CustomWork = 1
}
