import styled from '@emotion/styled';

export const H1 = styled.h1<{ color: string; center?: boolean }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 2.875rem;
    line-height: 2.875rem;
    font-weight: 500;
    margin-bottom: 24px;
    position: relative;
`;
