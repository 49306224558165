import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { AddSignedCollectionOverviewInputModel, CollectionPlainModel, EditCollectionInputModel } from 'hooks/collection/types';
import { Column } from 'react-table';
import useAllCollections from 'hooks/collection/useAllCollections';
import { debtorFullName, numberToEuroString } from 'utils/stringHelpers';
import { useHeader } from 'contexts/headerContext';
import useCollection from 'hooks/collection/useCollection';
import { toast } from 'react-toastify';
import { Button } from 'components/atoms/button';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormControl, FormikFormFile, FormikFormSelect } from 'components/atoms/form';
import Group from 'components/molecules/Group';
import { toFormikErrors } from 'utils/errorhelper';
import { CollectionStatus, collectionStatusOptions, getCollectionStatusDisplay } from 'utils/constants/collectionconstants';
import { format, parseISO } from 'date-fns';
import FormCurrencyFormik from 'components/atoms/form/FormCurrency/formik';

interface ManageCollectionsProps extends RouteComponentProps {

}

const ManageCollections: FC<ManageCollectionsProps> = () => {
    useHeader('Incasso\'s Nederland');

    const { data: collections = [], isLoading, refetch: refetchCollections } = useAllCollections();
    const { downloadCollectionFile, uploadSignedCollectionOverview, editCollection, deleteCollection } = useCollection();

    const [collectionOverviewUpload, setCollectionOverviewUpload] = useState<CollectionPlainModel>();
    const [collectionToEdit, setCollectionToEdit] = useState<CollectionPlainModel>();
    const [collectionToDelete, setCollectionToDelete] = useState<CollectionPlainModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const columns: Column<CollectionPlainModel>[] = [
        {
            id: 'caseNumber',
            Header: 'Dossiernummer',
            accessor: (collection) => collection.caseNumber ?? ''
        },
        {
            id: 'customerName',
            Header: 'Relatie',
            accessor: (collection) => collection.customerName
        },
        {
            id: 'debtorName',
            Header: 'Debiteur',
            accessor: (collection) => collection.debtor?.companyName ?? debtorFullName(collection.debtor?.contactPersonInitials, collection.debtor?.contactPersonLastName)
        },
        {
            Header: 'Incassobedrag',
            accessor: (collection) => numberToEuroString(collection.amount)
        },
        {
            Header: 'Status',
            accessor: (collection) => getCollectionStatusDisplay(collection?.status ?? CollectionStatus.InProgress)
        },
        {
            id: 'created',
            Header: 'Datum',
            accessor: (collection) => parseISO(collection.created).getTime(),
            Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
        }
    ];

    const downloadOverviewFile = async (collection: CollectionPlainModel) => {
        if (collection.overviewFile == null) {
            toast.error('Deze incasso heeft nog geen ondertekend overzicht');
            return;
        }

        const response = await downloadCollectionFile(collection.id, collection.overviewFile.id);

        if (response.ok) {
            saveAs(await response.blob(), collection.overviewFile.fileName);
        } else {
            toast.error('Oops, er ging iets mis bij het ophalen van het bestand');
        }
    };

    const initialValuesAddSignedCollectionOverview: AddSignedCollectionOverviewInputModel = {
        overviewFile: undefined
    };

    const [showUploadOverviewModal, hideUploadOverviewModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={closeUploadOverviewModal} size="large" title="Ondertekend incasso overzicht uploaden" subtitle="Voeg het bestand toe om het ondertekend incasso overzicht te uploaden">
                <Formik<AddSignedCollectionOverviewInputModel> initialValues={initialValuesAddSignedCollectionOverview} onSubmit={uploadSignedOverview}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <FormGroup label="Ondertekend incasso overzicht" required>
                                <FormikFormFile name="overviewFile" placeholder="Ondertekend incasso overzicht" />
                            </FormGroup>
                            <Group right>
                                <Button type="button" onClick={closeUploadOverviewModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Versturen</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [collectionOverviewUpload]
    );

    const openUploadOverviewModal = (collection: CollectionPlainModel) => {
        setCollectionOverviewUpload(collection);
        showUploadOverviewModal();
    };

    const closeUploadOverviewModal = () => {
        setCollectionOverviewUpload(undefined);
        hideUploadOverviewModal();
    };

    const uploadSignedOverview = async (values: AddSignedCollectionOverviewInputModel, { setErrors, resetForm }: FormikHelpers<AddSignedCollectionOverviewInputModel>) => {
        if (collectionOverviewUpload == null) {
            return;
        }

        const response = await uploadSignedCollectionOverview(collectionOverviewUpload.id, values);

        if (response.ok) {
            refetchCollections();
            toast.success('Bestand is succesvol geüpload');
            closeUploadOverviewModal();
            resetForm();
        } else if (response.errors) {
            const errors = toFormikErrors<AddSignedCollectionOverviewInputModel>(response.errors);
            setErrors(errors);
            toast.error('Niet alle velden zijn ingevuld');
        } else {
            toast.error('Oops, er ging iets mis bij het uploaden van het bestand');
        }
    };

    const initialEditCollectionValues: EditCollectionInputModel = {
        id: collectionToEdit?.id ?? '',
        amount: collectionToEdit?.amount ?? 0,
        caseNumber: collectionToEdit?.caseNumber ?? null,
        status: collectionToEdit?.status ?? CollectionStatus.InProgress
    };

    const onSumbitEditCollection = async (
        model: EditCollectionInputModel,
        { setErrors, setSubmitting }: FormikHelpers<EditCollectionInputModel>
    ) => {
        setSubmitting(true);
        const response = await editCollection(model);

        if (response.ok) {
            refetchCollections();
            hideEditCollectionModal();
            toast.success('Incasso succesvol opgeslagen');
        } else if (response.errors) {
            const errors = toFormikErrors<EditCollectionInputModel>(response.errors);
            setErrors(errors);
            toast.error('Niet alle velden zijn ingevuld');
        } else {
            toast.error('Oeps, er ging iets fout tijdens het aanpassen van de incasso. Probeer het later opnieuw!');
        }
        setSubmitting(false);
    };

    const [showEditCollectionModal, hideEditCollectionModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={closeEditCollectionModal} size="small" title="Incasso bewerken">
                <Formik<EditCollectionInputModel> initialValues={initialEditCollectionValues} onSubmit={onSumbitEditCollection}>
                    {({ isSubmitting, isValid, setFieldValue }) => (
                        <Form>
                            <FormGroup label="Openstaand bedrag voor hoofdsom" tooltip="Bedrag inclusief btw" required>
                                <FormCurrencyFormik name="amount" onValueChange={(value) => setFieldValue('amount', value)} />
                            </FormGroup>
                            <FormGroup label="Dossiernummer">
                                <FormikFormControl name="caseNumber" />
                            </FormGroup>
                            <FormGroup label="Status" required>
                                <FormikFormSelect name="status" options={collectionStatusOptions} />
                            </FormGroup>
                            <Group right>
                                <Button type="button" onClick={closeEditCollectionModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Opslaan</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [collectionToEdit]
    );

    const openEditCollectionModal = (collection: CollectionPlainModel) => {
        setCollectionToEdit(collection);
        showEditCollectionModal();
    };

    const closeEditCollectionModal = () => {
        setCollectionToEdit(undefined);
        hideEditCollectionModal();
    }; 
    
    const handleDelete = async () => {
        if (!collectionToDelete) {
            return;
        }

        setIsLoadingDelete(true);

        const response = await deleteCollection(collectionToDelete.id);

        if (response.ok) {
            refetchCollections();
            toast.success('Incasso is succesvol verwijderd');
            closeDeleteModal();
        } else {
            toast.error('Er ging iets fout bij het verwijderen van de incasso');
        }

        setIsLoadingDelete(false);
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="small" title="Incasso verwijderen" subtitle="Weet je zeker dat je deze incasso wilt verwijderen?">
                <Group right>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete} variant="error">Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [collectionToDelete, isLoadingDelete]
    );

    const openDeleteModal = (collection: CollectionPlainModel) => {
        setCollectionToDelete(collection);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setCollectionToDelete(undefined);
        hideDeleteModal();
    };

    return (
        <Card>
            <CardBody>
                <Table<CollectionPlainModel>
                    isLoading={isLoading}
                    data={collections}
                    columns={columns}
                    noDataMessage="Geen incasso's beschikbaar"
                    search
                    sortBy={[
                        { id: 'caseNumber', desc: true },
                    ]}
                    actions={[
                        {
                            icon: 'edit',
                            onClick: openEditCollectionModal,
                            title: 'Bewerken'
                        },
                        {
                            icon: 'download',
                            onClick: downloadOverviewFile,
                            title: 'Download ondertekend overzicht',
                            active: (item) => item.overviewFile != null
                        },
                        {
                            icon: 'plus',
                            title: 'Ondertekend overzicht toevoegen',
                            onClick: openUploadOverviewModal
                        },
                        {
                            icon: 'delete',
                            onClick: openDeleteModal,
                            title: 'Verwijderen'
                        },
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default ManageCollections;
