export enum Language {
    Nederlands = 0,
    Engels = 1,
    Duits = 2,
    Frans = 3
};

export interface LanguageModel {
    language: Language;
    name: string;
}