import { Button } from 'components/atoms/button';
import { DropdownItemProps } from 'components/atoms/item/DropdownItem';
import React, { FC, useCallback, useRef, useState } from 'react';
import { Dropdown as Wrapper, Header, Toggle } from './styles';
import { useLayer, Arrow } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { DropdownMenu } from 'components/molecules/dropdown';

export interface DropdownProps {
    items: ((close?: () => void) => DropdownItemProps[]) | DropdownItemProps[];
    onSubItemClick?: (value: string) => void;
    hideArrow?: boolean;
}

const Dropdown: FC<DropdownProps> = ({ children, items, onSubItemClick, hideArrow = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(document.createElement('div'));
    // Dropdown's methods.
    const toggle = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        setIsOpen((open) => !open);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    // Laag.
    const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
        isOpen,
        onOutsideClick: close,
        onDisappear: close,
        overflowContainer: false,
        placement: 'bottom-start', // we prefer to place the menu "bottom-start"
        // possiblePlacements: ['bottom-start', 'bottom-end'],
        auto: true,
        triggerOffset: 8,
        containerOffset: 16,
        arrowOffset: 16
    });

    // Render.
    return (
        <Wrapper ref={ref}>
            <Header {...triggerProps} onClick={toggle}>
                {typeof children === 'string' ? <Button>{children}</Button> : children}
                {!hideArrow && <Toggle name="chevron-down" />}
            </Header>
            {renderLayer(
                <AnimatePresence>
                    {isOpen && (
                        <DropdownMenu close={close} items={items} {...layerProps} ref={layerProps.ref} onSubItemClick={onSubItemClick} style={{ ...layerProps.style, width: ref.current.offsetWidth }}>
                            <Arrow {...arrowProps} />
                        </DropdownMenu>
                    )}
                </AnimatePresence>
            )}
        </Wrapper>
    );
};

export default Dropdown;
