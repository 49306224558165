import React, { FC } from 'react';
import { Step as Wrapper, Number, Content, Title, Subtitle } from './styles';

export interface StepProps {
    step: number;
    active?: boolean;
    optional?: boolean;
    title: string;
    onClick?: () => void;
    disabled?: boolean;
    completed?: boolean;
}

const Step: FC<StepProps> = ({ step, optional, active, title, onClick, disabled = false, completed = false }) => {
    // Step's methods.
    const handleOnClick = () => {
        onClick && !disabled && onClick();
    };

    // Render.
    return (
        <Wrapper onClick={handleOnClick} disabled={disabled}>
            <Number active={active} disabled={disabled} completed={completed}>
                {step}
            </Number>
            <Content>
                <Title active={active}>{title}</Title>
                {optional && <Subtitle>Optioneel</Subtitle>}
            </Content>
        </Wrapper>
    );
};

export default Step;
