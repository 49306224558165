import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { readableColor, lighten, rgba, darken } from 'polished';
import { ButtonProps } from './';
import Icon from 'components/atoms/Icon';

const lightenAmount = {
    hover: 0.05
};

export const Button = styled.button<ButtonProps>`
    border: none;
    height: 2.5rem;
    padding: 0 ${({ rounded }) => (rounded ? 24 : 16)}px;
    border-radius: ${({ theme, rounded }) => (rounded ? '1.25rem' : `${theme.borderRadius.small}px`)};
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    line-height: 1;
    align-items: center;
    align-self: flex-start;
    width: ${({ full }) => (full ? '100%' : 'auto')};
    cursor: pointer;
    ${({ theme, border }) => border && `border: 2px solid ${theme.colors.primary};`}

    ${({ theme, variant, disabled }) => {
        const color = theme.colors[variant || 'primary'];
        const isBorder = variant === 'border';
        const isWhite = color === theme.colors.white;

        return css`
            color: ${disabled || variant === 'grey' ? theme.colors.white : readableColor(color)};
            background-color: ${disabled ? theme.colors.grey : color};
            border: ${isBorder ? `3px solid ${theme.colors.primary}` : ''};

            &:focus {
                outline: none;
                box-shadow: 0 0 0 0.2rem ${rgba(color, 0.5)};
            }

            &:hover {
                background-color: ${disabled ? theme.colors.grey : isWhite ? darken(lightenAmount.hover, color) : lighten(lightenAmount.hover, color)};
            }
        `;
    }}

    ${({ size, loading, startIcon, endIcon }) =>
        size === 'lg' &&
        css`
            font-size: 1rem;
            height: 3rem;
            padding: 0 25px 0 ${!(startIcon || endIcon) && loading ? 12 : 25}px;
        `}

        ${({ size, rounded }) =>
        size === 'lg' &&
        rounded &&
        css`
            border-radius: 1.75rem;
        `}

        ${({ alignSelf }) =>
        alignSelf &&
        css`
            align-self: ${alignSelf};
        `}
`;

export const Loading = styled.div<{
    color?: string;
    icon?: boolean;
}>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 1rem;
    height: 1rem;
    margin-right: ${({ icon }) => (icon ? 'calc(16px + 0.25rem)' : '12px')};
    margin-left: ${({ icon }) => (icon ? 0.25 : 0)}rem;
    border: 2px solid ${({ color }) => color};
    animation: rotate 1s linear infinite;
    border-left-color: transparent;
    border-radius: 50%;
`;

export const StartIcon = styled(Icon) <{ noMargin?: boolean }>`
    margin-right: ${({ noMargin }) => (noMargin ? 0 : '16px')};
`;

export const EndIcon = styled(Icon) <{ noMargin?: boolean }>`
    margin-left: ${({ noMargin }) => (noMargin ? 0 : '16px')};
`;
