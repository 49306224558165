import React, { FC, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import { KnowledgeBaseItemModel } from 'hooks/knowledgebaseitem/types';
import useKnowledgeBaseItems from 'hooks/knowledgebaseitem';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

interface KnowledgeBaseItemProps extends RouteComponentProps { }

const columns: Column<KnowledgeBaseItemModel>[] = [
    {
        Header: 'Titel',
        accessor: (item) => item.title
    },
    {
        width: 70,
        Header: 'Categorie',
        accessor: (item) => item.category?.name ?? '-'
    },
    {
        width: 60,
        Header: 'Publicatie datum',
        accessor: (item) => parseISO(item.publicationDate).getTime(),
        Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
    },
    {
        width: 60,
        id: 'created',
        Header: 'Aangemaakt op',
        accessor: (item) => item.created != null ? parseISO(item.created).getTime() : null,
        Cell: ({ value }: any) => value != null ? format(value, 'dd-MM-yyyy') : '-'
    }
];

const KnowledgeBaseItem: FC<KnowledgeBaseItemProps> = () => {
    useHeader('Kennisbank');

    const { useAllKnowledgeBaseItems, deleteKnowledgeBaseItem } = useKnowledgeBaseItems();
    const { data: knowledgeBaseItem = [], isLoading: isLoadingKnowledgeBaseItems, refetch } = useAllKnowledgeBaseItems();

    const [knowledgeBaseItemToDelete, setKnowledgeBaseItemToDelete] = useState<KnowledgeBaseItemModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const openDeleteModal = (item: KnowledgeBaseItemModel) => {
        setKnowledgeBaseItemToDelete(item);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setKnowledgeBaseItemToDelete(undefined);
        hideDeleteModal();
    };

    const handleDelete = async () => {
        if (knowledgeBaseItemToDelete == null) {
            return;
        }

        setIsLoadingDelete(true);

        const response = await deleteKnowledgeBaseItem(knowledgeBaseItemToDelete.id);

        if (response.ok) {
            refetch();
            toast.success('Item succesvol verwijderd!');
            closeDeleteModal();
        } else {
            toast.error('Oeps, er ging iets fout tijdens het verwijderen van het item. Probeer het later opnieuw!');
        }

        setIsLoadingDelete(false);
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal
                inProp={inProp}
                onExited={onExited}
                onHide={hideDeleteModal}
                size="large"
                title="Kennisbank item verwijderen"
                subtitle="Weet je zeker dat je dit item wilt verwijderen"
            >
                <Group spaceBetween>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>
                        Verwijderen
                    </Button>
                </Group>
            </Modal>
        ),
        [knowledgeBaseItemToDelete]
    );
    return (
        <Card>
            <CardBody>
                <Table<KnowledgeBaseItemModel>
                    isLoading={isLoadingKnowledgeBaseItems}
                    data={knowledgeBaseItem}
                    noDataMessage="Kennisbank is leeg"
                    columns={columns}
                    search
                    onAddClick={() => navigate('/admin/knowledgebase/add')}
                    onSettingsClick={() => navigate('/admin/knowledgebase/settings')}
                    sortBy={[
                        { id: 'created', desc: true }
                    ]}
                    actions={[
                        {
                            icon: 'edit',
                            onClick: (item) => navigate('/admin/knowledgebase/add', { state: { item: item } })
                        },
                        {
                            icon: 'delete',
                            onClick: (item) => openDeleteModal(item)
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default KnowledgeBaseItem;
