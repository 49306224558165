export enum LegalForm {
    Proprietorship, // Eenmanszaak
    Partnership, // Vennootschap (vof)
    LimitedPartnerschip, // Commanditaire vennootschap
    PrivateCompany, // Besloten vennootschap (BV)
    LimitedLiabilityCompany, // Naamloze vennootschap
    Union, // Vereniging
    Foundation, // Stichting
    Unknown
}

export const legelFormOptions = [
    {
        value: LegalForm.Proprietorship,
        label: 'Eenmanszaak'
    },
    {
        value: LegalForm.Partnership,
        label: 'Vennootschap onder firma'
    },
    {
        value: LegalForm.LimitedPartnerschip,
        label: 'Commanditaire vennootschap'
    },
    {
        value: LegalForm.PrivateCompany,
        label: 'Besloten vennootschap'
    },
    {
        value: LegalForm.LimitedLiabilityCompany,
        label: 'Naamloze vennootschap'
    },
    {
        value: LegalForm.Union,
        label: 'Vereniging'
    },
    {
        value: LegalForm.Foundation,
        label: 'Stichting'
    },
    {
        value: LegalForm.Unknown,
        label: 'Onbekend'
    }
];

export const getLegalFormDisplay = (legalForm: LegalForm): string => {
    switch (legalForm) {
        case LegalForm.Proprietorship:
            return 'Eenmanszaak';
        case LegalForm.Partnership:
            return 'Vennootschap onder firma';
        case LegalForm.LimitedPartnerschip:
            return 'Commanditaire vennootschap';
        case LegalForm.PrivateCompany:
            return 'Besloten vennootschap';
        case LegalForm.LimitedLiabilityCompany:
            return 'Naamloze vennootschap';
        case LegalForm.Union:
            return 'Vereniging';
        case LegalForm.Foundation:
            return 'Stichting';
        case LegalForm.Unknown:
            return 'Onbekend';
    }
};
