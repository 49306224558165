import styled from '@emotion/styled';

export const Wrapper = styled.button`
    display: inline-flex;
    align-items: center;
    outline: 0;
    background: none;
    padding: 0;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: .85rem 1.25rem;
    line-height: 1;
    font-weight: 500;
    font-size: .9rem;
    cursor: pointer;
    border-radius: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
    .icon-wrapper {
        margin-left: 1rem;
    }
`;