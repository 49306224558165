import React, { FC, useMemo } from 'react';
import { Paragraph as Text } from './styles';
import { Colors } from 'theme/colors';
import { useTheme } from '@emotion/react';

export interface ParagraphProps {
    variant?: keyof Colors;
    grey?: number;
    center?: boolean;
    noMargin?: boolean;
    className?: string;
    lines?: number;
}

const Paragraph: FC<ParagraphProps> = ({ children, variant, grey, center, noMargin, className, lines }) => {
    const theme = useTheme();
    const color = useMemo(() => (variant ? theme.colors[variant] : grey ? theme.greys[grey] : theme.colors.dark), [variant, grey]);

    return (
        <Text color={color} center={center} className={className} lines={lines} noMargin={noMargin}>
            {children}
        </Text>
    );
};

export default Paragraph;
