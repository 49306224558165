import React, { FC } from 'react';
import { Header, Inner } from './styles';
import { H1 } from 'components/atoms/text';
import { useHeader } from 'contexts/headerContext';

export interface DefaultProps {
}

const Default: FC<DefaultProps> = ({ children }) => {
    const { headerTitle } = useHeader();

    return (
        <Header>
            <Inner>
                <H1>{headerTitle}</H1>
                {children}
            </Inner>
        </Header>
    );
};

export default Default;
