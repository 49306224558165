import React, { forwardRef, PropsWithChildren } from 'react';
import { DropdownIcon, DropdownItem as Wrapper, Title, NestedIcon } from './styles';
import { navigate } from '@reach/router';
import { useTheme } from '@emotion/react';

export interface DropdownItemProps {
    value?: string;
    title: string;
    onClick?: () => void;
    to?: string;
    icon?: string;
    items?: DropdownItemProps[];
    onSubItemClick?: (value: string) => void;
    access?: boolean;
    nested?: boolean;
}

const DropdownItem = forwardRef<HTMLLIElement, PropsWithChildren<DropdownItemProps>>(
    ({ value, title, onClick, to, icon, children, onSubItemClick, nested, ...props }, ref) => {
        const theme = useTheme();

        // DropdownItem's methods.
        const handleOnClick = (event: React.MouseEvent) => {
            if (to) {
                navigate(to);
            } else if (onClick) {
                onClick();
            }

            if (value && onSubItemClick) {
                onSubItemClick(value);
            }
        };

        // Render.
        return (
            <Wrapper onClick={handleOnClick} {...props} ref={ref}>
                {icon && <DropdownIcon name={icon} color={theme.greys[300]} />}
                <Title>{title}</Title>
                {nested && <NestedIcon name="chevron-right" color={theme.greys[100]} />}
                {children}
            </Wrapper>
        );
    }
);

export default DropdownItem;
