import { CorrespondenceTemplatePlainModel, EditCorrespondenceTemplateInputModel, CorrespondenceTemplateMutationContext } from 'hooks/correspondenceTemplate/types';
import { useMutation, useQueryClient } from 'react-query';
import { CustomResponse, useApi } from 'hooks/api';
import { correspondenceTemplateQueryKey } from 'hooks/correspondenceTemplate/useAllCorrespondenceTemplates';

const useEditAdminCorrespondenceTemplate = () => {

    const { put } = useApi();

    const baseUrl = '/correspondencetemplate';

    const queryClient = useQueryClient();

    const editCorrespondenceTemplate = async (values: EditCorrespondenceTemplateInputModel) => {

        const response = await put(
            baseUrl, values
        );

        if (!response.ok) {
            throw response;
        }

        return response;
    };


    const editCorrespondenceTemplateMutation = useMutation<CustomResponse<unknown>, CustomResponse<unknown>, EditCorrespondenceTemplateInputModel, CorrespondenceTemplateMutationContext>((values: EditCorrespondenceTemplateInputModel) => editCorrespondenceTemplate(values), {

        onMutate: async (values: EditCorrespondenceTemplateInputModel) => {
            
            await queryClient.cancelQueries(correspondenceTemplateQueryKey);

            const previousData = queryClient.getQueryData<CorrespondenceTemplatePlainModel[]>(correspondenceTemplateQueryKey);
            const newData = [...previousData ?? []];

            const templateToEdit = newData.find(item => item.id === values.id);

            if(templateToEdit != null){
                templateToEdit.name = values.name;
                templateToEdit.content = values.name;
            }

            queryClient.setQueryData<CorrespondenceTemplatePlainModel[]>(correspondenceTemplateQueryKey, newData);

            return { previousData };
        },
        onError: (error, variables, context) => {
            if(context?.previousData) {
                queryClient.setQueryData<CorrespondenceTemplatePlainModel[]>(correspondenceTemplateQueryKey, context.previousData);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(correspondenceTemplateQueryKey);
        }

    });

    return { editCorrespondenceTemplateMutation };
};

export default useEditAdminCorrespondenceTemplate;
