import { BannerPlainModel, DeleteBannerInputModel } from 'hooks/banner/types';
import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'hooks/api';
import { adminBannersQueryKey } from './useAllBanners';

const useDeleteAdminBanner = () => {

    const { deleteCall } = useApi();

    const baseUrl = '/banner';

    const queryClient = useQueryClient();

    const deleteBanner = async (values: DeleteBannerInputModel) => {

        const response = await deleteCall(`${baseUrl}/${values.id}`);

        if (!response.ok) {
            throw response;
        }

        return response;
    };


    const deleteBannerMutation = useMutation((values: DeleteBannerInputModel) => deleteBanner(values), {

        onMutate: async (values: DeleteBannerInputModel) => {

            await queryClient.cancelQueries(adminBannersQueryKey);

            const previousData = queryClient.getQueryData<BannerPlainModel[]>(adminBannersQueryKey);

            const filteredData = previousData?.filter(item => item.id !== values.id);
            queryClient.setQueryData<BannerPlainModel[]>(adminBannersQueryKey, filteredData ?? []);

            return { previousData };
        },
        onError: (err, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData(adminBannersQueryKey, context.previousData);
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries(adminBannersQueryKey);
        }

    });

    return { deleteBannerMutation };
};

export default useDeleteAdminBanner;
