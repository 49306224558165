import styled from '@emotion/styled';

export const H1 = styled.h1<{ color: string; center?: boolean }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    position: relative;
`;
