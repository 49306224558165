import React, { FC } from 'react';
import { Card, CardBody } from 'components/atoms/card';
import { FormGroup, FormRadio } from 'components/atoms/form';
import Margin from 'components/atoms/Margin';
import { Paragraph, H3 } from 'components/atoms/text';
import Group from 'components/molecules/Group';
import { useFormikContext } from 'formik';
import { AddCollectionInputModel } from 'hooks/collection/types';
import HeaderAction from 'components/atoms/HeaderAction';

export interface Step1Props {
}

const Step1: FC<Step1Props> = () => {

    const { setFieldValue, values } = useFormikContext<AddCollectionInputModel>();

    // Render.
    const title = values.debtor.isCompany ? 'Incasso-opdracht indienen' : 'Incasso-opdracht indienen';
    //const date = new Date();
    //const time = date.getHours();

    const setCompany = (value: boolean) => {
        setFieldValue('debtor.isCompany', value);

        if (!value) {
            setFieldValue('debtor.companyName', '');
            setFieldValue('debtor.kvkNumber', '');
            setFieldValue('debtor.legalForm', undefined);
        } else {
            setFieldValue('debtor.dateOfBirth', undefined);
        }
    };

    return (
        <>
            <Card fill variant="primary">
                <CardBody>
                    <H3 icon="about" variant="white">
                        {title}
                    </H3>
                    <Paragraph variant="white">
                        In dit deel kunt u eenvoudig uw incasso-opdracht indienen. Het invullen van het formulier duurt ongeveer 5 – 10 minuten. Voordat u begint adviseren wij u de relevante documenten te verzamelen (of beschikbaar te hebben om direct bij te kunnen voegen). Denkt u dan aan uw factuur, herinnering, aanmaning e.d. In het formulier is er op meerdere plekken ruimte om een toelichting te geven.
                    </Paragraph>
                    <Paragraph variant="white">
                        Kiest u hieronder allereerst of uw debiteur een consument of een bedrijf betreft.
                        Twijfelt u over de aard van uw debiteur? Kies dan voor de optie bedrijf.
                    </Paragraph>
                </CardBody>
            </Card>
            <Card fill>
                <CardBody>
                    {/* <Margin bottom={1.5}>
                        <H3>Incasso-opdracht indienen</H3>
                        <Paragraph>
                            {
                                time < 12 ? (
                                    <>Goedemorgen,</>
                                ) : time < 18 ? (
                                    <>Goedemiddag,</>
                                ) : (
                                    <>Goedeavond,</>
                                )
                            }
                        </Paragraph>
                        <Paragraph>
                            In dit deel kunt u eenvoudig uw incasso-opdracht indienen. Het invullen van het formulier duurt ongeveer 5 – 10 minuten. Voordat u begint adviseren wij u de relevante documenten te verzamelen (of beschikbaar te hebben om direct bij te kunnen voegen). Denkt u dan aan uw factuur, herinnering, aanmaning e.d. In het formulier is er op meerdere plekken ruimte om een toelichting te geven.
                        </Paragraph>
                        <Paragraph>
                            Kiest u hieronder allereerst of uw debiteur een consument of een bedrijf betreft.
                            Twijfelt u over de aard van uw debiteur? Kies dan voor de optie bedrijf.
                        </Paragraph>
                    </Margin> */}
                    <Margin bottom={1.5}>
                        <HeaderAction title="Uw debiteur" tooltip="Twijfelt u of uw debiteur een bedrijf of een consument is? Kies dan voor de optie 'bedrijf'." />                    
                    </Margin>
                    <Margin bottom={2}>
                        <Group spaceBetween>
                            <FormGroup noMarginHeader noMargin label="Mijn debiteur is een" />
                            <Group spaceBetween>
                                <FormRadio label="Bedrijf" checked={values.debtor.isCompany} onChange={() => setCompany(true)} />
                                <FormRadio label="Consument" checked={!values.debtor.isCompany} onChange={() => setCompany(false)} />
                            </Group>
                        </Group>
                    </Margin>
                </CardBody>
            </Card>
        </>
    );
};

export default Step1;
