import { useApi } from 'hooks/api';
import { CustomError } from 'hooks/api/types';
import { useQuery } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { buildUriWithParams } from 'utils/urihelper';
import { AddMaintenanceContractInputModel, GetMaintenanceContractInputModel, MaintenanceContractPlainModel, SendMaintenanceContractMailInputModel } from './types';

export const getMaintenanceContractsQueryKey = 'maintenancecontract.get';

const useMaintenanceContract = () => {

    const { get, post, deleteCall } = useApi();

    const baseUrl = '/maintenancecontract';

    const getAllMaintenanceContract = async (params?: GetMaintenanceContractInputModel) => {
        const url = buildUriWithParams(baseUrl, params);

        const response = await get<MaintenanceContractPlainModel[]>(url);

        return response;
    };

    const useAllMaintenanceContracts = (params?: GetMaintenanceContractInputModel) => {
        const queryKey = [getMaintenanceContractsQueryKey, params];

        return useQuery<MaintenanceContractPlainModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllMaintenanceContract(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const addMaintenanceContract = async (values: AddMaintenanceContractInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(baseUrl, fd);

        return response;
    };

    const deleteMaintenanceContract = async (id: string) => {
        const response = await deleteCall(`${baseUrl}/${id}`);

        return response;
    };

    const getMaintenanceContractFile = (maintenanceContractId: string) => {
        const response = get(`${baseUrl}/${maintenanceContractId}/file`);

        return response;
    };

    const sendMaintenanceContractMail = (id: string, values: SendMaintenanceContractMailInputModel) => {
        const response = post(`${baseUrl}/${id}/mail`, values);

        return response;
    };

    return {
        getAllMaintenanceContract,
        useAllMaintenanceContracts,
        addMaintenanceContract,
        deleteMaintenanceContract,
        getMaintenanceContractFile,
        sendMaintenanceContractMail
    };
};

export default useMaintenanceContract;
