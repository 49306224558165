import React from 'react';
import { Alert } from './styles';

interface AlertProps {
    text: string;
}

const AlertComponent = ({ text }: AlertProps) => {
    return (
        <Alert>{text}</Alert>
    );
};

export default AlertComponent;
