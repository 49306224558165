export const numberToEuroString = (value: number) => Intl.NumberFormat('nl', {
    style: 'currency',
    currency: 'eur'
}).format(value);


export const debtorFullName = (initials?: string, lastName?: string) => {
    if (initials == null && lastName == null) {
        return 'Onbekend';
    }

    const value = `${initials != null ? `${initials} ` : ''}${lastName}`;

    return value;
};
