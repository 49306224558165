import React, { FC, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import { Loading } from 'components/atoms/button/Button/styles';
import Margin from 'components/atoms/Margin';
import useAllCorrespondence from 'hooks/correspondence/useAllCorrespondence';
import { useHeader } from 'contexts/headerContext';
import { format, parseISO } from 'date-fns';
import { Button } from 'components/atoms/button';
import Icon from 'components/atoms/Icon';
import Group from 'components/molecules/Group';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { useSession } from 'contexts/sessionContext';
import moment from 'moment';
import htmr from 'htmr';

interface MessageDetailProps extends RouteComponentProps {
    id?: string;
}

const MessagesDetail: FC<MessageDetailProps> = ({ id = '' }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { session } = useSession();
    const { data: Correspondence = [] } = useAllCorrespondence({ id: id });

    const message = Correspondence[0];

    useHeader(message?.subject ? `Bericht - ${message.subject}` : 'Bericht');

    function parseVariables(text: string, date: Date) {
        // add name
        text = text.replace('{{name}}', session?.fullName ?? '');

        // add date
        text = text.replace('{{date}}', moment(date).format('DD-MM-YYYY'));

        // add mail
        text = text.replace('{{mail}}', session?.username ?? '');

        return text;
    }

    return message == null ? <Loading /> : (
        <>
            <Margin bottom={1}>
                <Group spaceBetween>
                    <Button onClick={() => window.history.back()}>Terug</Button>
                    <ReactToPrint content={() => ref.current}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button variant="white" alignSelf="flex-end" onClick={handlePrint}><Icon name="print" /></Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                </Group>
            </Margin>
            <Card>
                <CardBody ref={ref}>
                    <CardHeader flexDirection="column">
                        <H3>{message.subject}</H3>
                        <span>{format(parseISO(message.created), 'dd-MM-yyyy')}</span>
                    </CardHeader>
                    <Paragraph>
                        {htmr(parseVariables(message.content, parseISO(message.created)))}
                    </Paragraph>
                </CardBody>
            </Card>
        </>
    );
};

export default MessagesDetail;
