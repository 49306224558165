import Image from 'components/atoms/Image';
import React, { forwardRef } from 'react';
import { Visible } from 'react-grid-system';
import { Name, User as Wrapper } from './styles';
import { cdnFileUrl } from 'utils/cdnHelper';
import { useHeader } from 'contexts/headerContext';

interface UserProps {
    children: string;
}

const User = forwardRef<HTMLDivElement, UserProps>(({ children }, ref) => {
    const { headerProfile } = useHeader();

    return (
        <Wrapper ref={ref}>
            <Image image={headerProfile?.avatar != null ? cdnFileUrl(headerProfile?.avatar.hash) : undefined} placeholderLetter={children} round />
            <Visible lg xl xxl>
                <Name>{children}</Name>
            </Visible>
        </Wrapper>
    );
});

export default User;
