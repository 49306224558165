export enum Country {
    'België' = 'BE',
    'Duitsland' = 'DE',
    'Frankrijk' = 'FR',
    'Verenigd Koninkrijk' = 'GB',
    ' ' = '-',
    'Afghanistan' = 'AF',
    'Aland-eilanden' = 'AX',
    'Albanië' = 'AL',
    'Algerije' = 'DZ',
    'Amerikaans Samoa' = 'AS',
    'Amerikaanse Kleinere Afgelegen Eilanden' = 'UM',
    'Andorra' = 'AD',
    'Angola' = 'AO',
    'Anguilla' = 'AI',
    'Antarctica' = 'AQ',
    'Antigua en Barbuda' = 'AG',
    'Argentinië' = 'AR',
    'Armenië' = 'AM',
    'Aruba' = 'AW',
    'Australië' = 'AU',
    'Azerbeidzjan' = 'AZ',
    'Bahamas' = 'BS',
    'Bahrein' = 'BH',
    'Bangladesh' = 'BD',
    'Barbados' = 'BB',
    'Belize' = 'BZ',
    'Benin' = 'BJ',
    'Bermuda' = 'BM',
    'Bhutan' = 'BT',
    'Bolivia' = 'BO',
    'Bosnië-Herzegovina' = 'BA',
    'Botswana' = 'BW',
    'Bouvetisland' = 'BV',
    'Brazilië' = 'BR',
    'Brits-Indisch oceaan gebied' = 'IO',
    'Brunei Darussalam' = 'BN',
    'Bulgarije' = 'BG',
    'Burkinafaso' = 'BF',
    'Burundi' = 'BI',
    'Cambodja' = 'KH',
    'Canada' = 'CA',
    'Caribisch Nederland' = 'BQ',
    'Centraal Afrikaanse Republiek' = 'CF',
    'Chili' = 'CL',
    'China' = 'CN',
    'Cocoskeelingislands' = 'CC',
    'Colombia' = 'CO',
    'Comoros' = 'KM',
    'Congo' = 'CG',
    'Congodemocratedrepublic' = 'CD',
    'Cookislanden' = 'CK',
    'Costarica' = 'CR',
    'Cotedivoire' = 'CI',
    'Cuba' = 'CU',
    'Curacao' = 'CW',
    'Cyprus' = 'CY',
    'Denemarken' = 'DK',
    'Djibouti' = 'DJ',
    'Dominica' = 'DM',
    'Dominicaanse Republiek' = 'DO',
    'Ecuador' = 'EC',
    'Egypte' = 'EG',
    'Elsalvador' = 'SV',
    'Equatoriaal-Guinea' = 'GQ',
    'Eritrea' = 'ER',
    'Estland' = 'EE',
    'Ethiopië' = 'ET',
    'Falkland Eilanden' = 'FK',
    'FaroElands' = 'FO',
    'Fiji' = 'FJ',
    'Filippijnen' = 'PH',
    'Finland' = 'FI',
    'Frans-Polynesië' = 'PF',
    'Franse zuidelijke gebieden' = 'TF',
    'FranseGuana' = 'GF',
    'Gaan' = 'TG',
    'Gabon' = 'GA',
    'Gambia' = 'GM',
    'Georgië' = 'GE',
    'Ghana' = 'GH',
    'Gibraltar' = 'GI',
    'Grenada' = 'GD',
    'Griekenland' = 'GR',
    'Groenland' = 'GL',
    'Guadeloupe' = 'GP',
    'Guam' = 'GU',
    'Guatemala' = 'GT',
    'Guernsey' = 'GG',
    'Guinea' = 'GN',
    'Guinebissau' = 'GW',
    'Guyana' = 'GY',
    'Haïti' = 'HT',
    'Heard en McDonaldeilanden' = 'HM',
    'Honduras' = 'HN',
    'Hongarije' = 'HU',
    'Hongkong' = 'HK',
    'Ierland' = 'IE',
    'IJsland' = 'IS',
    'India' = 'IN',
    'Indonesië' = 'ID',
    'Irak' = 'IQ',
    'Iran' = 'IR',
    'Isleofman' = 'IM',
    'Israël' = 'IL',
    'Italië' = 'IT',
    'Jamaica' = 'JM',
    'Japan' = 'JP',
    'Jemen' = 'YE',
    'Jersey' = 'JE',
    'Jordanië' = 'JO',
    'Kaaiman Eilanden' = 'KY',
    'Kaapverdië' = 'CV',
    'Kalkoen' = 'TR',
    'Kameroen' = 'CM',
    'Kazachstan' = 'KZ',
    'Kenia' = 'KE',
    'Kersteiland' = 'CX',
    'Kirgizië' = 'KG',
    'Kiribati' = 'KI',
    'Koeweit' = 'KW',
    'Noord-Korea' = 'KP',
    'Zuid-Korea' = 'KR',
    'Kroatië' = 'HR',
    'Lao Democratische Volksrepubliek' = 'LA',
    'Lesotho' = 'LS',
    'Letland' = 'LV',
    'Libanon' = 'LB',
    'Liberia' = 'LR',
    'Libië' = 'LY',
    'Liechtenstein' = 'LI',
    'Litouwen' = 'LT',
    'Luxemburg' = 'LU',
    'Macao' = 'MO',
    'Macedonië' = 'MK',
    'Madagascar' = 'MG',
    'Malawi' = 'MW',
    'Maldiven' = 'MV',
    'Maleisië' = 'MY',
    'Mali' = 'ML',
    'Malta' = 'MT',
    'Marokko' = 'MA',
    'Marshall eilanden' = 'MH',
    'Martinique' = 'MQ',
    'Mauritanië' = 'MR',
    'Mauritius' = 'MU',
    'Mayotte' = 'YT',
    'Mexico' = 'MX',
    'Micronesië' = 'FM',
    'Moldavië' = 'MD',
    'Monaco' = 'MC',
    'Mongolië' = 'MN',
    'Montenegro' = 'ME',
    'Montserrat' = 'MS',
    'Mozambique' = 'MZ',
    'Myanmar' = 'MM',
    'Namibië' = 'NA',
    'Nauru' = 'NR',
    'Nederland' = 'NL',
    'Nepal' = 'NP',
    'Nicaragua' = 'NI',
    'Nieuw-Caledonië' = 'NC',
    'Nieuw-Zeeland' = 'NZ',
    'Niger' = 'NE',
    'Nigeria' = 'NG',
    'Niue' = 'NU',
    'Noordelijke Mariana eilanden' = 'MP',
    'Noorwegen' = 'NO',
    'Norfolkisland' = 'NF',
    'Oeganda' = 'UG',
    'Oekraïne' = 'UA',
    'Oezbekistan' = 'UZ',
    'Oman' = 'OM',
    'Oostenrijk' = 'AT',
    'Pakistan' = 'PK',
    'Palau' = 'PW',
    'Palestijns gebied' = 'PS',
    'Panama' = 'PA',
    'Papoea-Nieuw-Guinea' = 'PG',
    'Paraguay' = 'PY',
    'Peru' = 'PE',
    'Pitcairn' = 'PN',
    'Polen' = 'PL',
    'Portugal' = 'PT',
    'Puerto Rico' = 'PR',
    'Qatar' = 'QA',
    'Réunion' = 'RE',
    'Roemenië' = 'RO',
    'Russische Federatie' = 'RU',
    'Rwanda' = 'RW',
    'Saint Kitts en Nevis' = 'KN',
    'Saintelena' = 'SH',
    'Saintlucia' = 'LC',
    'Saintpierreandmiquelon' = 'PM',
    'SaintVincentandGrenadines' = 'VC',
    'Samoa' = 'WS',
    'San Marino' = 'SM',
    'Saoedi-Arabië' = 'SA',
    'SaoTomeandprincipe' = 'ST',
    'Senegal' = 'SN',
    'Servië' = 'RS',
    'Seychellen' = 'SC',
    'Sierra Leone' = 'SL',
    'Singapore' = 'SG',
    'Sint-Bartholomeus' = 'BL',
    'Sint-Maarten' = 'MF',
    'SintMaarten' = 'SX',
    'Slovenië' = 'SI',
    'Slowakije' = 'SK',
    'Soedan' = 'SD',
    'Solomon eilanden' = 'SB',
    'Somalië' = 'SO',
    'Spanje' = 'ES',
    'Sri Lanka' = 'LK',
    'Suriname' = 'SR',
    'Svalbardandjanmayen' = 'SJ',
    'Swaziland' = 'SZ',
    'Syrische Arabische Republiek' = 'SY',
    'Tadjikistan' = 'TJ',
    'Taiwan' = 'TW',
    'Tanzania' = 'TZ',
    'Thailand' = 'TH',
    'Timorleste' = 'TL',
    'Tokelau' = 'TK',
    'Tonga' = 'TO',
    'Trinidad en Tobago' = 'TT',
    'Tsjaad' = 'TD',
    'Tsjechische Republiek' = 'CZ',
    'Tunesië' = 'TN',
    'Turkmenistan' = 'TM',
    'Turks- en Caicoseilanden' = 'TC',
    'Tuvalu' = 'TV',
    'Uruguay' = 'UY',
    'Vanuatu' = 'VU',
    'Vaticaanstad' = 'VA',
    'Venezuela' = 'VE',
    'Verenigde Arabische Emiraten' = 'AE',
    'Verenigde Staten' = 'US',
    'Vietnam' = 'VN',
    'VirginislandsBritish' = 'VG',
    'Virginislandsus' = 'VI',
    'Wallis en Futuna' = 'WF',
    'Westelijke Sahara' = 'EH',
    'Wit-Rusland' = 'BY',
    'Zambia' = 'ZM',
    'Zimbabwe' = 'ZW',
    'Zuid Soedan' = 'SS',
    'Zuid-Afrika' = 'ZA',
    'Zuid-Georgia en de Zuidelijke Sandwicheilanden' = 'GS',
    'Zweden' = 'SE',
    'Zwitserland' = 'CH',
}
