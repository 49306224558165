import React, { FC } from 'react';
import { Wrapper, Inner, NoImage, Image } from './styles';
import noImage from 'static/no-photo.svg';

export interface FormImageProps {
    value?: string;
}

const FormImage: FC<FormImageProps> = ({ value }) => {
    const hasImage = value !== undefined;
    return (
        <Wrapper>
            <Inner heightPercentage={100}>
                {
                    hasImage ? (
                        <Image
                            image={value}
                        />
                    ) : (
                        <NoImage>
                            <img src={noImage} alt="Placeholder" />
                        </NoImage>
                    )
                }
            </Inner>
        </Wrapper>
    );
};

export default FormImage;
