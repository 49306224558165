import { AddBannerInputModel, BannerFormInputModel } from 'hooks/banner/types';
import { useApi } from 'hooks/api';
import { appendObject } from 'utils/objectAppender';

const useAddAdminBanner = () => {

    const { post } = useApi();

    const baseUrl = '/banner';

    const addBanner = async (values: AddBannerInputModel) => {

        const formData = new FormData();

        appendObject(values, formData);

        const response = await post(
            baseUrl, formData
        );

        return response;
    };

    const validateStep1AddBanner = async (values: BannerFormInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(
            `${baseUrl}/validate/step1`, fd
        );

        return response;
    };

    return {
        addBanner,
        validateStep1AddBanner
    };
};

export default useAddAdminBanner;
