import styled from '@emotion/styled';

export const Table = styled.table`
    table-collapse: collapse;
    border: 0;
    max-width: 100%;
    width: 100%;
    font-weight: 500;
    table-layout: fixed;
    td {
        padding: .5rem;
        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: 0;
            font-weight: 300;
            
            span {
                font-size: 1.35rem;
                line-height: 1rem;
            }
        }
    }
`;
