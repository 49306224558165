export interface Colors {
    primary: string;
    secondary: string;
    success: string;
    error: string;
    orange: string;
    dark: string;
    white: string;
    black: string;
    grey: string;
    light: string;
    border: string;
    green: string;
}

const colors: Colors = {
    primary: '#011F5E',
    secondary: '#D12229',
    success: '#4CAF50',
    error: '#D12229',
    orange: '#EBAA15',
    dark: '#001e5e',
    white: '#ffffff',
    black: '#000000',
    grey: '#b3b1b1',
    light: '#f5f3f1',
    border: '#D7D2C5',
    green: '#63b581'
};

export default colors;
