import React, { FC, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FormGroup } from 'components/atoms/form';
import { RouteComponentProps } from '@reach/router';
import * as Yup from 'yup';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import { ForgotPasswordInputModel } from 'hooks/account/types';
import useAccount from 'hooks/account/useAccount';
import { toFormikErrors } from 'utils/errorhelper';
import { H2 } from 'components/atoms/text';
import AccountForm from 'components/templates/AccountForm';

const validationSchema = Yup.object().shape({
    mail: Yup.string().email('Ongeldig e-mailadres')
});

const ForgotPassword: FC<RouteComponentProps> = ({ location }) => {
    const state = location?.state;
    const [send, setSend] = useState(false);

    const { forgotPassword } = useAccount();

    // ForgotPassword's methods.
    const onSubmit = async (values: ForgotPasswordInputModel, { setSubmitting, setErrors }: FormikHelpers<ForgotPasswordInputModel>) => {
        const response = await forgotPassword(values);

        if (response.ok) {
            setSend(true);
        } else if (response.errors) {
            const errors = toFormikErrors<ForgotPasswordInputModel>(response.errors);
            setErrors(errors);
        }

        setSubmitting(false);
    };

    // Formik.
    const initialValues: ForgotPasswordInputModel = {
        // @ts-ignore no type for state
        userName: (state && state.userName) || ''
    };

    if (!send) {
        return (
            <>
                <H2 center>Wachtwoord vergeten</H2>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting, isValid }) => (
                        <AccountForm
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            button={{
                                title: 'Opnieuw aanvragen'
                            }}
                        >
                            <FormGroup label="E-mailadres" isWhite>
                                <FormControlFormik name="userName" placeholder="E-mailadres" />
                            </FormGroup>
                        </AccountForm>
                    )}
                </Formik>
            </>
        );
    }

    return <H2 center>Verzonden!</H2>;
};

export default ForgotPassword;
