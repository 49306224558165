import React, { FC, useState } from 'react';
import { Card, CardBody } from 'components/atoms/card';
import { FormGroup, FormikFormControl, FormikFormDate, FormikFormSelect, FormikFormTextarea } from 'components/atoms/form';
import FormCheckFormik from 'components/atoms/form/FormCheck/formik';
import { useFormikContext } from 'formik';
import { AddCollectionForeignInputModel } from 'hooks/collectionforeign/types';
import HeaderAction from 'components/atoms/HeaderAction';
import { Col, Row } from 'react-grid-system';
import { legelFormOptions } from 'utils/constants/legalformconstants';
import KvkSearch from 'components/organisms/KvkSearch';
import { KvkCompanyInfoModel } from 'hooks/kvk/types';
import Margin from 'components/atoms/Margin';
import { Paragraph, H3 } from 'components/atoms/text';
import useKvk from 'hooks/kvk/useKvk';
import { Country } from 'utils/constants/address';
import { genderOptions } from 'utils/constants/personconstants';
import { KvkLegalFormStringToLegalForm } from 'utils/kvkHelper';

export interface Step2Props {
}

const Step2: FC<Step2Props> = () => {
    const { getBasicProfile } = useKvk();

    const { values, setFieldValue } = useFormikContext<AddCollectionForeignInputModel>();
    const [showCompanyNameField, setShowCompanyNameField] = useState(true);

    const onCompanySelectChange = (company: KvkCompanyInfoModel | undefined) => {
        if (company === undefined) {
            setShowCompanyNameField(true);
            setFieldValue('debtor.kvkNumber', '');
            setFieldValue('debtor.companyName', '');
            setFieldValue('debtor.isMailAddressDifferent', false);
            clearPostAddress();
            clearVisitAddress();
            return;
        }

        setShowCompanyNameField(false);
        setFieldValue('debtor.kvkNumber', company.kvkNumber);
        setFieldValue('debtor.companyName', company.name);

        getCompanyBasicProfile(company.kvkNumber);
    };

    const onCompanySearchDone = (hasResults: boolean) => {
        if (hasResults) {
            return;
        }

        setShowCompanyNameField(true);
        setFieldValue('debtor.kvkNumber', '');
        setFieldValue('debtor.companyName', '');
        setFieldValue('debtor.isMailAddressDifferent', false);
        clearPostAddress();
        clearVisitAddress();
    };

    const clearPostAddress = () => {
        setFieldValue('debtor.isMailAddressDifferent', false);
        setFieldValue('debtor.postalCodeMail', '');
        setFieldValue('debtor.cityMail', '');
        setFieldValue('debtor.addressMail', '');
    };

    const clearVisitAddress = () => {
        setFieldValue('debtor.postalCodeVisit', '');
        setFieldValue('debtor.cityVisit', '');
        setFieldValue('debtor.addressVisit', '');
    };

    const getCompanyBasicProfile = async (kvkNumber: string) => {
        const response = await getBasicProfile(kvkNumber);

        if (!response.ok || response.data == null || response.data.addresses.length === 0) {
            return;
        }

        const visitAddress = response.data.addresses.find((address) => address.type === 'bezoekadres');
        const postAddress = response.data.addresses.find((address) => address.type === 'correspondentieadres');

        setFieldValue('debtor.legalForm', KvkLegalFormStringToLegalForm(response.data.legalForm));

        if (visitAddress != null) {
            setFieldValue('debtor.postalCodeVisit', visitAddress.postalCode);
            setFieldValue('debtor.cityVisit', visitAddress.city);
            setFieldValue('debtor.addressVisit', `${visitAddress.street} ${visitAddress.houseNumber}${visitAddress.houseNumberAddition}`);
        }

        if (visitAddress == null && postAddress != null) {
            setFieldValue('debtor.postalCodeVisit', postAddress.postalCode);
            setFieldValue('debtor.cityVisit', postAddress.city);
            setFieldValue('debtor.addressVisit', `${postAddress.street} ${postAddress.houseNumber}${postAddress.houseNumberAddition}`);
        }

        if (visitAddress != null && postAddress != null) {
            setFieldValue('debtor.isMailAddressDifferent', true);
            setFieldValue('debtor.postalCodeMail', postAddress.postalCode);
            setFieldValue('debtor.cityMail', postAddress.city);
            setFieldValue('debtor.addressMail', `${postAddress.street} ${postAddress.houseNumber}${postAddress.houseNumberAddition}`);
        }

        if (visitAddress == null || postAddress == null) {
            clearPostAddress();
        }

        if (visitAddress == null && postAddress == null) {
            clearVisitAddress();
        }
    };


    const country: any = [];
    for (const [key, value] of Object.entries(Country)) {
        country.push({ label: key, value: value });
    }

    return (
        <>
            <Card fill variant="primary">
                <CardBody>
                    {values.debtor.isCompany ? (
                        <>
                            <H3 variant="white">Opdrachtformulier tot incasso - bedrijf</H3>
                            <Paragraph variant="white">
                                Met dit formulier geeft u Juresta opdracht, op basis van de gesloten incassomodule,
                                tot het behandelen van uw incasso-opdracht. Vul het formulier zo volledig mogelijk in. Waar
                                nodig kunt u direct de relevante documenten toevoegen. Door gebruik te maken van de
                                zoekfunctie “vind uw debiteur” kunt u eenvoudig de gegevens van uw debiteur overnemen in
                                dit formulier.
                            </Paragraph>
                            <Paragraph variant="white">
                                In het eerste deel van dit formulier vult u de bedrijfs- en contactgegevens in. In het
                                tweede deel vult u de gegevens met betrekking tot de openstaande post(en) in. Formulier ingevuld?
                                Dan heeft u de mogelijkheid het formulier digitaal te ondertekenen en een exemplaar op te slaan
                                of te printen voor uw eigen administratie.
                            </Paragraph>
                            <Paragraph variant="white">
                                Heeft u vragen over het invullen van dit formulier, neemt u dan gerust contact met ons op.
                            </Paragraph>
                        </>
                    ) : (
                        <>
                            <H3 variant="white">Opdrachtformulier tot incasso - consument</H3>
                            <Paragraph variant="white">
                                Met dit formulier geeft u Juresta opdracht, op basis van de gesloten incasso-overeenkomst, tot
                                het behandelen van uw incasso-opdracht. Vul het formulier zo volledig mogelijk in. Waar nodig
                                kunt u direct de relevante documenten toevoegen.
                            </Paragraph>
                            <Paragraph variant="white">
                                In het eerste deel van dit formulier vult u de persoonlijke- en contactgegevens in. In het
                                tweede deel vult u de gegevens met betrekking tot de openstaande post in.
                            </Paragraph>
                            <Paragraph variant="white">
                                Heeft u vragen over het invullen van dit formulier, neemt u dan gerust contact met ons op.
                            </Paragraph>
                        </>
                    )}
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    {values.debtor.isCompany ? (
                        <>
                            <Margin bottom={1.5}>
                                <H3>Gegevens van uw debiteur</H3>
                            </Margin>
                            <Row>
                                <Col xs={6}>
                                    <KvkSearch
                                        onSelectChange={onCompanySelectChange}
                                        onSearchDone={onCompanySearchDone}
                                        notFoundMessage="Het bedrijf kan niet worden gevonden in het KvK register, vul het hieronder handmatig in"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {
                                    showCompanyNameField &&
                                    <Col xs={6}>
                                        <FormGroup label="(Bedrijfs)naam" required>
                                            <FormikFormControl name="debtor.companyName" />
                                        </FormGroup>
                                    </Col>
                                }
                                <Col xs={6}>
                                    <FormGroup label="KvK-nummer" required>
                                        <FormikFormControl name="debtor.kvkNumber" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup label="Rechtsvorm" noMargin>
                                        <FormikFormSelect name="debtor.legalForm" options={legelFormOptions} placeholder="Selecteer één rechtsvorm" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Margin top={2}>
                                <HeaderAction title="Eigenaar en/ of contactpersoon" />
                                <Row gutterWidth={12}>
                                    <Col xs={6} md={3}>
                                        <FormGroup label="Aanhef" noMargin required>
                                            <FormikFormSelect name="debtor.gender" options={genderOptions} placeholder="Aanhef" />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <FormGroup label="Voorletters" required>
                                            <FormikFormControl name="debtor.contactPersonInitials" />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FormGroup label="Achternaam" required>
                                            <FormikFormControl name="debtor.contactPersonLastName" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Margin>
                        </>
                    ) : (
                        <>
                            <Margin bottom={1}>
                                <Margin bottom={1.5}>
                                    <HeaderAction title="Persoonsgegevens" />
                                </Margin>
                                <Row gutterWidth={12}>
                                    <Col xs={6} md={3}>
                                        <FormGroup label="Aanhef" noMargin required>
                                            <FormikFormSelect name="debtor.gender" options={genderOptions} placeholder="Aanhef" />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <FormGroup label="Voorletters" required>
                                            <FormikFormControl name="debtor.contactPersonInitials" />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FormGroup label="Achternaam" required>
                                            <FormikFormControl name="debtor.contactPersonLastName" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup label="Geboortedatum">
                                            <FormikFormDate name="debtor.dateOfBirth" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Margin>
                        </>
                    )}
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <Margin bottom={1.5}>
                        <H3>Adresgegevens</H3>
                    </Margin>
                    <Row>
                        <Col xs={6}>
                            <FormGroup label="Bezoekadres" required>
                                <FormikFormControl name="debtor.addressVisit" />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup label="Postcode" required>
                                <FormikFormControl name="debtor.postalCodeVisit" />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup label="Plaats" required>
                                <FormikFormControl name="debtor.cityVisit" />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup label="Land" required noMargin>
                                <FormikFormSelect name="debtor.countryVisit" options={country} placeholder="Selecteer land" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup noMargin>
                        <FormCheckFormik
                            name="debtor.isMailAddressDifferent"
                            label="Heeft uw debiteur een afwijkend postadres?"
                        />
                    </FormGroup>
                </CardBody>
            </Card>
            {values.debtor.isMailAddressDifferent && (
                <Card>
                    <CardBody>
                        <Margin bottom={1.5}>
                            <H3>Postadresgegevens</H3>
                        </Margin>
                        <Row>
                            <Col xs={6}>
                                <FormGroup label="Postadres" required>
                                    <FormikFormControl name="debtor.addressMail" />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup label="Postcode" required>
                                    <FormikFormControl name="debtor.postalCodeMail" />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup label="Plaats" required noMargin>
                                    <FormikFormControl name="debtor.cityMail" />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup label="Land" required noMargin>
                                    <FormikFormSelect name="debtor.countryMail" options={country} placeholder="Selecteer land" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
            <Card>
                <CardBody>
                    <Margin bottom={1.5}>
                        <H3>Contactgegevens</H3>
                    </Margin>
                    <Row>
                        <Col xs={6}>
                            <FormGroup label="Telefoon">
                                <FormikFormControl name="debtor.phoneNumber" />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup label="Mobiel">
                                <FormikFormControl name="debtor.mobileNumber" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <FormGroup label="E-mailadres">
                                <FormikFormControl name="debtor.email" />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup label="Website">
                                <FormikFormControl name="debtor.website" />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup label="Rekeningnummer">
                                <FormikFormControl name="debtor.iban" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup label="Overige opmerkingen" noMargin>
                        <FormikFormTextarea name="debtor.comments" />
                    </FormGroup>
                </CardBody>
            </Card>
        </>
    );
};

export default Step2;
