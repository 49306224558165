import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { useHeader } from 'contexts/headerContext';
import { H3, Paragraph } from 'components/atoms/text';
import Margin from 'components/atoms/Margin';
import { Button } from 'components/atoms/button';
import { saveAs } from 'file-saver';

const TacTools: FC<RouteComponentProps> = () => {
    useHeader('Algemene voorwaarden');

    const downloadFile = () => {
        saveAs('https://cdn.bluenotion.nl/28e55d339f287f756bf3203ee1d22b539343f1c8f90b69935617c1d10e2d2802.pdf', 'Handleiding_deponeren_en_toepasselijk_maken_van_voorwaarden.pdf');
    };

    // Render.
    return (
        <Card>
            <CardBody>
                <CardHeader borderBottom>
                    <H3>Handleiding</H3>
                </CardHeader>
                <Paragraph>
                    Hieronder vindt u de handleiding voor het gebruik van uw algemene voorwaarden. Leest u deze goed door. In deze handleiding leest u ook meer over het toepasselijk maken van uw voorwaarden of het deponeren hiervan. Heeft u vragen? Neemt u dan contact op met onze afdeling Juridische zaken (<a href="mailTo:juridischezaken@juresta.nl">juridischezaken@juresta.nl</a>) of telefonisch via 055 -5383086.
                </Paragraph>
                <Margin top={1}>
                    <Button onClick={downloadFile}>Download de handleiding</Button>
                </Margin>
            </CardBody>
        </Card>
    );
};

export default TacTools;
