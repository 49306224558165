import styled from '@emotion/styled';
import { rgba } from 'polished';

export const Tabs = styled.div`
    height: 3rem;
    margin-bottom: 1rem;
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    display: inline-flex;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    box-shadow: 0 10px 30px ${({ theme }) => rgba(theme.colors.dark, 0.07)};
`;
