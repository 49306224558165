import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { useHeader } from 'contexts/headerContext';
import { H3, Paragraph } from 'components/atoms/text';
import { Button } from 'components/atoms/button';
import Label from 'components/atoms/Label';
import { toast } from 'react-toastify';
import { ActivateModuleInputModel } from 'hooks/modules/types';
import useModules from 'hooks/modules/useModules';
import { Module } from '../../../utils/constants/moduleconstants';
import { useSession } from 'contexts/sessionContext';
import { Right } from 'utils/constants';
import { Form, Formik, FormikHelpers } from 'formik';
import { SendClientLegalAssistanceMailInputModel } from 'hooks/legalAssistance/types';
import { FormGroup, FormikFormFile, FormikFormTextarea } from 'components/atoms/form';
import useLegalAssistance from 'hooks/legalAssistance/useLegalAssistance';
import { toFormikErrors } from 'utils/errorhelper';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';


const LegalAid: FC<RouteComponentProps> = () => {
    useHeader('Rechtshulp');

    const { sendClientLegalAssistanceMail } = useLegalAssistance();
    const { activateModule } = useModules();
    const { session, hasRight } = useSession();

    const [hasRights, setHasRights] = useState(false);

    useEffect(() => {
        setHasRights(hasRight(Right.LegalAid));
    }, [session]);

    const activateModules = async (values: ActivateModuleInputModel, { setSubmitting, setErrors }: FormikHelpers<ActivateModuleInputModel>) => {
        if (!values) {
            return;
        }

        const response = await activateModule(values);

        if (response.ok) {
            toast.success('Uw informatie aanvraag voor de module juridische bijstand is succesvol verstuurd');
            hide();
        } else if (response.errors) {
            toast.error('Niet alle velden zijn ingevuld');
            const errors = toFormikErrors<ActivateModuleInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de aanvraag voor de module juridische bijstand');
        }

        setSubmitting(false);
    };

    const handleMail = async (values: SendClientLegalAssistanceMailInputModel, { setErrors, resetForm }: FormikHelpers<SendClientLegalAssistanceMailInputModel>) => {
        const response = await sendClientLegalAssistanceMail(values);

        if (response.ok) {
            toast.success('Uw aanvraag voor juridische bijstand is succesvol verstuurd');
            resetForm();
        } else if (response.errors) {
            const errors = toFormikErrors<SendClientLegalAssistanceMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de aanvraag voor juridische bijstand');
        }
    };

    const initialValues: SendClientLegalAssistanceMailInputModel = {
        file: undefined,
        description: ''
    };

    const activateModuleInitialValues: ActivateModuleInputModel = {
        type: Module.LegalAid,
        question: ''
    };

    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hide} size="small" title="Informatie aanvragen">
                <Formik<ActivateModuleInputModel> initialValues={activateModuleInitialValues} onSubmit={activateModules}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Paragraph>Met onderstaande formulier vraagt u vrijblijvend informatie aan over de rechtshulp module. Geeft u bij het opmerkingenveld alvast een korte omschrijving van uw bedrijfsactiviteiten eventueel aangevuld met specifieke zaken waar u tegenaan loopt.</Paragraph>
                            <FormGroup label="Vraag en/of opmerking" required>
                                <FormikFormTextarea name="question" />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button loading={isSubmitting}>Aanvragen</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        []
    );

    // Render.
    return (
        <>
            {
                !hasRights &&
                <Card>
                    <CardBody>
                        <CardHeader borderBottom>
                            <H3>Rechtshulp <Label text="Nog niet actief" /></H3>
                            <Button size="lg" rounded endIcon="plus" onClick={show}>Informatie aanvragen</Button>
                        </CardHeader>
                        <Paragraph>
                            Op basis van onze advocatenovereenkomst kunt u bij één van de advocaten uit het Juresta-netwerk terecht voor uw juridische vragen of kwesties. U beschikt nog niet over een advocatenovereenkomst.
                        </Paragraph>
                        <Paragraph>
                            Wilt u juridisch advies of heeft u rechtshulp nodig? Geef een (korte) omschrijving van uw vraag of probleem. Wij nemen zo spoedig mogelijk contact met u op om de mogelijkheden van de advocatenovereenkomst met u te bespreken.
                        </Paragraph>
                    </CardBody>
                </Card>
            }
            {
                hasRights &&
                <>
                    <Card variant="primary">
                        <CardBody>
                            <H3 variant="white">Module rechtshulp</H3>
                            <Paragraph variant="white">
                                Op basis van uw module rechtshulp kunt u bij één van de advocaten uit het Juresta-netwerk terecht voor uw juridische vragen of kwesties.
                            </Paragraph>
                            <Paragraph variant="white" noMargin>
                                Wilt u advies of heeft u rechtshulp nodig? Geef een (korte) omschrijving van uw vraag of probleem. Wij gaan direct voor u op zoek naar een passende advocaat. Vervolgens worden de mogelijkheden voor advies of rechtshulp met u besproken.
                            </Paragraph>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Formik<SendClientLegalAssistanceMailInputModel> initialValues={initialValues} onSubmit={handleMail}>
                                {({ isSubmitting }) => (
                                    <Form>
                                        <FormGroup label="Korte omschrijving van uw juridische probleem of vraag">
                                            <FormikFormTextarea name="description" placeholder="Omschrijving..." />
                                        </FormGroup>
                                        <FormGroup label="Bijlage toevoegen">
                                            <FormikFormFile name="file" />
                                        </FormGroup>
                                        <FormGroup noMargin alignRight>
                                            <Button loading={isSubmitting}>Verzenden</Button>
                                        </FormGroup>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </>
            }
        </>
    );
};

export default LegalAid;
