import { useApi } from 'hooks/api';
import { SendClientLegalAssistanceMailInputModel } from './types';
import { appendObject } from 'utils/objectAppender';

const useLegalAssistance = () => {

    const { post } = useApi();

    const baseUrl = '/legalassistance';

    const sendClientLegalAssistanceMail = async (values: SendClientLegalAssistanceMailInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(`${baseUrl}/clientMail`, fd);

        return response;
    };

    return {
        sendClientLegalAssistanceMail
    };
};

export default useLegalAssistance;
