import React from 'react';
import { ColumnInstance } from 'react-table';
import { TableLoading as Wrapper } from './styles';
import { Tr, Td } from '../Table/styles';
import Skeleton from 'react-loading-skeleton';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableLoadingProps<T extends object> {
    columns: ColumnInstance<T>[];
}

const SKELETON_ROWS = 4;

// eslint-disable-next-line @typescript-eslint/ban-types
const TableLoading = <T extends object>({ columns }: TableLoadingProps<T>) => {
    return (
        <Wrapper>
            {Array.from(Array(SKELETON_ROWS).keys()).map((row) => (
                <Tr key={row}>
                    {columns.map((column) => (
                        <Td key={column.id}>
                            <Skeleton />
                        </Td>
                    ))}
                </Tr>
            ))}
        </Wrapper>
    );
};

export default TableLoading;
