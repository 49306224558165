import styled from '@emotion/styled';
import { rgba } from 'polished';

export const Tooltip = styled.div`
    box-shadow: 0 10px 30px ${({ theme }) => rgba(theme.colors.dark, 0.2)};
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 250px;
`;

export const Trigger = styled.div`
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.greys[100]};
    display: flex;
    justify-content: center;
    align-items: center;
`;
