import React, { FC, ChangeEvent, useMemo } from 'react';
import { ErrorWrapper, FormYesNo as Wrapper } from './styles';
import { uniqueId } from 'lodash';
import InvalidFeedback from '../InvalidFeedback';
import FormRadio from '../FormRadio';

export interface FormYesNoProps {
    value?: boolean;
    label?: string;
    onChange?: (value: boolean) => void;
    error?: string;
    disabled?: boolean;
}

const YES = 'yes';
const NO = 'no';

const FormYesNo: FC<FormYesNoProps> = ({ value, onChange, label, error, disabled }) => {
    // methods.
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!onChange) {
            return;
        }

        onChange(event.target.value === YES);
    };

    // Render.
    const group = useMemo(() => uniqueId(), []);

    return (
        <>
            <Wrapper>
                {label && <label style={{ flex: 1 }}>{label}</label>}
                <FormRadio checked={value === true} value={YES} onChange={handleOnChange} name={group} label="Ja" disabled={disabled} />
                <FormRadio checked={value === false} value={NO} onChange={handleOnChange} name={group} label="Nee" disabled={disabled} />
            </Wrapper>

            {error && <ErrorWrapper><InvalidFeedback>{error}</InvalidFeedback></ErrorWrapper>}
        </>
    );
};

export default FormYesNo;
