import { useApi } from 'hooks/api';
import { appendObject } from 'utils/objectAppender';
import { UserInvitationModel, InviteAdminInputModel, CompleteRegistrationInputModel, InviteCustomerInputModel } from './types';

const useUserInvitation = () => {

    const { get, post } = useApi();

    const baseUrl = '/userInvitation';

    const getUserInvitation = async (code: string) => {
        const response = await get<UserInvitationModel>(`${baseUrl}/invitation/${code}`);

        return response;
    };

    const inviteAdmin = async (values: InviteAdminInputModel) => {
        const response = await post(
            `${baseUrl}/inviteAdmin`, values
        );

        return response;
    };

    const inviteCustomer = async (values: InviteCustomerInputModel) => {
        const fd = new FormData();
        appendObject(values, fd);
        const response = await post(
            `${baseUrl}/inviteCustomer`, fd
        );

        return response;
    };

    const completeRegistration = async (values: CompleteRegistrationInputModel) => {
        const response = await post(
            `${baseUrl}/completeRegistration`, values
        );

        return response;
    };

    const resendInvitation = async (userId: string) => {
        const response = await post(
            `${baseUrl}/resendInvitation`, userId
        );

        return response;
    };

    return {
        getUserInvitation,
        inviteAdmin,
        inviteCustomer,
        completeRegistration,
        resendInvitation
    };
};

export default useUserInvitation;
