import { Card, CardBody } from 'components/atoms/card';
import { FormikFormYesNo, FormikFormTextarea, FormGroup, FormikFormMultipleFile } from 'components/atoms/form';
import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { AddCollectionForeignInputModel } from 'hooks/collectionforeign/types';
import { H3, Paragraph } from 'components/atoms/text';
import FormCurrencyFormik from 'components/atoms/form/FormCurrency/formik';

export interface Step3Props { }

const Step3: FC<Step3Props> = () => {
    const { values, setFieldValue } = useFormikContext<AddCollectionForeignInputModel>();

    // Render.
    const showWrittenDocumentsExplanation = [
        values.hasSignedAgreements,
        values.hasQuotation,
        values.hasAssignmentLetter,
        values.hasSignedDeliveryNote
    ].some(Boolean);

    return (
        <>
            <Card fill>
                <CardBody>
                    <H3>Vordering</H3>
                    <FormGroup label="Openstaand bedrag voor hoofdsom" tooltip="Bedrag inclusief btw">
                        <FormCurrencyFormik name="amount" onValueChange={(value) => setFieldValue('amount', value)} />
                    </FormGroup>
                </CardBody>
            </Card>
            <Card fill>
                <CardBody>
                    <H3>Geschiedenis debiteur</H3>
                    <FormikFormYesNo label="Heeft u eerder zaken gedaan met deze debiteur?" name="hasHistoryWithDebtor" />
                    <FormikFormYesNo label="Wilt u dit nader toelichten?" name="historyWithDebtorWantToComment" />
                    {values.historyWithDebtorWantToComment && (
                        <FormGroup label="Toelichting">
                            <FormikFormTextarea darkBorder name="historyWithDebtorComments" />
                        </FormGroup>
                    )}
                </CardBody>
            </Card>
            <Card fill>
                <CardBody>
                    <H3>Verweer debiteur</H3>
                    <FormikFormYesNo label="Wordt er door de debiteur verweer gevoerd?" name="hasDebtorDefence" />
                    <FormikFormYesNo label="Wilt u dit nader toelichten?" name="debtorDefenceWantToComment" />
                    {values.debtorDefenceWantToComment && values.hasDebtorDefence && (
                        <>
                            <FormGroup>
                                <FormikFormMultipleFile whiteBackground name="debtorDefenceFiles" />
                            </FormGroup>
                            <FormGroup label="Toelichting">
                                <FormikFormTextarea darkBorder name="debtorDefenceComments" />
                            </FormGroup>
                        </>
                    )}
                    {values.debtorDefenceWantToComment && !values.hasDebtorDefence && (
                        <FormGroup label="Toelichting">
                            <FormikFormTextarea darkBorder name="debtorDefenceComments" />
                        </FormGroup>
                    )}
                </CardBody>
            </Card>
            <Card fill>
                <CardBody>
                    <H3>Voorwaarden</H3>
                    <FormikFormYesNo label="Zijn uw voorwaarden van toepassing?" name="areTermsApplied" />
                    {values.areTermsApplied && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="termsFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Wilt u dit nader toelichten?" name="termsAppliedWantToComment" />
                    {values.termsAppliedWantToComment && (
                        <FormGroup label="Toelichting">
                            <FormikFormTextarea darkBorder name="termsAppliedComments" />
                        </FormGroup>
                    )}
                </CardBody>
            </Card>
            <Card fill>
                <CardBody>
                    <H3>Documenten met betrekking tot uw vordering</H3>
                    <Paragraph>
                        Alle mogelijke documenten die kunnen leiden tot de vaststelling dat de overeenkomst tot stand is gekomen zijn van
                        belang. Denk bijvoorbeeld aan een ondertekend offerteformulier, werkbon, pakbon of een vrachtbrief. Beschikt u niet
                        over deze documenten? Vult u dan in het opmerkingenveld in hoe de overeenkomst tot stand is gekomen.
                    </Paragraph>
                    <Paragraph>
                        Daarnaast staan wij sterker in een incassoprocedure als alle relevante stukken van de vordering aanwezig zijn. Ook
                        een eenvoudige (telefoon)notitie of Whatsapp-bericht kan van belang zijn. Is het voor u niet mogelijk om uw
                        documenten direct bij te voegen? Stuurt u deze dan per mail naar teamincasso@juresta.nl.
                    </Paragraph>
                    <FormikFormYesNo label="Is er een ondertekende overeenkomst?" name="hasSignedAgreements" />
                    {values.hasSignedAgreements && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="signedAgreementsFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Is er een offerte uitgebracht?" name="hasQuotation" />
                    {values.hasQuotation && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="quotationFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Is er een opdrachtbevestiging?" name="hasAssignmentLetter" />
                    {values.hasAssignmentLetter && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="assignmentLetterFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Is er een ondertekende afleveringsbon?" name="hasSignedDeliveryNote" />
                    {values.hasSignedDeliveryNote && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="signedDeliveryNoteFiles" />
                        </FormGroup>
                    )}
                    {showWrittenDocumentsExplanation && (
                        <FormGroup label="Ruimte voor toelichting">
                            <FormikFormTextarea darkBorder name="writtenDocumentsComments" />
                        </FormGroup>
                    )}
                </CardBody>
            </Card>
            <Card fill>
                <CardBody>
                    <H3>Relevante stukken</H3>
                    <FormikFormYesNo label="Factuur/facturen" name="hasInvoices" />
                    {values.hasInvoices && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="invoiceFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Herinnering(en)" name="hasReminders" />
                    {values.hasReminders && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="reminderFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Aanmaning(en)" name="hasExhortation" />
                    {values.hasExhortation && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="exhortationFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Tekeningen / bestek" name="hasPlan" />
                    {values.hasPlan && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="planFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Gespreksnotitie" name="hasConversationNotes" />
                    {values.hasConversationNotes && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="conversationNotesFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Telefoonnotitie" name="hasPhoneNotes" />
                    {values.hasPhoneNotes && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="phoneNotesFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Whatsapp-berichten (of SMS)" name="hasAppOrSms" />
                    {values.hasAppOrSms && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="appOrSmsFiles" />
                        </FormGroup>
                    )}
                    <FormikFormYesNo label="Overige correspondentie met uw debiteur" name="hasOtherCorrespondence" />
                    {values.hasOtherCorrespondence && (
                        <FormGroup>
                            <FormikFormMultipleFile whiteBackground name="otherCorrespondenceFiles" />
                        </FormGroup>
                    )}
                </CardBody>
            </Card>
            <Card fill>
                <CardBody>
                    <H3>BTW</H3>
                    <FormikFormYesNo label="Moet u btw over deze vorderingen afdragen?" name="isTaxesRequired" />
                    {!values.isTaxesRequired && (
                        <FormGroup label="Toelichting">
                            <FormikFormTextarea darkBorder name="taxesComments" />
                        </FormGroup>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default Step3;
