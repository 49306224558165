import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Card } from 'components/atoms/card';
import ChevronDown from 'static/icons/chevron-down.svg';


export const Accordion = styled(Card)`

`;

export const AccordionHeader = styled.div<{ isOpen: boolean }>`
    position: relative;
    cursor: pointer;
    padding: 24px;
    &:after {
        content: '';
        background: url(${ChevronDown});
        background-position: center ;
        background-size: 32px 32px;
        height: 32px;
        width: 32px;
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translate(0, -50%) rotate(0deg);
        transition: all 0.3s ease-in-out;
    }
    ${({ isOpen }) =>
        isOpen && css`
            &:after {
                transform: translate(0, -50%) rotate(180deg);
            }
    `}
`;
