import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
    background-color: white;
    /* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 32px; */
`;

export const TableWrapper = styled.table`
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const Th = styled.th`
    text-align: left;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 600;
    font-size: 0.875rem;
    position: relative;
    padding-right: 1.5rem;

    &:first-of-kind {
        padding-left: 24px;
    }

    &:last-of-kind {
        padding-right: 24px;
    }
`;

export const Td = styled.td<{ fullWidth?: boolean }>`
    font-size: 0.875rem;
    padding: 0.75rem 8px;
    word-break: break-all;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            word-break: break-word;
            &:first-of-type {
                padding-left: 24px;
                align-items: center;
            }

            &:last-of-type {
                padding-right: 24px;
            }
        `
    }
`;

export const Tr = styled.tr`
    tbody &:first-of-type {
        border-top: 1px solid ${({ theme }) => theme.colors.primary};
    }
    &:not(:last-of-type) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    }
`;

export const Sort = styled.div`
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;
