import { useTheme } from '@emotion/react';
import React, { ReactNode } from 'react';
import Icon from '../Icon';
import { Action as Wrapper, ComponentWrapper, Title } from './styles';

export interface Action<T> {
    icon?: string;
    onClick?: (item: T, index: number) => void;
    title?: string;
    component?: (item: T) => ReactNode;
    active?: (item: T) => boolean;
}

interface ActionProps<T> extends Action<T> {
    item: T;
    index: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ActionComponent = <T extends object>({ onClick, icon, item, index, title, component, active }: ActionProps<T>) => {
    const theme = useTheme();

    const isActive = active == null || active(item);

    const handleOnClick = () => {
        if (onClick && isActive) {
            onClick(item, index);
        }
    };

    if (icon) {
        return (
            <Wrapper onClick={handleOnClick} title={isActive ? title : 'Actie niet beschikbaar'} type="button" isActive={isActive}>
                <Icon name={icon} size={1} color={isActive ? theme.greys[500] : theme.greys[100]} />
            </Wrapper>
        );
    }

    if (title) {
        return <Title>{title}</Title>;
    }

    if (component) {
        return <ComponentWrapper>{component(item)}</ComponentWrapper>;
    }

    return null;
};

export default ActionComponent;
