import { RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import { useHeader } from 'contexts/headerContext';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import React, { FC, useEffect, useState } from 'react';
import { SendTermsAndConditionsMailInputModel, TermsAndConditionsPlainModel } from 'hooks/termsAndConditions/types';
import useAllTermsAndConditions from 'hooks/termsAndConditions/useAllTermsAndConditions';
import { useSession } from 'contexts/sessionContext';
import useTermsAndConditions from 'hooks/termsAndConditions/useTermsAndConditions';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorhelper';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { FormGroup, FormikFormControl, FormikFormTextarea } from 'components/atoms/form';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import Label from 'components/atoms/Label';
import { Right } from 'utils/constants';
import { ActivateModuleInputModel } from 'hooks/modules/types';
import useModules from 'hooks/modules/useModules';
import { Module } from 'utils/constants/moduleconstants';
import { format, parseISO } from 'date-fns';

interface TermsAndConditionsProps extends RouteComponentProps { }

const TermsAndConditionsMain: FC<TermsAndConditionsProps> = () => {
    useHeader('Algemene Voorwaarden');

    const { session, hasRight } = useSession();
    const [hasRights, setHasRights] = useState(false);

    const { activateModule } = useModules();

    const activateModules = async (values: ActivateModuleInputModel, { setSubmitting, setErrors }: FormikHelpers<ActivateModuleInputModel>) => {
        if (!values) {
            return;
        }

        const response = await activateModule(values);

        if (response.ok) {
            toast.success('Uw informatie aanvraag voor de module algemene voorwaarden is succesvol verstuurd');
            hide();
        } else if (response.errors) {
            toast.error('Niet alle velden zijn ingevuld');
            const errors = toFormikErrors<ActivateModuleInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de aanvraag voor de module algemene voorwaarden');
        }

        setSubmitting(false);
    };

    const { data: termsAndConditions = [], isLoading: isLoadingTermsAndConditions } = useAllTermsAndConditions({ customerId: session?.customerId });
    const { getTermsAndConditionsFile, sendTermsAndConditionsMail } = useTermsAndConditions();

    const [termsToMail, setTermsToMail] = useState<TermsAndConditionsPlainModel>();
    const [fileToPrint, setFileToPrint] = useState<string | undefined>();
    const printRef = React.createRef<any>();

    useEffect(() => {
        setHasRights(hasRight(Right.GeneralConditions));
    }, [session]);

    const columns: Column<TermsAndConditionsPlainModel>[] = [
        {
            Header: 'Document',
            accessor: (tac) => tac.fileName
        },
        {
            Header: 'Datum',
            accessor: (tac) => parseISO(tac.date).getTime(),
            Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
        },
        {
            Header: 'Versie',
            accessor: (tac) => tac.version
        }
    ];

    const handleMail = async (values: SendTermsAndConditionsMailInputModel, { setErrors }: FormikHelpers<SendTermsAndConditionsMailInputModel>) => {
        if (!termsToMail) {
            return;
        }

        const response = await sendTermsAndConditionsMail(termsToMail.id, values);

        if (response.ok) {
            toast.success('Algemene voorwaarden is succesvol verstuurd');
            closeMailModal();
        } else if (response.errors) {
            const errors = toFormikErrors<SendTermsAndConditionsMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de algemene voorwaarden');
        }
    };

    const downloadFile = async (termsAndConditions: TermsAndConditionsPlainModel) => {

        const response = await getTermsAndConditionsFile(termsAndConditions.id);

        if (response.ok) {
            saveAs(await response.blob(), termsAndConditions.fileName);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de algemene voorwaarden');
        }
    };

    const printFile = async (termsAndConditions: TermsAndConditionsPlainModel) => {

        const response = await getTermsAndConditionsFile(termsAndConditions.id);

        if (response.ok) {
            const url = window.URL.createObjectURL(await response.blob());
            setFileToPrint(url);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de algemene voorwaarden');
        }
    };

    const initialValuesSendMail: SendTermsAndConditionsMailInputModel = {
        toName: '',
        toEmail: ''
    };

    const [showMailModal, hideMailModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideMailModal} size="large" title="Algemene voorwaarden versturen" subtitle="Vul de gegevens in om de algemene voowaarden te versturen">
                <Formik<SendTermsAndConditionsMailInputModel> initialValues={initialValuesSendMail} onSubmit={handleMail}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <FormGroup label="Naam">
                                <FormikFormControl name="toName" placeholder="Naam" />
                            </FormGroup>
                            <FormGroup label="E-mailadres" required>
                                <FormikFormControl name="toEmail" placeholder="E-mailadres" />
                            </FormGroup>
                            <Group right>
                                <Button type="button" onClick={closeMailModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Versturen</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [termsToMail]
    );

    const openMailModal = (termsAndConditions: TermsAndConditionsPlainModel) => {
        setTermsToMail(termsAndConditions);
        showMailModal();
    };

    const closeMailModal = () => {
        setTermsToMail(undefined);
        hideMailModal();
    };

    const activateModuleInitialValues: ActivateModuleInputModel = {
        type: Module.TermsAndConditions,
        question: ''
    };

    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hide} size="small" title="Informatie aanvragen">
                <Formik<ActivateModuleInputModel> initialValues={activateModuleInitialValues} onSubmit={activateModules}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Paragraph>Met onderstaande formulier vraagt u vrijblijvend informatie aan over algemene voorwaarden voor uw bedrijf. Geeft u bij het opmerkingenveld alvast een korte omschrijving van uw bedrijfsactiviteiten eventueel aangevuld met specifieke zaken die u wilt vastleggen of waar u tegenaan loopt.</Paragraph>
                            <FormGroup label="Vraag en/of opmerking" required>
                                <FormikFormTextarea name="question" />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button loading={isSubmitting}>Aanvragen</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        []
    );

    return (
        <>
            {
                hasRights ? (
                    <>
                        <Card variant="primary">
                            <CardBody>
                                <H3 variant="white">Uw algemene voorwaarden</H3>
                                <Paragraph variant="white" noMargin>
                                    In dit onderdeel van mijnjuresta.nl kunt u uw eigen algemene voorwaarden inzien. Ook kunt u uw algemene voorwaarden downloaden of direct per mail doorsturen naar een (mogelijke) opdrachtgever. Wij adviseren u de PDF-versie te versturen aan een (mogelijke) opdrachtgever, deze versie is dan niet te bewerken door de ontvanger. Daarmee kunnen er geen aanpassingen worden gedaan in het document en voorkomt u discussies achteraf.
                                </Paragraph>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <H3>Documenten</H3>
                                <Table<TermsAndConditionsPlainModel>
                                    data={termsAndConditions}
                                    noDataMessage="Geen documenten beschikbaar"
                                    columns={columns}
                                    isLoading={isLoadingTermsAndConditions}
                                    actions={[
                                        {
                                            icon: 'download',
                                            onClick: (item) => downloadFile(item),
                                            title: 'Download document'
                                        },
                                        {
                                            icon: 'print',
                                            onClick: (item) => printFile(item),
                                            title: 'Print document'
                                        },
                                        {
                                            icon: 'mail',
                                            onClick: (item) => openMailModal(item),
                                            title: 'Mail document'
                                        }
                                    ]}
                                />
                            </CardBody>
                        </Card>

                        {fileToPrint && <iframe ref={printRef} style={{ display: 'none' }} title="print" src={fileToPrint} onLoad={() => window.frames[0].print()}></iframe>}
                    </>
                ) : (
                    <Card>
                        <CardBody>
                            <CardHeader borderBottom>
                                <H3>Uw Algemene Voorwaarden <Label text="Nog niet actief" /></H3>
                                <Button size="lg" rounded endIcon="plus" onClick={show}>Informatie aanvragen</Button>
                            </CardHeader>
                            <Paragraph>
                                In dit onderdeel van mijnjuresta.nl kunt u uw eigen algemene voorwaarden inzien wanneer deze zijn vervaardigd door Juresta. Geen algemene voorwaarden? Vraag vrijblijvend advies aan voor de mogelijkheden voor uw bedrijf.
                            </Paragraph>
                        </CardBody>
                    </Card>
                )
            }
        </>
    );
};

export default TermsAndConditionsMain;
