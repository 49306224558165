import { RouteComponentProps } from '@reach/router';
import React, { FC, useState } from 'react';
import useModal from 'hooks/modal';
import { Column } from 'react-table';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import Modal from 'components/organisms/Modal';
import Wizard from 'components/organisms/Wizard';
import StampData from './components/StampData';
import { FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { toFormikErrors } from 'utils/errorhelper';
import { useHeader } from 'contexts/headerContext';
import { EditStampInputModel, StampPlainModel } from 'hooks/stamp/types';
import { useStamps } from 'hooks/stamp';
import { cdnFileUrl } from 'utils/cdnHelper';

export interface StampProps extends RouteComponentProps { }

const columns: Column<StampPlainModel>[] = [
    {
        Header: 'Naam',
        accessor: (stamp) => stamp.name
    },
    {
        Header: 'Beschrijving',
        accessor: (stamp) => stamp.description
    },
    {
        Header: 'Prijs',
        accessor: (stamp) => '€ ' + stamp.price.toFixed(2).toString().replace('.', ',')
    }
];

const ManageStamps: FC<StampProps> = () => {
    useHeader('Stempels');

    const { useAllStamps, addStamp, editStamp, deleteStamp } = useStamps();
    const { data: stamps = [], refetch, isLoading: isLoadingStamps } = useAllStamps();

    const [stamp, setStamp] = useState<EditStampInputModel>();
    const [stampToDelete, setStampToDelete] = useState<StampPlainModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const initialValues: EditStampInputModel = {
        id: stamp?.id ?? '',
        name: stamp?.name ?? '',
        description: stamp?.description ?? '',
        price: stamp?.price ?? 0,
        imageHash: stamp?.imageHash ?? '',
        stampImage: stamp?.imageHash ? cdnFileUrl(stamp.imageHash) : undefined
    };

    const handleshow = (item: StampPlainModel) => {
        setStamp(item);
        show();
    };

    const handleOnHide = (hide: () => void) => {
        setStamp(undefined);
        hide();
    };

    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={() => handleOnHide(hide)} title={`Stempel ${!initialValues.id ? 'toevoegen' : 'bewerken'}`}>
                <Wizard<EditStampInputModel>
                    initialValues={initialValues}
                    initialStep={0}
                    onSubmitLabel="Opslaan"
                    onSubmit={handleStampSubmit}
                    steps={[
                        {
                            step: 1,
                            title: 'Gegevens',
                            child: <StampData />
                        }
                    ]}
                />
            </Modal>
        ),
        [initialValues]
    );

    const handleStampSubmit = async (values: EditStampInputModel, actions: FormikHelpers<EditStampInputModel>) => {
        if (stamp) {
            const response = await editStamp(values);
            if (response.ok) {
                toast.success('Stempel succesvol aangepast!');
                hide();
                refetch();
            } else if (response.errors) {
                toast.error('Niet alle velden zijn ingevuld!');
                const errors = toFormikErrors<EditStampInputModel>(response.errors);
                actions.setErrors(errors);
            } else {
                toast.error('Oeps, er ging iets fout tijdens het aanpassen van de stempel. Probeer het later opnieuw!');
            }
        } else {
            const response = await addStamp(values);

            if (response.ok) {
                toast.success('Stempel succesvol aangemaakt!');
                hide();
                refetch();
            } else if (response.errors) {
                toast.error('Niet alle velden zijn ingevuld!');
                const errors = toFormikErrors<EditStampInputModel>(response.errors);
                actions.setErrors(errors);
            } else {
                toast.error('Oeps, er ging iets fout tijdens het aanmaken van de stempel. Probeer het later opnieuw!');
            }
        }
    };

    const handleStampDelete = async () => {
        if (stampToDelete === undefined) {
            return;
        }

        setIsLoadingDelete(true);

        const response = await deleteStamp(stampToDelete.id);
        if (response.ok) {
            toast.success('Stempel succesvol verwijderd!');
            refetch();
        } else {
            toast.error('Oeps, er ging iets fout tijdens het verwijderen van de stempel. Probeer het later opnieuw!');
        }

        setIsLoadingDelete(false);

        closeDeleteModal();
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={closeDeleteModal} size="large" title="Stempel verwijderen" subtitle={`Weet je zeker dat je stempel '${stampToDelete?.name}' wilt verwijderen?`}>
                <Group right>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleStampDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [stampToDelete]
    );

    const openDeleteModal = (stamp: StampPlainModel) => {
        setStampToDelete(stamp);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setStampToDelete(undefined);
        hideDeleteModal();
    };

    return (
        <Card>
            <CardBody>
                <Table<StampPlainModel>
                    data={stamps}
                    isLoading={isLoadingStamps}
                    noDataMessage="Geen stempel beschikbaar"
                    columns={columns}
                    search
                    onAddClick={show}
                    actions={[
                        {
                            icon: 'edit',
                            onClick: (item) => handleshow(item),
                            title: 'Pas stempel aan'
                        },
                        {
                            icon: 'delete',
                            onClick: (item) => openDeleteModal(item),
                            title: 'Verwijder stempel'
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default ManageStamps;
