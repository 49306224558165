import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { useHeader } from 'contexts/headerContext';
import useUsers from 'hooks/user';
import { UserModel } from 'hooks/user/types';
import { Column } from 'react-table';
import { useModal } from 'react-modal-hook';
import Modal from 'components/organisms/Modal';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormSelect } from 'components/atoms/form';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import Margin from 'components/atoms/Margin';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { Col, Row } from 'react-grid-system';
import HeaderAction from 'components/atoms/HeaderAction';
import { toFormikErrors } from 'utils/errorhelper';
import { AdminType, InviteAdminInputModel } from 'hooks/userInvitation/types';
import useUserInvitation from 'hooks/userInvitation/useUserInvitation';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { useSession } from 'contexts/sessionContext';

interface UserProps extends RouteComponentProps { }

const columns: Column<UserModel>[] = [
    {
        Header: 'Gebruiker',
        accessor: (user) =>
            user.insertion !== '' ? `${user.firstName} ${user.insertion} ${user.lastName}` : `${user.firstName} ${user.lastName}`
    },
    {
        Header: 'E-mail',
        accessor: (user) => user.email
    },
    {
        Header: 'Registratie afgerond op',
        accessor: (user) => user.finishedRegistration != null ? parseISO(user.finishedRegistration).getTime() : null,
        Cell: ({ value }: any) => value != null ? format(value, 'dd-MM-yyyy') : '-'
    }
];

const Users: FC<UserProps> = () => {
    useHeader('Gebruikers');

    const { useAllUsers, deleteUser } = useUsers();
    const { inviteAdmin, resendInvitation } = useUserInvitation();
    const { data: users = [], isLoading: isLoadingUsers, refetch } = useAllUsers({
        role: 'admin'
    });

    const [userToDelete, setUserToDelete] = useState<UserModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const { session } = useSession();

    const AddUser = async (values: InviteAdminInputModel, { setSubmitting, setErrors }: FormikHelpers<InviteAdminInputModel>) => {
        const response = await inviteAdmin(values);
        if (response.ok) {
            refetch();
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors<InviteAdminInputModel>(response.errors);
            setErrors(errors);
        }
        setSubmitting(false);
    };

    const initialValues: InviteAdminInputModel = {
        firstName: '',
        insertion: '',
        lastName: '',
        email: '',
        type: AdminType.Admin
    };

    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hide} title="Gebruiker toevoegen" size="small">
                <Formik initialValues={initialValues} onSubmit={AddUser}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <HeaderAction title="Persoonsgegevens" />
                            <Margin bottom={1}>
                                <FormGroup noMargin label="Voornaam">
                                    <FormControlFormik name="firstName" placeholder="Voornaam" />
                                </FormGroup>
                            </Margin>
                            <Margin bottom={1}>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <FormGroup noMargin label="Tussenvoegsel">
                                            <FormControlFormik name="insertion" placeholder="Tussenvoegsel" />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <FormGroup noMargin label="Achternaam">
                                            <FormControlFormik name="lastName" placeholder="Achternaam" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Margin>
                            <Margin bottom={1}>
                                <FormGroup noMargin label="E-mailadres">
                                    <FormControlFormik name="email" placeholder="E-mailadres" />
                                </FormGroup>
                            </Margin>
                            <Margin bottom={1}>
                                <FormGroup noMargin label="Gebruiker type">
                                    <FormikFormSelect
                                        name="type"
                                        options={[
                                            {
                                                value: AdminType.Admin,
                                                label: 'Beheerder'
                                            },
                                            {
                                                value: AdminType.Sales,
                                                label: 'Sales'
                                            }
                                        ]}
                                    />
                                </FormGroup>
                            </Margin>
                            <Margin top={2}>
                                <Group spaceBetween>
                                    <Button type="button" onClick={hide}>
                                        Annuleren
                                    </Button>
                                    <Button type="submit" loading={isSubmitting}>
                                        Opslaan
                                    </Button>
                                </Group>
                            </Margin>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        []
    );

    const openDeleteModal = (item: UserModel) => {
        setUserToDelete(item);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setUserToDelete(undefined);
        hideDeleteModal();
    };

    const handleDelete = async () => {
        if (userToDelete == null) {
            return;
        }

        setIsLoadingDelete(true);

        const response = await deleteUser(userToDelete.id);

        if (response.ok) {
            refetch();
            toast.success('Gebruiker succesvol verwijderd!');
            closeDeleteModal();
        } else {
            toast.error('Oeps, er ging iets fout tijdens het verwijderen van de gebruiker. Probeer het later opnieuw!');
        }

        setIsLoadingDelete(false);
    };

    const handleResendInvitation = async (item: UserModel) => {
        const response = await resendInvitation(item.id);

        if (response.ok) {
            toast.success('De uitnodiging is opnieuw verzonden');
        } else {
            toast.error('Oeps, er ging iets fout gegaan.');
        }
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="large" title="Gebruiker verwijderen" subtitle="Weet je zeker dat je deze gebruiker wilt verwijderen">
                <Group spaceBetween>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [userToDelete]
    );

    return (
        <Card>
            <CardBody>
                <Table<UserModel>
                    isLoading={isLoadingUsers}
                    data={users}
                    noDataMessage="Geen gebruikers beschikbaar"
                    columns={columns}
                    search
                    onAddClick={show}
                    actions={[
                        {
                            icon: 'mail',
                            onClick: handleResendInvitation,
                            title: 'Uitnodiging opnieuw versturen',
                            active: (i) => i.finishedRegistration == null
                        },
                        {
                            icon: 'delete',
                            onClick: (item) => {
                                openDeleteModal(item);
                            },
                            title: 'verwijderen',
                            active: (i) => i.id !== session?.userId
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default Users;
