import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { useHeader } from 'contexts/headerContext';
import { H3, Paragraph } from 'components/atoms/text';

const About: FC<RouteComponentProps> = () => {
    useHeader('Over Juresta');

    // Render.
    return (
        <>
            <Card>
                <CardBody>
                    <CardHeader borderBottom>
                        <H3>Over Juresta</H3>
                    </CardHeader>
                    <Paragraph>
                        <b>
                            Juresta staat ondernemers uit het midden- en kleinbedrijf bij voor de behandeling van incasso{'\''}s, rechtshulp op
                            ieder vlak en vervaardigt algemene voorwaarden en overeenkomsten.
                        </b>
                    </Paragraph>
                    <Paragraph>
                        Juresta richt zich sinds 1990 op de incassodienstverlening voor het Nederlandse bedrijfsleven. In de behandeling van
                        incassozaken, zowel in Nederland als in het buitenland, staan wij voor maatwerk, kwaliteit en een juridisch
                        verantwoorde aanpak. Daarnaast zijn wij de specialist voor het vervaardigen van uw algemene voorwaarden en
                        overeenkomsten.
                    </Paragraph>
                    <Paragraph>
                        Daarnaast biedt Juresta de zakelijke markt op laagdrempelige wijze toegang tot rechtsbijstand en juridisch advies
                        middels ons landelijke netwerk van advocatenkantoren.
                    </Paragraph>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <CardHeader borderBottom>
                        <H3>Contact met Juresta</H3>
                    </CardHeader>
                    <Paragraph>
                        Heeft u vragen of wilt u meer weten over onze (overige) mogelijkheden? Hieronder vindt u een overzicht van onze afdelingen en hun contactgegevens.
                    </Paragraph>
                    <Paragraph>
                        <b>Afdeling product- en klantadvies</b><br />
                        Heeft u vragen over uw lopende overeenkomst of module? Of wilt u meer weten over onze mogelijkheden voor uw bedrijf? Neemt u dan contact op met onze afdeling Product- en klantadvies via 055 5383000 of per mail via <a href="mailto:klantadvies@juresta.nl" target="blank">klantadvies@juresta.nl</a>.
                    </Paragraph>
                    <Paragraph>
                        <b>Afdeling intake & incasso</b><br />
                        Heeft u een vraag over herinneren, aanmanen of het uit handen geven van uw onbetaalde factuur? Of een vraag over uw incassodossier? Neemt u dan contact op met onze afdeling Intake & Incasso via 055 5383074 of per e-mail via <a href="mailto:teamincasso@juresta.nl" target="blank">teamincasso@juresta.nl</a>.
                    </Paragraph>
                    <Paragraph>
                        <b>Afdeling juridische zaken</b><br />
                        Heeft u een vragen over uw algemene voorwaarden? Of wilt u meer weten over het laten toetsen of vertalen van uw voorwaarden of juridische documenten? Neemt u dan contact op met onze afdeling Juridische zaken via 055 5383086 of per e-mail via <a href="mailto:juridischezaken@juresta.nl" target="blank">juridischezaken@juresta.nl</a>.
                    </Paragraph>
                    <Paragraph>
                        <b>Afdeling financiële administratie</b><br />
                        Heeft u een vraag over een factuur die u van ons heeft ontvangen? Of een toegestuurde afrekenstaat in uw incassodossier? Neemt u dan contact op met onze afdeling Financiële administratie via 055 5383051 of per e-mail via <a href="mailto:administratie@juresta.nl" target="blank">administratie@juresta.nl</a>.
                    </Paragraph>
                    <Paragraph>
                        Heeft u een andere of algemene vraag? Wij zijn bereikbaar via 055 5383000 of per e-mail via <a href="mailto:info@juresta.nl" target="blank">info@juresta.nl</a>.
                    </Paragraph>
                    <Paragraph>
                        Wij zijn gevestigd aan de Europaweg 204 A, 7336 AR te Apeldoorn. Dit is tevens ons bezoek- en postadres.
                    </Paragraph>
                </CardBody>
            </Card>
        </>
    );
};

export default About;
