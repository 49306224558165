import { CustomError, useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { EditStampInputModel, StampPlainModel } from './types';
import { appendObject } from 'utils/objectAppender';

const stampQueryKey = 'stamps';
const allStampsQueryKey = `${stampQueryKey}.all`;

export const useStamps = () => {
    const { get, post, put, deleteCall } = useApi();

    const fetchAllStamps = async () => await get<StampPlainModel[]>('/stamp');

    const useAllStamps = () => {
        return useQuery<StampPlainModel[], CustomError[]>(allStampsQueryKey, async () => {
            const response = await fetchAllStamps();

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const addStamp = async (values: EditStampInputModel) => {
        const formData = new FormData();
        appendObject(values, formData);

        const response = await post('/stamp', formData);
        return response;
    };

    const editStamp = async (values: EditStampInputModel) => {
        const formData = new FormData();
        appendObject(values, formData);

        const response = await put('/stamp', formData);
        if (!response.ok) {
            throw response;
        }

        return response;
    };

    const deleteStamp = async (id: string) => {
        const response = await deleteCall(`/stamp/${id}`);

        if (!response.ok) {
            throw response;
        }

        return response;
    };

    return {
        addStamp,
        editStamp,
        deleteStamp,
        useAllStamps,
    };
};