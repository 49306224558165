import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Group = styled.div<{ spaceBetween?: boolean; right?: boolean; center?: boolean }>`
    display: flex;
    justify-content: ${({ spaceBetween, center, right }) =>
        spaceBetween ? 'space-between' : center ? 'space-around' : right ? 'flex-end' : 'flex-start'};

    > * {
        margin-right: 8px;

        ${({ spaceBetween, right }) =>
            spaceBetween &&
            right &&
            css`
                :only-child {
                    margin-left: auto;
                }
            `};

        &:last-child {
            margin-right: 0;
        }
    }
`;
