import styled from '@emotion/styled';

export const User = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.normal}px;

  &:hover {
    cursor: pointer;
  }
`;

export const Name = styled.span`
  margin-left: 16px;
  font-weight: 600;
  font-size: 1rem;
`;
