import { useField } from 'formik';
import React, { FC } from 'react';
import FormYesNo, { FormYesNoProps } from '..';

export interface FormikFormYesNoProps extends FormYesNoProps {
    name: string;
}

const FormikFormYesNo: FC<FormikFormYesNoProps> = ({ name, ...props }) => {
    const [field, meta, helpers] = useField<boolean>(name);

    const error = !!(meta.touched && meta.error) ? meta.error : undefined;
    return <FormYesNo {...props} value={field.value} onChange={helpers.setValue} error={error} />;
};

export default FormikFormYesNo;
