import React, { FC } from 'react';
import { Footer as Wrapper, Text, Link } from './styles';
import Pagination, { PaginationProps } from '../Pagination';
import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';

export interface FooterProps extends PaginationProps {
    cta?: () => void;
    ctaText?: string;
    hidePagination?: boolean;
}

const Footer: FC<FooterProps> = ({ cta, ctaText, hidePagination, ...props }) => {
    const theme = useTheme();

    return (
        <Wrapper>
            {cta && (
                <Link onClick={cta}>
                    <Text>{ctaText || 'Bekijk alles'} <Icon color={theme.colors.primary} name="chevron-right" size={1} /></Text>
                    
                </Link>
            )}
            {!hidePagination &&
                <Pagination {...props} />
            }
        </Wrapper>
    );
};

export default Footer;
