import Icon from 'components/atoms/Icon';
import styled from '@emotion/styled';

export const Dropdown = styled.div`
    display: flex;
    position: relative;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    padding-right: 8px;
  `;

export const Toggle = styled(Icon)`
    margin-left: 8px;
    pointer-events: all;
    svg {
        fill: ${({ theme }) => theme.greys[100]};
    }
    
    &:hover {
        cursor: pointer;
        svg {
            fill: ${({ theme }) => theme.greys[300]};
        }
    }
`;
