import { CustomerPlainModel } from 'hooks/customer/types';

export interface TermsAndConditionsPlainModel {
    id: string;
    date: string;
    version: string;
    fileName: string;
    customer: CustomerPlainModel;
}

export interface AddTermsAndConditionsInputModel {
    customerId: string;
    date: Date;
    version: string;
    files?: File[];
    // TODO why are we sending a customer id and date if this can be defined on the backend?
}

export interface TermsAndConditionsMutationContext {
    previousData?: TermsAndConditionsPlainModel[];
}

export interface SendTermsAndConditionsMailInputModel {
    toName: string;
    toEmail: string;
}

export interface GetTermsAndConditionsInputModel {
    id?: string;
    customerId?: string;
}

export interface SendTermsAndConditionsTaskMailInputModel {
    file?: File;
    comments?: string;
    type: TermsAndConditionsType;
    language?: string;
}

export enum TermsAndConditionsType {
    Default = 0,
    Update = 1,
    Translation = 2,
    Review = 3,
    MaintenanceRequest = 4
}
