import { Card, CardBody } from 'components/atoms/card';
import Margin from 'components/atoms/Margin';
import { H3 } from 'components/atoms/text';
import { CustomerModel } from 'hooks/customer/types';
import { AddGeneralDocumentInputModel, DocumentModel } from 'hooks/document/types';
import React, { FC, useState } from 'react';
import { Column } from 'react-table';
import Table from 'components/molecules/table';
import { DocumentType, getDocumentTypeDisplay } from 'utils/constants/documentConstants';
import { toast } from 'react-toastify';
import useDocuments from 'hooks/document';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormControl, FormikFormDate, FormikFormFile } from 'components/atoms/form';
import { toFormikErrors } from 'utils/errorhelper';
import { format, parseISO } from 'date-fns';

interface CustomerDetailFilesProps {
    customer: CustomerModel;
}

const CustomerDetailFiles: FC<CustomerDetailFilesProps> = ({ customer }) => {

    const { useAllDocuments, getDocumentFile, addGeneralDocument, deleteDocument } = useDocuments();
    const { data: files = [], isLoading: isLoadingFiles, refetch: refetchFiles } = useAllDocuments({ customerId: customer.id, type: DocumentType.General });

    const printRef = React.createRef<any>();
    const [fileToPrint, setFileToPrint] = useState<string | undefined>();
    const [documentToDelete, setDocumentToDelete] = useState<DocumentModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const documentColumns: Column<DocumentModel>[] = [
        {
            Header: 'Naam',
            accessor: (item) => item.fileName
        },
        {
            Header: 'Type',
            accessor: (item) => getDocumentTypeDisplay(item.type)
        },
        {
            Header: 'Versie',
            accessor: (item) => item.version
        },
        {
            id: 'date',
            Header: 'Datum',
            accessor: (item) => parseISO(item.date).getTime(),
            Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
        }
    ];

    const downloadDocument = async (document: DocumentModel) => {
        const response = await getDocumentFile(document.id);

        if (response.ok) {
            saveAs(await response.blob(), document.fileName);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de algemene voorwaarden');
        }
    };

    const printDocument = async (document: DocumentModel) => {
        const response = await getDocumentFile(document.id);

        if (response.ok) {
            const url = window.URL.createObjectURL(await response.blob());
            setFileToPrint(url);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de algemene voorwaarden');
        }
    };

    // Methods.
    const handleAddGeneralDocument = async (values: AddGeneralDocumentInputModel, { setErrors, setSubmitting, resetForm }: FormikHelpers<AddGeneralDocumentInputModel>) => {
        const response = await addGeneralDocument(values);

        if (response.ok) {
            refetchFiles();
            hideAddGeneralDocument();
            resetForm();
        } else if (response.errors) {
            const errors = toFormikErrors<AddGeneralDocumentInputModel>(response.errors);
            setErrors(errors);
        }

        setSubmitting(false);
    };

    const initialValues: AddGeneralDocumentInputModel = {
        date: new Date(),
        version: '',
        customerId: customer.id,
        file: undefined
    };

    const [showAddGeneralDocument, hideAddGeneralDocument] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideAddGeneralDocument} size="large" title="Bestand toevoegen">
                <Formik<AddGeneralDocumentInputModel> initialValues={initialValues} onSubmit={handleAddGeneralDocument}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup label="Bestand" required>
                                <FormikFormFile name="file" />
                            </FormGroup>
                            <FormGroup label="Datum" required>
                                <FormikFormDate name="date" />
                            </FormGroup>
                            <FormGroup label="Versie" required>
                                <FormikFormControl name="version" placeholder="Versie" />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button loading={isSubmitting}>Toevoegen</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [customer]
    );

    const openDeleteModal = (document: DocumentModel) => {
        setDocumentToDelete(document);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        hideDeleteModal();
        setDocumentToDelete(undefined);
    };

    const handleDelete = async () => {
        if (documentToDelete == null) {
            return;
        }

        setIsLoadingDelete(true);

        const response = await deleteDocument(documentToDelete.id);

        if (response.ok) {
            refetchFiles();
            closeDeleteModal();
        } else {
            toast.error('Er ging iets fout bij het verwijderen van het bestand');
        }

        setIsLoadingDelete(false);
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="large" title="Bestand verwijderen" subtitle="Weet je zeker dat je dit bestand wilt verwijderen?">
                <p><strong>Bestandsnaam:</strong> {documentToDelete?.fileName}</p>
                <p style={{ marginBottom: 15 }}><strong>Versie:</strong> {documentToDelete?.version}</p>
                <Group right>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [documentToDelete]
    );

    return (
        <>
            <Card>
                <CardBody>
                    <Margin bottom={2}>
                        <Group spaceBetween>
                            <H3>Bestanden</H3>
                            <Button onClick={showAddGeneralDocument}>Toevoegen</Button>
                        </Group>
                    </Margin>
                    <Card variant="light" noMargin>
                        <CardBody>
                            <Card noMargin>
                                <CardBody>
                                    <Table<DocumentModel>
                                        data={files}
                                        columns={documentColumns}
                                        isLoading={isLoadingFiles}
                                        sortBy={[{ id: 'date', desc: true }]}
                                        actions={[
                                            {
                                                icon: 'download',
                                                title: 'Bestand downloaden',
                                                onClick: downloadDocument
                                            },
                                            {
                                                icon: 'print',
                                                title: 'Bestand printen',
                                                onClick: printDocument
                                            },
                                            {
                                                icon: 'delete',
                                                title: 'Bestand verwijderen',
                                                onClick: openDeleteModal
                                            }
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </CardBody>
                    </Card>
                </CardBody>
            </Card>

            {
                fileToPrint &&
                <iframe ref={printRef} style={{ display: 'none' }} title="print" src={fileToPrint} onLoad={() => window.frames[0].print()}></iframe>
            }
        </>
    );
};

export default CustomerDetailFiles;
