import { useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { CorrespondenceTemplatePlainModel } from 'hooks/correspondenceTemplate/types';

export const correspondenceTemplateQueryKey = 'templateLetterKey';

export default function useAllCorrespondenceTemplates() {

    const { get } = useApi();

    const getAllCorrespondenceTemplates = async () => {
        const { data } = await get<CorrespondenceTemplatePlainModel[]>('/correspondencetemplate');

        return data;
    };

    return useQuery<CorrespondenceTemplatePlainModel[] | undefined>(correspondenceTemplateQueryKey, getAllCorrespondenceTemplates);
}
