import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransitionStatus, ENTERING, ENTERED, EXITING, EXITED } from 'react-transition-group/Transition';
import { H3, Paragraph } from 'components/atoms/text';

export const Overlay = styled.div<{ state: TransitionStatus; duration: number }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transition: opacity ${({ duration }) => duration}ms ease;
    opacity: 0;

    ${({ state }) =>
        (state === ENTERING || state === ENTERED) &&
        css`
            opacity: 1;
        `}

    ${({ state }) =>
        (state === EXITING || state === EXITED) &&
        css`
            opacity: 0;
        `}
`;

export const Modal = styled.div<{ state: TransitionStatus; duration: number; size: 'small' | 'medium' | 'large' }>`
    width: 480px;
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    margin: 2.5rem 0;
    max-height: calc(100% - 5rem);
    transition: transform ${({ duration }) => duration}ms ease;
    transform: translateY(-24px);

    ${({ state }) =>
        (state === ENTERING || state === ENTERED) &&
        css`
            transform: translateY(0);
        `}

    ${({ state }) =>
        (state === EXITING || state === EXITED) &&
        css`
            transform: translateY(-24px);
        `}
    ${({ size }) =>
        (size === 'small') &&
        css`
            @media (min-width: 672px) { 
                width: 60%;
            }
            @media (min-width: 1056px) { 
                width: 42%;
            }
            @media (min-width: 1312px) {
                width: 36%;
            }
            @media (min-width: 1584px) {
                width: 36%;
            }
        `
    }
    ${({ size }) =>
        (size === 'medium') &&
        css`
            @media (min-width: 672px) { 
                width: 84%;
            }
            @media (min-width: 1056px) { 
                width: 60%;
            }
            @media (min-width: 1312px) {
                width: 48%;
            }
            @media (min-width: 1584px) {
                width: 48%;
            }
        `
    }
    ${({ size }) =>
        (size === 'large') &&
        css`
            @media (min-width: 672px) { 
                width: 96%;
            }
            @media (min-width: 1056px) { 
                width: 84%;
            }
            @media (min-width: 1312px) {
                width: 72%;
            }
            @media (min-width: 1584px) {
                width: 72%;
            }
        `
    }

	display: flex;
    flex-direction: column;
`;

export const Content = styled.div<({ size?: string }) >`
    padding: 32px;
    background-color: ${({ theme }) => theme.colors.white};
    overflow-y: ${({ size }) => size !== 'small' ? 'auto' : null};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.normal}px;
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.normal}px;
`;

export const Close = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    padding: 1rem;
    background: none;
    border: 0;
    outline: none;
`;

export const Header = styled.div`
    background-color: ${({ theme }) => theme.colors.light};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.large}px;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.large}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 32px;
`;

export const Title = styled(H3)`
`;

export const Subtitle = styled(Paragraph)`
    margin-bottom: 0;
`;
