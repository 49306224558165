import { BannerPlainModel, EditBannerInputModel } from 'hooks/banner/types';
import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'hooks/api';
import { adminBannersQueryKey } from './useAllBanners';
import { appendObject } from 'utils/objectAppender';

const useEditAdminBanner = () => {

    const { put } = useApi();

    const baseUrl = '/banner';

    const queryClient = useQueryClient();

    const editBanner = async (values: EditBannerInputModel) => {
        const formData = new FormData();

        appendObject(values, formData);

        const response = await put(
            baseUrl, formData
        );

        if (!response.ok) {
            throw response;
        }

        return response;
    };


    const editBannerMutation = useMutation((values: EditBannerInputModel) => editBanner(values), {

        onMutate: async (values: EditBannerInputModel) => {

            await queryClient.cancelQueries(adminBannersQueryKey);

            const previousData = queryClient.getQueryData<BannerPlainModel[]>(adminBannersQueryKey);
            const newData = [...previousData ?? []];

            const bannerToEdit = newData.find(item => item.id === values.id);

            if (bannerToEdit != null) {
                bannerToEdit.imageHash = values.imageHash;
                bannerToEdit.name = values.name;
                bannerToEdit.description = values.description;
            }

            queryClient.setQueryData<BannerPlainModel[]>(adminBannersQueryKey, newData);

            return { previousData };
        },
        onError: (err, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData(adminBannersQueryKey, context.previousData);
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries(adminBannersQueryKey);
        }

    });

    return { editBannerMutation };
};

export default useEditAdminBanner;
