import { CustomError, useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { buildUriWithParams } from 'utils/urihelper';
import { CustomerModel, EditCustomerInputModel, GetCustomersInputModel } from './types';

export const allCustomersFullQueryKey = 'customer.all.full';

const useCustomer = () => {

    const { get, post, put } = useApi();

    const baseUrl = '/customer';

    const getAllCustomers = async (params?: GetCustomersInputModel) => {
        const url = buildUriWithParams(`${baseUrl}/all`, params);

        const respone = await get<CustomerModel[]>(url);

        return respone;
    };

    const useAllCustomers = (params?: GetCustomersInputModel) => {
        const queryKey = [allCustomersFullQueryKey, params];

        return useQuery<CustomerModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllCustomers(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const editCustomer = async (values: EditCustomerInputModel) => {
        const form = new FormData();

        appendObject(values, form);

        const response = await put(baseUrl, form);

        return response;
    };

    const setCustomerBlocked = async (id: string, state: boolean) => {
        const response = await post(
            `${baseUrl}/${id}/setBlocked`, state
        );

        return response;
    };

    return {
        getAllCustomers,
        useAllCustomers,
        editCustomer,
        setCustomerBlocked
    };
};

export default useCustomer;
