import React, { FC, InputHTMLAttributes } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { FormControl as Wrapper } from './styles';

export interface FormControlProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    error?: string;
}

const FormControl: FC<FormControlProps> = ({ error, ...props }) => {
    return (
        <>
            <Wrapper {...props} />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormControl;
