import { useApi } from 'hooks/api';
import { AddStampOrderInputModel, StampOrderResponseModel } from './types';

const useStampOrder = () => {

    const { post } = useApi();

    const createStampOrder = async (values: AddStampOrderInputModel) => {
        const response = post<StampOrderResponseModel>('/stamporder', values);

        return response;
    };

    return {
        createStampOrder
    };
};

export default useStampOrder;
