import { useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { BannerPlainModel } from './types';

export const adminBannersQueryKey = 'adminBanners';

export default function useAllBanners() {

    const { get } = useApi();

    const getAllBanners = async () => {
        const { data } = await get<BannerPlainModel[]>('/banner');

        return data;
    };

    return useQuery<BannerPlainModel[] | undefined>(adminBannersQueryKey, getAllBanners);
}
