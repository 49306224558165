import React, { FC, useRef } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Card, CardBody } from 'components/atoms/card';
import { H3 } from 'components/atoms/text';
import Margin from 'components/atoms/Margin';
import { Col, Row } from 'react-grid-system';
import { FormGroup } from 'components/atoms/form';
import ButtonWrapper from 'components/atoms/ButtonWrapper';
import { TitleWrapper, Label } from './styles';
import Group from 'components/molecules/Group';
import AddFieldButton from 'components/atoms/AddFieldButton';
import HeaderAction from 'components/atoms/HeaderAction';
import { Button } from 'components/atoms/button';
import { Form, Formik, FormikHelpers } from 'formik';
import { AddCorrespondenceTemplateInputModel, CorrespondenceTemplateInputModel, CorrespondenceTemplatePlainModel, EditCorrespondenceTemplateInputModel } from 'hooks/correspondenceTemplate/types';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorhelper';
import useAddAdminCorrespondenceTemplate from 'hooks/correspondenceTemplate/useAddAdminCorrespondenceTemplate';
import useEditAdminCorrespondenceTemplate from 'hooks/correspondenceTemplate/useEditAdminCorrespondenceTemplate';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import WysiwygEditorFormik from 'components/atoms/form/WysiwygEditor/formik';
import { Editor as TinyMCEEditor } from 'tinymce';

export interface AddCorrespondenceTemplateProps extends RouteComponentProps<{ location: { state: { item: CorrespondenceTemplatePlainModel } } }> {
}

const AddCorrespondenceTemplate: FC<AddCorrespondenceTemplateProps> = ({ location }: AddCorrespondenceTemplateProps) => {
    // State.
    const editorRef = useRef<TinyMCEEditor>();

    const initialValues: CorrespondenceTemplateInputModel = {
        id: location?.state?.item ? location.state.item.id : '',
        name: location?.state?.item ? location.state.item.name : '',
        content: location?.state?.item ? location.state.item.content : '',
        subject: location?.state?.item ? location.state.item.subject : ''
    };

    // Hooks.
    useHeader('Nieuw template toevoegen');
    const { addCorrespondenceTemplateMutation } = useAddAdminCorrespondenceTemplate();
    const { editCorrespondenceTemplateMutation } = useEditAdminCorrespondenceTemplate();

    // Methods.
    const handleOnTagClick = (tag: string) => {
        editorRef.current?.execCommand('mceInsertContent', undefined, `{{${tag}}}`);
    };

    const handleSubmit = (values: EditCorrespondenceTemplateInputModel, { setErrors }: FormikHelpers<EditCorrespondenceTemplateInputModel>) => {
        if (values.id) {
            return editCorrespondenceTemplateMutation.mutateAsync(values, {
                onSuccess: () => {
                    navigate('/admin/correspondence/templates');
                    toast.success(`'${values.name}' succesvol opgeslagen`);
                },
                onError: (error) => {
                    if (error.errors) {
                        const errors = toFormikErrors<EditCorrespondenceTemplateInputModel>(error.errors);
                        setErrors(errors);
                        toast.error('Niet alle velden zijn ingevuld');
                    } else {
                        toast.error('Oeps, er ging iets fout tijdens het aanmaken van de template. Probeer het later opnieuw!');
                    }
                }
            });
        } else {
            return addCorrespondenceTemplateMutation.mutateAsync(values, {
                onSuccess: () => {
                    navigate('/admin/correspondence/templates');
                    toast.success('template succesvol aangemaakt!');
                },
                onError: (error) => {
                    if (error.errors) {
                        const errors = toFormikErrors<AddCorrespondenceTemplateInputModel>(error.errors);
                        setErrors(errors);
                        toast.error('Niet alle velden zijn ingevuld');
                    } else {
                        toast.error('Oeps, er ging iets fout tijdens het aanpassen van de template. Probeer het later opnieuw!');
                    }
                }
            });
        }
    };


    // Render.
    return (
        <>
            <Row>
                <Col xs={8}>
                    <Card>
                        <CardBody>
                            <Margin bottom={2}>
                                <TitleWrapper>
                                    <H3>Nieuwe template</H3>
                                    <Label>Concept</Label>
                                </TitleWrapper>
                            </Margin>
                            <Card noShadow noMargin variant="light">
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, isValid, values, setFieldValue }) => (
                                            <Form>
                                                <Margin bottom={2}>
                                                    <H3>Template</H3>
                                                    <FormGroup noMargin label="Template naam">
                                                        <FormControlFormik name="name" placeholder="Geef de template een naam" />
                                                    </FormGroup>
                                                </Margin>
                                                <Margin bottom={1}>
                                                    <HeaderAction title="E-mail" />
                                                </Margin>
                                                <FormGroup label="Onderwerp">
                                                    <FormControlFormik name="subject" placeholder="Geef het onderwerp van de mail aan" />
                                                </FormGroup>
                                                <FormGroup noMargin label="Tekst">
                                                    <Card noMargin>
                                                        <CardBody borderBottom>
                                                            <Margin bottom={1}>
                                                                <H3>Dynamische velden</H3>
                                                                <p>Selecteer de velden om ze toe te voegen</p>
                                                            </Margin>
                                                            <ButtonWrapper>
                                                                <AddFieldButton tag="name" title="Naam" onClick={handleOnTagClick} />
                                                                <AddFieldButton tag="date" title="Datum" onClick={handleOnTagClick} />
                                                                <AddFieldButton tag="mail" title="E-mail" onClick={handleOnTagClick} />
                                                                <AddFieldButton tag="document" title="Document" onClick={handleOnTagClick} />
                                                            </ButtonWrapper>
                                                        </CardBody>
                                                        <CardBody>
                                                            <WysiwygEditorFormik
                                                                name="content"
                                                                onInit={(_, editor) => editorRef.current = editor}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </FormGroup>
                                                <Margin top={2}>
                                                    <Group spaceBetween>
                                                        <Button type="button" onClick={() => navigate('/admin/correspondence/templates')} size="lg">Terug</Button>
                                                        <Button type="submit" size="lg" disabled={!isValid} loading={isSubmitting}>Opslaan</Button>
                                                    </Group>
                                                </Margin>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default AddCorrespondenceTemplate;
