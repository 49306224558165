import React, { FC, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import { useHeader } from 'contexts/headerContext';
import Label from 'components/atoms/Label';
import { Button } from 'components/atoms/button';
import Margin from 'components/atoms/Margin';
import { toast } from 'react-toastify';
import { Right } from 'utils/constants';
import { useSession } from 'contexts/sessionContext';
import { ActivateModuleInputModel } from 'hooks/modules/types';
import useModules from 'hooks/modules/useModules';
import { Module } from 'utils/constants/moduleconstants';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormTextarea } from 'components/atoms/form';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { toFormikErrors } from 'utils/errorhelper';

interface CollectionsProps extends RouteComponentProps {

}

const Collections: FC<CollectionsProps> = () => {
    useHeader('Incasso Nederland');

    const { session, hasRight } = useSession();
    const [hasRights, setHasRights] = useState(false);

    const { activateModule } = useModules();

    const activateModules = async (values: ActivateModuleInputModel, { setSubmitting, setErrors }: FormikHelpers<ActivateModuleInputModel>) => {
        if (!values) {
            return;
        }

        const response = await activateModule(values);

        if (response.ok) {
            toast.success('Uw informatie aanvraag voor de module incasso Nederland is succesvol verstuurd');
            hide();
        } else if (response.errors) {
            toast.error('Niet alle velden zijn ingevuld');
            const errors = toFormikErrors<ActivateModuleInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de aanvraag voor de module incasso Nederland');
        }

        setSubmitting(false);
    };

    useEffect(() => {
        setHasRights(hasRight(Right.Collection));
    }, [session]);

    const activateModuleInitialValues: ActivateModuleInputModel = {
        type: Module.Collections,
        question: ''
    };

    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hide} size="small" title="Informatie aanvragen">
                <Formik<ActivateModuleInputModel> initialValues={activateModuleInitialValues} onSubmit={activateModules}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Paragraph>Met onderstaande formulier vraagt u vrijblijvend informatie aan over de module Incasso Nederland.</Paragraph>
                            <FormGroup label="Vraag en/of opmerking" required>
                                <FormikFormTextarea name="question" />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button loading={isSubmitting}>Aanvragen</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        []
    );

    return (
        <>
            {
                hasRights ? (
                    <>
                        <Card fill>
                            <CardBody>
                                <Margin bottom={1.5}>
                                    <Paragraph>
                                        Op basis van uw incassomodule Nederland kunt een onbeperkt aantal incasso-opdrachten indienen. <Link to="/collection-netherlands/add">Klik hier wanneer u direct uw incasso-opdracht wilt indienen.</Link> Meer over herinneren en aanmanen leest u hieronder.
                                    </Paragraph>
                                    <H3>Wanneer herinneren of aanmanen?</H3>
                                    <Paragraph>
                                        Het is van belang dat u uw debiteuren op de betaling van uw factuur blijft attenderen. Dat kan zowel telefonisch als schriftelijk. Hanteert u bijvoorbeeld een betaaltermijn van 30 dagen, dan kan het geen kwaad om uw debiteur na 15 dagen eens te bellen of een vriendelijk mailtje te sturen met de vraag of de factuur in goede orde is ontvangen, of alles in orde is en uw debiteur op de hoogte is van de betaaltermijn van 30 dagen. Doet u dit telefonisch? Maak dan een notitie met de datum van het gesprek en noteer met wie u heeft gesproken.
                                    </Paragraph>
                                    <H3>Herinnering of een aanmaning?</H3>
                                    <Paragraph>
                                        Verstuur direct na het verstrijken van de betaaltermijn een herinnering. Een voorbeeld vindt u hier. Heeft u geen betaling ontvangen na de genoemde termijn in de uw herinnering? Verstuur dan een aanmaning per e-mail of post. Telefonisch aanmanen is niet voldoende omdat dit moeilijk te bewijzen is. Bewaar een kopie van al uw correspondentie met uw debiteur.
                                    </Paragraph>
                                    <H3>Laatste aanmaning</H3>
                                    <Paragraph>
                                        Verstuur na het uitblijven van betaling na het verstrijken van de termijn genoemd in uw aanmaning direct een laatste aanmaning. Hiermee stelt u uw debiteur onder andere aansprakelijk voor de buitengerechtelijke kosten.
                                    </Paragraph>
                                    <H3>Is uw debiteur een consument? </H3>
                                    <Paragraph>
                                        Om uw recht op vergoeding van de buitengerechtelijke incassokosten te verzekeren, bent u wettelijk verplicht tot het verzenden van een laatste schriftelijke aanmaning. Aan de inhoud en wijze van verzenden van deze zogenaamde laatste aanmaning worden door de wetgever strenge eisen gesteld. U dient uw debiteur, als laatste wapenfeit, nog minimaal 14 dagen in de gelegenheid te stellen om tot betaling van uw factuur over te gaan. Bovendien moet de debiteur er op worden geattendeerd dat wanneer hij niet binnen de aangegeven termijn alsnog betaalt, de buitengerechtelijke incassokosten en vertragingsrente verschuldigd worden. Let op: doet u dit niet dan wordt uw debiteur de incassokosten niet verschuldigd.
                                    </Paragraph>
                                    <H3>Versturen van uw laatste aanmaning </H3>
                                    <Paragraph>
                                        Wij adviseren u de laatste aanmaning per aangetekende post te versturen. U beschikt dan immers over een ontvangstbewijs. Daarnaast adviseren wij u om de brief tevens per gewone post en per e-mail met een ontvangst- en leesbevestiging te versturen.
                                    </Paragraph>
                                    <Paragraph>
                                        <Link to="/tools/templateletters">Klik hier voor onze voorbeeldbrieven.</Link> Vertragingsrente of incassokosten berekenen? <Link to="/tools/calculators">Klik hier</Link>. Heeft u vragen over uw openstaande facturen, herinneren of aanmanen? <a href="mailto:klantadvies@juresta.nl">Neem contact met ons op</a>, dat kan natuurlijk ook telefonisch via 055-5383074.
                                    </Paragraph>
                                </Margin>

                            </CardBody>
                        </Card>
                    </>
                ) : (
                    <Card>
                        <CardBody>
                            <CardHeader borderBottom>
                                <H3>Incassomodule Nederland <Label text="Nog niet actief" /></H3>
                                <Button size="lg" rounded endIcon="plus" onClick={show}>Informatie aanvragen</Button>
                            </CardHeader>
                            <Paragraph>
                                Heeft u te maken met onbetaalde facturen of een debiteur die al meermaals beloofde zijn factuur te betalen? En blijft betaling uit? U maakt nog geen gebruik van onze incassomogelijkheden, maar wij informeren u graag over de mogelijkheden.
                                Neemt u ook contact op wanneer u advies wenst over uw openstaande posten.
                            </Paragraph>
                        </CardBody>
                    </Card>
                )
            }
        </>
    );
};

export default Collections;
