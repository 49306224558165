import React, { FC } from 'react';
import { FormGroup, FormikFormControl, FormikFormCurrency, FormikFormImage } from 'components/atoms/form';

export interface StampDataProps { }

const StampData: FC<StampDataProps> = () => {
    return (
        <>
            <FormGroup required>
                <FormikFormControl hidden name="id" />
            </FormGroup>
            <FormGroup label="Stempel afbeelding">
                <FormikFormImage name="stampImage" />
            </FormGroup>
            <FormGroup label="Stempel naam" required>
                <FormikFormControl name="name" placeholder="Stempel naam" />
            </FormGroup>
            <FormGroup label="Stempel beschrijving" required>
                <FormikFormControl name="description" placeholder="Stempel beschrijving" />
            </FormGroup>
            <FormGroup label="Stempel prijs" required>
                <FormikFormCurrency name="price" type="number" min="0.00" placeholder="Prijs" />
            </FormGroup>
        </>
    );
};

export default StampData;
