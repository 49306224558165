import React, { FC, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { FormGroup, FormikFormControl, FormikFormDate, FormikFormMultipleFile, FormikFormSelect } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'components/atoms/button';
import { useHeader } from 'contexts/headerContext';
import useAgreements from 'hooks/agreement';
import { AddAgreementInputModel, AgreementPlainModel, SendAgreementMailInputModel } from 'hooks/agreement/types';
import { toFormikErrors } from 'utils/errorhelper';
import useAllCustomers from 'hooks/customer/useAllCustomers';
import Group from 'components/molecules/Group';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

const columns: Column<AgreementPlainModel>[] = [
    {
        Header: 'Relatienummer',
        accessor: (agreement) => agreement.customerRelationNumber
    },
    {
        Header: 'Bedrijfsnaam',
        accessor: (agreement) => agreement.customerName
    },
    {
        Header: 'Overeenkomst',
        accessor: (agreement) => agreement.fileName
    },
    {
        id: 'date',
        Header: 'Datum',
        accessor: (agreement) => parseISO(agreement.date).getTime(),
        Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
    },
    {
        Header: 'Versie',
        accessor: (agreement) => agreement.version
    }
];

const Agreements: FC<RouteComponentProps> = () => {
    useHeader('Overeenkomsten');

    const { addAgreement, useAllAgreements, deleteAgreement, getAgreementFile, sendAgreementMail } = useAgreements();
    const { data: agreements = [], refetch: refetchAgreements, isLoading: isLoadingAgreements } = useAllAgreements();
    const { data: customers = [], isFetching: isLoadingCustomers } = useAllCustomers();

    const [agreementToMail, setAgreementToMail] = useState<AgreementPlainModel>();
    const [agreementToDelete, setAgreementToDelete] = useState<AgreementPlainModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [fileToPrint, setFileToPrint] = useState<string | undefined>();
    const printRef = React.createRef<any>();

    const customerOptions = useMemo(() => customers.map((c) => ({
        value: c.id,
        label: c.name
    })), [customers]);

    // Methods.
    const handleOnSubmit = async (values: AddAgreementInputModel, { setErrors, setSubmitting, resetForm }: FormikHelpers<AddAgreementInputModel>) => {
        const response = await addAgreement(values);

        if (response.ok) {
            refetchAgreements();
            hideAddAgreement();
            resetForm();
        } else if (response.errors) {
            const errors = toFormikErrors<AddAgreementInputModel>(response.errors);
            setErrors(errors);
        }

        setSubmitting(false);
    };

    const initialValues: AddAgreementInputModel = {
        date: new Date(),
        version: '',
        customerId: undefined,
        files: undefined
    };

    // Formik.
    const [showAddAgreement, hideAddAgreement] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideAddAgreement} size="large" title="Overeenkomsten toevoegen">
                <Formik<AddAgreementInputModel> initialValues={initialValues} onSubmit={handleOnSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup label="Overeenkomsten" required>
                                <FormikFormMultipleFile name="files" />
                            </FormGroup>
                            <FormGroup label="Relate" required>
                                <FormikFormSelect
                                    isLoading={isLoadingCustomers}
                                    name="customerId"
                                    placeholder="Selecteer één relatie"
                                    options={customerOptions}
                                />
                            </FormGroup>
                            <FormGroup label="Datum" required>
                                <FormikFormDate name="date" />
                            </FormGroup>
                            <FormGroup label="Versie" required>
                                <FormikFormControl name="version" placeholder="Versie" />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button loading={isSubmitting}>Toevoegen</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [customerOptions, isLoadingCustomers]
    );

    const openDeleteModal = (agreement: AgreementPlainModel) => {
        setAgreementToDelete(agreement);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        hideDeleteModal();
        setAgreementToDelete(undefined);
    };

    const handleDelete = async () => {
        if (agreementToDelete == null) {
            return;
        }

        setIsLoadingDelete(true);

        const response = await deleteAgreement(agreementToDelete.id);

        if (response.ok) {
            refetchAgreements();
            closeDeleteModal();
        } else {
            toast.error('Er ging iets fout bij het verwijderen van de overeenkomst');
        }

        setIsLoadingDelete(false);
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="large" title="Overeenkomst verwijderen" subtitle="Weet je zeker dat je deze overeenkomst wilt verwijderen?">
                <p><strong>Bedrijfsnaam:</strong> {agreementToDelete?.customerName}</p>
                <p><strong>Bestandsnaam:</strong> {agreementToDelete?.fileName}</p>
                <p style={{ marginBottom: 15 }}><strong>Versie:</strong> {agreementToDelete?.version}</p>
                <Group right>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [agreementToDelete]
    );

    const downloadFile = async (agreement: AgreementPlainModel) => {
        const response = await getAgreementFile(agreement.id);

        if (response.ok) {
            saveAs(await response.blob(), agreement.fileName);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de overeenkomst');
        }
    };

    const printFile = async (agreement: AgreementPlainModel) => {
        const response = await getAgreementFile(agreement.id);

        if (response.ok) {
            const url = window.URL.createObjectURL(await response.blob());
            setFileToPrint(url);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de overeenkomst');
        }
    };

    const handleMail = async (values: SendAgreementMailInputModel, { setErrors }: FormikHelpers<SendAgreementMailInputModel>) => {
        if (!agreementToMail) {
            return;
        }

        const response = await sendAgreementMail(agreementToMail.id, values);

        if (response.ok) {
            toast.success('Overeenkomst is succesvol verstuurd');
            closeMailModal();
        } else if (response.errors) {
            const errors = toFormikErrors<SendAgreementMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de overeenkomst');
        }
    };

    const initialValuesSendMail: SendAgreementMailInputModel = {
        toEmail: ''
    };

    const [showMailModal, hideMailModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideMailModal} size="large" title="Overeenkomst versturen" subtitle="Vul een e-mailadres in om de overeenkomst te versturen">
                <Formik<SendAgreementMailInputModel> initialValues={initialValuesSendMail} onSubmit={handleMail}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <FormGroup label="E-mailadres" required>
                                <FormikFormControl name="toEmail" placeholder="E-mailadres" />
                            </FormGroup>
                            <Group right>
                                <Button type="button" onClick={closeMailModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Versturen</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [agreementToMail]
    );

    const openMailModal = (agreement: AgreementPlainModel) => {
        setAgreementToMail(agreement);
        showMailModal();
    };

    const closeMailModal = () => {
        setAgreementToMail(undefined);
        hideMailModal();
    };

    // Render.
    return (
        <>
            <Card>
                <CardBody>
                    <Table<AgreementPlainModel>
                        data={agreements}
                        noDataMessage="Geen overeenkomsten beschikbaar"
                        columns={columns}
                        isLoading={isLoadingAgreements}
                        search
                        onAddClick={showAddAgreement}
                        sortBy={[{ id: 'date', desc: true }]}
                        actions={[
                            {
                                icon: 'download',
                                onClick: (item) => downloadFile(item),
                                title: 'Download overeenkomst'
                            },
                            {
                                icon: 'print',
                                onClick: (item) => printFile(item),
                                title: 'Print overeenkomst'
                            },
                            {
                                icon: 'mail',
                                onClick: (item) => openMailModal(item),
                                title: 'Mail overeenkomst'
                            },
                            {
                                icon: 'delete',
                                onClick: (item) => openDeleteModal(item),
                                title: 'Verwijder overeenkomst'
                            }
                        ]}
                    />
                </CardBody>
            </Card>

            {fileToPrint && <iframe ref={printRef} title="print" src={fileToPrint} onLoad={() => window.frames[0].print()}></iframe>}
        </>
    );
};

export default Agreements;
