import styled from '@emotion/styled';
import Icon from '../Icon';

// #63b581
export const Wrapper = styled.div<{ isActive: boolean }>`
    color: ${({ theme, isActive }) => (isActive ? theme.colors.green : theme.colors.grey)};
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-items: center;
    height: 100%;
    padding: 0 30px;
    margin-right: -24px;
`;

export const ActiveIcon = styled(Icon)`
    margin-right: 2px;
`;
