import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

const horizontalPadding = (screenClass: string): number => {
    switch (screenClass) {
        case 'xs':
        case 'sm':
        case 'md':
            return 1;
        case 'lg':
            return 4;
        default:
            return 6;
    }
};

export const Wrapper = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    background-color: ${({ theme }) => rgba(theme.colors.border, 0.25)};
`;

export const Content = styled.div<{ screenClass: string }>`
    padding: 14rem ${({ screenClass }) => horizontalPadding(screenClass)}rem 0;
`;

export const Circles = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1;
`;

export const Circle = styled.div<{ isLeft?: boolean; isRight?: boolean }>`
    width: clamp(40rem, 45vw, calc(100vh - 16rem));
    height: clamp(40rem, 45vw, calc(100vh - 16rem));
    flex: 0 0 clamp(16rem, 45vw, calc(100vh - 16rem));
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: clamp(16rem, 45vw, calc(100vh - 16rem));
    padding: 4rem;
    display: flex;
    flex-direction: column;
    aspect-ratio: 1;
    margin: 0 auto;

    ${({ isLeft, isRight }) =>
        (isLeft || isRight) &&
        css`
            width: clamp(12rem, 45vw, 24rem);
            height: clamp(12rem, 45vw, 24rem);
            flex: 0 0 clamp(12rem, 45vw, 12rem);
            border-radius: clamp(12rem, 45vw, 12rem);
            max-width: 22.5vw;
            aspect-ratio: 1 / 2;
            padding: 0;
            justify-content: center;
            margin: unset;

            img {
                height: 60%;
                width: auto;
            }
        `}

    ${({ isLeft }) =>
        isLeft &&
        css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            align-items: flex-start;
        `}

    ${({ isRight }) =>
        isRight &&
        css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            align-items: flex-end;
        `}

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const Logo = styled.div`
    position: absolute;
    left: 2rem;
    top: 2rem;

    img {
        height: 3.5rem;
        width: auto;
    }
`;

export const Text = styled.span`
    position: absolute;
    bottom: 2rem;
    //right: 6rem; terugzetten als chat weer ingeschakeld wordt
    right: 2rem;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 600;
`;
