import styled from '@emotion/styled';
import { rgba } from 'polished';

export const Wrapper = styled.div`
    background-color: #f2f2f2;
    align-self: flex-start;
    align-items: center;
    display: inline-flex;
    padding: 0.5rem;
    border-radius: 1.5rem;
`;

export const Label = styled.span`
    color: #727272;
    margin: 0 0.5rem;
`;

export const FormSwitch = styled.label`
    display: inline-block;
    width: 3rem;
    height: 2rem;
    position: relative;

    .switch {
        width: 3rem;
        height: 2rem;
        position: absolute;
        top: 0;
        background-color: ${({ theme }) => theme.greys[50]};
        border-radius: 1rem;
        transition: background 0.4s ease;

        &:hover {
            cursor: pointer;
        }

        &:before {
            position: absolute;
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            background-color: ${({ theme }) => theme.colors.white};
            box-shadow: 0 5px 20px ${({ theme }) => rgba(theme.colors.dark, 0.1)};
            position: absolute;
            top: 0.25rem;
            left: 0.25rem;
            border-radius: 0.75rem;
            transition: left 0.3s ease;
        }
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .switch {
            background-color: ${({ theme }) => theme.colors.primary};

            &:before {
                left: 1.25rem;
            }
        }
    }
`;
