import { useApi } from 'hooks/api';
import { CustomErrors } from 'hooks/api/types';
import { useQuery } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { buildUriWithParams } from 'utils/urihelper';
import { AddGeneralDocumentInputModel, DocumentModel, GetDocumentInputModel, SendDocumentMailInputModel } from './types';

export const allDocumentsQueryKey = 'documents.all';

const useDocuments = () => {

    const { get, post, deleteCall } = useApi();

    const baseUrl = '/document';

    const getDocuments = async (params?: GetDocumentInputModel) => {
        const url = buildUriWithParams(baseUrl, params);

        const response = await get<DocumentModel[]>(url);

        return response;
    };

    const useAllDocuments = (params?: GetDocumentInputModel) => {
        const queryKey = [allDocumentsQueryKey, params];

        return useQuery<DocumentModel[], CustomErrors[]>(queryKey, async () => {
            const response = await getDocuments(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const getDocumentFile = (documentId: string) => {
        const response = get(`${baseUrl}/${documentId}/file`);

        return response;
    };

    const sendDocumentMail = (id: string, values: SendDocumentMailInputModel) => {
        const response = post(`${baseUrl}/${id}/mail`, values);

        return response;
    };

    const addGeneralDocument = async (values: AddGeneralDocumentInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(baseUrl, fd);

        return response;
    };

    const deleteDocument = async (id: string) => {
        const response = await deleteCall(`${baseUrl}/${id}`);

        return response;
    };

    return {
        getDocuments,
        useAllDocuments,
        getDocumentFile,
        sendDocumentMail,
        addGeneralDocument,
        deleteDocument
    };
};

export default useDocuments;
