import styled from '@emotion/styled';

export const FormYesNo = styled.div`
    margin-bottom: 1rem;
    display: flex;
`;

export const ErrorWrapper = styled.div`
    margin-bottom: 1rem;
`;
