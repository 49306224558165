import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Date = styled.span`
    font-size: 0.875rem;
    line-height: 1.5;
`;

export const Title = styled.span`
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 600;
`;
