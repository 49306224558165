import styled from '@emotion/styled';

export const Wrapper = styled.div`
    .react-datepicker-popper {
        z-index: 999;
    }

    input {
        width: 100%;
        height: 3rem;
        border: 1px solid ${({ theme }) => theme.colors.grey};
        border-radius: ${({ theme }) => theme.borderRadius.small}px;
        padding: 0 16px;
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.primary};

        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.greys[50]};
        }

        &:hover {
            border-color: ${({ theme }) => theme.greys[100]};
        }

        ::placeholder {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;
