import React, { FC } from 'react';
import { Group as Wrapper } from './styles';

export interface GroupProps {
    spaceBetween?: boolean;
    right?: boolean;
    center?: boolean;

}

const Group: FC<GroupProps> = ({ children, spaceBetween, right, center }) => {
    return (
        <Wrapper spaceBetween={spaceBetween} right={right} center={center}>
            {children}
        </Wrapper>
    );
};

export default Group;
