import React, { FC } from 'react';
import { Modal as Wrapper, Overlay, Content, Header, Title, Subtitle, Close } from './styles';
import { Transition } from 'react-transition-group';
import Icon from 'components/atoms/Icon';

const DURATION = 300;

export interface ModalProps {
    inProp: boolean;
    onExited: () => void;
    onHide: () => void;
    clickOutsideClose?: boolean;
    size?: 'small' | 'medium' | 'large';
    title?: string;
    subtitle?: string;
}

const Modal: FC<ModalProps> = ({ children, inProp, onExited, onHide, clickOutsideClose = false, size = 'medium', title, subtitle }) => {
    return (
        <Transition in={inProp} onExited={onExited} timeout={DURATION}>
            {(state) => (
                <Overlay
                    state={state}
                    duration={DURATION}
                    onClick={
                        clickOutsideClose
                            ? onHide
                            : () => {
                                /* nothing */
                            }
                    }
                >
                    <Wrapper state={state} duration={DURATION} onClick={(e) => e.stopPropagation()} size={size}>
                        <Close onClick={onHide}>
                            <Icon name="delete" size={1} />
                        </Close>
                        {title && (
                            <Header>
                                <Title center noMargin={(!subtitle || subtitle === '') ? true : false}>{title}</Title>
                                {subtitle && <Subtitle center>{subtitle}</Subtitle>}
                            </Header>
                        )}
                        <Content size={size}>{children}</Content>
                    </Wrapper>
                </Overlay>
            )}
        </Transition>
    );
};

export default Modal;
