import { useApi } from 'hooks/api';
import { buildUriWithParams } from 'utils/urihelper';
import { GetRightInputModel, RightModel } from './types';

const useRights = () => {

    const baseUrl = '/right';

    const { get } = useApi();

    const getRights = async (params?: GetRightInputModel) => {
        const url = buildUriWithParams(baseUrl, params);

        const response = await get<RightModel[]>(url);

        return response;
    };

    return {
        getRights
    };
};

export default useRights;
