import { useField } from 'formik';
import React, { FC } from 'react';
import FormImage, { FormImageProps } from '..';

export interface FormikFormImageProps extends FormImageProps {
    name: string;
}

const FormikFormImage: FC<FormikFormImageProps> = ({ name, ...props }) => {
    const [field, meta, helpers] = useField<File>(name);

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormImage {...props} value={field.value} onChange={helpers.setValue} error={error} />;
};

export default FormikFormImage;
