import React, { FC, ReactNode } from 'react';
import { FormGroup as Wrapper, Label, Header, Text } from './styles';
import Tooltip from 'components/atoms/Tooltip';

interface FormGroupProps {
    label?: string;
    required?: boolean;
    alignRight?: boolean;
    text?: string | ReactNode;
    noMargin?: boolean;
    noMarginHeader?: boolean;
    tooltip?: string;
    isWhite?: boolean;
}

const FormGroup: FC<FormGroupProps> = ({ label, text, children, required, noMargin, alignRight, noMarginHeader, tooltip, isWhite }) => {
    return (
        <Wrapper noMargin={noMargin} alignRight={alignRight}>
            {(label || text) && (
                <Header noMargin={noMarginHeader}>
                    {label && (
                        <Label isWhite={isWhite} required={required}>
                            {label}
                        </Label>
                    )}
                    {text && <Text>{text}</Text>}
                    {tooltip && <Tooltip>{tooltip}</Tooltip>}
                </Header>
            )}
            {children}
        </Wrapper>
    );
};

export default FormGroup;
