import React, { FC, useMemo } from 'react';
import { H5 as Text, Wrapper, H3Icon as Icon } from './styles';
import { Colors } from 'theme/colors';
import { useTheme } from '@emotion/react';

export interface H5Props {
    variant?: keyof Colors;
    grey?: number;
    center?: boolean;
    className?: string;
    icon?: string;
}

const H5: FC<H5Props> = ({ children, variant, grey, center, className, icon }) => {
    const theme = useTheme();
    const color = useMemo(() => (variant ? theme.colors[variant] : grey ? theme.greys[grey] : theme.colors.secondary), [variant, grey]);

    return (
        <Wrapper center={center}>
            {icon && <Icon name={icon} color={color} />}
            <Text color={color} className={className}>
                {children}
            </Text>
        </Wrapper>
    );
};

export default H5;
