import React, { FC } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { useHeader } from 'contexts/headerContext';
import { H3, Paragraph } from 'components/atoms/text';

const Extras: FC<RouteComponentProps> = () => {
    useHeader('Extra\'s');

    // Render.
    return (
        <Card>
            <CardBody>
                <CardHeader borderBottom>
                    <H3>Hulpmiddelen bij uw debiteurenbeheer</H3>
                </CardHeader>
                <Paragraph>
                    Op basis van uw module(s) met Juresta kunt u gebruik maken van een aantal extra’s.
                </Paragraph>
                <Paragraph>
                    <b>
                        Voorbeeldbrieven voor het herinneren en aanmanen
                        <br />
                    </b>
                    Verstuur direct na het leveren van uw goederen of diensten een factuur en vermeld in uw factuur duidelijk uw betaaltermijn. Blijft de betaling van uw factuur uit? Verstuur dan een herinnering of aanmaning. Meer over herinneren en aanmanen en naar onze <Link to="/tools/templateletters">voorbeeldbrieven</Link>.
                </Paragraph>
                <Paragraph>
                    <b>
                        Bereken uw vertragingsrente
                        <br />
                    </b>
                    Met onze calculator berekent u eenvoudig de door u debiteur verschuldigde rente of incassokosten. <Link to="/tools/calculators">Direct</Link> naar de calculator.
                </Paragraph>
                <Paragraph>
                    <b>
                        Juresta-stempel
                        <br />
                    </b>
                    Met onze Juresta-stempel geeft u een duidelijk signaal af aan uw debiteuren. Veel van onze klanten zien een aanmerkelijke verbetering in het
                    betaalgedrag van hun debiteuren. <Link to="/tools/tools">Lees meer</Link> over onze Juresta-stempel of bestel direct uw eigen exemplaar.
                </Paragraph>

                <Paragraph>
                    <b>
                        Juresta-banner
                        <br />
                    </b>
                    Met onze Juresta-banner geeft ook online een duidelijk signaal af aan uw (potentiële) relaties.
                    U kunt de banner direct downloaden of doorsturen naar uw webdesigner. <Link to="/tools/banners">Direct</Link> naar de Juresta-banners.
                </Paragraph>
            </CardBody>
        </Card>
    );
};

export default Extras;
