import styled from '@emotion/styled';

export const ShopCard = styled.div`

`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Price = styled.span`
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
    white-space: nowrap;
`;
