import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface CardActionsProps {
    align?: 'center' | 'left' | 'right';
}

const CardActions = styled.div<CardActionsProps>`
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    ${({ align }) =>
        align &&
        css`
            justify-content: ${align === 'center' ? 'center' : (align === 'left' ? 'flex-start' : 'flex-end')};
    `}

`;

export default CardActions;
