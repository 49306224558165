import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/atoms/button';
import { Card, CardBody } from 'components/atoms/card';
import { FormGroup, FormikFormDate, FormikFormImage, FormikFormSelect, FormikFormTextarea } from 'components/atoms/form';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import Margin from 'components/atoms/Margin';
import { H3 } from 'components/atoms/text';
import Group from 'components/molecules/Group';
import { useHeader } from 'contexts/headerContext';
import { Form, Formik, FormikHelpers } from 'formik';
import useKnowledgeBaseItems from 'hooks/knowledgebaseitem';
import useKnowledgeBaseCategories from 'hooks/knowledgebasecategory';
import { EditKnowledgeBaseItemInputModel, KnowledgeBaseItemModel } from 'hooks/knowledgebaseitem/types';
import React, { FC } from 'react';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-toastify';
import { cdnFileUrl } from 'utils/cdnHelper';
import { toFormikErrors } from 'utils/errorhelper';
import { parseISO } from 'date-fns';
import WysiwygEditorFormik from 'components/atoms/form/WysiwygEditor/formik';

interface AddKnowledgeBaseItemRouteProps {
    location: {
        state: {
            item: KnowledgeBaseItemModel;
        };
    };
}

export interface AddKnowledgeBaseItemProps extends RouteComponentProps<AddKnowledgeBaseItemRouteProps> { }

interface CategoriesProps {
    value: string;
    label: string;
}

const AddKnowledgeBaseItem: FC<AddKnowledgeBaseItemProps> = (props) => {
    const { location } = props;

    const knowledgeBaseItem = location?.state.item;

    const { useAllKnowledgeBaseCategories } = useKnowledgeBaseCategories();
    const { data: knowledgeBaseCategories = [] } = useAllKnowledgeBaseCategories();
    const categories: Array<CategoriesProps> = knowledgeBaseCategories.map(
        (category): CategoriesProps => ({ value: category.id, label: category.name })
    );

    useHeader(knowledgeBaseItem != null ? 'Kennisbank item aanpassen' : 'Nieuw kennisbank item toevoegen');

    const { addKnowledgeBaseItem, editKnowledgeBaseItem } = useKnowledgeBaseItems();

    const initialValues: EditKnowledgeBaseItemInputModel = {
        id: knowledgeBaseItem?.id ?? '',
        title: knowledgeBaseItem?.title ?? '',
        description: knowledgeBaseItem?.description ?? '',
        content: knowledgeBaseItem?.content ?? '',
        categoryId: knowledgeBaseItem?.category?.id ?? '',
        image: knowledgeBaseItem?.image != null ? cdnFileUrl(knowledgeBaseItem?.image.hash) : undefined,
        publicationDate: knowledgeBaseItem?.publicationDate != null ? parseISO(knowledgeBaseItem?.publicationDate) : new Date()
    };

    const handleSubmit = async (
        values: EditKnowledgeBaseItemInputModel,
        { setErrors, setSubmitting }: FormikHelpers<EditKnowledgeBaseItemInputModel>
    ) => {
        if (values.id) {
            const response = await editKnowledgeBaseItem(values);

            if (response.ok) {
                navigate('/admin/knowledgebase');
                toast.success(`'${values.title}' succesvol opgeslagen`);
            } else if (response.errors) {
                const errors = toFormikErrors<EditKnowledgeBaseItemInputModel>(response.errors);
                setErrors(errors);
                toast.error('Niet alle velden zijn ingevuld');
            } else {
                toast.error('Oeps, er ging iets fout tijdens het aanpassen van het item. Probeer het later opnieuw!');
            }
        } else {
            const response = await addKnowledgeBaseItem(values);

            if (response.ok) {
                navigate('/admin/knowledgebase');
                toast.success(`'${values.title}' succesvol aangemaakt`);
            } else if (response.errors) {
                const errors = toFormikErrors<EditKnowledgeBaseItemInputModel>(response.errors);
                setErrors(errors);
                toast.error('Niet alle velden zijn ingevuld');
            } else {
                toast.error('Oeps, er ging iets fout tijdens het aanmaken van het item. Probeer het later opnieuw!');
            }
        }

        setSubmitting(false);
    };

    return (
        <Row>
            <Col xs={8}>
                <Card>
                    <CardBody>
                        <Margin bottom={2}>
                            <H3>{knowledgeBaseItem != null ? 'Kennisbank item aanpassen' : 'Nieuwe kennisbank item'}</H3>
                        </Margin>
                        <Card noShadow noMargin variant="light">
                            <CardBody>
                                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                                    {({ isSubmitting, isValid }) => (
                                        <Form>
                                            <Margin bottom={2}>
                                                <H3>Kennisbank item</H3>
                                                <FormGroup noMargin label="Titel" required>
                                                    <FormControlFormik name="title" placeholder="Geef het item een titel" />
                                                </FormGroup>
                                            </Margin>
                                            <FormGroup label="Categorie" required>
                                                <FormikFormSelect name="categoryId" options={categories} placeholder="Kies een categorie" />
                                            </FormGroup>
                                            <FormGroup label="Publicatie datum" required>
                                                <FormikFormDate name="publicationDate" />
                                            </FormGroup>
                                            <FormGroup label="Foto">
                                                <FormikFormImage name="image" />
                                            </FormGroup>
                                            <FormGroup label="Beschrijving" required>
                                                <FormikFormTextarea
                                                    name="description"
                                                />
                                            </FormGroup>
                                            <FormGroup noMargin label="Tekst" required>
                                                <Card noMargin>
                                                    <CardBody>
                                                        <WysiwygEditorFormik
                                                            name="content"
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </FormGroup>
                                            <Margin top={2}>
                                                <Group spaceBetween>
                                                    <Button type="button" onClick={() => navigate('/admin/knowledgebase')} size="lg">
                                                        Terug
                                                    </Button>
                                                    <Button type="submit" size="lg" disabled={!isValid} loading={isSubmitting}>
                                                        Opslaan
                                                    </Button>
                                                </Group>
                                            </Margin>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default AddKnowledgeBaseItem;

