import React, { FC } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { CurrencyWrapper } from './styles';
import { CurrencyInputProps } from 'react-currency-input-field';
import { useField } from 'formik';

export interface FormCurrencyProps extends CurrencyInputProps {
    name: string;
    decimals?: boolean;
    error?: string;
}

const FormCurrency: FC<FormCurrencyProps> = ({ decimals, error, ...props }) => {
    const [field, , helpers] = useField(props);

    const onValueChange = (value: string | undefined) => {
        helpers.setValue(value?.replace(',', '.'));
    };

    const value = field.value != null && field.value ? field.value.toString().replace('.', ',') : undefined;

    return (
        <>
            <CurrencyWrapper name={props.name} prefix="€ " value={value} onValueChange={onValueChange} decimalSeparator="," groupSeparator="." />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormCurrency;
