import styled from '@emotion/styled';
import { Link } from '@reach/router';
import { css } from '@emotion/react';
import Icon from '../Icon';

export const MenuItem = styled(Link)<{ active?: boolean }>`
    height: 3rem;
    list-style-type: none;
    transition: background 0.4s ease;
    display: flex;
    align-items: center;
    user-select: none;
    text-decoration: none;
    margin-left: 0;
    transition: margin 0.3s ease-out, padding 0.3s ease-out;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
    padding: 0 24px;
    position: relative;
    cursor: pointer;
`;

export const Notifications = styled.div`
    background-color: ${({ theme }) => theme.colors.secondary};
    height: 2rem;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.small}px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    font-size: 0.875rem;
`;

export const Title = styled.span<{ active?: boolean }>`
    color: ${({ theme, active }) => (active ? theme.colors.primary : 'inherit')};
    line-height: 1;
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 1rem;

    ${({ active }) =>
        active &&
        css`
            font-weight: 500;
            text-decoration: underline;
        `};
`;

export const TitleIcon = styled(Icon)<{ isOpen: boolean }>`
    margin-left: 8px;
    transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
    transition: transform 0.3s ease;
`;

export const Bullet = styled.div<{ active?: boolean }>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ theme, active }) => (active ? theme.colors.error : theme.colors.primary)};
    margin-right: 12px;
`;
