import { useSession } from 'contexts/sessionContext';
import { useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { buildUriWithParams } from 'utils/urihelper';
import { CollectionPlainModel, GetCollectionInputModel } from './types';

export const CollectionAllQueryKey = 'collection.all';

export default function useAllCollections() {
    const { session } = useSession();
    const { get } = useApi();

    const getAllCollections = async (params?: GetCollectionInputModel) => {

        const uri = buildUriWithParams('/collection', params);
        const { data } = await get<CollectionPlainModel[]>(uri);

        return data;
    };

    return useQuery<CollectionPlainModel[] | undefined>(CollectionAllQueryKey, () => getAllCollections({ customerId: session?.customerId }));
}
