import React, { FC } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Wrapper } from './styles';

export interface FormDateProps extends ReactDatePickerProps {
    error?: string;
}

const FormDate: FC<FormDateProps> = ({ error, ...props }) => {
    return (
        <Wrapper>
            <DatePicker
                {...props}
                dateFormat="dd-MM-yyyy"
                showYearDropdown
                showMonthDropdown
            />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </Wrapper>
    );
};

export default FormDate;
