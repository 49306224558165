import styled from '@emotion/styled';

export const Header = styled.div`
    position: relative;
    z-index: 1;
    top: 0;
    padding: ${({ theme }) => theme.navigation.offset}px 2rem 0;
    z-index: 100;
    margin: auto;
    max-width: 1200px;

    h1, h2 {
        margin-bottom: 0;
        font-size: 2rem;
        font-weight: 500;
    }
`;

export const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
`;
