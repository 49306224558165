import React, { FC } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { Col, Row } from 'react-grid-system';
import { Card, CardBody } from 'components/atoms/card';
import { Button } from 'components/atoms/button';
import { H3, Paragraph } from 'components/atoms/text';
import Margin from 'components/atoms/Margin';
import { useHeader } from 'contexts/headerContext';
import Group from 'components/molecules/Group';

const AdminDashboard: FC<RouteComponentProps> = () => {
    useHeader('Dashboard');

    return (
        <Row>
            <Col md={12} lg={12} xl={6} xxl={4}>
                <Card>
                    <CardBody>
                        <H3 icon="customer">Relaties</H3>
                        <Paragraph>
                            PLACEHOLDER
                        </Paragraph>
                        <Margin top="auto">
                            <Group center>
                                <Button variant="light" rounded endIcon="right" size="lg" onClick={() => navigate('/admin/customers/')}>
                                    Naar relatiebeheer
                                </Button>
                            </Group>
                        </Margin>
                    </CardBody>
                </Card>
            </Col>
            <Col md={12} lg={12} xl={6} xxl={4}>
                <Card variant="primary">
                    <CardBody>
                        <H3 icon="mail" variant="white">Correspondentie</H3>
                        <Paragraph variant="white">
                            PLACEHOLDER
                        </Paragraph>
                        <Margin top="auto">
                            <Group center>
                                <Button variant="light" rounded endIcon="right" size="lg" onClick={() => navigate('/admin/correspondence/')}>
                                    Naar overzicht
                                </Button>
                            </Group>
                        </Margin>
                    </CardBody>
                </Card>
            </Col>
            <Col md={12} lg={12} xl={6} xxl={4}>
                <Card>
                    {/* <Pattern height={80} size="sm" variant="secondary" absolute /> */}
                    <CardBody>
                        <H3 icon="print">
                            Voorbeeldbrieven
                        </H3>
                        <Paragraph>
                            PLACEHOLDER
                        </Paragraph>
                        <Margin top="auto">
                            <Group center>
                                <Button variant="light" rounded endIcon="right" size="lg" onClick={() => navigate('/admin/templateletters/')}>
                                    Naar overzicht
                                </Button>
                            </Group>
                        </Margin>
                    </CardBody>
                </Card>
            </Col>
            <Col md={12} lg={12} xl={6} xxl={4}>
                <Card variant="primary">
                    <CardBody>
                        <H3 icon="document" variant="white">Nieuwsberichten</H3>
                        <Paragraph variant="white">
                            PLACEHOLDER
                        </Paragraph>
                        <Margin top="auto">
                            <Group center>
                                <Button variant="light" rounded endIcon="right" size="lg" onClick={() => navigate('/admin/news/')}>
                                    Naar nieuwsberichten
                                </Button>
                            </Group>
                        </Margin>
                    </CardBody>
                </Card>
            </Col>
            <Col md={12} lg={12} xl={6} xxl={4}>
                <Card>
                    <CardBody>
                        <H3 icon="message">Kennisbank</H3>
                        <Paragraph>
                            PLACEHOLDER
                        </Paragraph>
                        <Margin top="auto">
                            <Group center>
                                <Button variant="light" rounded endIcon="right" size="lg" onClick={() => navigate('/admin/knowledgebase/')}>
                                    Naar kennisbank artikelen
                                </Button>
                            </Group>
                        </Margin>
                    </CardBody>
                </Card>
            </Col>
            <Col md={12} lg={12} xl={6} xxl={4}>
                <Card variant="primary">
                    {/* <Pattern height={80} size="sm" variant="secondary" absolute /> */}
                    <CardBody>
                        <H3 icon="stamp" variant="white">
                            Banners
                        </H3>
                        <Paragraph variant="white">
                            PLACEHOLDER
                        </Paragraph>
                        <Margin top="auto">
                            <Group center>
                                <Button variant="light" rounded endIcon="right" size="lg" onClick={() => navigate('/admin/banners/')}>
                                    Naar banner overzicht
                                </Button>
                            </Group>
                        </Margin>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default AdminDashboard;
