import { TemplateLetterPlainModel, DeleteTemplateLetterInputModel } from './types';
import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'hooks/api';
import { templateLetterQueryKey } from './useAllTemplateLetters';

const useDeleteAdminTemplateLetter = () => {

    const { deleteCall } = useApi();

    const baseUrl = '/templateletter';

    const queryClient = useQueryClient();

    const deleteTemplateLetter = async (values: DeleteTemplateLetterInputModel) => {

        const response = await deleteCall(`${baseUrl}/${values.id}`);

        if(!response.ok) {
            throw response;
        }

        return response;
    };


    const deleteTemplateLetterMutation = useMutation((values: DeleteTemplateLetterInputModel) => deleteTemplateLetter(values), {

        onMutate: async (values: DeleteTemplateLetterInputModel) => {
            
            await queryClient.cancelQueries(templateLetterQueryKey);

            const previousData = queryClient.getQueryData<TemplateLetterPlainModel[]>(templateLetterQueryKey);

            const filteredData = previousData?.filter(item => item.id !== values.id);
            queryClient.setQueryData<TemplateLetterPlainModel[]>(templateLetterQueryKey, filteredData ?? []);

            return { previousData };
        },
        onError: (err, variables, context) => {
            if(context?.previousData) {
                queryClient.setQueryData(templateLetterQueryKey, context.previousData);
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries(templateLetterQueryKey);
        }

    });

    return { deleteTemplateLetterMutation };
};

export default useDeleteAdminTemplateLetter;
