import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { FormGroup, FormikFormControl, FormikFormDate, FormikFormMultipleFile, FormikFormSelect } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'components/atoms/button';
import useAllCustomers from 'hooks/customer/useAllCustomers';
import { useMemo } from 'react';
import useAllTermsAndConditions from 'hooks/termsAndConditions/useAllTermsAndConditions';
import { AddTermsAndConditionsInputModel, SendTermsAndConditionsMailInputModel, TermsAndConditionsPlainModel } from 'hooks/termsAndConditions/types';
import useTermsAndConditions from 'hooks/termsAndConditions/useTermsAndConditions';
import { toFormikErrors } from 'utils/errorhelper';
import { toast } from 'react-toastify';
import Group from 'components/molecules/Group';
import { saveAs } from 'file-saver';
import { useHeader } from 'contexts/headerContext';
import { format, parseISO } from 'date-fns';

const columns: Column<TermsAndConditionsPlainModel>[] = [
    {
        Header: 'Relatienummer',
        accessor: (tac) => tac.customer.relationNumber
    },
    {
        Header: 'Bedrijfsnaam',
        accessor: (tac) => tac.customer.name
    },
    {
        Header: 'Algemene voorwaarden',
        accessor: (tac) => tac.fileName
    },
    {
        id: 'date',
        Header: 'Datum',
        accessor: (tac) => parseISO(tac.date).getTime(),
        Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
    },
    {
        Header: 'Versie',
        accessor: (tac) => tac.version
    }
];

const initialValues: AddTermsAndConditionsInputModel = {
    date: new Date(),
    version: '',
    customerId: '',
    files: undefined
};

const initialValuesSendMail: SendTermsAndConditionsMailInputModel = {
    toName: '',
    toEmail: ''
};

const TermsAndConditions: FC<RouteComponentProps> = () => {
    useHeader('Algemene voorwaarden');

    const { data: termsAndConditions = [], isLoading: isLoadingTermsAndConditions } = useAllTermsAndConditions();
    const { data: customers = [], isFetching: isLoadingCustomers } = useAllCustomers();
    const { termsAndConditionsMutation, deleteTermsAndConditionsMutation, getTermsAndConditionsFile, sendTermsAndConditionsMail } = useTermsAndConditions();

    const [termsToDelete, setTermsToDelete] = useState<TermsAndConditionsPlainModel>();
    const [termsToMail, setTermsToMail] = useState<TermsAndConditionsPlainModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [fileToPrint, setFileToPrint] = useState<string | undefined>();
    const printRef = React.createRef<any>();

    const customerOptions = useMemo(() => customers.map((c) => ({
        value: c.id,
        label: c.name
    })), [customers]);

    // Methods.
    const handleOnSubmit = (values: AddTermsAndConditionsInputModel, { setErrors }: FormikHelpers<AddTermsAndConditionsInputModel>) => {
        return termsAndConditionsMutation.mutateAsync(values, {
            onSuccess: () => {
                toast.success('Algemene voorwaarden is succesvol toegevoegd');
                hide();
            },
            onError: (error) => {
                if (error.errors) {
                    const errors = toFormikErrors<AddTermsAndConditionsInputModel>(error.errors);
                    setErrors(errors);
                    toast.error('Niet alle velden zijn ingevuld');
                } else {
                    toast.error('Er ging iets fout bij het toevoegen van de algemene voorwaarden');
                }
            }
        });
    };

    const handleDelete = async () => {
        if (!termsToDelete) {
            return;
        }

        setIsLoadingDelete(true);

        await deleteTermsAndConditionsMutation.mutateAsync(termsToDelete.id, {
            onSuccess: () => {
                toast.success('Algemene voorwaarden is succesvol verwijderd');
                closeDeleteModal();
            },
            onError: () => {
                toast.error('Er ging iets fout bij het verwijderen van de algemene voorwaarden');
            }
        });

        setIsLoadingDelete(false);
    };

    const handleMail = async (values: SendTermsAndConditionsMailInputModel, { setErrors }: FormikHelpers<SendTermsAndConditionsMailInputModel>) => {
        if (!termsToMail) {
            return;
        }

        const response = await sendTermsAndConditionsMail(termsToMail.id, values);

        if (response.ok) {
            toast.success('Algemene voorwaarden is succesvol verstuurd');
            closeMailModal();
        } else if (response.errors) {
            const errors = toFormikErrors<SendTermsAndConditionsMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de algemene voorwaarden');
        }
    };

    const downloadFile = async (termsAndConditions: TermsAndConditionsPlainModel) => {

        const response = await getTermsAndConditionsFile(termsAndConditions.id);

        if (response.ok) {
            saveAs(await response.blob(), termsAndConditions.fileName);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de algemene voorwaarden');
        }
    };

    const printFile = async (termsAndConditions: TermsAndConditionsPlainModel) => {

        const response = await getTermsAndConditionsFile(termsAndConditions.id);

        if (response.ok) {
            const url = window.URL.createObjectURL(await response.blob());
            setFileToPrint(url);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de algemene voorwaarden');
        }
    };

    // Formik.
    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hide} size="large" title="Algemene voorwaarden toevoegen">
                <Formik<AddTermsAndConditionsInputModel> initialValues={initialValues} onSubmit={handleOnSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup label="Algemene voorwaarden" required>
                                <FormikFormMultipleFile name="files" />
                            </FormGroup>
                            <FormGroup label="Relate" required>
                                <FormikFormSelect
                                    isLoading={isLoadingCustomers}
                                    name="customerId"
                                    options={customerOptions}
                                    placeholder="Selecteer één relatie"
                                />
                            </FormGroup>
                            <FormGroup label="Datum" required>
                                <FormikFormDate name="date" />
                            </FormGroup>
                            <FormGroup label="Versie" required>
                                <FormikFormControl name="version" placeholder="Versie" />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button loading={isSubmitting}>Toevoegen</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [customerOptions, isLoadingCustomers]
    );

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="large" title="Algemene voorwaarden verwijderen" subtitle="Weet je zeker dat je deze algemene voorwaarden wilt verwijderen?">
                <p><strong>Bedrijfsnaam:</strong> {termsToDelete?.customer.name}</p>
                <p><strong>Bestandsnaam:</strong> {termsToDelete?.fileName}</p>
                <p style={{ marginBottom: 15 }}><strong>Versie:</strong> {termsToDelete?.version}</p>
                <Group right>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [termsToDelete]
    );

    const openDeleteModal = (termsAndConditions: TermsAndConditionsPlainModel) => {
        setTermsToDelete(termsAndConditions);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setTermsToDelete(undefined);
        hideDeleteModal();
    };

    const [showMailModal, hideMailModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideMailModal} size="large" title="Algemene voorwaarden versturen" subtitle="Vul de gegevens in om de algemene voowaarden te versturen">
                <Formik<SendTermsAndConditionsMailInputModel> initialValues={initialValuesSendMail} onSubmit={handleMail}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <FormGroup label="Naam">
                                <FormikFormControl name="toName" placeholder="Naam" />
                            </FormGroup>
                            <FormGroup label="E-mailadres" required>
                                <FormikFormControl name="toEmail" placeholder="E-mailadres" />
                            </FormGroup>
                            <Group right>
                                <Button type="button" onClick={closeMailModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Versturen</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [termsToMail]
    );

    const openMailModal = (termsAndConditions: TermsAndConditionsPlainModel) => {
        setTermsToMail(termsAndConditions);
        showMailModal();
    };

    const closeMailModal = () => {
        setTermsToMail(undefined);
        hideMailModal();
    };

    // Render.
    return (
        <>
            <Card>
                <CardBody>
                    <Table<TermsAndConditionsPlainModel>
                        data={termsAndConditions}
                        isLoading={isLoadingTermsAndConditions}
                        noDataMessage="Geen algemene voorwaarden beschikbaar"
                        columns={columns}
                        search
                        onAddClick={show}
                        sortBy={[{ id: 'date', desc: true }]}
                        actions={[
                            {
                                icon: 'download',
                                onClick: downloadFile,
                                title: 'Download algemene voorwaarden'
                            },
                            {
                                icon: 'print',
                                onClick: printFile,
                                title: 'Print algemene voorwaarden'
                            },
                            {
                                icon: 'mail',
                                onClick: openMailModal,
                                title: 'Mail algemene voorwaarden'
                            },
                            {
                                icon: 'delete',
                                onClick: openDeleteModal,
                                title: 'Verwijder algemene voorwaarden'
                            }
                        ]}
                    />
                </CardBody>
            </Card>

            {
                fileToPrint &&
                <iframe ref={printRef} style={{ display: 'none' }} title="print" src={fileToPrint} onLoad={() => window.frames[0].print()}></iframe>
            }
        </>
    );
};

export default TermsAndConditions;
