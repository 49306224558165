import { RouteComponentProps } from '@reach/router';
import React, { FC, useState } from 'react';
import useModal from 'hooks/modal';
import { BannerPlainModel, EditBannerInputModel, BannerFormInputModel, AddBannerInputModel } from 'hooks/banner/types';
import { Column } from 'react-table';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import Modal from 'components/organisms/Modal';
import Wizard from 'components/organisms/Wizard';
import BannerData from './components/BannerData';
import BannerFile from './components/BannerFile';
import { FormikHelpers } from 'formik';
import useAllBanners from 'hooks/banner/useAllBanners';
import useAddAdminBanner from 'hooks/banner/useAddAdminBanner';
import useEditAdminBanner from 'hooks/banner/useEditAdminBanner';
import useDeleteAdminBanner from 'hooks/banner/useDeleteAdminBanner';
import { toast } from 'react-toastify';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { toFormikErrors } from 'utils/errorhelper';
import { useHeader } from 'contexts/headerContext';

export interface BannerProps extends RouteComponentProps { }

const columns: Column<BannerPlainModel>[] = [
    {
        Header: 'Naam',
        accessor: (banner) => banner.name
    },
    {
        Header: 'Beschrijving',
        accessor: (banner) => banner.description
    }
];

const ManageBanners: FC<BannerProps> = () => {
    useHeader('Banners');

    const { addBanner, validateStep1AddBanner } = useAddAdminBanner();
    const { editBannerMutation } = useEditAdminBanner();
    const { deleteBannerMutation } = useDeleteAdminBanner();
    const { data: banners = [], refetch, isLoading: isLoadingBanners } = useAllBanners();

    const [banner, setBanner] = useState<EditBannerInputModel>();
    const [bannerToDelete, setBannerToDelete] = useState<BannerPlainModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const initialValues: BannerFormInputModel = {
        id: banner?.id ?? '',
        name: banner?.name ?? '',
        description: banner?.description ?? '',
        bannerImage: banner?.bannerImage ?? undefined,
        imageHash: banner?.imageHash ?? ''
    };

    const handleshow = (item: BannerPlainModel) => {
        setBanner(item);
        show();
    };

    const handleOnHide = (hide: () => void) => {
        setBanner(undefined);
        hide();
    };

    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={() => handleOnHide(hide)} title="Banner toevoegen">
                <Wizard<BannerFormInputModel>
                    initialValues={initialValues}
                    initialStep={0}
                    onSubmitLabel="Opslaan"
                    onSubmit={handleBannerSubmit}
                    steps={[
                        {
                            step: 1,
                            title: 'Gegevens',
                            child: <BannerData />,
                            onSubmit: validateStep1
                        },
                        {
                            step: 2,
                            title: 'Bestand uploaden',
                            child: <BannerFile />
                        }
                    ]}
                />
            </Modal>
        ),
        [initialValues]
    );

    const validateStep1 = async (values: BannerFormInputModel, { setSubmitting, setErrors }: FormikHelpers<BannerFormInputModel>): Promise<boolean> => {
        setSubmitting(true);

        const response = await validateStep1AddBanner(values);

        if (response.ok) {
            return true;
        } else if (response.errors) {
            const errors = toFormikErrors<AddBannerInputModel>(response.errors);
            setErrors(errors);
            toast.error('Niet alle velden zijn ingevuld');
        }

        setSubmitting(false);
        return false;
    };

    const handleBannerSubmit = async (values: EditBannerInputModel, actions: FormikHelpers<EditBannerInputModel>) => {
        if (banner) {
            await editBannerMutation
                .mutateAsync(values)
                .then(() => {
                    refetch();
                    toast.success('Banner succesvol aangepast!');
                    setBanner(undefined);
                    hide();
                })
                .catch(() => {
                    toast.error('Oeps, er ging iets fout tijdens het aanpassen van de banner. Probeer het later opnieuw!');
                });
        } else {
            const response = await addBanner(values);

            if (response.ok) {
                toast.success('Banner succesvol aangemaakt!');
                hide();
            } else if (response.errors) {
                toast.error('Niet alle velden zijn ingevuld!');
                const errors = toFormikErrors<EditBannerInputModel>(response.errors);
                actions.setErrors(errors);
            } else {
                toast.error('Oeps, er ging iets fout tijdens het aanmaken van de banner. Probeer het later opnieuw!');
            }
        }
    };

    const handleBannerDelete = async () => {
        if (bannerToDelete === undefined) {
            return;
        }

        setIsLoadingDelete(true);

        await deleteBannerMutation
            .mutateAsync({ id: bannerToDelete.id })
            .then(() => {
                toast.success('Banner succesvol verwijderd!');
            })
            .catch(() => {
                toast.error('Oeps, er ging iets fout tijdens het verwijderen van de banner. Probeer het later opnieuw!');
            });

        setIsLoadingDelete(false);

        closeDeleteModal();
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={closeDeleteModal} size="large" title="Banner verwijderen" subtitle={`Weet je zeker dat je banner '${bannerToDelete?.name}' wilt verwijderen?`}>
                <Group right>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleBannerDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [bannerToDelete]
    );

    const openDeleteModal = (banner: BannerPlainModel) => {
        setBannerToDelete(banner);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setBannerToDelete(undefined);
        hideDeleteModal();
    };

    return (
        <Card>
            <CardBody>
                <Table<BannerPlainModel>
                    data={banners}
                    isLoading={isLoadingBanners}
                    noDataMessage="Geen banners beschikbaar"
                    columns={columns}
                    search
                    onAddClick={show}
                    actions={[
                        {
                            icon: 'edit',
                            onClick: (item) => handleshow(item),
                            title: 'Pas banner aan'
                        },
                        {
                            icon: 'delete',
                            onClick: (item) => openDeleteModal(item),
                            title: 'Verwijder banner'
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default ManageBanners;
