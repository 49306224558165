import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';

export const H4 = styled.h4<{ color: string; center?: boolean }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 1.375rem;
    line-height: 1.5rem;
    font-weight: 400;
`;

export const Wrapper = styled.div`
    display: flex;
    margin-bottom: 24px;
`;

export const H4Icon = styled(Icon)`
    margin-right: 8px;
`;
