import React from 'react';
import { UseTableOptions, useTable, useGlobalFilter, usePagination, useSortBy, SortingRule } from 'react-table';
import { Wrapper, Table as StyledTable, Th, Td, Tr, Sort } from './styles';
import Search from './components/Search';
import Footer from './components/Footer';
import TableLoading from '../TableLoading';
import useActions from './hooks/useActions';
import { Action } from 'components/atoms/Action';
import Margin from 'components/atoms/Margin';
import Icon from 'components/atoms/Icon';

// eslint-disable-next-line @typescript-eslint/ban-types
interface TableProps<T extends object> extends UseTableOptions<T> {
    search?: boolean;
    onAddClick?: () => void;
    onAddClickLabel?: string;
    onSettingsClick?: () => void;
    actions?: Action<T>[];
    isLoading?: boolean;
    cta?: () => void;
    ctaText?: string;
    noDataMessage?: string;
    hideTableHeader?: boolean;
    hideFooter?: boolean;
    hidePagination?: boolean;
    sortBy?: SortingRule<T>[];
    fullWidth?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function Table<T extends object>({
    columns,
    data,
    search,
    onAddClick,
    onAddClickLabel,
    onSettingsClick,
    actions,
    isLoading,
    cta,
    ctaText,
    noDataMessage,
    hideTableHeader,
    hideFooter,
    hidePagination,
    sortBy,
    fullWidth = false
}: TableProps<T>) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        setGlobalFilter,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        visibleColumns,
        state: { pageIndex, globalFilter },
        pageCount,
        gotoPage
    } = useTable<T>(
        {
            columns,
            data,
            initialState: {
                sortBy: sortBy ? sortBy : [{ id: '', desc: true }]
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useActions<T>(actions)
    );

    const currentData = hideFooter ? rows : page;

    // Render.
    return (
        <Wrapper>
            {search && (
                <Margin bottom={1}>
                    <Search filter={globalFilter} setFilter={setGlobalFilter} onAddClick={onAddClick} onAddClickLabel={onAddClickLabel} onSettingsClick={onSettingsClick} />
                </Margin>
            )}
            <StyledTable {...getTableProps()}>
                {!hideTableHeader && (
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            // eslint-disable-next-line react/jsx-key
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} id={column.id}>
                                        {column.render('Header')}
                                        <Sort>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <Icon name="chevron-down" size={1} />
                                                ) : (
                                                    <span className="rotate">
                                                        <Icon name="chevron-down" size={1} />
                                                    </span>
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </Sort>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </thead>
                )}
                {isLoading ? (
                    <TableLoading<T> columns={visibleColumns} />
                ) : (
                    <tbody {...getTableBodyProps()}>
                        {currentData.length ? (
                            currentData.map((row) => {
                                prepareRow(row);

                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <Tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            // eslint-disable-next-line react/jsx-key
                                            return <Td fullWidth={fullWidth} {...cell.getCellProps()}>{cell.render('Cell')}</Td>;
                                        })}
                                    </Tr>
                                );
                            })

                        ) : (
                            <Tr>
                                <td colSpan={visibleColumns.length}>
                                    <Margin bottom={1} top={1}>
                                        <p style={{ textAlign: 'center' }}>{noDataMessage ?? 'Geen informatie beschikbaar'}</p>
                                    </Margin>
                                </td>
                            </Tr>
                        )}
                    </tbody>
                )}
            </StyledTable>
            {/* {rows.length === 0 && (
                
                    <p style={{ textAlign: 'center' }}>{noDataMessage ?? 'Geen informatie beschikbaar'}</p>
                </Margin>
            )} */}
            {!hideFooter &&
                <Footer
                    cta={cta}
                    ctaText={ctaText}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    hidePagination={hidePagination}
                />
            }
        </Wrapper>
    );
}

export default Table;
