import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Image = styled.div<{ background?: string }>`
    background-color: ${({ theme }) => theme.colors.white};
    height: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 0 0 ${({ theme }) => theme.colors.light};

    ${({ background, theme }) =>
        background &&
        css`
            background-image: url('${background}');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: ${theme.colors.white};
        `}
`;

export const Logo = styled.img``;
