import React from 'react';
import { useTheme } from '@emotion/react';
import { Header } from './styles';
import Icon from 'components/atoms/Icon';
import Tooltip from '../Tooltip';
import Margin from '../Margin';

interface Action {
    title: string;
    onClick: () => void;
}

interface HeaderActionProps {
    title: string;
    tooltip?: string;
    action?: Action;
}

const HeaderActionComponent = ({ title, tooltip, action }: HeaderActionProps) => {
    const theme = useTheme();
    return (
        <Header>
            <p>{title}</p>
            {tooltip &&
                <Margin left={1}><Tooltip placement="top-center">{tooltip}</Tooltip></Margin>
            }
            <hr />
            {
                action &&
                <button onClick={action.onClick}>
                    <Icon name="edit" size={.85} color={theme.colors.primary} />
                    {action.title}
                </button>
            }
        </Header>
    );
};

export default HeaderActionComponent;
