import React, { FC, useState } from 'react';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Row, Col } from 'react-grid-system';
import { Card, CardActions, CardBody, CardContent, CardHeader } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import useAllCorrespondence from 'hooks/correspondence/useAllCorrespondence';
import { Button } from 'components/atoms/button';
import { orderBy } from 'lodash';
import { format, parseISO } from 'date-fns';
import { FormSelect } from 'components/atoms/form';
import { IOption } from 'components/atoms/form/FormSelect';
import { Options } from 'react-select';
import moment from 'moment';
import { useSession } from 'contexts/sessionContext';
import htmr from 'htmr';

interface MessagesProps extends RouteComponentProps {
    archive: boolean;
}

const Messages: FC<MessagesProps> = ({ archive = false }) => {
    const { session } = useSession();
    useHeader(archive ? 'Berichten archief' : 'Berichten');

    const [filter, setFilter] = useState<string>('');

    const { data: correspondence = [] } = useAllCorrespondence();
    const handleOnItemClick = (id: string) => {
        navigate(`/messages/${id}/detail`);
    };

    const handleOnCategoryChange = (option: IOption | Options<IOption> | null) => {
        if (option == null || option instanceof Array) {
            return;
        }

        if (typeof option.value === 'number') {
            return;
        }

        setFilter(option.value as string);
    };

    const categoryOptions = [{ value: '', label: 'Alles' }, { value: 'messages', label: 'Berichten' }, { value: 'questionnaires', label: 'Vragenlijsten' }];

    const sortedCorrespondence = orderBy(
        correspondence,
        (cor) => parseISO(cor.created).getTime(),
        ['desc']
    );

    const messages = filter !== 'questionnaires' ? sortedCorrespondence.filter(item => !item.isQuestionnaire) : [];
    const questionnaires = filter !== 'messages' ? sortedCorrespondence.filter(item => item.isQuestionnaire) : [];
    const totalMessages = messages.concat(questionnaires);

    let sortedMessages = orderBy(totalMessages, (item) => parseISO(item.created).getTime(), ['desc']);

    if (!archive) {
        sortedMessages = sortedMessages.slice(0, 6);
    }

    function parseVariables(text: string, date: string) {
        // add name
        text = text.replace('{{name}}', session?.fullName ?? '');

        // add date
        text = text.replace('{{date}}', moment(date).format('DD-MM-YYYY'));

        // add mail
        text = text.replace('{{mail}}', session?.username ?? '');

        return text;
    }

    return (
        <>
            <Row>
                <Col xl={6} offset={{ xl: 6 }}>
                    <Card fill variant="primary">
                        <CardBody>
                            <Row>
                                <Col style={{ display: 'flex', alignItems: 'center' }} md={6}>
                                    <Paragraph variant="white" noMargin>Filter op categorie</Paragraph>
                                </Col>
                                <Col md={6}>
                                    <FormSelect defaultValue={{ value: '', label: 'Alles' }} options={categoryOptions} onChange={handleOnCategoryChange} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                {sortedMessages.length === 0 &&
                    <H3 center>
                        Geen berichten gevonden
                    </H3>
                }
                {sortedMessages.map((item) => (
                    <Col md={4} key={item.id}>
                        <Card fill>
                            <CardBody>
                                <CardHeader flexDirection="column">
                                    <H3>{item.subject}</H3>
                                    <span>{format(parseISO(item.created), 'dd-MM-yyyy')}</span>
                                </CardHeader>
                                <CardContent>
                                    <Paragraph lines={4}>{htmr(parseVariables(item.content, item.created))}</Paragraph>
                                </CardContent>
                                <CardActions align="center">
                                    <Button onClick={() => handleOnItemClick(item.id)}>Lees verder</Button>
                                </CardActions>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            {
                !archive && totalMessages.length > 6 &&
                <Row>
                    <Col>
                        <Link to={'/messages-archive'}>Bekijk oudere berichten</Link>
                    </Col>
                </Row>
            }
        </>
    );
};

export default Messages;
