import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import { useHeader } from 'contexts/headerContext';
import { SendTermsAndConditionsTaskMailInputModel, TermsAndConditionsType } from 'hooks/termsAndConditions/types';
import useTermsAndConditions from 'hooks/termsAndConditions/useTermsAndConditions';
import { FormGroup, FormikFormFile, FormikFormTextarea, FormikFormControl } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import { toast } from 'react-toastify';
import { Button } from 'components/atoms/button';
import React, { FC } from 'react';

interface TranslationProps extends RouteComponentProps { }

const initialValues: SendTermsAndConditionsTaskMailInputModel = {
    file: undefined,
    comments: '',
    type: TermsAndConditionsType.Translation,
    language: ''
};

const Translation: FC<TranslationProps> = () => {
    useHeader('Algemene Voorwaarden');

    const { sendTermsAndConditionsTaskMail } = useTermsAndConditions();


    const handleMail = async (values: SendTermsAndConditionsTaskMailInputModel, { setErrors, resetForm }: FormikHelpers<SendTermsAndConditionsTaskMailInputModel>) => {
        const response = await sendTermsAndConditionsTaskMail(values);

        if (response.ok) {
            toast.success('Uw aanvraag voor vertaling is succesvol verstuurd');
            resetForm();
        } else if (response.errors) {
            const errors = toFormikErrors<SendTermsAndConditionsTaskMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de vertaal-aanvraag');
        }
    };

    return (
        <>
            <Card variant="primary">
                <CardBody>
                    <H3 variant="white">Vertalen algemene voorwaarden</H3>
                    <Paragraph variant="white">
                        Wanneer u zakendoet in het buitenland of anderstalige opdrachtgevers heeft, is een vertaling van uw algemene voorwaarden aan te bevelen. Met een vertaling van uw algemene voorwaarden sluit u het risico van onduidelijkheden door een taalbarrière zoveel mogelijk uit.
                    </Paragraph>
                    <Paragraph variant="white" noMargin>
                        Interesse in een vertaling (of vertalingen) van uw algemene voorwaarden? Wij sturen u graag vrijblijvend een prijsopgave.
                    </Paragraph>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <Formik<SendTermsAndConditionsTaskMailInputModel> initialValues={initialValues} onSubmit={handleMail}>
                        {({ isSubmitting }) => (
                            <Form>
                                <FormGroup label="Bijlage toevoegen" required>
                                    <FormikFormFile name="file" />
                                </FormGroup>
                                <FormGroup label="Taal" required>
                                    <FormikFormControl name="language" />
                                </FormGroup>
                                <FormGroup label="Vraag of opmerking">
                                    <FormikFormTextarea name="comments" placeholder="Opmerking" />
                                </FormGroup>
                                <FormGroup noMargin alignRight>
                                    <Button loading={isSubmitting}>Verzenden</Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    );
};

export default Translation;
