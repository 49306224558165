import React, { FC } from 'react';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Row, Col } from 'react-grid-system';
import { Card, CardActions, CardBody, CardContent, CardHeader } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import useNewsItems from 'hooks/newsitem';
import { Button } from 'components/atoms/button';
import { orderBy } from 'lodash';
import { format, parseISO } from 'date-fns';

interface MessagesProps extends RouteComponentProps {
    archive: boolean;
}

const NewsItem: FC<MessagesProps> = ({ archive }) => {
    useHeader(archive ? 'Nieuws archief' : 'Nieuws');

    const { useAllNewsItems } = useNewsItems();
    const { data: newsItems = [] } = useAllNewsItems();

    const handleOnItemClick = (id: string) => {
        navigate(`/news/${id}/detail`);
    };

    let sortedNewsItems = orderBy(newsItems, item => parseISO(item.publicationDate ?? new Date().toISOString()).getTime(), ['desc']);

    if (!archive) {
        sortedNewsItems = sortedNewsItems.slice(0, 6);
    }

    return (
        <>
            <Card fill variant="primary">
                <CardBody>
                    <Paragraph variant="white" noMargin>
                        Op deze pagina informeren wij over actuele zaken op bijvoorbeeld financieel of juridisch vlak.
                    </Paragraph>
                </CardBody>
            </Card>
            <Row>
                {sortedNewsItems.length === 0 &&
                    <H3 center>
                        Geen nieuws gevonden
                    </H3>
                }
                {sortedNewsItems.map((item) => (
                    <Col md={4} key={item.id}>
                        <Card fill>
                            <CardBody>
                                <CardHeader flexDirection="column">
                                    <H3>{item.title}</H3>
                                    {
                                        item.publicationDate &&
                                        <span>{format(parseISO(item.publicationDate), 'dd-MM-yyyy')}</span>
                                    }
                                </CardHeader>
                                <CardContent>
                                    <Paragraph lines={4}>{item.description}</Paragraph>
                                </CardContent>
                                <CardActions align="center">
                                    <Button onClick={() => handleOnItemClick(item.id)}>Lees verder</Button>
                                </CardActions>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            {
                !archive && newsItems.length > 6 &&
                <Row>
                    <Col>
                        <Link to={'/news-archive'}>Bekijk oudere berichten</Link>
                    </Col>
                </Row>
            }
        </>
    );
};

export default NewsItem;
