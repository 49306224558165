import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';

export const Navigation = styled.div`
    height: 100vh;
    flex: 0 0 ${({ theme }) => theme.navigation.width}px;
`;

export const Content = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const Header = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    img {
        margin: 1.75rem ${({ theme }) => theme.navigation.padding.horizontal}px;
        height: auto;
        width: 100%;
        max-width: 12rem;
        vertical-align: middle;
    }
`;

export const Footer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.large}px;
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.large}px;
`;

export const SignOut = styled.button`
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.secondary};

    &:hover {
        cursor: pointer;
    }
`;

export const SignOutIcon = styled(Icon)`
    margin-left: 8px;
`;
