import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import Wizard from 'components/organisms/Wizard';
import Step2 from './components/Step2';
import Step1 from './components/Step1';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import { AddCollectionInputModel } from 'hooks/collection/types';
import { FormikHelpers } from 'formik';
import useCollection from 'hooks/collection/useCollection';
import { toFormikErrors } from 'utils/errorhelper';
import { toast } from 'react-toastify';
import { useHeader } from 'contexts/headerContext';
import Margin from 'components/atoms/Margin';
import { persistCollectionKey } from 'utils/constants/localstrorageconstants';

const AddCollection: FC<RouteComponentProps> = () => {
    useHeader('Nieuwe Incasso - Opdracht');

    const { validateStep1AddCollection, validateStep3AddCollection, addCollection } = useCollection();

    const handleOnSubmit = async (
        values: AddCollectionInputModel,
        { setErrors, setSubmitting, resetForm }: FormikHelpers<AddCollectionInputModel>
    ) => {
        const response = await addCollection(values);
        if (response.ok && response.data) {
            setSubmitting(false);
            resetForm();
            localStorage.removeItem(persistCollectionKey);

            window.location.href = response.data;
        } else if (response.errors) {
            const errors = toFormikErrors<AddCollectionInputModel>(response.errors);
            setErrors(errors);
            toast.error('Niet alle velden zijn ingevuld');
        } else {
            toast.error('Er ging iets fout bij het toevoegen van de incasso');
        }

        setSubmitting(false);
    };

    const validateStep1 = async (
        values: AddCollectionInputModel,
        { setSubmitting, setErrors }: FormikHelpers<AddCollectionInputModel>
    ): Promise<boolean> => {
        setSubmitting(true);

        const response = await validateStep1AddCollection(values);

        if (response.ok) {
            setSubmitting(false);
            return true;
        } else if (response.errors) {
            const errors = toFormikErrors<AddCollectionInputModel>(response.errors);
            setErrors(errors);
            toast.error('Niet alle velden zijn ingevuld');
        }

        setSubmitting(false);
        return false;
    };

    const validateStep3 = async (
        values: AddCollectionInputModel,
        { setSubmitting, setErrors }: FormikHelpers<AddCollectionInputModel>
    ): Promise<boolean> => {
        setSubmitting(true);

        const response = await validateStep3AddCollection(values);

        if (response.ok) {
            setSubmitting(false);
            return true;
        } else if (response.errors) {
            const errors = toFormikErrors<AddCollectionInputModel>(response.errors);
            setErrors(errors);
            toast.error('Niet alle velden zijn ingevuld');
        }

        setSubmitting(false);
        return false;
    };

    // Formik.
    const initialValues: AddCollectionInputModel = {
        debtor: {
            isCompany: true,
            addressMail: '',
            addressVisit: '',
            cityMail: '',
            cityVisit: '',
            comments: '',
            companyName: '',
            contactPersonInitials: '',
            contactPersonLastName: '',
            dateOfBirth: undefined,
            gender: undefined,
            email: '',
            iban: '',
            kvkNumber: '',
            legalForm: undefined,
            mobileNumber: '',
            phoneNumber: '',
            postalCodeMail: '',
            postalCodeVisit: '',
            website: '',
            isMailAddressDifferent: false,
            relationNumber: 0
        },
        amount: 0,
        hasHistoryWithDebtor: false,
        historyWithDebtorWantToComment: false,
        historyWithDebtorComments: '',
        hasDebtorDefence: false,
        debtorDefenceFiles: undefined,
        debtorDefenceWantToComment: false,
        debtorDefenceComments: '',
        areTermsApplied: false,
        termsAppliedWantToComment: false,
        termsAppliedComments: '',
        termsFiles: undefined,
        hasSignedAgreements: false,
        signedAgreementsFiles: undefined,
        hasQuotation: false,
        quotationFiles: undefined,
        hasAssignmentLetter: false,
        assignmentLetterFiles: undefined,
        hasSignedDeliveryNote: false,
        signedDeliveryNoteFiles: undefined,
        writtenDocumentsComments: '',
        hasInvoices: false,
        invoiceFiles: undefined,
        hasReminders: false,
        reminderFiles: undefined,
        hasExhortation: false,
        exhortationFiles: undefined,
        hasPlan: false,
        planFiles: undefined,
        hasConversationNotes: false,
        conversationNotesFiles: undefined,
        hasPhoneNotes: false,
        phoneNotesFiles: undefined,
        hasAppOrSms: false,
        appOrSmsFiles: undefined,
        hasOtherCorrespondence: false,
        otherCorrespondenceFiles: undefined,
        isTaxesRequired: false,
        taxesComments: '',
        isStoryInformationRequested: false,
        hasAgreed: false,
        userFunction: ''
    };

    return (
        <Margin bottom={2}>
            <Wizard<AddCollectionInputModel>
                initialValues={initialValues}
                initialStep={0}
                onSubmit={handleOnSubmit}
                onSubmitLabel="Ondertekenen"
                persistKey={persistCollectionKey}
                steps={[
                    {
                        step: 1,
                        title: 'Zakelijk of particulier',
                        child: <Step1 />,
                        needsValidation: false
                    },
                    {
                        step: 2,
                        title: 'Gewenste debiteur',
                        child: <Step2 />,
                        onSubmit: validateStep1,
                        needsValidation: true
                    },
                    {
                        step: 3,
                        title: 'Gegevens vordering',
                        child: <Step3 />,
                        onSubmit: validateStep3,
                        needsValidation: true
                    },
                    {
                        step: 4,
                        title: 'Indienen incasso',
                        child: <Step4 />
                    }
                ]}
            />
        </Margin>
    );
};

export default AddCollection;
