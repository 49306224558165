import styled from '@emotion/styled';

export const List = styled.form`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    font-weight: 500;
`;

export const ListItem = styled.div`
    padding: .75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        margin-bottom: 0;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    }
`;

export const Label = styled.label`
    display: inline-block;
    width: 2.5rem;
    height: 1.5rem;
    position: relative;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + div {
            background-color: ${({ theme }) => theme.colors.primary};

            &:before {
                left: 1.25rem;
            }
        }
    }
`;

export const Switch = styled.div`
    width: 2.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0;
    background-color: ${({ theme }) => theme.colors.light};
    border-radius: 1rem;
    transition: background 0.4s ease;

    &:hover {
        cursor: pointer;
    }

    &:before {
        position: absolute;
        content: '';
        width: 1rem;
        height: 1rem;
        background-color: ${({ theme }) => theme.colors.white};
        position: absolute;
        top: .25rem;
        left: .25rem;
        border-radius: 0.75rem;
        transition: left 0.3s ease;
    }
`;