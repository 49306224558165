import { useApi } from 'hooks/api';
import { buildUriWithParams } from 'utils/urihelper';
import { EditUserInfoRequestModel, GetEditUserInfoRequestModel } from './types';

const useEditUserInfoRequests = () => {

    const { get, put } = useApi();

    const baseUrl = '/EditUserInfoRequest';

    const getEditUserInfoRequests = async (params?: GetEditUserInfoRequestModel) => {
        const url = buildUriWithParams(baseUrl, params);

        const response = await get<EditUserInfoRequestModel[]>(url);

        return response;
    };

    const completeEditUserInfoRequest = async (id: string) => {
        const response = await put(`${baseUrl}/${id}/complete`);

        return response;
    };

    return {
        getEditUserInfoRequests,
        completeEditUserInfoRequest
    };
};

export default useEditUserInfoRequests;
