import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Wrapper = styled.div`
    display: flex;
    > button {
        height: 3rem;
        white-space: nowrap;
        width: 11rem;
    }
`;

export const FilesWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 3rem;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 1rem;
    margin-right: 1rem;
    width: calc(100% - 11rem);
    
    div:last-of-type {
        margin-right: 0;
    }
`;

export const File = styled.div<{ noRemoveIcon?: boolean }>`
    position: relative;
    display: inline-block;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    margin-right: .75rem;
    border-radius: 1rem;
    padding: .5rem .75rem;
    padding-right: 2rem;
    line-height: 1;
    font-weight: 500;
    font-size: .75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    ${({ noRemoveIcon }) =>
    noRemoveIcon &&
        css`
        padding-right: .75rem;
    `}
`;

export const Remove = styled.button`
    position: absolute;
    right: .75rem;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    cursor: pointer;
    background: none;
    border: 0;
    outline: 0;
    line-height: 1;
`;
