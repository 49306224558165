import { RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import { useHeader } from 'contexts/headerContext';
import { SendTermsAndConditionsTaskMailInputModel, TermsAndConditionsType } from 'hooks/termsAndConditions/types';
import useTermsAndConditions from 'hooks/termsAndConditions/useTermsAndConditions';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import Group from 'components/molecules/Group';
import { toast } from 'react-toastify';
import { Button } from 'components/atoms/button';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import React, { FC, useEffect, useState } from 'react';
import { MaintenanceContractPlainModel, SendMaintenanceContractMailInputModel } from 'hooks/maintenancecontract/types';
import useMaintenanceContract from 'hooks/maintenancecontract';
import { Right } from 'utils/constants';
import { useSession } from 'contexts/sessionContext';
import { Loading } from 'components/atoms/button/Button/styles';
import { FormGroup, FormikFormControl, FormikFormTextarea } from 'components/atoms/form';
import Label from 'components/atoms/Label';
import { Module } from 'utils/constants/moduleconstants';
import useModules from 'hooks/modules/useModules';
import { ActivateModuleInputModel } from 'hooks/modules/types';
import { format, parseISO } from 'date-fns';

interface MaintenanceProps extends RouteComponentProps { }

const initialValues: SendTermsAndConditionsTaskMailInputModel = {
    file: undefined,
    comments: '',
    type: TermsAndConditionsType.MaintenanceRequest
};

const initialValuesSendMail: SendMaintenanceContractMailInputModel = {
    toEmail: ''
};

const MaintenanceContracts: FC<MaintenanceProps> = () => {
    useHeader('Algemene Voorwaarden');

    const columns: Column<MaintenanceContractPlainModel>[] = [
        {
            Header: 'Document',
            accessor: (tac) => tac.fileName
        },
        {
            Header: 'Datum',
            accessor: (tac) => parseISO(tac.date).getTime(),
            Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
        },
        {
            Header: 'Versie',
            accessor: (tac) => tac.version
        }
    ];

    const [hasRights, setHasRights] = useState(false);
    const { session, hasRight } = useSession();
    const { sendTermsAndConditionsTaskMail } = useTermsAndConditions();
    const { useAllMaintenanceContracts, sendMaintenanceContractMail, getMaintenanceContractFile } = useMaintenanceContract();
    const { data: maintenanceContracts = [], isLoading: isLoadingMaintenanceContracts } = useAllMaintenanceContracts({ customerId: session?.customerId });

    const [maintenanceContractToMail, setMaintenanceContractToMail] = useState<MaintenanceContractPlainModel>();
    const [fileToPrint, setFileToPrint] = useState<string | undefined>();
    const printRef = React.createRef<any>();

    const { activateModule } = useModules();

    const activateModules = async (values: ActivateModuleInputModel, { setSubmitting, setErrors }: FormikHelpers<ActivateModuleInputModel>) => {
        if (!values) {
            return;
        }

        const response = await activateModule(values);

        if (response.ok) {
            toast.success('Uw informatie aanvraag voor de module algemene voorwaarden is succesvol verstuurd');
            hide();
        } else if (response.errors) {
            toast.error('Niet alle velden zijn ingevuld');
            const errors = toFormikErrors<ActivateModuleInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de aanvraag voor de module algemene voorwaarden');
        }

        setSubmitting(false);
    };
    useEffect(() => {
        setHasRights(hasRight(Right.GeneralConditions));
    }, [session]);
    const handleTaskMail = async (values: SendTermsAndConditionsTaskMailInputModel, { setErrors, resetForm }: FormikHelpers<SendTermsAndConditionsTaskMailInputModel>) => {
        const response = await sendTermsAndConditionsTaskMail(values);

        if (response.ok) {
            toast.success('Uw aanvraag tot onderhoud is succesvol gestuurd. Wij nemen per ommegaande contact met u op.');
            hideTaskMailModal();
            resetForm();
        } else if (response.errors) {
            const errors = toFormikErrors<SendTermsAndConditionsTaskMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van het onderhouds-verzoek.');
        }
    };

    const handleMail = async (values: SendMaintenanceContractMailInputModel, { setErrors }: FormikHelpers<SendMaintenanceContractMailInputModel>) => {
        if (!maintenanceContractToMail) {
            return;
        }

        const response = await sendMaintenanceContractMail(maintenanceContractToMail.id, values);

        if (response.ok) {
            toast.success('Onderhoudscontract is succesvol verstuurd');
            closeTaskMailModal();
        } else if (response.errors) {
            const errors = toFormikErrors<SendMaintenanceContractMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van het onderhoudscontract');
        }
    };

    const downloadFile = async (maintenanceContractAndConditions: MaintenanceContractPlainModel) => {

        const response = await getMaintenanceContractFile(maintenanceContractAndConditions.id);

        if (response.ok) {
            saveAs(await response.blob(), maintenanceContractAndConditions.fileName);
        } else {
            toast.error('Er ging iets fout bij het ophalen van het onderhoudscontract');
        }
    };

    const printFile = async (maintenanceContractAndConditions: MaintenanceContractPlainModel) => {

        const response = await getMaintenanceContractFile(maintenanceContractAndConditions.id);

        if (response.ok) {
            const url = window.URL.createObjectURL(await response.blob());
            setFileToPrint(url);
        } else {
            toast.error('Er ging iets fout bij het ophalen van het onderhoudscontract');
        }
    };

    const [showTaskMailModal, hideTaskMailModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideTaskMailModal} size="large" title="Aanvraag tot onderhoud">
                <Paragraph>
                    Informeer vrijblijvend naar de mogelijkheden voor een onderhoudscontract voor uw algemene voorwaarden.
                    <br />
                </Paragraph>

                <Formik<SendTermsAndConditionsTaskMailInputModel> initialValues={initialValues} onSubmit={handleTaskMail}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <Group right>
                                <Button type="button" onClick={closeTaskMailModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Versturen</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [initialValues]
    );

    const openTaskMailModal = () => {
        showTaskMailModal();
    };

    const closeTaskMailModal = () => {
        hideTaskMailModal();
    };

    const [showMailModal, hideMailModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideMailModal} size="large" title="onderhoudscontract versturen" subtitle="Vul een e-mailadres in om het onderhoudscontract te versturen">
                <Formik<SendMaintenanceContractMailInputModel> initialValues={initialValuesSendMail} onSubmit={handleMail}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <FormGroup label="E-mailadres" required>
                                <FormikFormControl name="toEmail" placeholder="E-mailadres" />
                            </FormGroup>
                            <Group right>
                                <Button type="button" onClick={closeMailModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Versturen</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [maintenanceContractToMail]
    );

    const openMailModal = (maintenanceContract: MaintenanceContractPlainModel) => {
        setMaintenanceContractToMail(maintenanceContract);
        showMailModal();
    };

    const closeMailModal = () => {
        setMaintenanceContractToMail(undefined);
        hideMailModal();
    };

    const activateModuleInitialValues: ActivateModuleInputModel = {
        type: Module.TermsAndConditions,
        question: ''
    };

    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hide} size="small" title="Informatie aanvragen">
                <Formik<ActivateModuleInputModel> initialValues={activateModuleInitialValues} onSubmit={activateModules}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Paragraph>Met onderstaande formulier vraagt u vrijblijvend informatie aan over onderhoudscontract (onderdeel van de algemene voorwaarden module) voor uw bedrijf. Geeft u bij het opmerkingenveld alvast een korte omschrijving van uw bedrijfsactiviteiten eventueel aangevuld met specifieke zaken die u wilt vastleggen of waar u tegenaan loopt.</Paragraph>
                            <FormGroup label="Vraag en/of opmerking" required>
                                <FormikFormTextarea name="question" />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button loading={isSubmitting}>Aanvragen</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        []
    );

    // If no MaintenanceContracts
    const NoContracts = () => {
        return (
            <Card>
                <CardBody>
                    <H3>Onderhoudscontracten</H3>
                    <Paragraph>
                        Met een onderhoudscontract algemene voorwaarden bent u verzekerd van algemene voorwaarden. U maakt nog geen gebruik van het onderhoudscontract algemene voorwaarden. Vraag vrijblijvend informatie aan over de mogelijkheden, wij informeren u graag.
                    </Paragraph>
                    <Button onClick={openTaskMailModal}>informatie onderhoudscontract</Button>
                </CardBody>
            </Card>
        );
    };

    return !hasRights ? (
        <>
            <Card>
                <CardBody>
                    <CardHeader borderBottom>
                        <H3>Onderhoudscontract algemene voorwaarden <Label text="Nog niet actief" /></H3>
                        <Button size="lg" rounded endIcon="plus" onClick={show}>Informatie aanvragen</Button>
                    </CardHeader>
                    <Paragraph>
                        Met een onderhoudscontract algemene voorwaarden bent u verzekerd van actuele voorwaarden die aansluiten bij uw bedrijfsactiviteiten. Heeft u nog geen onderhoudscontract algemene voorwaarden?
                    </Paragraph>
                    <Paragraph>
                        Wij informeren u graag over de mogelijkheden voor uw voorwaarden.
                    </Paragraph>
                </CardBody>
            </Card>
        </>
    ) : (
        isLoadingMaintenanceContracts
            ? <Loading />
            : maintenanceContracts.length > 0
                ? <>
                    <Card>
                        <CardBody>
                            <H3>Onderhoudscontracten</H3>
                            <Paragraph>
                                Met een onderhoudscontract algemene voorwaarden bent u verzekerd van actuele voorwaarden die aansluiten bij uw bedrijfsactiviteiten. Hieronder vindt u een kopie van uw onderhoudscontract.
                            </Paragraph>
                        </CardBody>
                        <CardBody>
                            <H3>Documenten</H3>
                            <Table<MaintenanceContractPlainModel>
                                data={maintenanceContracts}
                                noDataMessage="Geen documenten beschikbaar"
                                columns={columns}
                                isLoading={isLoadingMaintenanceContracts}
                                actions={[
                                    {
                                        icon: 'download',
                                        onClick: (item) => downloadFile(item),
                                        title: 'Download document'
                                    },
                                    {
                                        icon: 'print',
                                        onClick: (item) => printFile(item),
                                        title: 'Print document'
                                    },
                                    {
                                        icon: 'mail',
                                        onClick: (item) => openMailModal(item),
                                        title: 'Mail document'
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>

                    {fileToPrint && <iframe ref={printRef} title="print" src={fileToPrint} onLoad={() => window.frames[0].print()}></iframe>}
                </>
                : <NoContracts />
    );
};

export default MaintenanceContracts;
