import { useApi } from 'hooks/api';
import { TemplateLetterSendMailModel } from './types';

const useSendTemplateLetterMail = () => {
    const baseUrl = '/templateletter';
    const { post } = useApi();

    const sendTemplateLetterMail = async (values: TemplateLetterSendMailModel) => {
        const response = await post(
            `${baseUrl}/mail`,
            values
        );

        return response;
    };

    return {
        sendTemplateLetterMail
    };
};

export default useSendTemplateLetterMail;
