import styled from '@emotion/styled';

export const Header = styled.div`
    position: relative;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    font-size: 1.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    hr {
        flex-grow: 1;
        margin: 0 1rem;
        border: 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.border};
        height: 0;
    }

    button {
        background: none;
        cursor: pointer;
        padding: 0;
        color: inherit;
        font-weight: 500;
        display: inline-flex;
        font-size: .8rem;
        align-items: center;
        border: 0;
        outline: 0;
        box-shadow: none;
        .icon-wrapper {
            margin-right: .35rem;
        }
    }
`;
