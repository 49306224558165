import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { H3, H5, Paragraph } from 'components/atoms/text';
import useKnowledgeBaseItems from 'hooks/knowledgebaseitem';
import { Loading } from 'components/atoms/button/Button/styles';
import { cdnFileUrl } from 'utils/cdnHelper';
import Margin from 'components/atoms/Margin';
import { useHeader } from 'contexts/headerContext';
import { format, parseISO } from 'date-fns';
import { Button } from 'components/atoms/button';
import htmr from 'htmr';

interface KnowledgeBaseDetailProps extends RouteComponentProps {
    id?: string;
}

const KnowledgeBaseDetail: FC<KnowledgeBaseDetailProps> = ({ id = '' }) => {
    useHeader('Kennisbank');

    const { useAllKnowledgeBaseItems } = useKnowledgeBaseItems();
    const { data: knowledgeBaseItems = [] } = useAllKnowledgeBaseItems({
        id
    });

    const knowledgeBaseItem = knowledgeBaseItems[0];

    return knowledgeBaseItem == null ? <Loading /> : (
        <>
            <Margin bottom={1}>
                <Button variant="primary" onClick={() => window.history.back()}>Terug</Button>
            </Margin>
            <Card>
                <CardBody>
                    <CardHeader flexDirection="column">
                        <H5>{knowledgeBaseItem.category?.name}</H5>
                        <H3>{knowledgeBaseItem.title}</H3>
                        <span>{format(parseISO(knowledgeBaseItem.publicationDate), 'dd-MM-yyyy')}</span>
                    </CardHeader>
                    {
                        knowledgeBaseItem.image != null &&
                        <Margin bottom={2}>
                            <img alt="" src={cdnFileUrl(knowledgeBaseItem.image.fileName)} style={{ maxWidth: 500 }} />
                        </Margin>
                    }
                    <Paragraph>
                        {htmr(knowledgeBaseItem.content)}
                    </Paragraph>
                </CardBody>
            </Card>
        </>
    );
};

export default KnowledgeBaseDetail;
