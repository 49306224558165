import { useApi } from 'hooks/api';
import { ActivateModuleInputModel, EditCustomerModuleInputModel } from './types';

const useModules = () => {

    const { post, put } = useApi();

    const baseUrl = '/modules';

    const activateModule = async (values: ActivateModuleInputModel) => {
        const response = post<ActivateModuleInputModel>(baseUrl, values);

        return response;
    };

    const editCustomerModule = async (values: EditCustomerModuleInputModel) => {
        const response = await put(baseUrl, values);

        return response;
    };

    return {
        activateModule,
        editCustomerModule
    };
};

export default useModules;
