import { useQueryClient } from 'react-query';
import { templateLetterQueryKey } from './useAllTemplateLetters';

const usePurgeTemplateLetterCache = () => {

    const queryClient = useQueryClient();

    const purgeTemplateLetterCache = () => {
        queryClient.removeQueries(templateLetterQueryKey, { exact: true });
    };

    return { purgeTemplateLetterCache };
};

export default usePurgeTemplateLetterCache;
