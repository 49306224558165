import styled from '@emotion/styled';

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    height: 4rem;
    border-top: 1px solid ${({ theme }) => theme.colors.primary};
`;

export const Text = styled.span`
    display: flex;
    flex-direction: row no-wrap;
`;

export const Link = styled.a`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.875rem;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};

    &:hover {
        cursor: pointer;
    }

    span {
        align-items: center;
    }
`;
