import React from 'react';
import { Table } from './styles';

interface Item {
    key: string;
    value: string | boolean;
    required?: boolean;
    include?: boolean;
}

interface KeyValueProps {
    items: Item[];
}

const KeyValueComponent = ({ items }: KeyValueProps) => {
    return (
        <Table>
            <tbody>
                {
                    items.map((item, index) =>
                        item.include &&
                        <tr key={index}>
                            <td>{item.key}</td>
                            <td>{item.value} {item.required && <span>*</span>}</td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
    );
};

export default KeyValueComponent;
