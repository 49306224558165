import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/atoms/button';
import { Card, CardBody } from 'components/atoms/card';
import { FormGroup, FormikFormDate, FormikFormImage, FormikFormTextarea } from 'components/atoms/form';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import WysiwygEditorFormik from 'components/atoms/form/WysiwygEditor/formik';
import Margin from 'components/atoms/Margin';
import { H3 } from 'components/atoms/text';
import Group from 'components/molecules/Group';
import { useHeader } from 'contexts/headerContext';
import { parseISO } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import useNewsItems from 'hooks/newsitem';
import { EditNewsItemInputModel, NewsItemModel } from 'hooks/newsitem/types';
import React, { FC } from 'react';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-toastify';
import { cdnFileUrl } from 'utils/cdnHelper';
import { toFormikErrors } from 'utils/errorhelper';

interface AddKNowledgeBaseItemRouteProps {
    location: {
        state: {
            item: NewsItemModel;
        };
    };
}

export interface AddNewsItemProps extends RouteComponentProps<AddKNowledgeBaseItemRouteProps> {
}

const AddNewsItem: FC<AddNewsItemProps> = (props) => {

    const {
        location
    } = props;

    const newsItem = location?.state.item;

    useHeader(newsItem != null ? 'Nieuwsbericht aanpassen' : 'Nieuw nieuwsbericht toevoegen');

    const { addNewsItem, editNewsItem } = useNewsItems();

    const initialValues: EditNewsItemInputModel = {
        id: newsItem?.id ?? '',
        title: newsItem?.title ?? '',
        description: newsItem?.description ?? '',
        content: newsItem?.content ?? '',
        image: newsItem?.image != null ? cdnFileUrl(newsItem?.image.hash) : undefined,
        publicationDate: newsItem?.publicationDate != null ? parseISO(newsItem?.publicationDate) : new Date()
    };

    const handleSubmit = async (values: EditNewsItemInputModel, { setErrors, setSubmitting }: FormikHelpers<EditNewsItemInputModel>) => {
        if (values.id) {
            const response = await editNewsItem(values);

            if (response.ok) {
                navigate('/admin/news');
                toast.success(`'${values.title}' succesvol opgeslagen`);
            } else if (response.errors) {
                const errors = toFormikErrors<EditNewsItemInputModel>(response.errors);
                setErrors(errors);
                toast.error('Niet alle velden zijn ingevuld');
            } else {
                toast.error('Oeps, er ging iets fout tijdens het aanpassen van het nieuwsbericht. Probeer het later opnieuw!');
            }
        } else {
            const response = await addNewsItem(values);

            if (response.ok) {
                navigate('/admin/news');
                toast.success(`'${values.title}' succesvol aangemaakt`);
            } else if (response.errors) {
                const errors = toFormikErrors<EditNewsItemInputModel>(response.errors);
                setErrors(errors);
                toast.error('Niet alle velden zijn ingevuld');
            } else {
                toast.error('Oeps, er ging iets fout tijdens het aanmaken van het nieuwsbericht. Probeer het later opnieuw!');
            }

        }

        setSubmitting(false);
    };

    return (
        <Row>
            <Col xs={8}>
                <Card>
                    <CardBody>
                        <Margin bottom={2}>
                            <H3>{newsItem != null ? 'Nieuwsbericht aanpassen' : 'Nieuwe nieuwsbericht'}</H3>
                            <p>
                                Voeg hier een nieuw Nieuws-item toe.
                            </p>
                        </Margin>
                        <Card noShadow noMargin variant="light">
                            <CardBody>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting, isValid }) => (
                                        <Form>
                                            <Margin bottom={2}>
                                                <H3>Nieuwsbericht</H3>
                                                <FormGroup noMargin label="Titel">
                                                    <FormControlFormik name="title" placeholder="Geef het nieuwsbericht een titel" />
                                                </FormGroup>
                                            </Margin>
                                            <FormGroup label="Publicatie datum" required>
                                                <FormikFormDate name="publicationDate" />
                                            </FormGroup>
                                            <FormGroup label="Foto">
                                                <FormikFormImage name="image" />
                                            </FormGroup>
                                            <FormGroup label="Beschrijving" required>
                                                <FormikFormTextarea
                                                    name="description"
                                                />
                                            </FormGroup>
                                            <FormGroup noMargin label="Tekst">
                                                <Card noMargin>
                                                    <CardBody>
                                                        <WysiwygEditorFormik
                                                            name="content"
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </FormGroup>
                                            <Margin top={2}>
                                                <Group spaceBetween>
                                                    <Button type="button" onClick={() => navigate('/admin/news')} size="lg">Terug</Button>
                                                    <Button type="submit" size="lg" disabled={!isValid} loading={isSubmitting}>Opslaan</Button>
                                                </Group>
                                            </Margin>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default AddNewsItem;
