import { useEffect, useState } from 'react';
import { navigate } from '@reach/router';

import { Role } from 'utils/constants';
import { defaultAreaRoute } from 'utils/areaHelper';
import { useSession } from 'contexts/sessionContext';

export const useAreaAuthorization = (initialized: boolean, role?: Role) => {
    const { session } = useSession();
    const [initializedAuth, setInitializedAuth] = useState(false);

    useEffect(() => {
        if (session != null && initialized) {
            if (session.role === role) {
                setInitializedAuth(true);
            } else {
                const areaRoute = defaultAreaRoute(session.role);
                let path = window.location.pathname;

                if (session.role !== Role.Admin && path.startsWith('/admin')) {
                    path = path.substring(7);
                }

                const route = `${areaRoute}${path}`;
                navigate(route);
            }
        }

        if (initializedAuth && session == null) {
            navigate('/account/');
            return;
        }
    }, [session, initialized, initializedAuth]);

    return { initializedAuth };
};

export const useAdminAreaAuthorization = (initialized: boolean) => {
    const { session } = useSession();
    const [initializedAuth, setInitializedAuth] = useState(false);

    useEffect(() => {
        if (session != null && initialized) {
            if (session.role === Role.Admin) {
                setInitializedAuth(true);
            } else {
                const route = defaultAreaRoute(session.role);
                navigate(route);
            }
        }

        if (initializedAuth && session == null) {
            navigate('/account/');
            return;
        }

        // TODO: Add checks for ActiveEnvironment etc
    }, [session, initialized, initializedAuth]);

    return { initializedAuth };
};

export const useDefaultAreaAuthorization = (initialized: boolean) => {
    const { session } = useSession();
    const [initializedAuth, setInitializedAuth] = useState(false);

    useEffect(() => {
        if (session != null && initialized) {
            if (!session.role) {
                setInitializedAuth(true);
            } else {
                const route = defaultAreaRoute(session.role);
                navigate(route);
            }
        }

        if (initializedAuth && session == null) {
            navigate('/account/');
            return;
        }

        // TODO: Add checks for ActiveEnvironment etc
    }, [session, initialized, initializedAuth]);

    return { initializedAuth };
};
