import React, { FC } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import Wizard from 'components/organisms/Wizard';
import Step2 from './components/Step2';
import Step1 from './components/Step1';
import { FormikHelpers } from 'formik';
import { CustomerPlainModel } from 'hooks/customer/types';
import { toFormikErrors } from 'utils/errorhelper';
import useAllCustomers from 'hooks/customer/useAllCustomers';
import { useHeader } from 'contexts/headerContext';
import { InviteCustomerInputModel } from 'hooks/userInvitation/types';
import useUserInvitation from 'hooks/userInvitation/useUserInvitation';
import { format, parseISO } from 'date-fns';

const columns: Column<CustomerPlainModel>[] = [
    {
        Header: 'Relatienummer',
        accessor: (customer) => customer.relationNumber
    },
    {
        Header: 'Bedrijfsnaam',
        accessor: (customer) => customer.name
    },
    {
        Header: 'KvK-nummer',
        accessor: (customer) => customer.kvkNumber
    },
    {
        Header: 'Laatste inlog',
        accessor: (customer) => customer.lastLogin != null ? parseISO(customer.lastLogin).getTime() : null,
        Cell: ({ value }: any) => value != null ? format(value, 'dd-MM-yyyy HH:mm') : ''
    },
    {
        Header: 'Aangemaakt op',
        accessor: (customer) => customer.created != null ? parseISO(customer.created).getTime() : null,
        Cell: ({ value }: any) => value != null ? format(value, 'dd-MM-yyyy HH:mm') : ''
    }
];

const Customers: FC<RouteComponentProps> = () => {
    useHeader('Relaties');

    const { inviteCustomer } = useUserInvitation();
    const { data: customers = [], refetch, isLoading } = useAllCustomers();

    const onAddCustomer = async (values: InviteCustomerInputModel, { setSubmitting, setErrors }: FormikHelpers<InviteCustomerInputModel>) => {
        const response = await inviteCustomer(values);

        if (response.ok) {
            refetch();
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors<InviteCustomerInputModel>(response.errors);
            setErrors(errors);
        }

        setSubmitting(false);
    };

    const initialValues: InviteCustomerInputModel = {
        gender: null,
        kvkNumber: '',
        name: '',
        initials: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        mobileNumber: '',
        email: '',
        emailInvoice: '',
        addressVisit: '',
        cityVisit: '',
        postalCodeVisit: '',
        isMailAddressDifferent: false,
        addressMail: '',
        postalCodeMail: '',
        cityMail: '',
        function: '',
        bankAccount: '',
        paymentMethod: null,
        relationNumber: '',
        hasModuleCollection: false,
        hasModuleCollectionAbroad: false,
        hasModuleLegalAid: false,
        hasModuleGeneralConditions: false
    };

    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hide} title="Klant toevoegen">
                <Wizard<InviteCustomerInputModel>
                    initialValues={initialValues}
                    initialStep={0}
                    onSubmitLabel="Uitnodiging versturen"
                    onSubmit={onAddCustomer}
                    steps={[
                        {
                            step: 1,
                            title: 'Zoek bedrijf',
                            child: <Step1 />
                        },
                        {
                            step: 2,
                            title: 'Gegevens',
                            child: <Step2 />
                        }
                    ]}
                />
            </Modal>
        ),
        []
    );

    const navigateToCustomerDetailPage = (item: CustomerPlainModel | undefined) => {
        navigate(`/admin/customers/${item?.id}/detail`, { state: { customer: item } });
    };

    return (
        <Card>
            <CardBody>
                <Table<CustomerPlainModel>
                    isLoading={isLoading}
                    data={customers}
                    noDataMessage="Geen relaties beschikbaar"
                    columns={columns}
                    search
                    onAddClick={show}
                    actions={[
                        {
                            icon: 'right',
                            onClick: (item) => navigateToCustomerDetailPage(item),
                            title: 'Ga naar detail pagina'
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default Customers;
