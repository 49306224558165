import styled from '@emotion/styled';
import CurrencyInput from 'react-currency-input-field';

export const CurrencyWrapper = styled(CurrencyInput)`
  width: 100%;
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  padding: 0 16px;
  font-size: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.dark};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.greys[50]};
  }

  &:hover {
    border-color: ${({ theme }) => theme.greys[100]};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.dark};
    cursor: not-allowed;
    
    &:hover {
      border-color: ${({ theme }) => theme.greys[50]};
    }
  }
`;
