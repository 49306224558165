import { RouteComponentProps } from '@reach/router';
import { useStamps } from 'hooks/stamp';
import React, { FC, useState } from 'react';
import useStampOrder from 'hooks/stampOrder/useStampOrder';
import { useSession } from 'contexts/sessionContext';
import { ShopCard } from 'components/molecules/card';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-toastify';
import { useHeader } from 'contexts/headerContext';
import { Card, CardBody } from 'components/atoms/card';
import { Paragraph } from 'components/atoms/text';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { StampPlainModel } from 'hooks/stamp/types';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { getPaymentMethodDisplay, PaymentMethod } from 'utils/constants/paymentmethodconstants';
import FormRadio from 'components/atoms/form/FormRadio';
import { Form, Formik, FormikHelpers } from 'formik';
import { AddStampOrderInputModel } from 'hooks/stampOrder/types';

export interface ToolsProps extends RouteComponentProps { }

const Tools: FC<ToolsProps> = () => {
    useHeader('Extra\'s');

    const { createStampOrder } = useStampOrder();
    const { useAllStamps } = useStamps();
    const { data: stamps = [] } = useAllStamps();

    const { session } = useSession();

    const [stampToOrder, setStampToOrder] = useState<StampPlainModel | null>(null);

    // const params = new URLSearchParams(window.location.search);
    // const orderId = params.get('order_id');
    // const status = params.get('status');
    // const signature = params.get('signature');

    // useEffect(() => {
    //     if (orderId != null && status != null && signature != null) {

    //         if (status === OmnikassaOrderStatus.Cancelled || status === OmnikassaOrderStatus.Expired) {
    //             toast.error('De betaling was geannuleerd');
    //         } else {
    //             toast.success('We hebben uw bestelling succesvol ontvangen!');
    //         }

    //         handleVerifyPayment();
    //     }
    // }, []);

    const makeStampOrder = async (values: AddStampOrderInputModel, { setSubmitting, resetForm }: FormikHelpers<AddStampOrderInputModel>) => {
        if (stampToOrder == null) {
            return;
        }

        setSubmitting(true);

        const response = await createStampOrder(values);

        if (response.ok && response.data) {
            toast.success('We hebben uw bestelling succesvol ontvangen en zullen zo snel als mogelijk contact met u opnemen!');
            closeOrderModal();
            resetForm();
        } else {
            toast.error('Bestelling kan niet worden geplaatst.');
        }

        setSubmitting(false);
    };

    const initialValues: AddStampOrderInputModel = {
        customerId: session?.customerId ?? '',
        stampId: stampToOrder?.id ?? '',
        paymentMethod: PaymentMethod.Invoice
    };

    // const handleVerifyPayment = async () => {
    //     if (orderId == null || status == null || signature == null) {
    //         return;
    //     }

    //     const response = await verifyPayment({
    //         orderId,
    //         signature,
    //         status
    //     });

    //     if (!response.ok) {
    //         toast.error('Er ging iets mis met de betaling. Neem contact met ons op');
    //     }
    // };

    const [showOrderModal, hideOrderModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideOrderModal} size="small" title="Stempel bestellen" subtitle="U staat op het punt een Juresta-stempel te bestellen. U ontvangt deze per post en per e-mail.">
                <Formik initialValues={initialValues} onSubmit={makeStampOrder}>
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form>
                        <p>Kies een betaalwijze (in beide gevallen ontvangt u onze factuur na verzending van de stempel).</p>
                        <Row>
                            <Col xs={12}>
                                <FormRadio label={getPaymentMethodDisplay(PaymentMethod.Automatic)} checked={values.paymentMethod === PaymentMethod.Automatic} onChange={() => setFieldValue('paymentMethod', PaymentMethod.Automatic)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormRadio label={getPaymentMethodDisplay(PaymentMethod.Invoice)} checked={values.paymentMethod === PaymentMethod.Invoice} onChange={() => setFieldValue('paymentMethod', PaymentMethod.Invoice)} />
                            </Col>
                        </Row>
                        <Group right>
                            <Button type="button" onClick={closeOrderModal} variant="light">Annuleren</Button>
                            <Button loading={isSubmitting}>Bestellen</Button>
                        </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [stampToOrder]
    );

    const openOrderModal = (stamp: StampPlainModel) => {
        setStampToOrder(stamp);
        showOrderModal();
    };

    const closeOrderModal = () => {
        setStampToOrder(null);
        hideOrderModal();
    };

    return (
        <>
            <Card fill variant="primary">
                <CardBody>
                    <Paragraph variant="white">
                        <b>Juresta-stempel</b><br />
                        De Juresta-stempel heeft zich de afgelopen jaren bewezen als een effectief hulpmiddel in het debiteurenbeheer. Onze klanten die gebruik maken van de stempel melden dat hun facturen gemiddeld 43% sneller betaald worden. Uiteraard kunt u de stempel naar eigen inzicht inzetten. Standaard op uw facturen bij trage betalers en alleen op herinneren voor klanten die doorgaans op tijd betalen. Ons advies is om op een aanmaning altijd gebruik te maken van de Juresta-stempel.
                    </Paragraph>
                    <Paragraph variant="white">
                        Met de stempel geeft u aan dat u uw debiteurenbeheer serieus neemt en tevens gebruik maakt van een deskundige incassopartner. Een vriendelijke, maar duidelijke hint naar uw klanten. De stempel is verkrijgbaar in het Nederlands en/ of Engels.
                        U ontvangt de stempel zowel als een digitale versie per e-mail en als een fysieke versie per post.
                    </Paragraph>
                </CardBody>
            </Card>
            <Row>
                {stamps.map((value) => (
                    <Col key={value.id} md={12} lg={12} xl={6} xxl={4}>
                        <ShopCard
                            price={value.price}
                            imageHash={value.imageHash}
                            key={value.id}
                            title={value.name}
                            description={value.description}
                            onOrderClick={() => openOrderModal(value)}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default Tools;
