import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';

interface EmptyProps extends RouteComponentProps {
    title: string;
}

const Empty: FC<EmptyProps> = ({ title }) => {
    useHeader(title);

    return null;
};

export default Empty;
