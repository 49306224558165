import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import { useHeader } from 'contexts/headerContext';
import { SendTermsAndConditionsTaskMailInputModel, TermsAndConditionsType } from 'hooks/termsAndConditions/types';
import useTermsAndConditions from 'hooks/termsAndConditions/useTermsAndConditions';
import { FormGroup, FormikFormFile, FormikFormTextarea } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import { toast } from 'react-toastify';
import { Button } from 'components/atoms/button';
import React, { FC } from 'react';

interface ReviewProps extends RouteComponentProps { }

const initialValues: SendTermsAndConditionsTaskMailInputModel = {
    file: undefined,
    comments: '',
    type: TermsAndConditionsType.Review
};

const Review: FC<ReviewProps> = () => {
    useHeader('Algemene Voorwaarden');

    const { sendTermsAndConditionsTaskMail } = useTermsAndConditions();

    const handleMail = async (values: SendTermsAndConditionsTaskMailInputModel, { setErrors, resetForm }: FormikHelpers<SendTermsAndConditionsTaskMailInputModel>) => {
        const response = await sendTermsAndConditionsTaskMail(values);

        if (response.ok) {
            toast.success('Uw aanvraag voor toetsing is succesvol verstuurd');
            resetForm();
        } else if (response.errors) {
            const errors = toFormikErrors<SendTermsAndConditionsTaskMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de toetsings-aanvraag');
        }
    };

    return (
        <Card>
            <CardBody>
                <H3>Toetsing</H3>
                <Paragraph></Paragraph>
                <Card variant="light" noMargin noShadow>
                    <CardBody>
                        <Formik<SendTermsAndConditionsTaskMailInputModel> initialValues={initialValues} onSubmit={handleMail}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <FormGroup label="Bijlage toevoegen" required>
                                        <FormikFormFile name="file" whiteBackground />
                                    </FormGroup>
                                    <FormGroup label="Opmerking">
                                        <FormikFormTextarea name="comments" placeholder="Opmerking" />
                                    </FormGroup>
                                    <FormGroup noMargin alignRight>
                                        <Button loading={isSubmitting}>Verzenden</Button>
                                    </FormGroup>
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
            </CardBody>
        </Card>
    );
};

export default Review;
