import { useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { CustomerPlainModel } from './types';

export const allCustomersQueryKey = 'customer.all';



export default function useAllCustomers() {
    const { get } = useApi();
    const getAllCustomers = async () => {
        const { data } = await get<CustomerPlainModel[]>('/customer');

        return data;
    };

    return useQuery<CustomerPlainModel[] | undefined>(allCustomersQueryKey, getAllCustomers);
}
