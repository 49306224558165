import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface CardBodyProps {
    borderBottom?: boolean;
}

const CardBody = styled.div<CardBodyProps>`
    padding: 24px;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    ${({ theme, borderBottom }) =>
    borderBottom &&
    css`
        border-bottom: 1px solid ${theme.colors.border};
    `}
`;

export default CardBody;
