import React, { FC, ReactElement } from 'react';
import { Match, RouteComponentProps } from '@reach/router';
import Tab from 'components/atoms/Tab';
import TabsMolecule from 'components/molecules/Tabs';

interface DefaultProps extends RouteComponentProps {
    titles: string[];
}

const Tabs: FC<DefaultProps> = ({ titles, children, navigate, path }) => {
    const paths = React.Children.map((children as ReactElement)?.props.children, ({ props }) => (props.default ? path : props.path));

    return (
        <>
            <TabsMolecule>
                {titles.map((tab, index) => (
                    <Match key={tab} path={paths[index]}>
                        {({ match }) => <Tab title={tab} to={paths[index]} active={!!match} />}
                    </Match>
                ))}
            </TabsMolecule>
            {children}
        </>
    );
};

export default Tabs;
