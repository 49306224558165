import { Button } from 'components/atoms/button';
import { Card, CardBody, CardImage } from 'components/atoms/card';
import React, { FC } from 'react';
import { H3, Paragraph } from 'components/atoms/text';
import { Header, Price } from './styles';
import numeral from 'numeral';

import 'numeral/locales/nl-nl';
import Group from 'components/molecules/Group';
numeral.locale('nl-nl');

export interface ShopCardProps {
    title: string;
    price: number;
    description: string;
    imageHash: string;
    onOrderClick?: () => void;
    isLoading?: boolean;
}

const ShopCard: FC<ShopCardProps> = ({ description, onOrderClick, title, imageHash, price, isLoading }) => {
    return (
        <Card overflow="hidden">
            <CardImage title={title} imageHash={imageHash} />
            <CardBody>
                <Header>
                    <H3>{title}</H3>
                </Header>
                <Paragraph>{description}</Paragraph>
                <Group right>
                    <Price>{numeral(price).format('$0.00')} excl. BTW</Price>
                </Group>
                <Button onClick={onOrderClick} variant="primary" rounded endIcon="right" size="lg" loading={isLoading} alignSelf="flex-end" style={{ marginTop: 15 }}>
                    Bestellen
                </Button>
            </CardBody>
        </Card>
    );
};

export default ShopCard;
