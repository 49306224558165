import styled from '@emotion/styled';
import { lighten } from 'polished';

export const Input = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

export const Label = styled.label`
    display: flex;
`;

export const Wrapper = styled.div<{ isFocused: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const Files = styled.div<{ whiteBackground?: boolean }>`
    background-color: ${({ theme, whiteBackground }) => (whiteBackground ? theme.colors.white : theme.colors.light)};
    border-radius: ${({ theme }) => `${theme.borderRadius.small}px`};
    display: flex;
    flex-wrap: wrap;
    min-height: 3.25rem;
    width: 100%;
    margin-right: 1rem;
    align-items: center;
    font-size: 1rem;
    padding: 8px 16px;
    font-weight: 500;
`;

export const Button = styled.div`
    height: 100%;
    padding: 0 16px;
    border-radius: ${({ theme }) => `${theme.borderRadius.small}px`};
    font-size: 1rem;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    white-space: pre;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => lighten(0.05, theme.colors.primary)};
    }
`;

export const File = styled.span`
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 1rem;
    padding: 8px 16px;
    line-height: 1;
    font-size: .75rem;
    margin-right: .5rem;
    margin-top: .25rem;
    margin-bottom: .25rem;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-right: 2rem;
    display: inline-block;
    position: relative;
`;


export const Remove = styled.button`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    outline: 0;
    border: 0;
    margin-left: 8px;
    background: none;
    padding: 0;
    cursor: pointer;
`;
