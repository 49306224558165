import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Field, Formik, FormikHelpers } from 'formik';
import { FormCheck, FormGroup, FormikFormControl } from 'components/atoms/form';
import { Button } from 'components/atoms/button';
import { toast } from 'react-toastify';
import useAccount, { VerifyTfaCodeInputModel } from 'hooks/account/useAccount';
import Group from 'components/molecules/Group';
import { Content } from 'components/templates/AccountForm/styles';

interface TfaProps extends RouteComponentProps {
    redirectUrl?: string;
}

const initialValues: VerifyTfaCodeInputModel = {
    code: '',
    rememberVerification: false
};

const TwoFactorAuthentication: FC<TfaProps> = (props) => {
    const { redirectUrl } = props;

    const { resendTfaCode, verifyTfaCode } = useAccount();
    const [isLoadingNewCode, setIsLoadingNewCode] = useState(false);

    useEffect(() => {
        newCode();
    }, []);

    const newCode = async () => {
        if (!isLoadingNewCode) {
            setIsLoadingNewCode(true);
            try {
                const response = await resendTfaCode();

                if (response.status === 200) {
                    toast.success('Er is een bericht met een verificatiecode verstuurd naar uw apparaat. Vul de code in om door te gaan.');
                } else {
                    toast.error('Code kon niet worden verstuurd');
                }
            } catch (ex) {
                toast.error('Code kon niet worden verstuurd');
            }
            setIsLoadingNewCode(false);
        }
    };

    const submit = async (values: VerifyTfaCodeInputModel, actions: FormikHelpers<VerifyTfaCodeInputModel>) => {
        actions.setSubmitting(true);
        try {
            await verifyTfaCode(values, redirectUrl);
        } catch (ex) {
            toast.error('Code kon niet worden geverifiërd');
        }
        actions.setSubmitting(false);
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={submit}>
                {({ handleSubmit, isSubmitting, isValid }) => (
                    <Content>
                        <FormGroup label="Unieke code" isWhite>
                            <FormikFormControl name="code" placeholder="Unieke code" onLoad={newCode} />
                        </FormGroup>
                        <FormGroup isWhite>
                            <Field type="checkbox" name="rememberVerification" label="Bewaren voor 30 dagen" isWhite as={FormCheck} tabIndex={4} />
                        </FormGroup>
                        <Group spaceBetween>
                            <Button
                                size="lg"
                                disabled={isLoadingNewCode}
                                loading={isLoadingNewCode}
                                onClick={newCode}
                                variant="white"
                                rounded
                                type="button"
                            >
                                Nieuwe code aanvragen
                            </Button>
                            <Button onClick={() => handleSubmit()} size="lg" disabled={!isValid} loading={isSubmitting} variant="white" rounded>
                                Inloggen
                            </Button>
                        </Group>
                    </Content>
                )}
            </Formik>
        </>
    );
};

export default TwoFactorAuthentication;
