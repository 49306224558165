import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import {
    AddKnowledgeBaseCategoryInputModel,
    EditKnowledgeBaseCategoryInputModel,
    KnowledgeBaseCategoryModel
} from 'hooks/knowledgebasecategory/types';
import useKnowledgeBaseCategories from 'hooks/knowledgebasecategory';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { toast } from 'react-toastify';
import Margin from 'components/atoms/Margin';
import { H3 } from 'components/atoms/text';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup } from 'components/atoms/form';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import { toFormikErrors } from 'utils/errorhelper';

interface KnowledgeBaseCategoryProps extends RouteComponentProps { }

const columns: Column<KnowledgeBaseCategoryModel>[] = [
    {
        Header: 'Categorie',
        accessor: (category) => category.name
    }
];

const AddKnowledgeBaseCategory: FC<KnowledgeBaseCategoryProps> = (props) => {
    useHeader('Kennisbank instellingen');

    const { addKnowledgeBaseCategory, editKnowledgeBaseCategory, useAllKnowledgeBaseCategories, deleteKnowledgeBaseCategory } =
        useKnowledgeBaseCategories();
    const { data: knowledgeBaseCategories = [], isLoading: isLoadingKnowledgeBaseCategories, refetch } = useAllKnowledgeBaseCategories();

    const [knowledgeBaseCategoryToDelete, setKnowledgeBaseCategoryToDelete] = useState<KnowledgeBaseCategoryModel>();
    const [knowledgeBaseCategoryToEdit, setKnowledgeBaseCategoryToEdit] = useState<KnowledgeBaseCategoryModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const openAddModal = (category: KnowledgeBaseCategoryModel) => {
        showAddModal();
    };

    const closeAddModal = () => {
        setKnowledgeBaseCategoryToEdit(undefined);
        hideAddModal();
    };

    const openDeleteModal = (category: KnowledgeBaseCategoryModel) => {
        setKnowledgeBaseCategoryToDelete(category);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setKnowledgeBaseCategoryToDelete(undefined);
        hideDeleteModal();
    };

    const handleDelete = async () => {
        if (knowledgeBaseCategoryToDelete == null) {
            return;
        }

        setIsLoadingDelete(true);

        const response = await deleteKnowledgeBaseCategory(knowledgeBaseCategoryToDelete.id);

        if (response.ok) {
            refetch();
            toast.success('Categorie succesvol verwijderd!');
            closeDeleteModal();
        } else if (response.errors) {
            toast.error(response.errors.map(e => e.message).join(', /n'));
        } else {
            toast.error('Oeps, er ging iets fout tijdens het verwijderen van deze categorie. Probeer het later opnieuw!');
        }

        setIsLoadingDelete(false);
    };
    const initialValues: EditKnowledgeBaseCategoryInputModel = {
        id: knowledgeBaseCategoryToEdit?.id ?? '',
        name: knowledgeBaseCategoryToEdit?.name ?? ''
    };

    const handleAdd = async (
        values: EditKnowledgeBaseCategoryInputModel,
        { setErrors, setSubmitting }: FormikHelpers<EditKnowledgeBaseCategoryInputModel>
    ) => {
        if (values.id) {
            const response = await editKnowledgeBaseCategory(values);

            if (response.ok) {
                refetch();
                closeAddModal();
                toast.success(`'${values.name}' succesvol opgeslagen`);
            } else if (response.errors) {
                const errors = toFormikErrors<EditKnowledgeBaseCategoryInputModel>(response.errors);
                setErrors(errors);
                toast.error('Niet alle velden zijn ingevuld');
            } else {
                toast.error('Oeps, er ging iets fout tijdens het aanpassen van deze categorie. Probeer het later opnieuw!');
            }
        } else {
            const response = await addKnowledgeBaseCategory(values);

            if (response.ok) {
                refetch();
                closeAddModal();
                toast.success(`'${values.name}' succesvol aangemaakt`);
            } else if (response.errors) {
                const errors = toFormikErrors<AddKnowledgeBaseCategoryInputModel>(response.errors);
                setErrors(errors);
                toast.error('Niet alle velden zijn ingevuld');
            } else {
                toast.error('Oeps, er ging iets fout tijdens het aanmaken van deze categorie. Probeer het later opnieuw!');
            }
        }

        setSubmitting(false);
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal
                inProp={inProp}
                onExited={onExited}
                onHide={hideDeleteModal}
                size="large"
                title="Categorie verwijderen"
                subtitle="Weet je zeker dat je deze categorie wilt verwijderen"
            >
                <Group spaceBetween>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>
                        Verwijderen
                    </Button>
                </Group>
            </Modal>
        ),
        [knowledgeBaseCategoryToDelete]
    );
    const [showAddModal, hideAddModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal
                inProp={inProp}
                onExited={onExited}
                onHide={hideAddModal}
                size="large"
                title={`Categorie ${knowledgeBaseCategoryToEdit != null ? 'bewerken' : 'aanmaken'}`}
            >
                <Formik initialValues={initialValues} onSubmit={handleAdd}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <Margin bottom={2}>
                                <FormGroup noMargin label="Categorie naam">
                                    <FormControlFormik name="name" placeholder="Categorie naam" />
                                </FormGroup>
                            </Margin>
                            <Margin top={2}>
                                <Group spaceBetween>
                                    <Button type="button" onClick={closeAddModal}>
                                        Annuleren
                                    </Button>
                                    <Button type="submit" loading={isSubmitting}>
                                        Opslaan
                                    </Button>
                                </Group>
                            </Margin>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [knowledgeBaseCategoryToEdit, initialValues]
    );
    return (
        <Card>
            <CardBody>
                <Margin bottom={2}>
                    <H3>Categoriën</H3>
                </Margin>
                <Table<KnowledgeBaseCategoryModel>
                    isLoading={isLoadingKnowledgeBaseCategories}
                    data={knowledgeBaseCategories}
                    noDataMessage="Geen categoriën gevonden"
                    columns={columns}
                    search
                    onAddClick={() => showAddModal()}
                    actions={[
                        {
                            icon: 'edit',
                            onClick: (category) => {
                                setKnowledgeBaseCategoryToEdit(category);
                                openAddModal(category);
                            }

                        },
                        {
                            icon: 'delete',
                            onClick: (category) => openDeleteModal(category)
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default AddKnowledgeBaseCategory;
