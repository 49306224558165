export enum CollectionStatus {
    NeedsSigning = 0,
    InProgress = 1,
    Finished = 2
}

export const collectionStatusOptions = [
    {
        value: CollectionStatus.NeedsSigning,
        label: 'Vereist handtekening'
    },
    {
        value: CollectionStatus.InProgress,
        label: 'In behandeling'
    },
    {
        value: CollectionStatus.Finished,
        label: 'Afgewikkeld'
    }
];

export const getCollectionStatusDisplay = (status: CollectionStatus) => {
    switch (status) {
        case CollectionStatus.NeedsSigning:
            return 'Vereist handtekening';
        case CollectionStatus.InProgress:
            return 'In behandeling';
        case CollectionStatus.Finished:
            return 'Afgewikkeld';
    };
};
