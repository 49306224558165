import { LegalForm } from 'utils/constants/legalformconstants';

export const KvkLegalFormStringToLegalForm = (legalForm: string) => {

    switch (legalForm.toLowerCase()) {
        case 'eenmanszaak':
        case 'eenmanszaak met meer dan één eigenaar':
            return LegalForm.Proprietorship;
        case 'vennootschap onder firma':
            return LegalForm.Partnership;
        case 'commanditaire vennootschap':
            return LegalForm.LimitedPartnerschip;
        case 'besloten vennootschap':
        case 'besloten vennootschap met gewone structuur':
            return LegalForm.PrivateCompany;
        case 'naamloze vennootschap':
        case 'naamloze vennootschap met gewone structuur':
        case 'naamloze vennootschap blijkens statuten structuurvennootschap':
        case 'naamloze vennootschap beleggingsmaatschappij met veranderlijk kapitaal':
        case 'naamloze vennootschap beleggingsmaatschappij met veranderlijk kapitaal blijkens statuten structuurvennootschap':
        case 'europese naamloze vennootschap (SE) met gewone structuur':
        case 'europese naamloze vennootschap (SE) blijkens statuten structuurvennootschap':
            return LegalForm.LimitedLiabilityCompany;
        case 'vereniging met volledige rechtsbevoegdheid':
        case 'vereniging met beperkte rechtsbevoegdheid':
            return LegalForm.Union;
        case 'stichting':
            return LegalForm.Foundation;
        default:
            return LegalForm.Unknown;
    }
};
