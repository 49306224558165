import React, { FC } from 'react';
import { cdnString } from 'utils/cdnHelper';
import { Image, Logo } from './styles';

export interface CardImageProps {
    imageHash: string;
    title: string;
    isLogo?: boolean;
    style?: React.CSSProperties;
}

const IMAGE_WIDTH = 672;
const IMAGE_HEIGHT = 384;

const CardImage: FC<CardImageProps> = ({ imageHash, title, isLogo, style }) => {
    const image = isLogo ? cdnString(imageHash) : cdnString(imageHash, IMAGE_WIDTH, IMAGE_HEIGHT);

    return <Image style={style} background={!isLogo ? image : undefined}>{isLogo && <Logo src={image} alt={title} />}</Image>;
};

export default CardImage;
