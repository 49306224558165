import { RouteComponentProps } from '@reach/router';
import useAllBanners from 'hooks/banner/useAllBanners';
import useSendBannerMail from 'hooks/banner/useSendBannerMail';
import React, { FC, useMemo, useState } from 'react';
import { cdnString } from 'utils/cdnHelper';
import { Col, Row } from 'react-grid-system';
import { Button } from 'components/atoms/button';
import download from 'downloadjs';
import { toast } from 'react-toastify';
import { useSession } from 'contexts/sessionContext';
import { Card, CardBody, CardImage } from 'components/atoms/card';
import { useHeader } from 'contexts/headerContext';
import { Column } from './styles';
import Margin from 'components/atoms/Margin';
import { Paragraph } from 'components/atoms/text';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { Form, Formik } from 'formik';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import { BannerSendMailModel } from 'hooks/banner/types';

export interface BannerProps extends RouteComponentProps { }

const Banners: FC<BannerProps> = () => {
    useHeader('Extra\'s');

    const { data: banners = [] } = useAllBanners();
    const { sendBanner } = useSendBannerMail();
    const { session } = useSession();

    const [mailingBanner, setMailingBanner] = useState<string | null>(null);
    const [sendBannerMail, setSendBannerMail] = useState<BannerSendMailModel>();

    const handleMailOnClick = async (bannerId: string) => {
        const response = await sendBanner({
            bannerId,
            customerId: session?.customerId ?? ''
        });

        if (response.ok) {
            toast.success('We hebben u deze banner per mail gestuurd!');
        } else {
            toast.error('Oeps, er ging iets fout tijdens het versturen van de mail. Probeer het later opnieuw!');
        }

        setMailingBanner(null);
    };

    const handleSendOnClick = (values: BannerSendMailModel) => {
        setSendBannerMail(values);
        showBannerEmail();
    };

    const handleOnClick = (imageHash: string) => {
        const constructUrl = cdnString(imageHash);
        download(constructUrl);
    };

    const initialValues = useMemo(() => ({
        bannerId: sendBannerMail?.bannerId ?? '',
        email: ''
    }), [sendBannerMail]);


    const handleBannerMailOnSubmit = async (values: BannerSendMailModel) => {
        if (!sendBannerMail)
            return;

        const response = await sendBanner(values);

        if (response.ok) {
            toast.success('We hebben deze banner naar het opgegeven e-mailadres verstuurd!');
            hideBannerEmail();
            setMailingBanner(null);
        } else {
            toast.error('Oeps, er ging iets fout tijdens het versturen van de mail. Probeer het later opnieuw!');
        }
    };

    const [showBannerEmail, hideBannerEmail] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideBannerEmail} size="small" title="Banner versturen naar">
                <Formik<BannerSendMailModel> enableReinitialize initialValues={initialValues} onSubmit={handleBannerMailOnSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup label="E-mailadres">
                                <FormikFormControl name="email" placeholder="E-mail" />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button type="submit" loading={isSubmitting}>Verstuur</Button>
                            </FormGroup>

                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [initialValues]
    );

    return (
        <>
            <Card fill variant="primary">
                <CardBody>
                    <Paragraph variant="white">
                        <b>Juresta-banner</b><br />
                        Met de Juresta-banner op uw website geeft u ook online een duidelijk signaal af aan uw (potentiële) klanten. U geeft daarmee aan dat Juresta uw partner is op incasso- of juridisch gebied. U kunt de banner eenvoudig downloaden of direct doorsturen naar uw webdesigner.
                    </Paragraph>
                </CardBody>
            </Card>
            <Row>
                {banners.map((value) => (
                    <Col xs={12} sm={12} md={12} lg={6} key={value.id}>
                        <Card overflow="hidden" flexDirection="row">
                            <CardImage style={{ flex: '1 1 auto' }} title={value.name} imageHash={value.imageHash} />
                            <CardBody style={{ flex: '0 0 250px' }}>
                                <Column>
                                    <Margin bottom={0.5}>
                                        <Button full onClick={() => handleOnClick(value.imageHash)}>download</Button>
                                    </Margin>
                                    <Margin bottom={0.5}>
                                        <Button full onClick={() => handleMailOnClick(value.id)} loading={mailingBanner === value.id} variant="white" border>ontvang per mail</Button>
                                    </Margin>
                                    <Margin>
                                        <Button full onClick={() => handleSendOnClick({ bannerId: value.id })} variant="white" border>verstuur per mail</Button>
                                    </Margin>
                                </Column>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default Banners;
