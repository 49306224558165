import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormCheckProps } from './';
import { lighten } from 'polished';

export const Checkbox = styled.div<{
    checked?: boolean;
}>`
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid ${({ theme }) => theme.greys[50]};
    border-radius: ${({ theme }) => theme.borderRadius.small}px;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex: 0 0 1.5rem;
    justify-content: center;
    align-items: center;

    &:hover {
        border-color: ${({ theme }) => theme.greys[100]};
    }

    ${({ theme, checked }) =>
        checked &&
        css`
            background-color: ${theme.colors.primary};
            border: none;

            &:hover {
                background-color: ${lighten(0.1, theme.colors.primary)};
            }
        `}
`;

export const HiddenCheckbox = styled.input<FormCheckProps>`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:focus + div {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.greys[50]};
    }
`;

export const Label = styled.span<{
    isWhite?: boolean;
}>`
    color: ${({ theme, isWhite }) => (isWhite ? theme.colors.white : theme.colors.dark)};
    margin-left: 16px;
    font-size: 1rem;
    line-height: 1rem;
`;

export const Wrapper = styled.label<{
    label?: string;
}>`
    display: ${({ label }) => (label ? 'flex' : 'inline-flex')};
    align-items: center;
    user-select: none;

    &:hover {
        cursor: pointer;
    }
`;
