import { rgba } from 'polished';
import styled from '@emotion/styled';
import { Colors } from 'theme/colors';
import { css } from '@emotion/react';

interface CardProps {
    noMargin?: boolean;
    noShadow?: boolean;
    variant?: keyof Colors;
    fill?: boolean;
    overflow?: 'visible' | 'hidden' | 'scroll' | 'auto';
    disabled?: boolean;
    flexDirection?: 'row';
}

const Card = styled.div<CardProps>`
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 10px 30px ${({ theme }) => rgba(theme.colors.dark, 0.07)};
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '2rem')};
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'column'};
    height: ${({ fill, noMargin }) => (fill ? `calc(100% - ${noMargin ? 0 : '2rem'})` : 'auto')};
    position: relative;
    overflow: ${({ overflow }) => overflow};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    ${({ theme, variant }) => {
        const color = theme.colors[variant || 'white'];

        return css`
            background-color: ${color};
        `;
    }};

    ${({ noShadow }) =>
        noShadow &&
        css`
            box-shadow: none;
        `}
`;

export default Card;
