import { CustomError, useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { AddProspectInputModel, AddProspectResponseModel, GetProspectsInputModel, ProspectModel } from './types';

const useProspect = () => {
    const { post } = useApi();

    const baseUrl = '/prospect';

    const getAllProspects = async (params: GetProspectsInputModel) => {
        const response = await post<ProspectModel[]>(`${baseUrl}/all`, params);
        return response;
    };

    const useGetAllProspects = (params: GetProspectsInputModel) => {
        const queryKey = [getAllProspects, params];

        return useQuery<ProspectModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllProspects(params);
            if (!response.ok && response.errors != null) {
                throw response.errors;
            }
            return response.data || [];
        });
    };

    const addProspect = async (params: AddProspectInputModel) => {
        const response = await post<AddProspectResponseModel>(baseUrl, params);
        return response;
    };

    return {
        useGetAllProspects,
        addProspect
    };
};

export default useProspect;
