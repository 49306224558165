import { Loading } from 'components/atoms/button/Button/styles';
import { FormGroup, FormSelect } from 'components/atoms/form';
import { IOption } from 'components/atoms/form/FormSelect';
import InvalidFeedback from 'components/atoms/form/InvalidFeedback';
import Margin from 'components/atoms/Margin';
import { KvkCompanyInfoModel } from 'hooks/kvk/types';
import useKvk from 'hooks/kvk/useKvk';
import { debounce } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { InputActionMeta, Options } from 'react-select';
import { Option } from 'react-select/src/filters';

interface KvkSearchProps {
    onSelectChange: (value: KvkCompanyInfoModel | undefined) => void;
    notFoundMessage?: string;
    onSearchDone?: (hasResults: boolean) => void;
}

const KvkSearch: FC<KvkSearchProps> = ({ onSelectChange, notFoundMessage, onSearchDone }) => {
    const { searchKvk } = useKvk();

    const [searchString, setSearchString] = useState<string>();
    const [value, setValue] = useState<IOption | Options<IOption> | null>(null);
    const [companies, setCompanies] = useState<KvkCompanyInfoModel[]>([]);
    const [companyOptions, setCompanyOptions] = useState<Option[]>([]);
    const [isLoadingKvk, setIsLoadingKvk] = useState(false);
    const [error, setError] = useState('');

    const debounced_test = debounce((newValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            setSearchString(newValue);
        }
    }, 1500);

    const searchInKvk = async () => {
        if (searchString === undefined) {
            return;
        }

        setError('');
        setIsLoadingKvk(true);

        const response = await searchKvk(searchString);

        if (response.ok && response.data) {
            setCompanies(response.data.items);
            const options = response.data.items
                .map((item) => ({
                    label: item.name,
                    value: item.kvkNumber,
                    data: item
                }));
            setCompanyOptions(options);

            if (options.length === 0) {
                const error = notFoundMessage ?? 'Het bedrijf kan niet worden gevonden in het KvK register';
                setError(error);
                onSearchDone && onSearchDone(false);
                setValue(null);
            } else {
                onSearchDone && onSearchDone(true);
            }
        } else {
            onSearchDone && onSearchDone(false);
            setValue(null);
        }

        setIsLoadingKvk(false);
    };

    useEffect(() => {
        (async () => {
            await searchInKvk();
        })();
    }, [searchString]);

    const onCompanySelectChange = (option: IOption | Options<IOption> | null) => {
        if (option == null || option instanceof Array) {
            return;
        }

        setValue(option);

        const company = companies.find((c) => c.kvkNumber === option.value);

        onSelectChange(company);
    };

    return (
        <>
            <FormGroup label="Zoek bedrijf">
                <FormSelect
                    value={value}
                    options={companyOptions}
                    onChange={onCompanySelectChange}
                    onInputChange={debounced_test}
                    isLoading={isLoadingKvk}
                    placeholder="Zoek bedrijf..."
                    noOptionsMessage={() => error ? (notFoundMessage ?? 'Het bedrijf kan niet worden gevonden in het KvK register') : 'Start met zoeken'}
                    loadingMessage={() => 'Aan het zoeken naar bedrijven...'}
                />
                {error != null && <InvalidFeedback>{error}</InvalidFeedback>}
            </FormGroup>
            {isLoadingKvk && (
                <Margin bottom={1}>
                    <Loading />
                </Margin>
            )}
        </>
    );
};

export default KvkSearch;
