import { useField } from 'formik';
import React, { FC } from 'react';
import FormDate from '..';
import moment from 'moment';

interface FormikFormDateProps {
    name: string;
}

const FormikFormDate: FC<FormikFormDateProps> = ({ name, ...props }) => {
    const [field, meta, helpers] = useField(name);

    // Methods.
    const handleOnChange = (date: Date) => {
        if (date)
            helpers.setValue(date);
    };

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormDate selected={field.value ? moment(field.value).toDate() : null} {...props} onChange={handleOnChange} error={error} />;
};

export default FormikFormDate;
