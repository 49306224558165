import styled from '@emotion/styled';
import { lighten } from 'polished';

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    > * {
        margin-bottom: 0 !important;
    }
`;

export const Label = styled.div`
    padding: .65rem 1rem;
    line-height: 1;
    font-weight: 500;
    font-size: .85rem;
    margin-left: 1rem;
    background-color: ${({ theme }) => lighten(.35, theme.colors.orange)};
    color: ${({ theme }) => theme.colors.orange};
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
`;
