import React, { FC, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import { NewsItemModel } from 'hooks/newsitem/types';
import useNewsItems from 'hooks/newsitem';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

interface NewsItemProps extends RouteComponentProps {
}


const columns: Column<NewsItemModel>[] = [
    {
        Header: 'Titel',
        accessor: (mail) => mail.title
    },
    {
        width: 60,
        Header: 'Publicatiedatum',
        accessor: (mail) => mail.publicationDate != null ? parseISO(mail.publicationDate).getTime() : null,
        Cell: ({ value }: any) => value != null ? format(value, 'dd-MM-yyyy') : 'niet gepubliceerd'
    },
    {
        width: 60,
        id: 'created',
        Header: 'Aangemaakt op',
        accessor: (mail) => mail.created != null ? parseISO(mail.created).getTime() : null,
        Cell: ({ value }: any) => value != null ? format(value, 'dd-MM-yyyy') : '-'
    }
];

const NewsItem: FC<NewsItemProps> = () => {
    useHeader('Nieuwsberichten');

    const { useAllNewsItems, deleteNewsItem } = useNewsItems();
    const { data: newsItem = [], isLoading: isLoadingNewsItems, refetch } = useAllNewsItems();

    const [newsItemToDelete, setNewsItemToDelete] = useState<NewsItemModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const openDeleteModal = (item: NewsItemModel) => {
        setNewsItemToDelete(item);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setNewsItemToDelete(undefined);
        hideDeleteModal();
    };

    const handleDelete = async () => {
        if (newsItemToDelete == null) {
            return;
        }

        setIsLoadingDelete(true);

        const response = await deleteNewsItem(newsItemToDelete.id);

        if (response.ok) {
            refetch();
            toast.success('Nieuwsbericht succesvol verwijderd!');
            closeDeleteModal();
        } else {
            toast.error('Oeps, er ging iets fout tijdens het verwijderen van het nieuwsbericht. Probeer het later opnieuw!');
        }

        setIsLoadingDelete(false);
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="large" title="Nieuwsbericht verwijderen" subtitle="Weet je zeker dat je dit nieuwsbericht wilt verwijderen">
                <Group spaceBetween>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [newsItemToDelete]
    );

    return (
        <Card>
            <CardBody>
                <Table<NewsItemModel>
                    isLoading={isLoadingNewsItems}
                    data={newsItem}
                    noDataMessage="Geen nieuwsberichten gevonden"
                    columns={columns}
                    search
                    onAddClick={() => navigate('/admin/news/add')}
                    sortBy={[
                        { id: 'created', desc: true }
                    ]}
                    actions={[
                        {
                            icon: 'edit',
                            onClick: (item) => navigate('/admin/news/add', { state: { item: item } })
                        },
                        {
                            icon: 'delete',
                            onClick: (item) => openDeleteModal(item)
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default NewsItem;
