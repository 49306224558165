import { useApi } from 'hooks/api';
import { CustomError } from 'hooks/api/types';
import { useQuery } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { buildUriWithParams } from 'utils/urihelper';
import { AddNewsItemInputModel, EditNewsItemInputModel, GetNewsItemInputModel, NewsItemModel } from './types';

const getNewsItemsQueryKey = 'newsitem.get';

const useNewsItems = () => {

    const { get, post, put, deleteCall } = useApi();

    const baseUrl = '/newsitem';

    const getAllNewsItem = async (params?: GetNewsItemInputModel) => {
        const url = buildUriWithParams(baseUrl, params);

        const response = await get<NewsItemModel[]>(url);

        return response;
    };

    const useAllNewsItems = (params?: GetNewsItemInputModel) => {
        const queryKey = [getNewsItemsQueryKey, params];

        return useQuery<NewsItemModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllNewsItem(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const addNewsItem = async (values: AddNewsItemInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(baseUrl, fd);

        return response;
    };

    const editNewsItem = async (values: EditNewsItemInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await put(baseUrl, fd);

        return response;
    };

    const deleteNewsItem = async (id: string) => {
        const response = await deleteCall(`${baseUrl}/${id}`);

        return response;
    };

    return {
        getAllNewsItem,
        useAllNewsItems,
        addNewsItem,
        editNewsItem,
        deleteNewsItem
    };
};

export default useNewsItems;
