import { CustomResponse, useApi } from 'hooks/api';
import { useMutation, useQueryClient } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { AddCollectionForeignInputModel, AddSignedCollectionForeignOverviewInputModel, CollectionForeignMutationContext, CollectionForeignPlainModel, EditCollectionForeignInputModel } from './types';
import { CollectionForeignAllQueryKey } from './useAllCollectionForeigns';
import { v4 as uuid } from 'uuid';
import { formatISO } from 'date-fns';
import { CollectionStatus } from 'utils/constants/collectionconstants';

const useCollectionForeign = () => {
    const queryClient = useQueryClient();

    const { post, get, put, deleteCall } = useApi();

    const baseUrl = '/collectionforeign';

    const addCollectionForeign = async (values: AddCollectionForeignInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post<string>(
            baseUrl, fd
        );

        return response;
    };


    const editCollectionForeign = async (values: EditCollectionForeignInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await put<string>(
            baseUrl, fd
        );

        return response;
    };

    const validateStep1AddCollectionForeign = async (values: AddCollectionForeignInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(
            `${baseUrl}/validate/step1`, fd
        );

        return response;
    };

    const validateStep3AddCollectionForeign = async (values: AddCollectionForeignInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(
            `${baseUrl}/validate/step3`, fd
        );

        return response;
    };

    const collectionForeignMutation = useMutation<CustomResponse<unknown>, CustomResponse<unknown>, AddCollectionForeignInputModel, CollectionForeignMutationContext>((values: AddCollectionForeignInputModel) => addCollectionForeign(values), {
        onMutate: async (values: AddCollectionForeignInputModel) => {
            await queryClient.cancelQueries(CollectionForeignAllQueryKey);

            const previousData = queryClient.getQueryData<CollectionForeignPlainModel[]>(CollectionForeignAllQueryKey);

            const newData: CollectionForeignPlainModel[] = [
                ...(previousData ?? []), {
                    id: uuid(),
                    amount: values.amount,
                    created: formatISO(new Date()),
                    debtor: {
                        id: uuid(),
                        contactPersonInitials: values.debtor.contactPersonInitials,
                        contactPersonLastName: values.debtor.contactPersonLastName,
                        companyName: values.debtor.companyName,
                        relationNumber: values.debtor.relationNumber
                    },
                    customerName: '',
                    caseNumber: '',
                    status: CollectionStatus.InProgress
                }
            ];

            queryClient.setQueryData(CollectionForeignAllQueryKey, newData);

            return { previousData };
        },
        onError: (error, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData<CollectionForeignPlainModel[]>(CollectionForeignAllQueryKey, context.previousData);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(CollectionForeignAllQueryKey);
        }
    });

    const getExampleCollectionForeignOverview = async (values: AddCollectionForeignInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(
            `${baseUrl}/example-overview`, fd
        );

        return response;
    };

    const downloadCollectionForeignFile = async (collectionForeignId: string, fileId: string) => {
        const response = await get(
            `${baseUrl}/file/${collectionForeignId}/${fileId}`
        );

        return response;
    };

    const uploadSignedCollectionForeignOverview = async (collectionId: string, values: AddSignedCollectionForeignOverviewInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(`${baseUrl}/${collectionId}/signed`, fd);

        return response;
    };

    const getCollectionForeignSignRequestUrl = async (collectionId: string) => {
        const response = await get<string>(`${baseUrl}/${collectionId}/signrequesturl`);

        return response;
    };

    const deleteCollectionForeign = async (collectionId: string) => {
        const response = await deleteCall(`${baseUrl}/${collectionId}`);

        return response;
    };

    return {
        addCollectionForeign,
        editCollectionForeign,
        validateStep1AddCollectionForeign,
        validateStep3AddCollectionForeign,
        collectionForeignMutation,
        getExampleCollectionForeignOverview,
        downloadCollectionForeignFile,
        uploadSignedCollectionForeignOverview,
        getCollectionForeignSignRequestUrl,
        deleteCollectionForeign
    };
};

export default useCollectionForeign;
