import React, { FC, useEffect, useState } from 'react';
import FormFile, { FormFileProps } from '../FormFile';
import InvalidFeedback from '../InvalidFeedback';
import { FormFileRemove, FormFileWrapper, FormFileAdd } from './styles';
import { useTheme } from '@emotion/react';

export interface FormMultipleFileProps {
    error?: string;
    value?: File[];
    onChange?: (value: File[]) => void;
    whiteBackground?: boolean;
}

const FormMultipleFile: FC<FormMultipleFileProps> = ({ error, value, onChange, whiteBackground }) => {
    const [files, setFiles] = useState<FormFileProps[]>(value ? value.map((value) => ({ value })) : [{}]);
    const [fileError, setFileError] = useState(error);
    const theme = useTheme();

    useEffect(() => {
        setFileError(error);
    }, [error]);

    const handleOnChange = (index: number) => (file: File | null) => {

        if (file == null) {
            handleOnRemoveClick(index)();
            return;
        }

        if (file.size > 20000000) {
            setFileError('Bestand mag maximaal 20MB groot zijn');
            return;
        } else {
            setFileError(undefined);
        }

        // Make copy of current files.
        const newFiles = [...files];

        // Replace the file.
        newFiles.splice(index, 1, { value: file });

        // Set new files.
        setFiles(newFiles);

        onFileChange(newFiles);
    };

    const onFileChange = (files: FormFileProps[]) => {
        if (onChange) {
            const f = files.reduce((a: File[], c) => {
                return c.value ? [...a, c.value] : a;
            }, []);

            onChange(f);
        }
    };

    const handleOnAddClick = () => {
        const newFiles = [...files];

        newFiles.push({});

        setFiles(newFiles);
    };

    const handleOnRemoveClick = (index: number) => () => {
        if (index === 0) {
            const newFiles = [...files];

            newFiles[0].value = undefined;

            setFiles(newFiles);
            onFileChange(newFiles);
        } else {
            const newFiles = [...files];

            newFiles.splice(index, 1);

            setFiles(newFiles);
            onFileChange(newFiles);
        }
    };

    return (
        <>
            {files?.map((file, index) => (
                <FormFileWrapper key={index}>
                    <FormFile {...file} onChange={handleOnChange(index)} whiteBackground={whiteBackground} />
                    {(index !== 0 || (index === 0 && files[0].value)) && (
                        <FormFileRemove
                            type="button"
                            onClick={handleOnRemoveClick(index)}
                            startIcon="remove"
                            iconColor={theme.colors.white}
                            variant="error"
                        />
                    )}
                    {index === files.length - 1 && (
                        <FormFileAdd
                            type="button"
                            onClick={handleOnAddClick}
                            variant="primary"
                            startIcon="add"
                            iconColor={theme.colors.white}
                        />
                    )}
                </FormFileWrapper>
            ))}

            {fileError && <InvalidFeedback>{fileError}</InvalidFeedback>}
        </>
    );
};

export default FormMultipleFile;
