import { useSession } from 'contexts/sessionContext';
import { GetProfileModel } from 'hooks/profile/types';
import useProfile from 'hooks/profile/useProfile';
import React, { FC, useState, useEffect } from 'react';

export interface HeaderContextValue {
    headerTitle?: string;
    setHeaderTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
    headerProfile?: GetProfileModel;
    setHeaderProfile: React.Dispatch<React.SetStateAction<GetProfileModel | undefined>>;
}

const HeaderContext = React.createContext<HeaderContextValue>({
    headerTitle: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setHeaderTitle: () => { },
    headerProfile: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setHeaderProfile: () => { }
});

interface HeaderProviderProps {

}

export const HeaderProvider: FC<HeaderProviderProps> = (props) => {
    const { session } = useSession();

    const [headerTitle, setHeaderTitle] = React.useState<string>();
    const { getProfile } = useProfile();
    const [headerProfile, setHeaderProfile] = useState<GetProfileModel>();

    useEffect(() => {
        if (session) {
            (async () => {
                const profileCall = loadProfile();
                await Promise.all([profileCall]);
            })();
        }
    }, [session]);

    const loadProfile = async () => {
        const response = await getProfile();

        if (response.ok && response.data) {
            setHeaderProfile(response.data);
        }
    };

    const context: HeaderContextValue = React.useMemo(() => ({
        headerTitle,
        setHeaderTitle,
        headerProfile,
        setHeaderProfile
    }), [headerTitle, headerProfile]);

    return (
        <HeaderContext.Provider value={context} {...props} />
    );
};

export const useHeader = (title?: string) => {
    const context = React.useContext(HeaderContext);

    if (!context) {
        throw new Error('useHeader must be used within a HeaderProvider');
    }
    useEffect(() => {
        context.setHeaderTitle(title);
    }, []);

    return context;
};
