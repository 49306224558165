import React from 'react';
import { Wrapper } from './styles';

interface LabelProps {
    text: string;
}

const Label = ({ text }: LabelProps) => {
    return (
        <Wrapper>{text}</Wrapper>
    );
};

export default Label;
