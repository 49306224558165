export enum Right {
    Collection = 'Collection',
    CollectionAbroad = 'CollectionAbroad',
    LegalAid = 'LegalAid',
    GeneralConditions = 'GeneralConditions'
};

export const getRightDisplay = (right: string): string => {
    switch (right) {
        case Right.Collection:
            return 'Incasso';
        case Right.CollectionAbroad:
            return 'Incasso buitenland';
        case Right.LegalAid:
            return 'Rechtshulp';
        case Right.GeneralConditions:
            return 'Algemene voorwaarden';
    }

    return '';
};
