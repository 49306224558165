import Margin from 'components/atoms/Margin';
import KvkSearch from 'components/organisms/KvkSearch';
import { useFormikContext } from 'formik';
import { KvkCompanyInfoModel } from 'hooks/kvk/types';
import useKvk from 'hooks/kvk/useKvk';
import React, { FC } from 'react';
import { KvkLegalFormStringToLegalForm } from 'utils/kvkHelper';

export interface Step1Props { }

const Step1: FC<Step1Props> = () => {
    const { getBasicProfile } = useKvk();
    const { setFieldValue } = useFormikContext();

    const onCompanySelectChange = async (company: KvkCompanyInfoModel | undefined) => {
        if (company === undefined) {
            setFieldValue('kvkNumber', '');
            setFieldValue('name', '');
            return;
        }

        const companyData = await getBasicProfile(company.kvkNumber);

        if (companyData.data && companyData.data.addresses?.[0]) {
            const { street, houseNumber, houseNumberAddition, postalCode, city } = companyData.data.addresses?.[0] ?? {};

            const addressVisit = `${street} ${houseNumber}${houseNumberAddition ?? ''}`;

            setFieldValue('postalCodeVisit', postalCode);
            setFieldValue('cityVisit', city);
            setFieldValue('addressVisit', addressVisit);
        }

        if (companyData.data != null && companyData.data.legalForm != null) {
            setFieldValue('legalForm', KvkLegalFormStringToLegalForm(companyData.data.legalForm));
        }

        setFieldValue('kvkNumber', company.kvkNumber);
        setFieldValue('name', company.name);

    };

    const onSearchDone = (hasResults: boolean) => {
        if (hasResults) {
            return;
        }

        setFieldValue('kvkNumber', '');
        setFieldValue('name', '');
    };

    return (
        <Margin bottom={8}>
            <KvkSearch
                onSelectChange={onCompanySelectChange}
                onSearchDone={onSearchDone}
                notFoundMessage="Het bedrijf kan niet worden gevonden in het KvK register. Kies 'Volgende Stap' om het bedrijf handmatig toe te voegen"
            />
        </Margin>
    );
};

export default Step1;
