import styled from '@emotion/styled';
import { lighten } from 'polished';

export const Wrapper = styled.span`
    display: inline-block;
    margin: 0;
    padding: .35rem .5rem;
    line-height: 1;
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    color: ${({ theme }) => theme.colors.orange};
    background-color: ${({ theme }) => lighten(.4, theme.colors.orange)};
    font-weight: 500;
    font-size: .85rem;
    align-self: flex-start;
    margin-left: 1rem;
`;