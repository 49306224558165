import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SessionProvider } from 'contexts/sessionContext';


ReactDOM.render(
    <StrictMode>
        <SessionProvider>
            <App />
            <ToastContainer
                transition={Bounce}
                autoClose={2500}
            />
        </SessionProvider>
    </StrictMode>,
    document.getElementById('root')
);
