import React, { FC, useCallback, useEffect, useState } from 'react';
import { Card, CardBody } from 'components/atoms/card';
import { Paragraph, H3, H4 } from 'components/atoms/text';
import { Button } from 'components/atoms/button';
import ButtonWrapper from 'components/atoms/ButtonWrapper';
import useCollectionForeign from 'hooks/collectionforeign/useCollectionForeign';
import { useFormikContext } from 'formik';
import { AddCollectionForeignInputModel } from 'hooks/collectionforeign/types';
import { toast } from 'react-toastify';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Margin from 'components/atoms/Margin';
import Group from 'components/molecules/Group';
import { FormikFormYesNo } from 'components/atoms/form';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import moment from 'moment';
import { useSession } from 'contexts/sessionContext';
import { Col, Row } from 'react-grid-system';

export interface Step4Props { }

const Step4: FC<Step4Props> = () => {
    const { values } = useFormikContext<AddCollectionForeignInputModel>();
    const { getExampleCollectionForeignOverview } = useCollectionForeign();
    const { session } = useSession();

    const [valuesChanged, setValuesChanged] = useState(false);
    const [isLoadingDownload, setIsLoadingDownload] = useState(false);
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [pdf, setPdf] = useState<Blob>();
    const [showPdf, setShowPdf] = useState(false);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (numPages: number) => {
        setNumPages(numPages);
    };

    const downloadExampleCollectionForeignOverview = async () => {
        if (!valuesChanged && pdf != null) {
            saveAs(pdf, 'voorbeeld_incasso_overzicht.pdf');
            return;
        }

        setIsLoadingDownload(true);

        const response = await getExampleCollectionForeignOverview(values);

        if (response.ok) {
            const blob = await response.blob();
            saveAs(blob, 'voorbeeld_incasso_overzicht.pdf');
            setPdf(blob);
            setValuesChanged(false);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de PDF');
        }

        setIsLoadingDownload(false);
    };

    const previewExampleCollectionForeignOverview = async () => {
        if (!valuesChanged && pdf != null) {
            setShowPdf(true);
        }

        setIsLoadingPreview(true);

        const response = await getExampleCollectionForeignOverview(values);

        if (response.ok) {
            const blob = await response.blob();
            setPdf(blob);
            setShowPdf(true);
            setValuesChanged(false);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de PDF');
        }

        setIsLoadingPreview(false);
    };

    useEffect(() => {
        setValuesChanged(true);
    }, [values]);

    const nextPage = useCallback(() => {
        if (pageNumber === numPages) {
            return;
        }

        setPageNumber(pageNumber + 1);
    }, [pageNumber, numPages]);

    const previousPage = useCallback(() => {
        if (pageNumber === 1) {
            return;
        }

        setPageNumber(pageNumber - 1);
    }, [pageNumber, numPages]);

    // Render.
    return (
        <>
            <Card fill>
                <CardBody>
                    <H3>Verhaalsinformatie</H3>
                    <Paragraph>
                        Verhaalsinformatie geeft u inzicht in de (financiële) situatie van uw debiteur en geeft een indicatie van het te
                        volgen traject bij het incasseren van uw vordering. Wij adviseren verhaalsinformatie bij vorderingen vanaf €
                        2.500,00. Op basis van uw incassomodule ontvangt u 50% korting op het verhaalsrapport, wij informeren u
                        hierover nader.
                    </Paragraph>
                    <FormikFormYesNo label="Wenst u verhaalsinformatie over deze debiteur?" name="isStoryInformationRequested" />
                </CardBody>
            </Card>
            <Card fill>
                <CardBody>
                    <Margin bottom={2}>
                        <H3>Machtiging</H3>
                        <Paragraph>
                            Door het verzenden van dit formulier machtigt u Juresta om alle zaken met betrekking tot deze in behandeling gegeven
                            incasso-opdracht te beheren, de belangen waar te nemen, voor de rechten van de opdrachtgever op te komen en deze bij
                            dit alles - zowel in als buiten rechte - te vertegenwoordigen en verder alles te doen wat de gevolmachtigde in het
                            belang van de volmachtgever gewenst, nuttig of noodzakelijk zal achten.
                        </Paragraph>
                    </Margin>
                    <H4>Uw gegevens</H4>
                    <Margin bottom={1}>
                        <Row>
                            <Col xs={2}>Datum</Col>
                            <Col>{moment().format('DD-MM-YYYY')}</Col>
                        </Row>
                    </Margin>
                    <Margin bottom={1}>
                        <Row>
                            <Col xs={2}>Naam</Col>
                            <Col>{session?.fullName ?? ''}</Col>
                        </Row>
                    </Margin>
                    <Margin bottom={2}>
                        <Row>
                            <Col xs={2}>Functie</Col>
                            <Col><FormControlFormik name="userFunction" placeholder="Vul hier je functie in" /></Col>
                        </Row>
                    </Margin>
                    <Group>
                        <p>Heeft u de gegevens zo volledig mogelijk en naar waarheid ingevuld?</p>
                        <FormikFormYesNo name="hasAgreed" />
                    </Group>
                </CardBody>
            </Card>
            <Card fill>
                <CardBody>
                    <Paragraph>
                        Bekijk de door u ingevulde gegevens of download een overzicht, u kunt ook
                        een exemplaar opslaan voor uw eigen administratie.
                    </Paragraph>
                    <ButtonWrapper>
                        <Button onClick={downloadExampleCollectionForeignOverview} loading={isLoadingDownload} type="button" variant="white">
                            PDF downloaden
                        </Button>
                        <Button onClick={previewExampleCollectionForeignOverview} loading={isLoadingPreview} type="button" variant="white">
                            PDF inzien
                        </Button>
                    </ButtonWrapper>
                    {pdf && showPdf && (
                        <Margin top={2}>
                            <Document file={pdf} onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}>
                                <Page pageNumber={pageNumber} />
                                <Margin top={1}>
                                    <Group>
                                        <Button onClick={previousPage} type="button">
                                            Vorige
                                        </Button>
                                        <p>{`${pageNumber} van ${numPages}`}</p>
                                        <Button onClick={nextPage} type="button">
                                            Volgende
                                        </Button>
                                    </Group>
                                </Margin>
                            </Document>
                        </Margin>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default Step4;
