import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FormGroup = styled.div<{
    noMargin?: boolean;
    alignRight?: boolean;
}>`
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '2rem')};
    ${({ alignRight }) =>
        alignRight &&
        css`
            display: flex;
            justify-content: flex-end;
        `};
`;

export const Label = styled.label<{
    required?: boolean;
    isWhite?: boolean;
}>`
    display: block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
    font-weight: normal;
    color: ${({ theme, isWhite }) => (isWhite ? theme.colors.white : theme.colors.dark)};
    white-space: nowrap;

    ${({ required, theme, isWhite }) =>
        required &&
        css`
            &:after {
                content: ' *';
                color: ${isWhite ? theme.colors.white : theme.colors.secondary};
                font-weight: 600;
            }
        `};
`;

export const Header = styled.div<{
    noMargin?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '.65rem')};
`;

export const Text = styled.span`
    &,
    a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 8px;
        color: ${({ theme }) => theme.greys[300]};
        font-size: 0.875rem;
        line-height: 1rem;
        flex: 1;
        text-align: right;
    }
`;
