import { CustomResponse, useApi } from 'hooks/api';
import { useMutation, useQueryClient } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { AddCollectionInputModel, AddSignedCollectionOverviewInputModel, CollectionMutationContext, CollectionPlainModel, EditCollectionInputModel } from './types';
import { CollectionAllQueryKey } from './useAllCollections';
import { v4 as uuid } from 'uuid';
import { formatISO } from 'date-fns';
import { CollectionStatus } from 'utils/constants/collectionconstants';

const useCollection = () => {
    const queryClient = useQueryClient();

    const { post, get, put, deleteCall } = useApi();

    const baseUrl = '/collection';

    const addCollection = async (values: AddCollectionInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post<string>(
            baseUrl, fd
        );

        return response;
    };

    const editCollection = async (values: EditCollectionInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await put<string>(
            baseUrl, fd
        );

        return response;
    };

    const validateStep1AddCollection = async (values: AddCollectionInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(
            `${baseUrl}/validate/step1`, fd
        );

        return response;
    };

    const validateStep3AddCollection = async (values: AddCollectionInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(
            `${baseUrl}/validate/step3`, fd
        );

        return response;
    };

    const collectionMutation = useMutation<CustomResponse<unknown>, CustomResponse<unknown>, AddCollectionInputModel, CollectionMutationContext>((values: AddCollectionInputModel) => addCollection(values), {
        onMutate: async (values: AddCollectionInputModel) => {
            await queryClient.cancelQueries(CollectionAllQueryKey);

            const previousData = queryClient.getQueryData<CollectionPlainModel[]>(CollectionAllQueryKey);

            const newData: CollectionPlainModel[] = [
                ...(previousData ?? []), {
                    id: uuid(),
                    amount: values.amount,
                    created: formatISO(new Date()),
                    debtor: {
                        id: uuid(),
                        contactPersonInitials: values.debtor.contactPersonInitials,
                        contactPersonLastName: values.debtor.contactPersonLastName,
                        companyName: values.debtor.companyName,
                        relationNumber: values.debtor.relationNumber
                    },
                    customerName: '',
                    caseNumber: '',
                    status: CollectionStatus.InProgress
                }
            ];

            queryClient.setQueryData(CollectionAllQueryKey, newData);

            return { previousData };
        },
        onError: (error, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData<CollectionPlainModel[]>(CollectionAllQueryKey, context.previousData);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(CollectionAllQueryKey);
        }
    });

    const getExampleCollectionOverview = async (values: AddCollectionInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(
            `${baseUrl}/example-overview`, fd
        );

        return response;
    };

    const downloadCollectionFile = async (collectionId: string, fileId: string) => {
        const response = await get(
            `${baseUrl}/file/${collectionId}/${fileId}`
        );

        return response;
    };

    const uploadSignedCollectionOverview = async (collectionId: string, values: AddSignedCollectionOverviewInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(`${baseUrl}/${collectionId}/signed`, fd);

        return response;
    };

    const getCollectionSignRequestUrl = async (collectionId: string) => {
        const response = await get<string>(`${baseUrl}/${collectionId}/signrequesturl`);

        return response;
    };

    const deleteCollection = async (collectionId: string) => {
        const response = await deleteCall(`${baseUrl}/${collectionId}`);

        return response;
    };

    return {
        addCollection,
        editCollection,
        validateStep1AddCollection,
        validateStep3AddCollection,
        collectionMutation,
        getExampleCollectionOverview,
        downloadCollectionFile,
        uploadSignedCollectionOverview,
        getCollectionSignRequestUrl,
        deleteCollection
    };
};

export default useCollection;
