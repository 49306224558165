import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import { navigate } from '@reach/router';
import { CorrespondenceTemplatePlainModel, DeleteCorrespondenceTemplateInputModel } from 'hooks/correspondenceTemplate/types';
import Modal from 'components/organisms/Modal';
import useModal from 'hooks/modal';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { toast } from 'react-toastify';
import useAllCorrespondenceTemplates from 'hooks/correspondenceTemplate/useAllCorrespondenceTemplates';
import useDeleteAdminCorrespondenceTemplate from 'hooks/correspondenceTemplate/useDeleteAdminCorrespondenceTemplate';
import { format, parseISO } from 'date-fns';

interface CorrespondenceTemplatesProps extends RouteComponentProps {
}

const columns: Column<CorrespondenceTemplatePlainModel>[] = [
    {
        Header: 'Naam',
        accessor: (template) => template.name
    },
    {
        Header: 'Aangemaakt op',
        accessor: (template) => parseISO(template.created).getTime(),
        Cell: ({ value }: any) => format(value, 'dd-MM-yyyy HH:mm')
    },
    {
        Header: 'Laatst gewijzigd',
        accessor: (template) => parseISO(template.changed).getTime(),
        Cell: ({ value }: any) => format(value, 'dd-MM-yyyy HH:mm')
    }
];

const CorrespondenceTemplates: FC<CorrespondenceTemplatesProps> = () => {
    useHeader('Templates');

    const [correspondenceTemplateToDelete, setCorrespondenceTemplateToDelete] = useState<DeleteCorrespondenceTemplateInputModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const { deleteCorrespondenceTemplateMutation } = useDeleteAdminCorrespondenceTemplate();
    const { data: correspondenceTemplates = [], isLoading: isLoadingCorrespondenceTemplates } = useAllCorrespondenceTemplates();

    const handleDelete = async () => {

        if (!correspondenceTemplateToDelete) {
            return;
        }

        setIsLoadingDelete(true);

        await deleteCorrespondenceTemplateMutation.mutateAsync(correspondenceTemplateToDelete)
            .then(() => {
                toast.success('template succesvol verwijderd!');
            }).catch(() => {
                toast.error('Oeps, er ging iets fout tijdens het verwijderen van de template. Probeer het later opnieuw!');
            });

        setIsLoadingDelete(false);
        closeDeleteModal();
    };

    const handleManage = (item: CorrespondenceTemplatePlainModel | undefined) => {
        navigate('/admin/correspondence/templates/add', { state: { item: item } });
    };

    const openDeleteModal = (templateLetter: DeleteCorrespondenceTemplateInputModel) => {
        setCorrespondenceTemplateToDelete(templateLetter);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setCorrespondenceTemplateToDelete(undefined);
        hideDeleteModal();
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="large" title="Template verwijderen" subtitle="Weet je zeker dat je deze template wilt verwijderen">
                <Group spaceBetween>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [correspondenceTemplateToDelete]
    );

    return (
        <Card>
            <CardBody>
                <Table<CorrespondenceTemplatePlainModel>
                    isLoading={isLoadingCorrespondenceTemplates}
                    data={correspondenceTemplates}
                    noDataMessage="Geen templates beschikbaar"
                    columns={columns}
                    search
                    onAddClick={() => navigate('/admin/correspondence/templates/add')}
                    actions={[
                        {
                            icon: 'edit',
                            onClick: (item, index) => handleManage(item),
                            title: 'Pas template aan'
                        },
                        {
                            icon: 'delete',
                            onClick: (item, index) => openDeleteModal(item),
                            title: 'Verwijder template'
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default CorrespondenceTemplates;
