import React, { FC, useMemo } from 'react';
import { H3 as Text, Wrapper, H3Icon as Icon } from './styles';
import { Colors } from 'theme/colors';
import { useTheme } from '@emotion/react';

export interface H3Props {
    variant?: keyof Colors;
    grey?: number;
    center?: boolean;
    className?: string;
    icon?: string;
    noMargin?: boolean;
}

const H3: FC<H3Props> = ({ children, variant, grey, center, className, icon, noMargin }) => {
    const theme = useTheme();
    const color = useMemo(() => (variant ? theme.colors[variant] : grey ? theme.greys[grey] : theme.colors.primary), [variant, grey]);

    return (
        <Wrapper noMargin={noMargin}>
            {icon && <Icon name={icon} color={color} />}
            <Text color={color} center={center} className={className}>
                {children}
            </Text>
        </Wrapper>
    );
};

export default H3;
