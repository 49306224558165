import { useApi, CustomError } from 'hooks/api';
import { useQuery } from 'react-query';
import { LanguageModel } from './types';

const queryKey = 'languages.';

export const useLanguage = () => {
    const { get } = useApi();

    const fetchAllLanguages = async () => await get<LanguageModel[]>('/language');

    const useAllLanguages = () => {
        const allLanguagesQueryKey = `${queryKey}.all`;

        return useQuery<LanguageModel[], CustomError[]>(allLanguagesQueryKey, async () => {
            const response = await fetchAllLanguages();

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    return {
        useAllLanguages,
    };
};