import React, { FC, useEffect, useState } from 'react';
import { FormGroup, FormikFormFile } from 'components/atoms/form';
import { useFormikContext } from 'formik';
import { EditBannerInputModel } from 'hooks/banner/types';
import { cdnString } from 'utils/cdnHelper';

export interface BannerFileProps {
}

const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
});

const BannerFile: FC<BannerFileProps> = () => {
    const { values } = useFormikContext<EditBannerInputModel>();
    const [image, setImage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (values.bannerImage) {
            toBase64(values.bannerImage).then(image => setImage(image));
        }

    }, [values.bannerImage]);

    // Render
    const src = values.bannerImage ? image : values.imageHash && cdnString(values.imageHash);

    return (
        <>
            {values.imageHash && 
                <FormGroup label="Huidige afbeelding">
                    <img alt={values.description} style={{ width: '100%', height: 'auto' }} src={src} />
                </FormGroup>
            }
            <FormGroup>
                <FormikFormFile required name="bannerImage" />
            </FormGroup>
        </>
    );
};

export default BannerFile;
