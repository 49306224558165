import { RouteComponentProps } from '@reach/router';
import React, { FC, useState, useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { FormGroup, FormikFormControl, FormikFormSelect, FormikFormYesNo } from 'components/atoms/form';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import { CalculatorType } from 'utils/constants/calculatortypeconstants';
import { CalculatorInterestType } from 'utils/constants/calculatorinteresttypeconstants';
import { CalculatorInputModel } from 'hooks/calculators/types';
import { Form, Formik } from 'formik';
import { H3, Paragraph } from 'components/atoms/text';
import { useHeader } from 'contexts/headerContext';
import FormCurrencyFormik from 'components/atoms/form/FormCurrency/formik';
import Group from 'components/molecules/Group';
import Tooltip from 'components/atoms/Tooltip';
import Margin from 'components/atoms/Margin';
import moment from 'moment';

export interface CalculatorsProps extends RouteComponentProps { }

const initialValues: CalculatorInputModel = {
    amount: 0,
    fromDate: moment(new Date()).format('YYYY-MM-DD'),
    toDate: moment(new Date()).format('YYYY-MM-DD'),
    type: CalculatorType.ConsumerTransaction,
    interestType: CalculatorInterestType.Combined,
    interestPercentage: 0,
    isTaxesRequired: false
};

const Calculators: FC<CalculatorsProps> = () => {
    useHeader('Extra\'s');

    const [interestPercentageVisisble, setInterestPercentageVisisble] = useState(false);

    const [legalInterest, setLegalInterest] = useState(0);
    const [costs, setCosts] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [formValues, setFormValues] = useState<CalculatorInputModel>(initialValues);
    const onSubmit = (values: CalculatorInputModel) => {
        setInterestPercentageVisisble(values.type === CalculatorType.ContractTransaction ? true : false);
        const interestCost = calculateLegalInterest(values);
        const collectionCosts = calculateCosts(values);
        const totalCosts = +values.amount + +interestCost + +collectionCosts;
        setTotalAmount(Math.round((totalCosts + Number.EPSILON) * 100) / 100);
    };

    const calculateLegalInterest = (values: CalculatorInputModel) => {
        // To calculate the time difference of two dates
        const difference_In_Time = Date.parse(values.toDate.toString()) - Date.parse(values.fromDate.toString());

        // To calculate the no. of days between two dates
        const difference_In_Days = difference_In_Time / (1000 * 3600 * 24);

        // calculate number of interest periodes
        const interestPeriods = difference_In_Days / 365;

        // Get interestpercentage => ContractTransaction = userinput; TradeTransaction = 8%, ConsumerTransaction = 2%
        const interestPercentage = values.type === CalculatorType.ContractTransaction ? values.interestPercentage : values.type === CalculatorType.TradeTransaction ? 8 : 2;

        //Get the interest based on if we need to calculate using compound interest or singular interest?
        let interest = values.interestType === CalculatorInterestType.Singular ?
            interestPeriods * interestPercentage / 100 * values.amount :
            (values.amount * ((1 + (interestPercentage / 100)) ** interestPeriods)) - values.amount;
        if (isNaN(interest))
            interest = 0;
        // Set and round
        setLegalInterest(Math.round((interest + Number.EPSILON) * 100) / 100);
        return Math.round((interest + Number.EPSILON) * 100) / 100;
    };

    const calculateCosts = (values: CalculatorInputModel) => {
        let costs = 0;
        if (values.amount > 200000) {
            // For invoices higher then 200000 use baseValue (2775) + 0.5% for everything above 200000
            costs = 2775 + ((values.amount - 200000) / 100 * 0.5);
        } else if (values.amount > 10000) {
            // For invoices higher then 10000 but lower then 200000 use baseValue (875) + 1% for everything above 10000
            costs = 875 + ((values.amount - 10000) / 100 * 1);
        } else if (values.amount > 5000) {
            // For invoices higher then 5000 but lower then 10000 use baseValue (625) + 5% for everything above 5000
            costs = 625 + ((values.amount - 5000) / 100 * 5);
        } else if (values.amount > 2500) {
            // For invoices higher then 2500 but lower then 5000 use baseValue (375) + 10% for everything above 2500
            costs = 375 + ((values.amount - 2500) / 100 * 10);
        } else {
            // For invoices lower then 2500 use 15% of invoice amount. 
            costs = values.amount / 100 * 15;
        }

        if (costs > 6775) {
            // cost may never exceed 6775
            costs = 6775;
        } else if (costs < 40) {
            // there's a minimum of 40
            costs = 40;
        }

        // If vat is needed add current vat percentage (high) 21%
        costs = values.isTaxesRequired ? costs * 1.21 : costs;

        // Set and round
        setCosts(Math.round((costs + Number.EPSILON) * 100) / 100);
        return Math.round((costs + Number.EPSILON) * 100) / 100;
    };

    const typeOptions = [
        {
            value: CalculatorType.ConsumerTransaction,
            label: 'Consumententransactie'
        },
        {
            value: CalculatorType.TradeTransaction,
            label: 'Handelstransactie'
        },
        {
            value: CalculatorType.ContractTransaction,
            label: 'Contractueel'
        }
    ];

    const interestTypeOptions = [
        {
            value: CalculatorInterestType.Combined,
            label: 'Samengesteld'
        },
        {
            value: CalculatorInterestType.Singular,
            label: 'Enkelvoudig'
        }
    ];

    useEffect(() => {
        // @ts-ignore: amount is door de currencyinput een string die hier omgezet wordt naar een number
        if (typeof formValues.amount === 'string' && formValues.amount.indexOf(',') === formValues.amount.length - 3) {
            // @ts-ignore: cont.
            formValues.amount = formValues.amount.replaceAll(',', '.');
            // @ts-ignore: cont.
        } else if (typeof formValues.amount === 'string' && (formValues.amount.indexOf(',') === formValues.amount.length - 1 || formValues.amount.indexOf(',') === formValues.amount.length - 2)) {
            return;
        }
        onSubmit(formValues);
    }, [formValues]);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => {

                setFormValues(values);

                return (
                    <Form>
                        <Card fill variant="primary">
                            <CardBody>
                                <Paragraph variant="white" noMargin>
                                    Met onderstaande calculator kun u eenvoudig de door uw debiteur verschuldigde incassokosten en rente berekenen.
                                </Paragraph>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <CardHeader>
                                    <H3>Calculator wettelijke rente en incassokosten (WIK)<Margin left={0.5}><Tooltip placement="bottom-center">Volgens de Wet Incassokosten zijn de incassokosten een percentage van het factuurbedrag, met een minimum van €40,00 en een maximum van €6.775,00. Het percentage wordt lager als de vordering hoger wordt. De wet hanteert hiervoor een rekenmethode, een zogenoemde staffel.</Tooltip></Margin></H3>
                                </CardHeader>
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup label="Hoofdsom">
                                            <FormCurrencyFormik name="amount" onValueChange={(value) => setFieldValue('amount', value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup label="Berekenen vanaf">
                                            <FormikFormControl name="fromDate" type="date" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup label="Berekenen tot en met">
                                            <FormikFormControl name="toDate" type="date" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup label="Type">
                                            <FormikFormSelect name="type" options={typeOptions} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup label="Rente type">
                                            <FormikFormSelect name="interestType" options={interestTypeOptions} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        {interestPercentageVisisble &&
                                            <FormGroup label="Rentepercentage">
                                                <FormControlFormik name="interestPercentage" type="numeric" />
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup label="Btw berekenen">
                                            <FormikFormYesNo name="isTaxesRequired" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <CardHeader>
                                    <H3>Uitkomst berekening</H3>
                                </CardHeader>
                                <Row>
                                    <Col sm={4}>
                                        <Card variant="dark" noMargin>
                                            <CardBody>
                                                <Paragraph variant="white" noMargin>
                                                    <Group spaceBetween>
                                                        <b>Rente</b>
                                                        <b>€{legalInterest.toFixed(2).replace('.', ',')}</b>
                                                    </Group>
                                                </Paragraph>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm={4}>
                                        <Card variant="dark" noMargin>
                                            <CardBody>
                                                <Paragraph variant="white" noMargin>
                                                    <Group spaceBetween>
                                                        <b>Incassokosten</b>
                                                        <b>€{costs.toFixed(2).replace('.', ',')}</b>
                                                    </Group>
                                                </Paragraph>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm={4}>
                                        <Card variant="dark" noMargin>
                                            <CardBody>
                                                <Paragraph variant="white" noMargin>
                                                    <Group spaceBetween>
                                                        <b>Totaalbedrag</b>
                                                        <b>€{totalAmount.toFixed(2).replace('.', ',')}</b>
                                                    </Group>
                                                </Paragraph>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default Calculators;
