import Menu, { MenuProps } from 'components/molecules/Menu';
import React, { FC } from 'react';
import { Navigation as Wrapper, Content, Header } from './styles';
import Logo from 'static/logo.svg';

interface NavigationProps extends MenuProps { }

const Navigation: FC<NavigationProps> = (props) => {

    return (
        <Wrapper>
            <Content>
                <Header>
                    <img src={Logo} alt="Juresta" />
                </Header>
                <Menu {...props} />
            </Content>
        </Wrapper>
    );
};

export default Navigation;
