import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';

export const Tab = styled(Link) <{ active?: boolean }>`
    height: 3rem;
    display: inline-flex;
    padding: 0 24px;
    align-items: center;
    position: relative;
    text-decoration: none;

    ${({ active, theme }) =>
        active &&
        css`
            border-radius: ${theme.borderRadius.normal}px;
            background-color: ${theme.colors.primary};
        `}

    &:hover {
        cursor: pointer;
    }
`;

export const Title = styled.span<{ active?: boolean }>`
    color: ${({ theme }) => theme.colors.primary};
    transition: color 0.4s ease;

    ${({ active, theme }) =>
        active &&
        css`
            color: ${theme.colors.white};
        `};
`;
