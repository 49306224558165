import React, { lazy, Suspense, useEffect, useState } from 'react';
import theme from './theme';
import { Global, css, ThemeProvider } from '@emotion/react';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import grid from 'theme/grid';
import colors from 'theme/colors';
import greys from 'theme/greys';
import { Router } from '@reach/router';
import Tabs from 'components/templates/Tabs';
import ForgotPassword from 'components/pages/ForgotPassword';
import ResetPassword from 'components/pages/ResetPassword';
import TwoFactorAuthentication from 'components/pages/TwoFactorAuthentication';
import Extras from 'components/pages/Extras';
import Calculators from 'components/pages/Calculators';
import TemplateLetters from 'components/pages/TemplateLetters';
import ManageTemplateLetters from 'components/pages/TemplateLetters/Manage';
import Tools from 'components/pages/Tools';
import Banners from 'components/pages/Banners';
import ManageBanners from 'components/pages/Banners/Manage';
import Account from 'components/templates/Account';
import Default from 'components/templates/Default';
import { QueryClient, QueryClientProvider } from 'react-query';
import borderRadius from 'theme/borderRadius';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';

// Dashboard pages (The nav is not a heavy component, so no need for lazy import. Lazy loading is done inside default/admin template).
import Dashboard from 'components/pages/Dashboard';
import About from 'components/pages/About';
import LegalAid from 'components/pages/LegalAid';
import AdminDashboard from 'components/pages/AdminDashboard';
import Profile from 'components/pages/Profile';
import Customers from 'components/pages/Customers';
import CustomerDetail from 'components/pages/CustomerDetail';
import AddCollection from 'components/pages/Collection';
import AddCollectionForeign from 'components/pages/CollectionForeign';
import Correspondence from 'components/pages/Correspondence';
import CorrespondenceTemplates from 'components/pages/CorrespondenceTemplates';
import AddCorrespondenceTemplate from 'components/pages/AddCorrespondenceTemplate';
import SendCorrespondence from 'components/pages/SendCorrespondence';
import Collections from 'components/pages/Collections';
import ManageCollections from 'components/pages/Collections/manage';
import CollectionOverview from 'components/pages/Collections/overview';
import CollectionForeigns from 'components/pages/CollectionForeigns';
import ManageCollectionForeigns from 'components/pages/CollectionForeigns/manage';
import CollectionForeignOverview from 'components/pages/CollectionForeigns/overview';
import AdminTermsAndConditions from 'components/pages/AdminTermsAndConditions';
import Agreements from 'components/pages/Agreements';
import AdminMaintenanceContracts from 'components/pages/AdminMaintenanceContracts';
import TacMain from 'components/pages/TacMain';
import TacReview from 'components/pages/TacReview';
import TacTranslation from 'components/pages/TacTranslation';
import TacUpdate from 'components/pages/TacUpdate';
import TacMaintenanceContracts from 'components/pages/TacMaintenanceContracts';
import Empty from 'components/pages/Empty';
import ManageTemplate from 'components/pages/TemplateLetters/Manage/components/ManageTemplate';
import { HeaderProvider } from 'contexts/headerContext';
import ClientAgreements from 'components/pages/ClientAgreements';
import Messages from 'components/pages/Messages';
import KnowledgeBase from 'components/pages/KnowledgeBase';
import KnowledgeBaseDetail from 'components/pages/KnowledgeBaseDetail';
import ManageKnowledgeBase from 'components/pages/KnowledgeBase/Manage';
import ManageKnowledgeBaseSettings from 'components/pages/KnowledgeBaseSettings/Manage';
import AddKnowledgeBaseItem from 'components/pages/AddKnowledgeBaseItem';
import ManageNewsItems from 'components/pages/NewsItems/Manage';
import AddNewsItem from 'components/pages/AddNewsItem';
import { Right, Role } from 'utils/constants';
import NewsItem from 'components/pages/NewsItems';
import Users from 'components/pages/Users';
import TacTools from 'components/pages/TacTools';
import CaUpdate from 'components/pages/CaUpdate';
import CaTranslation from 'components/pages/CaTranslation';
import NewsItemDetail from 'components/pages/NewsItemDetail';
import ManageStamps from 'components/pages/Stamps/Manage';
import CollectionFileDownload from 'components/pages/CollectionFileDownload';
import { useSession } from 'contexts/sessionContext';
import MessagesDetail from 'components/pages/MessagesDetail';
import Prospects from 'components/pages/Prospects';

setConfiguration(grid);

// Account pages.
const Login = lazy(() => import('components/pages/Login'));
const LoginWithUsername = lazy(() => import('components/pages/LoginWithUsername'));
const Register = lazy(() => import('components/pages/Register'));
const CompleteRegistration = lazy(() => import('components/pages/CompleteRegistration'));

const styles = css`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        font-size: 16px;
        color: ${colors.dark};
        font-family: 'Poppins', Helvetica, Arial, sans-serif;
    }

    button, input, textarea {
        font-family: inherit;
    }

    h1, h2, h3, h4, h5, h6 {
        line-height: 1.2;
        margin-bottom: 1rem;
        font-weight: 600;
    }

    h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
        font-weight: 500;
    }

    h2 {
        font-size: 1.625rem;
        font-weight: 500;
    }

    h3, h4, h5, h6 {
        font-size: 1rem;
        line-height: 1.4;
        margin-bottom: 0;
    }

    @keyframes rotate {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .icon-wrapper {
        pointer-events: none;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .rotate {
        transform: rotate(180deg);
        transform-origin: center center;
        display: inline-block;
    }

    .react-code-input {
        width: 100%;
        border-top: 1px solid ${greys[50]};
        border-left: 1px solid ${greys[50]};
        border-radius: ${borderRadius.normal}px;
        overflow: hidden;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        display: block;
        width: 100%;
    }

    .react-datepicker__input-container input {
        width: 100%;
        height: 3rem;
        border: 1px solid ${greys[50]};
        border-radius: ${borderRadius.small}px;
        padding: 0 16px;
        font-size: 1rem;

        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.2rem ${greys[50]};
        }

        &:hover {
            border-color: ${greys[100]};
        }

        ::placeholder {
            color: ${greys[300]};
        }
    }

    hr {
        border: 0;
        height: 0;
        border-bottom: 1px solid ${colors.border};
        margin: 1.5rem 0;
    }

    table {
        th#Action {
            width: 100px;
        }
    }

    .ReactCollapse--collapse {
        transition: height 0.5s;
    }
`;

const queryClient = new QueryClient();
const App = () => {
    const { session, hasRight } = useSession();
    const [hasCollectionsRights, setHasCollectionsRights] = useState(false);
    const [hasCollectionsForeignRights, setHasCollectionsForeignRights] = useState(false);
    const [, setHasTermsAndConditionsRights] = useState(false);
    useEffect(() => {
        setHasCollectionsRights(hasRight(Right.Collection));
        setHasCollectionsForeignRights(hasRight(Right.CollectionAbroad));
        setHasTermsAndConditionsRights(hasRight(Right.GeneralConditions));
    }, [session]);
    return (
        <ScreenClassProvider>
            <Global styles={styles} />
            <ThemeProvider theme={theme}>
                <HeaderProvider>
                    <ModalProvider rootComponent={TransitionGroup}>
                        <QueryClientProvider client={queryClient}>
                            <Suspense fallback="loading">
                                <Router>
                                    <Account path="account">
                                        <Login default />
                                        <LoginWithUsername path="loginwithusername/:username" />
                                        <Register path="registreren" />
                                        <ForgotPassword path="wachtwoord-vergeten" />
                                        <ResetPassword path="wachtwoord-aanmaken" />
                                        <TwoFactorAuthentication path="tfa" />
                                        <CompleteRegistration path="registratie-voltooien/:code" />
                                    </Account>
                                    <Default
                                        default
                                        items={[
                                            {
                                                title: 'Dashboard',
                                                to: '/'
                                            },
                                            {
                                                title: 'Mijn gegevens',
                                                to: '/profile'
                                            },
                                            {
                                                title: 'Incasso Nederland',
                                                to: '/collection-netherlands',
                                                items: hasCollectionsRights ? [
                                                    {
                                                        title: 'Overzicht',
                                                        to: '/collection-netherlands/overview'
                                                    },
                                                    {
                                                        title: 'Incasso-opdracht indienen',
                                                        to: '/collection-netherlands/add'
                                                    }
                                                ] : undefined
                                            },
                                            {
                                                title: 'Incasso Buitenland',
                                                to: '/collection-foreign',
                                                items: hasCollectionsForeignRights ? [
                                                    {
                                                        title: 'Overzicht',
                                                        to: '/collection-foreign/overview'
                                                    },
                                                    {
                                                        title: 'Incasso-opdracht indienen',
                                                        to: '/collection-foreign/add'
                                                    }
                                                ] : undefined
                                            },
                                            {
                                                title: 'Rechtshulp',
                                                to: '/legal-aid'
                                            },
                                            {
                                                title: 'Algemene voorwaarden',
                                                to: '/terms-and-conditions',
                                            },
                                            {
                                                title: 'Overeenkomsten',
                                                to: '/agreements',
                                            },
                                            {
                                                title: 'Berichten',
                                                to: '/messages'
                                            },
                                            {
                                                title: 'Extra\'s',
                                                to: '/tools'
                                            },
                                            {
                                                title: 'Nieuws',
                                                to: '/news'
                                            },
                                            {
                                                title: 'Kennisbank',
                                                to: '/knowledge-base'
                                            },
                                            {
                                                title: 'Over Juresta',
                                                to: '/about'
                                            }
                                        ]}
                                    >
                                        <Dashboard default />
                                        <About path="about" />
                                        <Collections path="collection-netherlands" />
                                        <CollectionOverview path="collection-netherlands/overview" />
                                        <AddCollection path="collection-netherlands/add" />
                                        <CollectionFileDownload path="collection/file-download/:collectionId/:overviewFileId" />
                                        <CollectionForeigns path="collection-foreign" />
                                        <CollectionForeignOverview path="collection-foreign/overview" />
                                        <AddCollectionForeign path="collection-foreign/add" />
                                        <CollectionFileDownload isForeign path="collection-foreign/file-download/:collectionId/:overviewFileId" />
                                        <Empty path="collection-abroad" title="Incasso Buitenland" />
                                        <LegalAid path="legal-aid" />

                                        {/* - start - terms and conditions */}
                                        <Tabs path="/terms-and-conditions" titles={hasRight(Right.GeneralConditions) ? ['uw voorwaarden', 'onderhoudscontract', 'handleiding', 'vertalen', 'update'] : ['uw voorwaarden', 'onderhoudscontract']}>
                                            <TacMain default path="terms-and-conditions" />
                                            <TacMaintenanceContracts path="maintenance-contracts" />
                                            <TacTools path="manual" />
                                            <TacTranslation path="translate" />
                                            <TacUpdate path="update" />
                                            <TacReview path="review" />
                                        </Tabs>
                                        {/* - end - terms and conditions */}

                                        <Tabs path="/agreements" titles={['overzicht', 'vertalen', 'update']}>
                                            <ClientAgreements default />
                                            <CaTranslation path="translate" />
                                            <CaUpdate path="update" />
                                        </Tabs>

                                        {/* in combination with a child with the default attribute, the path attribute of the <Tabs/> component needs to start with a '/'. */}
                                        <Tabs path="/tools" titles={['Hulpmiddelen', 'Voorbeeldbrieven', 'Calculator', 'Stempel', 'Banner']}>
                                            <Extras default />
                                            <TemplateLetters path="templateletters" />
                                            <Calculators path="calculators" />
                                            <Tools path="tools" />
                                            <Banners path="banners" />
                                        </Tabs>
                                        <NewsItem path="news" archive={false} />
                                        <NewsItem path="news-archive" archive={true} />
                                        <Messages path="messages" archive={false} />
                                        <Messages path="messages-archive" archive={true} />
                                        <KnowledgeBase path="knowledge-base" />
                                        <KnowledgeBaseDetail path="knowledge-base/:id/detail" />
                                        <NewsItemDetail path="news/:id/detail" />
                                        <MessagesDetail path="messages/:id/detail" />
                                        <Empty path="about" title="Over Juresta" />
                                        <Profile path="profile" />
                                    </Default>
                                    <Default
                                        role={Role.Admin}
                                        path="/admin"
                                        items={[
                                            {
                                                title: 'Dashboard',
                                                to: '/admin'
                                            },
                                            // TODO: Weer uncommented wanneer de klant daarom vraagt.
                                            // {
                                            //     title: 'Prospects',
                                            //     to: '/sales/prospects'
                                            // },
                                            {
                                                title: 'Relaties',
                                                to: '/admin/customers'
                                            },
                                            {
                                                title: 'Incasso Nederland',
                                                to: 'collection-netherlands'
                                            },
                                            {
                                                title: 'Incasso Buitenland',
                                                to: 'collection-foreign'
                                            },
                                            {
                                                title: 'Algemene voorwaarden',
                                                to: '/admin/terms-and-conditions',
                                                items: [
                                                    {
                                                        title: 'Onderhoudscontracten',
                                                        to: '/admin/terms-and-conditions/maintenance-contracts'
                                                    }
                                                ]
                                            },
                                            {
                                                title: 'Overeenkomsten',
                                                to: '/admin/agreements'
                                            },
                                            {
                                                title: 'Correspondentie',
                                                to: '/admin/correspondence',
                                                items: [
                                                    {
                                                        title: 'Templates',
                                                        to: '/admin/correspondence/templates'
                                                    }
                                                ]
                                            },
                                            {
                                                title: 'Banners beheren',
                                                to: '/admin/banners'
                                            },
                                            {
                                                title: 'Stempels beheren',
                                                to: '/admin/stamps'
                                            },
                                            {
                                                title: 'Voorbeeldbrieven Beheren',
                                                to: '/admin/templateletters'
                                            },
                                            {
                                                title: 'Kennisbank beheren',
                                                to: '/admin/knowledgebase'
                                            },
                                            {
                                                title: 'Nieuwsberichten beheren',
                                                to: '/admin/news'
                                            },
                                            {
                                                title: 'Gebruikers',
                                                to: '/admin/users'
                                            }
                                        ]}
                                    >
                                        <AdminDashboard default />
                                        <Profile path="profile" />
                                        <Customers path="customers" />
                                        <CustomerDetail path="customers/:id/detail" />
                                        <ManageBanners path="banners" />
                                        <ManageStamps path="stamps" />

                                        {/* - start - terms and conditions */}
                                        <AdminTermsAndConditions path="terms-and-conditions" />
                                        <AdminMaintenanceContracts path="terms-and-conditions/maintenance-contracts" />
                                        <Empty path="terms-and-conditions/update" title="Update" />
                                        <Empty path="terms-and-conditions/translate" title="Vertalen" />
                                        <Empty path="terms-and-conditions/review" title="Toetsing" />
                                        {/* - end - terms and conditions */}

                                        <Agreements path="agreements" />

                                        {/* - start - correspondence */}
                                        <Correspondence path="correspondence" />
                                        <CorrespondenceTemplates path="correspondence/templates" />
                                        <AddCorrespondenceTemplate path="correspondence/templates/add" />
                                        <SendCorrespondence path="correspondence/send" />
                                        {/* - end - correspondence */}

                                        <ManageTemplateLetters path="templateletters" />
                                        <ManageTemplate path="templateletters/manage" />
                                        <ManageKnowledgeBase path="knowledgebase" />
                                        <ManageKnowledgeBaseSettings path="knowledgebase/settings" />
                                        <AddKnowledgeBaseItem path="knowledgebase/add" />
                                        <ManageNewsItems path="news" />
                                        <AddNewsItem path="news/add" />
                                        <ManageCollections path="collection-netherlands" />
                                        <ManageCollectionForeigns path="collection-foreign" />
                                        <Users path="users" />
                                        <CollectionFileDownload path="collection/file-download/:collectionId/:overviewFileId" />
                                        <CollectionFileDownload isForeign path="collection-foreign/file-download/:collectionId/:overviewFileId" />
                                        {/* <Prospects default path="prospects" /> */}
                                    </Default>
                                    <Default
                                        role={Role.Sales}
                                        path="/sales"
                                        items={[
                                            {
                                                title: 'Prospects',
                                                to: '/sales/prospects'
                                            }
                                        ]}
                                    >
                                        <Prospects default path="prospects" />
                                    </Default>
                                </Router>
                            </Suspense>
                        </QueryClientProvider>
                    </ModalProvider>
                </HeaderProvider>
            </ThemeProvider>
        </ScreenClassProvider>
    );
};

export default App;
