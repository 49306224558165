import { useField } from 'formik';
import React, { FC } from 'react';
import FormFile, { FormFileProps } from '..';

export interface FormikFormFileProps extends FormFileProps {
    name: string;
}

const FormikFormFile: FC<FormikFormFileProps> = ({ name, ...props }) => {
    const [field, meta, helpers] = useField<File | null>(name);

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormFile {...props} value={field.value ?? undefined} onChange={helpers.setValue} error={error} />;
};

export default FormikFormFile;
