import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Action = styled.button<{ isActive: boolean }>`
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${({ theme, isActive }) => isActive ? theme.greys[50] : theme.greys[25]};
    border-radius: 50%;
    border: none;
    flex-shrink: 0;
    margin-right: 8px;

    ${({ theme, isActive }) =>
        isActive &&
        css`
            &:hover {
                background-color: ${theme.greys[100]};
                cursor: pointer;
            }
        `}
    `;

export const Title = styled.a`
    font-size: 0.875rem;
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
`;

export const ComponentWrapper = styled.div`
    margin-right: 8px;
`;
