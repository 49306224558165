import { useApi } from 'hooks/api';
import { KvkBasicProfileModel, KvkDataModel } from './types';

const useKvk = () => {

    const { get } = useApi();

    const baseUrl = '/kvk';

    const searchKvk = async (searchValue: string) => {
        const response = get<KvkDataModel>(
            `${baseUrl}/search/companies?name=${searchValue}`
        );

        return response;
    };

    const getBasicProfile = async (kvkNumber: string) => {
        const response = get<KvkBasicProfileModel>(
            `${baseUrl}/profile/${kvkNumber}`
        );

        return response;
    };

    return {
        searchKvk,
        getBasicProfile
    };
};

export default useKvk;
