import { LegalForm } from 'utils/constants/legalformconstants';
import { PaymentMethod } from 'utils/constants/paymentmethodconstants';
import { Gender } from 'utils/constants/personconstants';

export interface UserInvitationModel {
    id: string;
    email: string;
}

export interface InviteCustomerInputModel {
    kvkNumber: string;
    gender: Gender | null;
    name: string;
    relationNumber?: string;
    legalForm?: LegalForm;
    emailInvoice?: string;
    addressVisit: string;
    postalCodeVisit: string;
    cityVisit: string;
    isMailAddressDifferent: boolean;
    addressMail: string;
    postalCodeMail: string;
    cityMail: string;
    initials: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    mobileNumber: string;
    function: string;
    bankAccount: string;
    paymentMethod: PaymentMethod | null;
    hasModuleCollection: boolean;
    hasModuleCollectionAbroad: boolean;
    hasModuleLegalAid: boolean;
    hasModuleGeneralConditions: boolean;
    website?: string;
}

export interface InviteAdminInputModel {
    firstName: string;
    insertion?: string;
    lastName: string;
    email: string;
    type: AdminType;
}

export enum AdminType {
    Admin = 0,
    Sales = 1
}

export interface CompleteRegistrationInputModel {
    code: string;
    email: string;
    password: string;
    passwordConfirm: string;
}
