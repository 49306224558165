import { FormGroup, FormikFormControl, FormikFormSelect, FormikFormSwitch, FormikFormYesNo, FormRow } from 'components/atoms/form';
import HeaderAction from 'components/atoms/HeaderAction';
import { useFormikContext } from 'formik';
import { InviteCustomerInputModel } from 'hooks/userInvitation/types';
import React, { FC } from 'react';
import { Row, Col } from 'react-grid-system';
import { legelFormOptions } from 'utils/constants/legalformconstants';
import { paymentMethodOptions } from 'utils/constants/paymentmethodconstants';
import { genderOptions } from 'utils/constants/personconstants';

export interface Step2Props { }

const Step2: FC<Step2Props> = () => {
    const { values } = useFormikContext<InviteCustomerInputModel>();
    return (
        <>
            <HeaderAction title="Persoonsgegevens" />
            <Row>
                <Col md={6} sm={12}>
                    <FormGroup label="Aanhef" required>
                        <FormikFormSelect name="gender" options={genderOptions} placeholder="Kies een aanhef" />
                    </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                    <FormGroup label="Initialen">
                        <FormikFormControl name="initials" placeholder="Initialen" />
                    </FormGroup>
                </Col>
                <Col md={4} xs={12}>
                    <FormGroup label="Voornaam" required>
                        <FormikFormControl name="firstName" placeholder="Voornaam" />
                    </FormGroup>
                </Col>
                <Col md={3} xs={12}>
                    <FormGroup label="Tussenvoegsel">
                        <FormikFormControl name="insertion" placeholder="Tussenvoegsel" />
                    </FormGroup>
                </Col>
                <Col md={5} xs={12}>
                    <FormGroup label="Achternaam" required>
                        <FormikFormControl name="lastName" placeholder="Achternaam" />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup label="Mobiel telefoonnummer">
                        <FormikFormControl name="mobileNumber" placeholder="Mobiel telefoonnummer" />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup label="Telefoonnummer">
                        <FormikFormControl name="phoneNumber" placeholder="Telefoonnummer" />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup label="Functie" required>
                        <FormikFormControl name="function" placeholder="Functie" />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup label="E-mailadres" required>
                        <FormikFormControl name="email" placeholder="E-mailadres" />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup label="E-mailadres voor facturatie">
                        <FormikFormControl name="emailInvoice" placeholder="E-mailadres voor facturatie" />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup label="Website">
                        <FormikFormControl name="website" placeholder="Website" />
                    </FormGroup>
                </Col>
            </Row>
            <HeaderAction title="Bedrijfsgegevens" />
            <Row>
                <Col xs={12}>
                    <FormGroup label="Bedrijfsnaam" required>
                        <FormikFormControl name="name" placeholder="Bedrijfsnaam" />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup label="Relatienummer">
                        <FormikFormControl name="relationNumber" placeholder="Relatienummer" />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup label="KvK-nummer" required>
                        <FormikFormControl name="kvkNumber" placeholder="KvK-nummer" />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup label="Rechtsvorm" required>
                <FormikFormSelect name="legalForm" options={legelFormOptions} />
            </FormGroup>
            <FormGroup label="Bezoekadres" required>
                <FormikFormControl name="addressVisit" placeholder="Bezoekadres" />
            </FormGroup>
            <Row>
                <Col md={6}>
                    <FormGroup label="Postcode" required>
                        <FormikFormControl name="postalCodeVisit" placeholder="Postcode" />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup label="Plaats" required>
                        <FormikFormControl name="cityVisit" placeholder="Plaats" />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <FormikFormYesNo label="Postadres wijkt af van bezoekadres" name="isMailAddressDifferent" />
            </FormGroup>
            {values.isMailAddressDifferent && (
                <>
                    <FormGroup label="Postadres" required>
                        <FormikFormControl name="addressMail" placeholder="Postadres" />
                    </FormGroup>
                    <Row>
                        <Col md={6}>
                            <FormGroup label="Postcode" required>
                                <FormikFormControl name="postalCodeMail" placeholder="Postcode" />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup label="Plaats" required>
                                <FormikFormControl name="cityMail" placeholder="Plaats" />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            )}
            <HeaderAction title="Betaalgegevens" />
            <Row>
                <Col xs={6}>
                    <FormGroup label="Rekeningnummer">
                        <FormikFormControl name="bankAccount" placeholder="Rekeningnummer" />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup label="Betaalwijze" required>
                        <FormikFormSelect name="paymentMethod" options={paymentMethodOptions} placeholder="Kies een betaalwijze" />
                    </FormGroup>
                </Col>
            </Row>
            <HeaderAction title="Modulegebruik" />
            <FormRow title="Module Incasso Nederland">
                <FormikFormSwitch name="hasModuleCollection" />
            </FormRow>
            <FormRow title="Module Incasso Buitenland">
                <FormikFormSwitch name="hasModuleCollectionAbroad" />
            </FormRow>
            <FormRow title="Module Rechtshulp">
                <FormikFormSwitch name="hasModuleLegalAid" />
            </FormRow>
            <FormRow title="Module Algemene voorwaarden">
                <FormikFormSwitch name="hasModuleGeneralConditions" />
            </FormRow>
        </>
    );
};

export default Step2;
