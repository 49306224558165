import React, { FC, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import useAllCorrespondence from 'hooks/correspondence/useAllCorrespondence';
import { CorrespondencePlainModel } from 'hooks/correspondence/types';
import { format, parseISO } from 'date-fns';
import useDeleteAdminCorrespondence from 'hooks/correspondence/useDeleteAdminCorrespondence';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { toast } from 'react-toastify';

interface CorrespondenceProps extends RouteComponentProps {
}


const columns: Column<CorrespondencePlainModel>[] = [
    {
        Header: 'Onderwerp',
        accessor: (mail) => mail.subject
    },
    {
        Header: 'Type',
        accessor: (mail) => mail.isQuestionnaire ? 'Vragenlijst' : 'Bericht'
    },
    {
        Header: 'Verzonden op',
        accessor: (mail) => parseISO(mail.created).getTime(),
        Cell: ({ value }: any) => format(value, 'dd-MM-yyyy HH:mm')
    },
    {
        Header: 'Ontvangers',
        accessor: (mail) => mail.customers.map((c) => c.name).join(', ')
    }
];

const Correspondence: FC<CorrespondenceProps> = () => {
    useHeader('Correspondentie');


    const [correspondenceToDelete, setCorrespondenceToDelete] = useState<CorrespondencePlainModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const { data: correspondence = [], isLoading: isLoadingCorrespondence } = useAllCorrespondence();
    const { deleteCorrespondenceMutation } = useDeleteAdminCorrespondence();

    const deleteCorrespondence = async () => {
        if (correspondenceToDelete == null) {
            return;
        }

        setIsLoadingDelete(true);

        await deleteCorrespondenceMutation.mutateAsync({ id: correspondenceToDelete.id })
            .then(() => {
                toast.success('Correspondentie succesvol verwijderd!');
            }).catch(() => {
                toast.error('Oeps, er ging iets fout tijdens het verwijderen van de correspondentie. Probeer het later opnieuw!');
            });

        setIsLoadingDelete(false);
        closeDeleteModal();
    };

    const openDeleteModal = (templateLetter: CorrespondencePlainModel) => {
        setCorrespondenceToDelete(templateLetter);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setCorrespondenceToDelete(undefined);
        hideDeleteModal();
    };

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="small" title="Correspondentie verwijderen" subtitle="Weet je zeker dat je deze correspondentie wilt verwijderen?">
                <Group spaceBetween>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={deleteCorrespondence} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [correspondenceToDelete, isLoadingDelete]
    );

    return (
        <Card>
            <CardBody>
                <Table<CorrespondencePlainModel>
                    isLoading={isLoadingCorrespondence}
                    data={correspondence}
                    noDataMessage="Geen correspondentie beschikbaar"
                    columns={columns}
                    search
                    onAddClick={() => navigate('/admin/correspondence/send')}
                    sortBy={[
                        { id: 'Verzonden op', desc: true }
                    ]}
                    actions={[
                        {
                            icon: 'delete',
                            title: 'Verwijder correspondentie',
                            onClick: (item) => openDeleteModal(item)
                        }
                    ]}
                />
            </CardBody>
        </Card>
    );
};

export default Correspondence;
