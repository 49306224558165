import { EditTemplateLetterInputModel } from 'hooks/templateLetter/types';
import { useApi } from 'hooks/api';
import { appendObject } from 'utils/objectAppender';

const useEditAdminTemplateLetter = () => {

    const { put } = useApi();

    const baseUrl = '/templateletter';

    const editTemplateLetter = async (values: EditTemplateLetterInputModel) => {
        const fd = new FormData();
        appendObject(values, fd);

        const response = await put(
            baseUrl, fd
        );

        if (!response.ok) {
            throw response;
        }

        return response;
    };

    return { editTemplateLetter };
};

export default useEditAdminTemplateLetter;
