import React, { FC, InputHTMLAttributes } from 'react';
import { FormSwitch as Switch, Wrapper, Label } from './styles';

export interface FormSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

const FormSwitch: FC<FormSwitchProps> = ({ label, ...props }) => {
    if (label) {
        return (
            <Wrapper>
                <Switch>
                    <input type="checkbox" {...props} />
                    <div className="switch" />
                </Switch>
                <Label>{label}</Label>
            </Wrapper>
        );
    }

    return (
        <Switch>
            <input type="checkbox" {...props} />
            <div className="switch" />
        </Switch>
    );
};

export default FormSwitch;
