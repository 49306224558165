export function buildUriWithParams(uri: string, params?: { [key: string]: any }) {
    if (!params) {
        return uri;
    }
    const args = Object.keys(params)
        .map((k) => {
            const value = params[k];

            if (value instanceof Array) {
                const arrayValue = value
                    .map((v) => `${k}=${v}`)
                    .reduce((p, c) => {
                        if (p) {
                            return `${p}&${c}`;
                        }

                        return p;
                    });
                return arrayValue;
            }

            return `${k}=${value}`;
        })
        .reduce((total, current) => {
            if (total) {
                return `${total}&${current}`;
            }

            return current;
        });

    const uriWithParams = args.length > 0
        ? `${uri}?${args}`
        : uri;

    return uriWithParams;
}
