import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

export const FormSelect = styled.div<{ isFocused: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 3rem;
    border: 1px solid ${({ theme }) => theme.colors.grey};
    border-radius: ${({ theme }) => theme.borderRadius.small}px;
    background-color: ${({ theme }) => theme.colors.white};

    &:hover {
        border-color: ${({ theme }) => theme.greys[100]};
    }

    ${({ isFocused, theme }) =>
        isFocused &&
        css`
            outline: none;
            box-shadow: 0 0 0 0.2rem ${theme.greys[50]};
        `}
`;

export const ValueContainer = styled.div`
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    padding: 0 16px;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
`;

export const Option = styled.div<{ isSelected: boolean }>`
    display: inline-flex;
    height: 2.5rem;
    width: 100%;
    align-items: center;
    padding: 0 16px;
    border-top: 1px solid ${({ theme }) => theme.greys[50]};
    font-size: 0.875rem;
    font-weight: 500;
    transition: background 0.3s ease;

    &:hover {
        background-color: ${({ theme }) => theme.greys[50]};
        cursor: pointer;
    }

    &:first-of-type {
        border-top: none;
    }

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            background-color: ${theme.greys[50]};
            border-top-color: transparent;

            + div {
                border-top-color: transparent;
            }

            &:hover {
                background-color: ${theme.greys[100]};
            }
        `}
`;

export const Menu = styled.div`
    box-shadow: 0 10px 30px ${({ theme }) => rgba(theme.colors.dark, 0.1)};
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    overflow-y: auto;
    margin-top: 0.5rem;
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.white};
    max-height: 200px;
`;

export const MenuList = styled.div`
    padding: 0.5rem 0;
`;
