import styled from '@emotion/styled';

const InvalidFeedback = styled.span`
  display: block;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 0.5rem;
`;

export default InvalidFeedback;
