import { useField } from 'formik';
import React, { FC } from 'react';
import { Options } from 'react-select';
import FormSelect, { FormSelectProps, IOption } from '..';

interface FormikFormSelectProps extends FormSelectProps {
    name: string;
}

const FormikFormSelect: FC<FormikFormSelectProps> = ({ name, options, ...props }) => {
    const [field, meta, helpers] = useField(name);

    // FormikFormSelect's methods.
    const handleOnChange = (option: IOption | Options<IOption> | null) => {
        const value = option instanceof Array ? option.map((o) => o.value) : option?.value;
        helpers.setValue(value);
    };

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;
    const value = props.isMulti
        ? options?.filter(option => field.value.indexOf(option.value) >= 0)
        : options?.find(option => option.value === field.value);

    return (
        <FormSelect
            {...field}
            {...props}
            value={value}
            onChange={handleOnChange}
            error={error}
            options={options}
        />
    );
};

export default FormikFormSelect;
