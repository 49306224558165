import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Wrapper, Inner, NoImage, UploadIcon, Image, Input, Label } from './styles';
import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';
import noImage from 'static/no-photo.svg';
import InvalidFeedback from '../InvalidFeedback';

export interface FormImageProps {
    heightPercentage?: number;
    onChange?: (value: File) => void;
    value?: File | string;
    error?: string;
}

const FormImage: FC<FormImageProps> = ({ heightPercentage = 100, value, onChange, error }) => {
    const theme = useTheme();
    const hasImage = value !== undefined;

    const [url, setUrl] = useState('');

    useEffect(() => {
        if (value === undefined) {
            return;
        }

        if (value instanceof File) {
            setUrl(URL.createObjectURL(value));
        } else {
            setUrl(value as string);
        }
    }, [value]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file && onChange) {
            onChange(file);
        }
    };

    return (
        <Wrapper>
            <UploadIcon>
                <Icon name="edit" size={1} color={theme.colors.white} />
            </UploadIcon>
            <Label>
                <Inner heightPercentage={heightPercentage} hasError={!!error}>
                    <Input type="file" multiple={false} onChange={handleOnChange} accept="image/*" />
                    {hasImage ? (
                        <Image image={url} />
                    ) : (
                        <NoImage>
                            <img src={noImage} alt="Placeholder" />
                        </NoImage>
                    )}
                </Inner>
            </Label>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </Wrapper>
    );
};

export default FormImage;
