import React, { FC, useMemo } from 'react';
import { RouteComponentProps, navigate, Link } from '@reach/router';
import { Col, Row } from 'react-grid-system';
import { Card, CardHeader } from 'components/atoms/card';
import { H4, Paragraph } from 'components/atoms/text';
import Margin from 'components/atoms/Margin';
import { useHeader } from 'contexts/headerContext';
import { Column } from 'react-table';
import Table from 'components/molecules/table';
import { Button } from 'components/atoms/button';
import ActiveInactive from 'components/atoms/ActiveInactive';
import DateTitleCell from 'components/atoms/DateTitleCell';
import { useSession } from 'contexts/sessionContext';
import Tooltip from 'components/atoms/Tooltip';
import { Right } from 'utils/constants/rightconstants';
import useKnowledgeBaseItems from 'hooks/knowledgebaseitem';
import useNewsItems from 'hooks/newsitem';
import { NewsItemModel } from 'hooks/newsitem/types';
import { KnowledgeBaseItemModel } from 'hooks/knowledgebaseitem/types';
import { parseISO } from 'date-fns';
import { orderBy } from 'lodash';

// TODO replace for db modal.
interface NewsItem extends NewsItemModel { }

const newsColumns: Column<NewsItem>[] = [
    {
        Header: 'Titel',
        accessor: (news) => <DateTitleCell date={news.publicationDate ? parseISO(news.publicationDate) : new Date()} title={news.title} />
    }
];

// TODO replace for db modal.
interface Article extends KnowledgeBaseItemModel { }

const articleColumns: Column<Article>[] = [
    {
        Header: 'Titel',
        accessor: (article) => <DateTitleCell date={parseISO(article?.publicationDate)} title={article.title} />
    }
];

// TODO replace for db modal.
interface Agreement {
    title: string;
    active?: boolean;
    to: string;
}

const agreementColumns: Column<Agreement>[] = [
    {
        Header: 'Titel',
        accessor: (collection) => collection.title
    }
];

const Dashboard: FC<RouteComponentProps> = () => {
    const { session } = useSession();
    const agreements = useMemo(() => ([
        {
            title: 'Incassomodule Nederland',
            active: session?.rights?.some((ur) => ur === Right.Collection) ? true : false,
            to: '/collection-netherlands'
        },
        {
            title: 'Incassomodule Buitenland',
            active: session?.rights?.some((ur) => ur === Right.CollectionAbroad) ? true : false,
            to: '/collection-foreign'
        },
        {
            title: 'Module Rechtshulp',
            active: session?.rights?.some((ur) => ur === Right.LegalAid) ? true : false,
            to: '/legal-aid'
        },
        {
            title: 'Algemene voorwaarden',
            active: session?.rights?.some((ur) => ur === Right.GeneralConditions) ? true : false,
            to: '/terms-and-conditions'
        }
    ]), [session?.rights]);

    const { useAllNewsItems } = useNewsItems();
    const { data: newsItems = [] } = useAllNewsItems();
    const sortedNewsItems = orderBy(newsItems, item => parseISO(item.publicationDate ?? new Date().toISOString()).getTime(), ['desc']);

    const { useAllKnowledgeBaseItems } = useKnowledgeBaseItems();
    const { data: articles = [] } = useAllKnowledgeBaseItems();
    const sortedArticles = orderBy(articles, article => parseISO(article.publicationDate).getTime(), ['desc']);

    const greeting = useMemo(() => {
        const hourNow = new Date().getHours();
        let greeting;

        if (hourNow > 18) {
            greeting = 'Goedenavond ';
        } else if (hourNow > 12) {
            greeting = 'Goedemiddag ';
        } else if (hourNow > 0) {
            greeting = 'Goedemorgen ';
        } else {
            greeting = 'Welkom ';
        }

        if (session?.gender === 'De heer' || session?.gender === 'Mevrouw') {
            let gender = '';
            if (session?.gender === 'De heer') {
                gender = 'Meneer';
            } else {
                gender = session?.gender;
            }
            greeting = `${greeting} ${gender.toLowerCase()} ${session?.insertion ?? ''} ${session?.lastName ?? ''}`;
        } else {
            greeting = `${greeting} ${session?.fullName ?? ''}`;
        }

        return `${greeting},`;
    }, [session?.gender]);

    useHeader(greeting);

    // Render.
    return (
        <>
            <Paragraph>
                In uw persoonlijke Juresta-omgeving leest u meer over de door u gesloten modules met Juresta. Ook kunt u eenvoudig een incasso-opdracht indienen of gebruik maken van diverse <Link to="/tools">hulpmiddelen</Link> bij uw debiteurenbeheer. Maakt u nog geen gebruik van een bepaalde module of wilt u bijvoorbeeld meer weten over onze mogelijkheden? Wij informeren u graag.
            </Paragraph>
            <Margin bottom={1}>
                <Row>
                    <Col md={6} xl={6}>
                        <Card overflow="hidden">
                            <CardHeader isDark withBody>
                                <H4 variant="white">Nieuws</H4>
                                <Tooltip>In dit deel leest u relevante nieuwsberichten van en over Juresta.</Tooltip>
                            </CardHeader>
                            <Table<NewsItem>
                                data={sortedNewsItems.slice(0, 3)}
                                columns={newsColumns}
                                cta={() => navigate('news')}
                                ctaText="Bekijk al het nieuws"
                                actions={[
                                    {
                                        component: ({ id }) => <Link to={`/news/${id}/detail`}>Lees {'>'}</Link>
                                    }
                                ]}
                                sortBy={[]}
                                hideTableHeader
                                hidePagination
                                fullWidth
                            />
                        </Card>
                    </Col>
                    <Col md={6} xl={6}>
                        <Card overflow="hidden">
                            <CardHeader isDark withBody>
                                <H4 variant="white">Kennisbank</H4>
                                <Tooltip>In dit deel kunt u artikelen raadplegen over diverse relevante onderwerpen. Denk daarbij aan onderwerpen die voor u als ondernemer van belang zijn. Heeft u vragen naar aanleiding van een artikel? Neem gerust contact met ons op. Wij zijn u graag van dienst.</Tooltip>
                            </CardHeader>
                            <Table<Article>
                                data={sortedArticles.slice(0, 3)}
                                columns={articleColumns}
                                cta={() => navigate('knowledge-base')}
                                ctaText="Bekijk de gehele kennisbank"
                                actions={[
                                    {
                                        component: ({ id }) => <Link to={`/knowledge-base/${id}/detail`}>Lees {'>'}</Link>
                                    }
                                ]}
                                sortBy={[]}
                                hideTableHeader
                                hidePagination
                                fullWidth
                            />
                        </Card>
                    </Col>
                </Row>
                <Card overflow="hidden" noMargin>
                    <CardHeader isDark withBody>
                        <H4 variant="white">Modules</H4>
                        <Tooltip>Uw overeenkomsten met Juresta. Klik op de overeenkomst voor meer informatie.</Tooltip>
                    </CardHeader>
                    <Table<Agreement>
                        data={agreements}
                        columns={agreementColumns}
                        actions={[
                            {
                                component: ({ to, active }) => <Link to={to}><Button style={{ width: 200 }} title={active ? 'bekijk' : 'meer informatie'} /></Link>
                            },
                            {
                                component: ({ active }) => <ActiveInactive style={{ width: 120, justifyContent: 'center' }} isActive={active} />
                            }
                        ]}
                        sortBy={[{ id: 'title', desc: false }]}
                        hideTableHeader
                        hideFooter
                        fullWidth
                    />
                </Card>
            </Margin>
            <Paragraph variant="grey">
                * Wanneer een module op inactief staat, maakt u nog geen gebruik van deze mogelijkheid. Meer weten? Klik dan op “meer informatie” of neem <Link to="/about">direct</Link> contact met ons op.
            </Paragraph>
        </>
    );
};

export default Dashboard;
