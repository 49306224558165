export enum DocumentType {
    General = 0,
    TermsAndConditions = 1,
    MaintenanceContract = 2,
    Agreement = 3
};

export const getDocumentTypeDisplay = (type: DocumentType): string => {
    switch (type) {
        case DocumentType.General:
            return 'Algemeen';
        case DocumentType.TermsAndConditions:
            return 'Algemene voorwaarden';
        case DocumentType.MaintenanceContract:
            return 'Onderhoudscontract';
        case DocumentType.Agreement:
            return 'Overeenkomst';
    }

    return '';
};
