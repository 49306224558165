import { useApi } from 'hooks/api';
import { CustomError } from 'hooks/api/types';
import { useQuery } from 'react-query';
import { appendObject } from 'utils/objectAppender';
import { buildUriWithParams } from 'utils/urihelper';
import { AddKnowledgeBaseCategoryInputModel, EditKnowledgeBaseCategoryInputModel, GetKnowledgeBaseCategoryInputModel, KnowledgeBaseCategoryModel } from './types';

const getKnowledgeBaseCategoriesQueryKey = 'knowledgebasecategory.get';

const useKnowledgeBaseCategories = () => {

    const { get, post, put, deleteCall } = useApi();

    const baseUrl = '/knowledgebasecategory';

    const getAllKnowledgeBaseCategory = async (params?: GetKnowledgeBaseCategoryInputModel) => {
        const url = buildUriWithParams(baseUrl, params);

        const response = await get<KnowledgeBaseCategoryModel[]>(url);

        return response;
    };

    const useAllKnowledgeBaseCategories = (params?: GetKnowledgeBaseCategoryInputModel) => {
        const queryKey = [getKnowledgeBaseCategoriesQueryKey, params];

        return useQuery<KnowledgeBaseCategoryModel[], CustomError[]>(queryKey, async () => {
            const response = await getAllKnowledgeBaseCategory(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const addKnowledgeBaseCategory = async (values: AddKnowledgeBaseCategoryInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(baseUrl, fd);

        return response;
    };

    const editKnowledgeBaseCategory = async (values: EditKnowledgeBaseCategoryInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await put(baseUrl, fd);

        return response;
    };

    const deleteKnowledgeBaseCategory = async (id: string) => {
        const response = await deleteCall(`${baseUrl}/${id}`);

        return response;
    };

    return {
        getAllKnowledgeBaseCategory,
        useAllKnowledgeBaseCategories,
        addKnowledgeBaseCategory,
        editKnowledgeBaseCategory,
        deleteKnowledgeBaseCategory
    };
};

export default useKnowledgeBaseCategories;
