import React, { FC } from 'react';
import { FormGroup, FormikFormControl } from 'components/atoms/form';

export interface BannerDataProps {}

const BannerData: FC<BannerDataProps> = () => {

    return (
        <>
            <FormGroup required>
                <FormikFormControl hidden name="id" />
            </FormGroup>
            <FormGroup label="Banner naam" required>
                <FormikFormControl name="name" placeholder="Banner naam" />
            </FormGroup>
            <FormGroup label="Banner beschrijving" required>
                <FormikFormControl name="description" placeholder="Banner beschrijving" />
            </FormGroup>
        </>
    );
};

export default BannerData;
