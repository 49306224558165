import React, { FC } from 'react';
import { Submenu as Wrapper } from './styles';
import SubmenuItem, { SubmenuItemProps } from 'components/atoms/SubmenuItem';

export interface SubmenuProps {
    items: SubmenuItemProps[];
}

const Submenu: FC<SubmenuProps> = ({ items }) => {
    return (
        <Wrapper>
            {items.map((item) => (
                <SubmenuItem key={item.title} {...item}>
                    {item.title}
                </SubmenuItem>
            ))}
        </Wrapper>
    );
};

export default Submenu;
