import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
`;
