import React, { FC } from 'react';
import { FormSelect as Control, Option, Menu, MenuList, ValueContainer } from './styles';
import Select, { InputActionMeta, OnChangeValue, ActionMeta } from 'react-select';
import InvalidFeedback from '../InvalidFeedback';
import theme from 'theme';

export interface IOption {
    value: string | number;
    label: string;
}

export interface FormSelectProps {
    value?: readonly IOption[] | IOption | null | undefined;
    defaultValue?: readonly IOption[] | IOption | null | undefined;
    error?: string;
    options?: ReadonlyArray<IOption> | undefined;
    onChange?: ((value: OnChangeValue<IOption, boolean>, actionMeta: ActionMeta<IOption>) => void) | undefined;
    isMulti?: boolean;
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    isLoading?: boolean;
    placeholder?: string;
    noOptionsMessage?: ((obj: { inputValue: string; }) => string | null) | undefined
    loadingMessage?: ((obj: { inputValue: string; }) => string | null) | undefined
    isDisabled?: boolean;
}

const FormSelect: FC<FormSelectProps> = ({ error, ...props }) => {
    return (
        <>
            <Select
                {...props}
                captureMenuScroll={false}
                components={{
                    Control: ({ children, innerProps, innerRef, isFocused }) => (
                        <Control ref={innerRef} {...innerProps} isFocused={isFocused}>
                            {children}
                        </Control>
                    ),
                    ValueContainer: ({ children }) => <ValueContainer>{children}</ValueContainer>,
                    Option: ({ children, innerProps, innerRef, isSelected }) => (
                        <Option ref={innerRef} {...innerProps} isSelected={isSelected}>
                            {children}
                        </Option>
                    ),
                    Menu: ({ children, innerRef, innerProps }) => (
                        <Menu ref={innerRef} {...innerProps}>
                            {children}
                        </Menu>
                    ),
                    MenuList: ({ children, innerRef }) => <MenuList ref={innerRef}>{children}</MenuList>
                }}
                styles={{
                    singleValue: (provided) => {
                        const color = theme.colors.primary;
                        return { ...provided, color };
                    },
                    placeholder: (provided) => {
                        const color = theme.colors.primary;
                        return { ...provided, color };
                    },
                    noOptionsMessage: (provided) => {
                        const color = theme.colors.primary;
                        return { ...provided, color };
                    }
                }}
            />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormSelect;
