import React from 'react';
import { CellProps, Hooks } from 'react-table';
import ActionComponent, { Action } from 'components/atoms/Action';

// eslint-disable-next-line @typescript-eslint/ban-types
const useActions = <T extends object>(actions?: Action<T>[]) => {
    return (hooks: Hooks<T>) => {
        if (!actions?.length) {
            return;
        }

        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                id: 'Action',
                Header: 'Acties',
                width: '1px',
                Cell: ({ row }: CellProps<T>) => {
                    return (
                        <div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {actions.map((action, index) => (
                                <ActionComponent<T> key={index} {...action} item={row.original} index={row.index} />
                            ))}
                        </div>
                    );
                }
            }
        ]);
    };
};

export default useActions;
