import React, { FC, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { FormGroup, FormikFormControl, FormikFormDate, FormikFormFile, FormikFormSelect } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'components/atoms/button';
import { useHeader } from 'contexts/headerContext';
import useMaintenanceContract from 'hooks/maintenancecontract';
import { AddMaintenanceContractInputModel, MaintenanceContractPlainModel, MaintenanceContractType, SendMaintenanceContractMailInputModel } from 'hooks/maintenancecontract/types';
import Group from 'components/molecules/Group';
import useAllCustomers from 'hooks/customer/useAllCustomers';
import { toFormikErrors } from 'utils/errorhelper';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

const columns: Column<MaintenanceContractPlainModel>[] = [
    {
        Header: 'Relatienummer',
        accessor: (tac) => tac.customerRelationNumber
    },
    {
        Header: 'Bedrijfsnaam',
        accessor: (tac) => tac.customerName
    },
    {
        Header: 'Onderhoudscontract',
        accessor: (tac) => tac.fileName
    },
    {
        id: 'date',
        Header: 'Datum',
        accessor: (tac) => parseISO(tac.date).getTime(),
        Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
    },
    {
        Header: 'Versie',
        accessor: (tac) => tac.version
    }
];

const initialValues: AddMaintenanceContractInputModel = {
    date: new Date(),
    version: '',
    customerId: '',
    file: undefined,
    type: MaintenanceContractType.BranchOriented
};

const initialValuesSendMail: SendMaintenanceContractMailInputModel = {
    toEmail: ''
};

const MaintenanceContracts: FC<RouteComponentProps> = () => {
    useHeader('Onderhoudscontracten');

    const {
        addMaintenanceContract,
        deleteMaintenanceContract,
        sendMaintenanceContractMail,
        useAllMaintenanceContracts,
        getMaintenanceContractFile
    } = useMaintenanceContract();

    const { data: maintenanceContracts = [], isLoading: isLoadingMaintenanceContracts, refetch: refetchMaintenanceContracts } = useAllMaintenanceContracts();
    const { data: customers = [], isFetching: isLoadingCustomers } = useAllCustomers();

    const [maintenanceContractToDelete, setMaintenanceContractToDelete] = useState<MaintenanceContractPlainModel>();
    const [maintenanceContractToMail, setMaintenanceContractToMail] = useState<MaintenanceContractPlainModel>();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [fileToPrint, setFileToPrint] = useState<string | undefined>();
    const printRef = React.createRef<any>();

    const customerOptions = useMemo(() => customers.map((c) => ({
        value: c.id,
        label: c.name
    })), [customers]);

    // Methods.
    const handleOnSubmit = async (values: AddMaintenanceContractInputModel, { setErrors, resetForm }: FormikHelpers<AddMaintenanceContractInputModel>) => {
        const response = await addMaintenanceContract(values);

        if (response.ok) {
            toast.success('Onderhoudscontract is succesvol toegevoegd');
            refetchMaintenanceContracts();
            hide();
            resetForm();
        } else if (response.errors) {
            const errors = toFormikErrors<AddMaintenanceContractInputModel>(response.errors);
            setErrors(errors);
            toast.error('Niet alle velden zijn ingevuld');
        }
    };

    const handleDelete = async () => {
        if (!maintenanceContractToDelete) {
            return;
        }

        setIsLoadingDelete(true);

        const response = await deleteMaintenanceContract(maintenanceContractToDelete.id);

        if (response.ok) {
            refetchMaintenanceContracts();
            toast.success('Onderhoudscontract is succesvol verwijderd');
            closeDeleteModal();
        } else {
            toast.error('Er ging iets fout bij het verwijderen van het onderhoudscontract');
        }

        setIsLoadingDelete(false);
    };

    const handleMail = async (values: SendMaintenanceContractMailInputModel, { setErrors }: FormikHelpers<SendMaintenanceContractMailInputModel>) => {
        if (!maintenanceContractToMail) {
            return;
        }

        const response = await sendMaintenanceContractMail(maintenanceContractToMail.id, values);

        if (response.ok) {
            toast.success('Onderhouscontract is succesvol verstuurd');
            closeMailModal();
        } else if (response.errors) {
            const errors = toFormikErrors<SendMaintenanceContractMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van het onderhoudscontract');
        }
    };

    const downloadFile = async (maintenanceContractAndConditions: MaintenanceContractPlainModel) => {

        const response = await getMaintenanceContractFile(maintenanceContractAndConditions.id);

        if (response.ok) {
            saveAs(await response.blob(), maintenanceContractAndConditions.fileName);
        } else {
            toast.error('Er ging iets fout bij het ophalen van het onderhoudscontract');
        }
    };

    const printFile = async (maintenanceContractAndConditions: MaintenanceContractPlainModel) => {

        const response = await getMaintenanceContractFile(maintenanceContractAndConditions.id);

        if (response.ok) {
            const url = window.URL.createObjectURL(await response.blob());
            setFileToPrint(url);
        } else {
            toast.error('Er ging iets fout bij het ophalen van het onderhoudscontract');
        }
    };

    // Formik.
    const [show, hide] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hide} size="large" title="Onderhoudscontract toevoegen">
                <Formik<AddMaintenanceContractInputModel> initialValues={initialValues} onSubmit={handleOnSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup label="Onderhoudscontract" required>
                                <FormikFormFile name="file" />
                            </FormGroup>
                            <FormGroup label="Relatie" required>
                                <FormikFormSelect
                                    placeholder="Zoeken binnen relaties"
                                    isLoading={isLoadingCustomers}
                                    name="customerId"
                                    options={customerOptions}
                                />
                            </FormGroup>
                            <FormGroup label="Datum" required>
                                <FormikFormDate name="date" />
                            </FormGroup>
                            <FormGroup label="Versie" required>
                                <FormikFormControl name="version" placeholder="Versie" />
                            </FormGroup>
                            <FormGroup label="Type" required>
                                <FormikFormSelect
                                    name="type"
                                    options={[
                                        {
                                            value: MaintenanceContractType.BranchOriented,
                                            label: 'Branchgerichte voorwaarden'
                                        },
                                        {
                                            value: MaintenanceContractType.CustomWork,
                                            label: 'Maatwerk voorwaarden'
                                        }
                                    ]}
                                />
                            </FormGroup>
                            <FormGroup noMargin alignRight>
                                <Button loading={isSubmitting}>Toevoegen</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [customerOptions, isLoadingCustomers]
    );

    const [showDeleteModal, hideDeleteModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideDeleteModal} size="large" title="Onderhoudscontract verwijderen" subtitle="Weet je zeker dat je dit onderhoudscontract wilt verwijderen?">
                <p><strong>Bedrijfsnaam:</strong> {maintenanceContractToDelete?.customerName}</p>
                <p><strong>Bestandsnaam:</strong> {maintenanceContractToDelete?.fileName}</p>
                <p style={{ marginBottom: 15 }}><strong>Versie:</strong> {maintenanceContractToDelete?.version}</p>
                <Group right>
                    <Button onClick={closeDeleteModal}>Annuleren</Button>
                    <Button onClick={handleDelete} loading={isLoadingDelete}>Verwijderen</Button>
                </Group>
            </Modal>
        ),
        [maintenanceContractToDelete]
    );

    const openDeleteModal = (maintenanceContract: MaintenanceContractPlainModel) => {
        setMaintenanceContractToDelete(maintenanceContract);
        showDeleteModal();
    };

    const closeDeleteModal = () => {
        setMaintenanceContractToDelete(undefined);
        hideDeleteModal();
    };

    const [showMailModal, hideMailModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideMailModal} size="large" title="Onderhoudscontract versturen" subtitle="Vul een e-mailadres in om het onderhoudscontract te versturen">
                <Formik<SendMaintenanceContractMailInputModel> initialValues={initialValuesSendMail} onSubmit={handleMail}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <FormGroup label="E-mailadres" required>
                                <FormikFormControl name="toEmail" placeholder="E-mailadres" />
                            </FormGroup>
                            <Group right>
                                <Button type="button" onClick={closeMailModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Versturen</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [maintenanceContractToMail]
    );

    const openMailModal = (maintenanceContract: MaintenanceContractPlainModel) => {
        setMaintenanceContractToMail(maintenanceContract);
        showMailModal();
    };

    const closeMailModal = () => {
        setMaintenanceContractToMail(undefined);
        hideMailModal();
    };

    // Render.
    return (
        <>
            <Card>
                <CardBody>
                    <Table<MaintenanceContractPlainModel>
                        isLoading={isLoadingMaintenanceContracts}
                        data={maintenanceContracts}
                        noDataMessage="Geen onderhoudscontracten beschikbaar"
                        columns={columns}
                        search
                        onAddClick={show}
                        sortBy={[{ id: 'date', desc: true }]}
                        actions={[
                            {
                                icon: 'download',
                                onClick: (item) => downloadFile(item),
                                title: 'Download onderhoudscontract'
                            },
                            {
                                icon: 'print',
                                onClick: (item) => printFile(item),
                                title: 'Print onderhoudscontract'
                            },
                            {
                                icon: 'mail',
                                onClick: (item) => openMailModal(item),
                                title: 'Mail onderhoudscontract'
                            },
                            {
                                icon: 'delete',
                                onClick: (item) => openDeleteModal(item),
                                title: 'Delete onderhoudscontract'
                            }
                        ]}
                    />
                </CardBody>
            </Card>


            {
                fileToPrint &&
                <iframe ref={printRef} style={{ display: 'none' }} title="print" src={fileToPrint} onLoad={() => window.frames[0].print()}></iframe>
            }
        </>
    );
};

export default MaintenanceContracts;
