import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { lighten, rgba } from 'polished';

export const Wrapper = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 6rem;
`;

export const Inner = styled.div<{ heightPercentage?: number, hasError?: boolean }>`
    position: relative;
    overflow: hidden;
    height: 0;
    box-shadow: 0px 1px 4px 0px ${({ theme }) => rgba(theme.colors.black, 0.1)};
    width: 100%;
    border-radius: 50%;
    background-color: ${({ hasError, theme }) => hasError ? lighten(.35, theme.colors.error) : theme.colors.white};
    ${({ heightPercentage }) =>
        heightPercentage &&
        css`
        padding-bottom: ${heightPercentage}%;
    `}
`;

export const Image = styled.div<{ image?: string; }>`
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;

    ${({ image }) =>
        image != null &&
        css`
        background-image: url(${image});
    `}
`;

export const NoImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    user-select: none;

    img {
        width: 100%;
        height: auto;
        max-width: 4rem;
    }
`;
