import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Paragraph = styled.p<{ color: string; center?: boolean; noMargin?: boolean; lines?: number }>`
    ${({ color }) =>
        color &&
        css`
            color: ${color};

            & a {
                color: ${color};
            }
        `
    }
    
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;

    ${({ lines }) =>
        lines !== undefined &&
        css`
            -webkit-line-clamp: ${lines};
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
        `
    }

    ${({ noMargin }) =>
        noMargin &&
        css`
            margin: 0;
        `
    }
`;
