import styled from '@emotion/styled';

export const Stepper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
`;

export const Space = styled.div`
    flex: 1 1 auto;
    border-top: 1px solid ${({ theme }) => theme.greys[100]};
    margin: 0 16px;
`;

export const StepWrapper = styled.div`
    flex: 1;
`;
