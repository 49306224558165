import { CorrespondencePlainModel, DeleteCorrespondenceInputModel } from 'hooks/correspondence/types';
import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'hooks/api';
import { correspondenceQueryKey } from 'hooks/correspondence/useAllCorrespondence';

const useDeleteAdminCorrespondence = () => {

    const { deleteCall } = useApi();

    const baseUrl = '/correspondence';

    const queryClient = useQueryClient();

    const deleteCorrespondence = async (values: DeleteCorrespondenceInputModel) => {

        const response = await deleteCall(`${baseUrl}/${values.id}`);

        if(!response.ok) {
            throw response;
        }

        return response;
    };


    const deleteCorrespondenceMutation = useMutation((values: DeleteCorrespondenceInputModel) => deleteCorrespondence(values), {

        onMutate: async (values: DeleteCorrespondenceInputModel) => {
            
            await queryClient.cancelQueries(correspondenceQueryKey);

            const previousData = queryClient.getQueryData<CorrespondencePlainModel[]>(correspondenceQueryKey);

            const filteredData = previousData?.filter(item => item.id !== values.id);
            queryClient.setQueryData<CorrespondencePlainModel[]>(correspondenceQueryKey, filteredData ?? []);

            return { previousData };
        },
        onError: (err, variables, context) => {
            if(context?.previousData) {
                queryClient.setQueryData(correspondenceQueryKey, context.previousData);
            }
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries(correspondenceQueryKey);
        }

    });

    return { deleteCorrespondenceMutation };
};

export default useDeleteAdminCorrespondence;
