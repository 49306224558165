import Icon from 'components/atoms/Icon';
import React, { ChangeEvent, FC, InputHTMLAttributes, useEffect, useState } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { Input, Label, Button, Files, Wrapper, File, Remove } from './styles';
import { useTheme } from '@emotion/react';

export interface FormFileProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
    error?: string;
    value?: File;
    onChange?: (value: File | null) => void;
    whiteBackground?: boolean;
    className?: string;
}

const FormFile: FC<FormFileProps> = ({ error, value, onChange, whiteBackground, className, ...props }) => {
    const theme = useTheme();

    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [fileError, setFileError] = useState(error);

    const text = value ? value.name : 'Geen bestand gekozen';

    useEffect(() => {
        setFileError(error);
    }, [error]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (!file) {
            return;
        }

        if (file.size > 20000000) {
            setFileError('Bestand mag maximaal 20MB groot zijn');
            return;
        } else {
            setFileError(undefined);
        }

        if (onChange) {
            onChange(file);
        }
    };

    const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {

        event.preventDefault();

        if (onChange) {
            onChange(null);
        }
    };

    return (
        <>
            <Label className={className}>
                {/* multiple is false. If you want to you a multi upload, use <FormMultiForm/> (TODO) */}
                <Input
                    type="file"
                    multiple={false}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={handleOnChange}
                    {...props}
                />
                <Wrapper isFocused={isFocused}>
                    <Files whiteBackground={whiteBackground}>
                        {
                            value ? (
                                <File>
                                    {text}
                                    <Remove type="button" onClick={handleDelete}>
                                        <Icon name="delete" color={theme.colors.primary} size={.7} />
                                    </Remove>
                                </File>
                            ) : (
                                text
                            )
                        }
                    </Files>
                    <Button>Bestand toevoegen</Button>
                </Wrapper>
            </Label>
            {fileError && <InvalidFeedback>{fileError}</InvalidFeedback>}
        </>
    );
};

export default FormFile;
