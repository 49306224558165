import React, { FC, useEffect } from 'react';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { CollectionForeignPlainModel } from 'hooks/collectionforeign/types';
import { Column } from 'react-table';
import useAllCollectionForeigns from 'hooks/collectionforeign/useAllCollectionForeigns';
import { debtorFullName, numberToEuroString } from 'utils/stringHelpers';
import { useHeader } from 'contexts/headerContext';
import useCollectionForeign from 'hooks/collectionforeign/useCollectionForeign';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { Paragraph } from 'components/atoms/text';
import { CollectionStatus, getCollectionStatusDisplay } from 'utils/constants/collectionconstants';

interface CollectionForeignsProps extends RouteComponentProps {

}

const CollectionForeigns: FC<CollectionForeignsProps> = () => {
    useHeader('Incasso Buitenland overzicht');

    const { search } = useLocation();
    const { data: collections = [], isLoading } = useAllCollectionForeigns();
    const { downloadCollectionForeignFile, getCollectionForeignSignRequestUrl } = useCollectionForeign();

    const columns: Column<CollectionForeignPlainModel>[] = [
        {
            id: 'created',
            Header: 'Datum',
            accessor: (collection) => parseISO(collection.created).getTime(),
            Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
        },
        {
            id: 'debtorName',
            Header: 'Debiteur',
            accessor: (collection) => collection?.debtor?.companyName ?? debtorFullName(collection.debtor?.contactPersonInitials, collection.debtor?.contactPersonLastName)
        },
        {
            id: 'caseNumber',
            Header: 'Dossiernummer',
            accessor: (collection) => collection.caseNumber
        },
        {
            Header: 'Status',
            accessor: (collection) => getCollectionStatusDisplay(collection?.status ?? CollectionStatus.InProgress)
        },
        {
            Header: 'Hoofdsom',
            accessor: (collection) => numberToEuroString(collection.amount)
        }
    ];

    useEffect(() => {
        const signed = new URLSearchParams(search).get('signed');
        if (signed == null) {
            return;
        }

        if (signed === 'true') {
            toast.success('De incasso is succesvol toegevoegd en ondertekend. Het kan een paar minuten duren voordat de status is aangepast.');
        } else {
            toast.error('De incasso is toegevoegd maar niet ondertekend');
        }
    }, [search]);

    const downloadOverviewFile = async (collection: CollectionForeignPlainModel) => {
        if (collection.overviewFile == null) {
            return;
        }

        const response = await downloadCollectionForeignFile(collection.id, collection.overviewFile.id);

        if (response.ok) {
            saveAs(await response.blob(), collection.overviewFile.fileName);
        } else {
            toast.error('Oops, er ging iets mis bij het ophalen van het bestand');
        }
    };

    const signCollection = async (collectionId: string) => {
        const response = await getCollectionForeignSignRequestUrl(collectionId);

        if (response.ok && response.data) {
            window.location.href = response.data;
        } else {
            toast.error('Oops, er ging iets mis bij het starten van het ondertekenen');
        }
    };

    return (
        <>
            <Card variant="primary">
                <CardBody>
                    <Paragraph variant="white">
                        Hieronder vindt u een overzicht van de door u ingediende incasso-opdrachten.
                        Let wel: dit betreft een overzicht van uw dossiers, maar is geen weergave van de stand van zaken in uw dossier.
                    </Paragraph>
                    <Paragraph variant="white" noMargin>
                        Wenst u een stand van zaken in uw dossiers? <a href="mailto:klantadvies@juresta.nl">Neemt u dan contact met ons op</a> onder vermelding van het dossiernummer of relatienummer.
                    </Paragraph>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <Table<CollectionForeignPlainModel>
                        isLoading={isLoading}
                        data={collections}
                        columns={columns}
                        onAddClick={() => navigate('/collection-foreign/add')}
                        onAddClickLabel="incasso-opdracht indienen"
                        noDataMessage="Geen incasso's beschikbaar"
                        search
                        sortBy={[
                            { id: 'caseNumber', desc: true },
                        ]}
                        actions={[
                            {
                                icon: 'download',
                                title: 'Download overzicht',
                                onClick: downloadOverviewFile,
                                active: (item) => item.overviewFile != null
                            },
                            {
                                icon: 'edit',
                                title: 'Ondertekenen',
                                onClick: (item) => signCollection(item.id),
                                active: (item) => item.status === CollectionStatus.NeedsSigning
                            }
                        ]}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default CollectionForeigns;
