export enum PaymentMethod {
    Invoice = 0,
    Automatic = 1
}

export const paymentMethodOptions = [
    {
        value: PaymentMethod.Invoice,
        label: 'Per factuur'
    },
    {
        value: PaymentMethod.Automatic,
        label: 'Automatische incasso'
    }
];

export const getPaymentMethodDisplay = (paymentMethod: PaymentMethod): string => {
    switch (paymentMethod) {
        case PaymentMethod.Invoice:
            return 'Per factuur (betaaltermijn 14 dagen)';
        case PaymentMethod.Automatic:
            return 'Automatische incasso';
    }
};
