import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { useHeader } from 'contexts/headerContext';
import { Column } from 'react-table';
import useProspect from 'hooks/prospect/useProspect';
import { AddProspectInputModel, ProspectModel } from 'hooks/prospect/types';
import { useModal } from 'react-modal-hook';
import { FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import Modal from 'components/organisms/Modal';
import Wizard from 'components/organisms/Wizard';
import Step1 from '../Customers/components/Step1';
import Step2 from '../Customers/components/Step2';
import { format, parseISO } from 'date-fns';
import { ProspectModuleSignRequestModel } from 'hooks/prospect/types';
import { getProspectModuleSignRequestDisplay } from 'utils/constants/prospectmodulesignrequestconstants';
import { Button } from 'components/atoms/button';
import Group from 'components/molecules/Group';

interface ProspectsProps extends RouteComponentProps { }

const columns: Column<ProspectModel>[] = [
    {
        Header: 'Relatienummer',
        accessor: (prospect) => prospect.relationNumber
    },
    {
        Header: 'Bedrijfsnaam',
        accessor: (prospect) => prospect.name
    },
    {
        Header: 'KvK-nummer',
        accessor: (prospect) => prospect.kvkNumber
    },
    {
        Header: 'Aangemaakt op',
        accessor: (prospect) => prospect.created != null ? parseISO(prospect.created).getTime() : null,
        Cell: ({ value }: any) => value != null ? format(value, 'dd-MM-yyyy HH:mm') : ''
    }
];

const Prospects: FC<ProspectsProps> = () => {
    useHeader('Prospects');

    const { useGetAllProspects, addProspect } = useProspect();
    const { data: prospects = [], isLoading, refetch } = useGetAllProspects({});

    const [signRequests, setSignRequests] = useState<ProspectModuleSignRequestModel[]>([]);

    const onAddProspect = async (values: AddProspectInputModel, { setSubmitting, setErrors }: FormikHelpers<AddProspectInputModel>) => {
        const response = await addProspect(values);
        if (response.ok && response.data) {
            refetch();
            hideAddModal();
            setSignRequests(response.data.prospectModuleSignRequests);
            showSignRequestsModal();
        } else if (response.errors) {
            const errors = toFormikErrors<AddProspectInputModel>(response.errors);
            setErrors(errors);
        }
        setSubmitting(false);
    };

    const initialValues: AddProspectInputModel = {
        gender: null,
        kvkNumber: '',
        name: '',
        initials: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        mobileNumber: '',
        email: '',
        emailInvoice: '',
        addressVisit: '',
        cityVisit: '',
        postalCodeVisit: '',
        isMailAddressDifferent: false,
        addressMail: '',
        postalCodeMail: '',
        cityMail: '',
        function: '',
        bankAccount: '',
        paymentMethod: null,
        relationNumber: '',
        hasModuleCollection: false,
        hasModuleCollectionAbroad: false,
        hasModuleLegalAid: false,
        hasModuleGeneralConditions: false
    };

    const [showAddModal, hideAddModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideAddModal} title="Prospect toevoegen">
                <Wizard<AddProspectInputModel>
                    initialValues={initialValues}
                    initialStep={0}
                    onSubmitLabel="Uitnodiging versturen"
                    onSubmit={onAddProspect}
                    steps={[
                        {
                            step: 1,
                            title: 'Zoek bedrijf',
                            child: <Step1 />
                        },
                        {
                            step: 2,
                            title: 'Gegevens',
                            child: <Step2 />
                        }
                    ]}
                />
            </Modal>
        ),
        []
    );

    const [showSignRequestsModal, hideSignReuqestsModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideSignReuqestsModal} title="Module contracten" size="small">
                <>
                {
                    signRequests.map((sr) => (
                        <p key={sr.id}>
                            <a href={sr.signRequestUrl} target="_blank" rel="noreferrer">Contract module {getProspectModuleSignRequestDisplay(sr.type)}</a>
                        </p>)
                    )
                }
                <Group right>
                    <Button onClick={hideSignReuqestsModal}>Sluiten</Button>
                </Group>
                </>
            </Modal>
        ),
        [signRequests]
    );

    return (
        <Card>
            <CardBody>
                <Table<ProspectModel>
                    isLoading={isLoading}
                    data={prospects}
                    noDataMessage="Geen prospects beschikbaar"
                    columns={columns}
                    search
                    onAddClick={showAddModal}
                />
            </CardBody>
        </Card>
    );
};

export default Prospects;
