import { CustomResponse, useApi } from 'hooks/api';
import { useMutation, useQueryClient } from 'react-query';
import { AddTermsAndConditionsInputModel, SendTermsAndConditionsMailInputModel, TermsAndConditionsMutationContext, TermsAndConditionsPlainModel, SendTermsAndConditionsTaskMailInputModel } from './types';
import { allTermsAndConditionsKey } from './useAllTermsAndConditions';
import { v4 as uuid } from 'uuid';
import { CustomerPlainModel } from 'hooks/customer/types';
import { allCustomersQueryKey } from 'hooks/customer/useAllCustomers';
import { appendObject } from 'utils/objectAppender';

const useTermsAndConditions = () => {
    const queryClient = useQueryClient();

    const { get, post, deleteCall } = useApi();

    const baseUrl = '/termsandconditions';

    const addTermsAndConditions = async (values: AddTermsAndConditionsInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(baseUrl, fd);

        if (!response.ok) {
            throw response;
        }

        return response;
    };

    const termsAndConditionsMutation = useMutation<CustomResponse<unknown>, CustomResponse<unknown>, AddTermsAndConditionsInputModel, TermsAndConditionsMutationContext>((values: AddTermsAndConditionsInputModel) => addTermsAndConditions(values), {
        onMutate: async (values: AddTermsAndConditionsInputModel) => {
            await queryClient.cancelQueries(allTermsAndConditionsKey);

            const previousData = queryClient.getQueryData<TermsAndConditionsPlainModel[]>(allTermsAndConditionsKey);
            const customersData = queryClient.getQueryData<CustomerPlainModel[]>(allCustomersQueryKey);

            const selectedCustomer = customersData?.find((c) => c.id === values.customerId);

            if (!selectedCustomer) {
                return { previousData };
            }

            const newFiles = values.files?.map((f) => ({
                id: uuid(),
                    date: values.date.toISOString(),
                    version: values.version,
                    fileName: f.name,
                    customer: selectedCustomer
            })) ?? [];

            const newData: TermsAndConditionsPlainModel[] = [
                ...(previousData ?? []), ...newFiles
            ];

            queryClient.setQueryData(allTermsAndConditionsKey, newData);

            return { previousData };
        },
        onError: (error, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData<TermsAndConditionsPlainModel[]>(allTermsAndConditionsKey, context.previousData);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(allTermsAndConditionsKey);
        }
    });

    const deleteTermsAndConditions = async (id: string) => {
        const response = await deleteCall(`${baseUrl}/${id}`);

        if (!response.ok) {
            throw response;
        }

        return response;
    };

    const deleteTermsAndConditionsMutation = useMutation<CustomResponse<unknown>, CustomResponse<unknown>, string, TermsAndConditionsMutationContext>((id: string) => deleteTermsAndConditions(id), {
        onMutate: async (id: string) => {
            await queryClient.cancelQueries(allTermsAndConditionsKey);

            const previousData = queryClient.getQueryData<TermsAndConditionsPlainModel[]>(allTermsAndConditionsKey) ?? [];

            const indexOfRemovedTerms = previousData?.findIndex((t) => t.id === id);

            if (indexOfRemovedTerms === -1) {
                return { previousData };
            }

            const newData = [...previousData];
            newData.splice(indexOfRemovedTerms, 1);

            queryClient.setQueryData(allTermsAndConditionsKey, newData);

            return { previousData };
        },
        onError: (error, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData<TermsAndConditionsPlainModel[]>(allTermsAndConditionsKey, context.previousData);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(allTermsAndConditionsKey);
        }
    });

    const getTermsAndConditionsFile = (termsAndConditionsId: string) => {
        const response = get(`${baseUrl}/${termsAndConditionsId}/file`);

        return response;
    };

    const sendTermsAndConditionsMail = (id: string, values: SendTermsAndConditionsMailInputModel) => {
        const response = post(`${baseUrl}/${id}/mail`, values);

        return response;
    };

    const sendTermsAndConditionsTaskMail = (values: SendTermsAndConditionsTaskMailInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = post(`${baseUrl}/task/mail`, fd);

        return response;
    };

    return {
        addTermsAndConditions,
        deleteTermsAndConditions,
        termsAndConditionsMutation,
        deleteTermsAndConditionsMutation,
        getTermsAndConditionsFile,
        sendTermsAndConditionsMail,
        sendTermsAndConditionsTaskMail
    };
};

export default useTermsAndConditions;
