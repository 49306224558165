import { useApi } from 'hooks/api';
import { AddAgreementInputModel, AgreementPlainModel, GetAgreementInputModel, SendAgreementMailInputModel, SendAgreementTaskMailInputModel } from 'hooks/agreement/types';
import { useQuery } from 'react-query';
import { CustomError } from 'hooks/api/types';
import { buildUriWithParams } from 'utils/urihelper';
import { appendObject } from 'utils/objectAppender';


export const getAgreementsQueryKey = 'agreement.get';

const useAgreements = () => {

    const baseUrl = '/agreement';

    const { post, get, deleteCall } = useApi();

    const useAllAgreements = (params?: GetAgreementInputModel) => {
        const queryKey = [getAgreementsQueryKey, params];

        return useQuery<AgreementPlainModel[], CustomError[]>(queryKey, async () => {
            const response = await allAgreements(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const allAgreements = async (params?: GetAgreementInputModel) => {
        const url = buildUriWithParams(baseUrl, params);

        const response = await get<AgreementPlainModel[]>(url);

        return response;
    };

    const addAgreement = async (values: AddAgreementInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await post(baseUrl, fd);

        return response;
    };

    const deleteAgreement = async (id: string) => {
        const response = await deleteCall(`${baseUrl}/${id}`);

        return response;
    };

    const getAgreementFile = async (agreementId: string) => {
        const response = await get(`${baseUrl}/${agreementId}/file`);

        return response;
    };

    const sendAgreementMail = async (id: string, values: SendAgreementMailInputModel) => {
        const response = await post(`${baseUrl}/${id}/mail`, values);

        return response;
    };

    const sendAgreementTaskMail = (values: SendAgreementTaskMailInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = post(`${baseUrl}/task/mail`, fd);

        return response;
    };

    return {
        addAgreement,
        useAllAgreements,
        allAgreements,
        deleteAgreement,
        getAgreementFile,
        sendAgreementMail,
        sendAgreementTaskMail
    };
};

export default useAgreements;
