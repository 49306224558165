import { Card, CardBody } from 'components/atoms/card';
import { FormYesNo } from 'components/atoms/form';
import Margin from 'components/atoms/Margin';
import Table from 'components/molecules/table';
import { H3 } from 'components/atoms/text';
import { CustomerModel } from 'hooks/customer/types';
import { RightModel } from 'hooks/right/types';
import React, { FC, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { getRightDisplay } from 'utils/constants/rightconstants';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import useModules from 'hooks/modules/useModules';
import { toast } from 'react-toastify';

interface CustomerDetailModulesProps {
    customer: CustomerModel;
    rights: RightModel[];
    fetchCustomer: () => Promise<void>;
}

interface ModuleModel {
    id: string;
    name: string;
    enabled: boolean;
}

const CustomerDetailModules: FC<CustomerDetailModulesProps> = ({ customer, rights, fetchCustomer }) => {

    const { editCustomerModule } = useModules();

    const [isLoadingModuleEdit, setIsLoadingModuleEdit] = useState(false);
    const [moduleToToggle, setModuleToToggle] = useState<ModuleModel>();

    const modules: ModuleModel[] = useMemo(() => rights.map((r) => ({
        id: r.id,
        name: getRightDisplay(r.name),
        enabled: customer?.user.rights.some((ur) => ur.id === r.id) ?? false
    })), [customer]);

    const openToggleModal = (module: ModuleModel) => {
        setModuleToToggle(module);
        showToggleModal();
    };

    const closeToggleModal = () => {
        hideToggleModal();
        setModuleToToggle(undefined);
    };

    const [showToggleModal, hideToggleModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal
                inProp={inProp}
                onExited={onExited}
                onHide={hideToggleModal}
                size="large"
                title={`Module ${moduleToToggle?.name} ${moduleToToggle?.enabled ? 'uitzetten' : 'aanzetten'}`}
                subtitle={`Weet je zeker dat je module ${moduleToToggle?.name} wilt ${moduleToToggle?.enabled ? 'uitzetten' : 'aanzetten'} voor deze klant?`}
            >
                <Group spaceBetween>
                    <Button onClick={closeToggleModal}>Annuleren</Button>
                    <Button loading={isLoadingModuleEdit} onClick={editModule}>Versturen</Button>
                </Group>
            </Modal>
        ),
        [moduleToToggle, isLoadingModuleEdit]
    );

    const editModule = async () => {
        if (moduleToToggle == null) {
            return;
        }

        setIsLoadingModuleEdit(true);

        const response = await editCustomerModule({ rightId: moduleToToggle.id, customerId: customer.id, enable: !moduleToToggle.enabled });

        if (response.ok) {
            await fetchCustomer();
            toast.success(`Module is succesvol ${moduleToToggle.enabled ? 'uitgezet' : 'aangezet'}`);
            closeToggleModal();
        } else {
            toast.error('Oops, er ging iets mis bij het bewerken van deze module');
        }

        setIsLoadingModuleEdit(false);
    };

    const moduleColumns: Column<ModuleModel>[] = [
        {
            Header: 'Naam',
            accessor: (item) => item.name
        },
        {
            Header: 'Status',
            accessor: (item) => <FormYesNo value={item.enabled} onChange={() => openToggleModal(item)} />
        }
    ];

    return (
        <Card noMargin>
            <CardBody>
                <Margin bottom={2}>
                    <H3>Modules</H3>
                </Margin>
                <Card variant="light" noMargin>
                    <CardBody>
                        <Card noMargin>
                            <CardBody>
                                <Table<ModuleModel>
                                    data={modules}
                                    columns={moduleColumns}
                                />
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </CardBody>
        </Card>
    );
};

export default CustomerDetailModules;
