import { useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { buildUriWithParams } from 'utils/urihelper';
import { TemplateLetterPlainModel } from './types';

export const templateLetterQueryKey = 'templateLetterKey';

export default function useAllTemplateLetters() {

    const { get } = useApi();

    const getAllTemplateLetters = async (params = {}) => {
        const baseUrl = '/templateletter';
        const url = buildUriWithParams(baseUrl, params);
        const { data } = await get<TemplateLetterPlainModel[]>(url);

        return data;
    };

    return useQuery<TemplateLetterPlainModel[] | undefined>(templateLetterQueryKey, getAllTemplateLetters);
}
