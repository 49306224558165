import React, { FC, useMemo, useRef } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Card, CardBody } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import Margin from 'components/atoms/Margin';
import { FormGroup, FormSelect, FormikFormMultipleFile, FormikFormSelect, FormikFormYesNo } from 'components/atoms/form';
import ButtonWrapper from 'components/atoms/ButtonWrapper';
import Alert from 'components/atoms/Alert';
import AddFieldButton from 'components/atoms/AddFieldButton';
import { TitleWrapper, Label } from './styles';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import HeaderAction from 'components/atoms/HeaderAction';
import { Form, Formik, FormikHelpers } from 'formik';
import { AddCorrespondenceInputModel, CorrespondenceInputModel, EditCorrespondenceInputModel } from 'hooks/correspondence/types';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorhelper';
import useAddAdminCorrespondence from 'hooks/correspondence/useAddAdminCorrespondence';
import useEditAdminCorrespondence from 'hooks/correspondence/useEditAdminCorrespondence';
import useAllCorrespondenceTemplates from 'hooks/correspondenceTemplate/useAllCorrespondenceTemplates';
import FormControlFormik from 'components/atoms/form/FormControl/formik';
import useAllCustomers from 'hooks/customer/useAllCustomers';
import { Editor as TinyMCEEditor } from 'tinymce';
import WysiwygEditorFormik from 'components/atoms/form/WysiwygEditor/formik';

interface SendCorrespondenceProps extends RouteComponentProps { }

const SendCorrespondence: FC<SendCorrespondenceProps> = () => {
    useHeader('Bericht versturen');

    // State.
    const editorRef = useRef<TinyMCEEditor>();

    const initialValues: CorrespondenceInputModel = {
        id: '',
        content: '',
        subject: '',
        customerIds: [],
        files: undefined,
        isQuestionnaire: false,
        link: undefined
    };

    // Hooks.
    const { addCorrespondenceMutation } = useAddAdminCorrespondence();
    const { editCorrespondenceMutation } = useEditAdminCorrespondence();
    const { data: correspondenceTemplates = [] } = useAllCorrespondenceTemplates();
    const { data: customers = [] } = useAllCustomers();

    const correspondenceTemplateOptions = useMemo(() => [{ value: '', label: 'Geen template' }].concat(correspondenceTemplates.map((c) => ({
        value: c.id,
        label: c.name
    }))), [correspondenceTemplates]);

    const customerOptions = useMemo(() => customers.map((c) => ({
        value: c.id,
        label: `${c.relationNumber ? c.relationNumber : 'Geen relatienummer'} - ${c.name} - ${c.userFullName}`
    })), [customers]);

    // Methods.
    const handleOnTagClick = (tag: string) => {
        editorRef.current?.execCommand('mceInsertContent', undefined, `{{${tag}}}`);
    };

    const handleSubmit = (values: EditCorrespondenceInputModel, { setErrors }: FormikHelpers<EditCorrespondenceInputModel>) => {
        if (values.id) {
            return editCorrespondenceMutation.mutateAsync(values, {
                onSuccess: () => {
                    navigate('/admin/correspondence');
                    toast.success(`'${values.subject}' succesvol opgeslagen`);
                },
                onError: (error) => {
                    if (error.errors) {
                        const errors = toFormikErrors<EditCorrespondenceInputModel>(error.errors);
                        setErrors(errors);
                        toast.error('Niet alle velden zijn ingevuld');
                    } else {
                        toast.error('Oeps, er ging iets fout tijdens het aanmaken van het bericht. Probeer het later opnieuw!');
                    }
                }
            });
        } else {
            return addCorrespondenceMutation.mutateAsync(values, {
                onSuccess: () => {
                    navigate('/admin/correspondence');
                    toast.success('bericht succesvol aangemaakt!');
                },
                onError: (error) => {
                    if (error.errors) {
                        const errors = toFormikErrors<AddCorrespondenceInputModel>(error.errors);
                        setErrors(errors);
                        toast.error('Niet alle velden zijn ingevuld');
                    } else {
                        toast.error('Oeps, er ging iets fout tijdens het aanpassen van het bericht. Probeer het later opnieuw!');
                    }
                }
            });
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values, isSubmitting }) => (
                    <Form>
                        <Margin bottom={1}>
                            <Group spaceBetween>
                                <Button variant="grey" type="button" onClick={() => navigate('/admin/correspondence')}>Terug</Button>
                                <Group>
                                    <Button loading={isSubmitting}>Versturen</Button>
                                </Group>
                            </Group>
                        </Margin>
                        <Card>
                            <CardBody>
                                <Card noMargin noShadow variant="light">
                                    <CardBody>
                                        <Margin bottom={1}>
                                            <H3>Versturen</H3>
                                            <Paragraph>Naar wie wil je dit bericht versturen?</Paragraph>
                                        </Margin>
                                        <FormikFormSelect
                                            name="customerIds"
                                            options={customerOptions}
                                            isMulti
                                        />
                                    </CardBody>
                                </Card>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Margin bottom={2}>
                                    <TitleWrapper>
                                        <H3>Nieuwe bericht versturen</H3>
                                        <Label>Concept</Label>
                                    </TitleWrapper>
                                </Margin>
                                <Card noShadow noMargin variant="light">
                                    <CardBody>
                                        <Margin bottom={2}>
                                            <H3>Bericht</H3>
                                            <FormGroup>
                                                <FormikFormYesNo name="isQuestionnaire" label="Is dit een vragenlijst?" />
                                            </FormGroup>
                                            <FormGroup noMargin label="Template naam">
                                                <FormSelect
                                                    options={correspondenceTemplateOptions}
                                                    onChange={(value) => {
                                                        if (value instanceof Array) {
                                                            return;
                                                        }

                                                        const subject = correspondenceTemplates.find(x => x.id === value?.value)?.subject ?? '';
                                                        const newContent = correspondenceTemplates.find(x => x.id === value?.value)?.content ?? '';
                                                        setFieldValue('subject', subject);
                                                        setFieldValue('content', newContent);
                                                    }}
                                                />
                                            </FormGroup>
                                            <Margin top={1}>
                                                <Alert text="* Wanneer u een template heeft geselecteerd is het nog steeds mogelijk om het bericht aan te passen." />
                                            </Margin>
                                        </Margin>
                                        <Margin bottom={1}>
                                            <HeaderAction title="E-mail" />
                                        </Margin>
                                        <FormGroup label="Onderwerp">
                                            <FormControlFormik name="subject" placeholder="Geef het onderwerp van de mail aan" />
                                        </FormGroup>
                                        {
                                            values.isQuestionnaire &&
                                            <FormGroup label="Link">
                                                <FormControlFormik name="link" placeholder="Geef een link mee in het bericht" />
                                            </FormGroup>
                                        }
                                        <FormGroup label="Tekst">
                                            <Card noMargin>
                                                <CardBody borderBottom>
                                                    <Margin bottom={1}>
                                                        <H3>Dynamische velden</H3>
                                                        <p>Selecteer de velden om ze toe te voegen</p>
                                                    </Margin>
                                                    <ButtonWrapper>
                                                        <AddFieldButton tag="name" title="Naam" onClick={handleOnTagClick} />
                                                        <AddFieldButton tag="date" title="Datum" onClick={handleOnTagClick} />
                                                        <AddFieldButton tag="mail" title="E-mail" onClick={handleOnTagClick} />
                                                    </ButtonWrapper>
                                                </CardBody>
                                                <CardBody style={{ minHeight: '200px' }}>
                                                    <WysiwygEditorFormik
                                                        name="content"
                                                        onInit={(_, editor) => editorRef.current = editor}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </FormGroup>
                                        <FormGroup noMargin label="Bijlagen">
                                            <FormikFormMultipleFile whiteBackground name="files" />
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </CardBody>
                        </Card>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default SendCorrespondence;
