import styled from '@emotion/styled';

export const Wrapper = styled.div`
    min-height: calc(100vh -${({ theme }) => theme.header.height}rem);
    position: relative;
    display: flex;
    flex-direction: row nowrap;
`;

export const Content = styled.div`
    padding-top: 2rem;
    position: relative;
    padding: 0 2rem 2rem;
    max-width: 1200px;
    min-height: calc(100vh -${({ theme }) => theme.header.height}rem);
    margin: 0 auto;
`;

export const MainContent = styled.div`
    flex: 1 1 auto;
    height: 100vh;
    overflow-y: auto;
`;
