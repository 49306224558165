import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody, CardHeader } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import { Loading } from 'components/atoms/button/Button/styles';
import { cdnFileUrl } from 'utils/cdnHelper';
import Margin from 'components/atoms/Margin';
import useNewsItems from 'hooks/newsitem';
import { useHeader } from 'contexts/headerContext';
import { format, parseISO } from 'date-fns';
import { Button } from 'components/atoms/button';
import htmr from 'htmr';

interface NewsItemDetailProps extends RouteComponentProps {
    id?: string;
}

const NewsItemDetail: FC<NewsItemDetailProps> = ({ id = '' }) => {
    useHeader('Nieuws');

    const { useAllNewsItems } = useNewsItems();
    const { data: newsItems = [] } = useAllNewsItems({
        id
    });

    const newsItem = newsItems[0];

    return newsItem == null ? <Loading /> : (
        <>
            <Margin bottom={1}>
                <Button variant="primary" onClick={() => window.history.back()}>Terug</Button>
            </Margin>
            <Card fill>
                <CardBody>
                    <CardHeader flexDirection="column">
                        <H3>{newsItem.title}</H3>
                        {
                            newsItem.publicationDate &&
                            <span>{format(parseISO(newsItem.publicationDate), 'dd-MM-yyyy')}</span>
                        }
                    </CardHeader>
                    {
                        newsItem.image != null &&
                        <Margin bottom={2}>
                            <img alt="" src={cdnFileUrl(newsItem.image.fileName)} style={{ maxWidth: 500 }} />
                        </Margin>
                    }
                    <Paragraph>
                        {htmr(newsItem.content)}
                    </Paragraph>
                </CardBody>
            </Card>
        </>
    );
};

export default NewsItemDetail;
