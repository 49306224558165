export enum Gender {
    Male = 1,
    Female = 2,
    //Other = 3,
    Unknown = 3
}

export const genderOptions = [
    {
        value: Gender.Male,
        label: 'De heer'
    },
    {
        value: Gender.Female,
        label: 'Mevrouw'
    },
    /* {
        value: Gender.Other,
        label: 'Anders'
    }, */
    {
        value: Gender.Unknown,
        label: 'Onbekend'
    }
];

export const getGenderDisplay = (gender: Gender): string => {
    switch (gender) {
        case Gender.Male:
            return 'de heer';
        case Gender.Female:
            return 'mevrouw';
        /* case Gender.Other:
            return 'anders'; */
        case Gender.Unknown:
            return 'onbekend';
    }
};
