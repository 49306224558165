import Step, { StepProps } from 'components/atoms/Step';
import React, { FC } from 'react';
import { Visible } from 'react-grid-system';
import { Stepper as Wrapper, Space } from './styles';

export interface StepperProps {
    steps: StepProps[];
    currentStep: number;
}

const first = (index: number) => {
    return index === 0;
};

const Stepper: FC<StepperProps> = ({ steps, currentStep }) => {
    return (
        <Wrapper>
            {steps.map((step, index) => (
                <Visible key={step.step} xs={step.active} sm={step.active} md lg xl xxl>
                    <Visible md lg xl xxl>
                        {!first(index) && <Space />}
                    </Visible>
                    <Step {...step} completed={step.step < currentStep + 1} />
                </Visible>
            ))}
        </Wrapper>
    );
};

export default Stepper;
