export interface BorderRadius {
  small: number;
  normal: number;
  large: number;
}

const borderRadius: BorderRadius = {
  small: 4,
  normal: 8,
  large: 12,
};

export default borderRadius;