import { useSession } from 'contexts/sessionContext';
import { useApi } from 'hooks/api';
import { useQuery } from 'react-query';
import { buildUriWithParams } from 'utils/urihelper';
import { CollectionForeignPlainModel, GetCollectionForeignInputModel } from './types';

export const CollectionForeignAllQueryKey = 'collectionforeign.all';

export default function useAllCollectionForeigns() {
    const { session } = useSession();
    const { get } = useApi();

    const getAllCollectionForeigns = async (params?: GetCollectionForeignInputModel) => {

        const uri = buildUriWithParams('/collectionforeign', params);
        const { data } = await get<CollectionForeignPlainModel[]>(uri);

        return data;
    };

    return useQuery<CollectionForeignPlainModel[] | undefined>(CollectionForeignAllQueryKey, () => getAllCollectionForeigns({ customerId: session?.customerId }));
}
