import { CorrespondencePlainModel, EditCorrespondenceInputModel, CorrespondenceMutationContext } from 'hooks/correspondence/types';
import { useMutation, useQueryClient } from 'react-query';
import { CustomResponse, useApi } from 'hooks/api';
import { correspondenceQueryKey } from 'hooks/correspondence/useAllCorrespondence';
import { appendObject } from 'utils/objectAppender';
import { CustomerPlainModel } from 'hooks/customer/types';
import { allCustomersQueryKey } from 'hooks/customer/useAllCustomers';

const useEditAdminCorrespondence = () => {

    const { put } = useApi();

    const baseUrl = '/correspondence';

    const queryClient = useQueryClient();

    const editCorrespondence = async (values: EditCorrespondenceInputModel) => {
        const fd = new FormData();

        appendObject(values, fd);

        const response = await put(
            baseUrl, fd
        );

        if (!response.ok) {
            throw response;
        }

        return response;
    };


    const editCorrespondenceMutation = useMutation<CustomResponse<unknown>, CustomResponse<unknown>, EditCorrespondenceInputModel, CorrespondenceMutationContext>((values: EditCorrespondenceInputModel) => editCorrespondence(values), {

        onMutate: async (values: EditCorrespondenceInputModel) => {

            await queryClient.cancelQueries(correspondenceQueryKey);

            const customersData = queryClient.getQueryData<CustomerPlainModel[]>(allCustomersQueryKey);
            const previousData = queryClient.getQueryData<CorrespondencePlainModel[]>(correspondenceQueryKey);
            const newData = [...previousData ?? []];

            const ToEdit = newData.find(item => item.id === values.id);
            const newCustomers = customersData?.filter((c) => values.customerIds.some((id) => id === c.id)) ?? [];

            if (ToEdit != null) {
                ToEdit.content = values.content;
                ToEdit.customers = newCustomers;
                ToEdit.files = values.files;
                ToEdit.subject = values.subject;
                ToEdit.link = values.link;
            }

            queryClient.setQueryData<CorrespondencePlainModel[]>(correspondenceQueryKey, newData);

            return { previousData };
        },
        onError: (error, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData<CorrespondencePlainModel[]>(correspondenceQueryKey, context.previousData);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(correspondenceQueryKey);
        }

    });

    return { editCorrespondenceMutation };
};

export default useEditAdminCorrespondence;
