import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Step = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                cursor: pointer;
            }
        `}
`;

export const Number = styled.div<{ active?: boolean; disabled?: boolean; completed?: boolean }>`
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${({ theme, active, disabled, completed }) => (active
        ? theme.colors.primary
        : completed
            ? theme.colors.green
            : disabled
                ? theme.colors.grey
                : theme.colors.primary)};
    transition: background 0.4s ease;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.875rem;
    flex: 0 0 1.5rem;
    margin-right: 1rem;
`;

export const Content = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

export const Title = styled.span<{ active?: boolean }>`
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    color: ${({ theme, active }) => (active ? theme.colors.dark : theme.greys[500])};
`;

export const Subtitle = styled.span`
    color: ${({ theme }) => theme.greys[300]};
    font-size: 0.875rem;
    line-height: 0.875rem;
`;
