import React, { FC, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useHeader } from 'contexts/headerContext';
import { Row, Col } from 'react-grid-system';
import { Card, CardActions, CardBody, CardContent, CardHeader } from 'components/atoms/card';
import { H3, H5, Paragraph } from 'components/atoms/text';
import useKnowledgeBaseItems from 'hooks/knowledgebaseitem';
import { Button } from 'components/atoms/button';
import { FormSelect } from 'components/atoms/form';
import useKnowledgeBaseCategories from 'hooks/knowledgebasecategory';
import { IOption } from 'components/atoms/form/FormSelect';
import { Options } from 'react-select';
import { orderBy } from 'lodash';
import { format, parseISO } from 'date-fns';

interface MessagesProps extends RouteComponentProps { }

const KnowledgeBase: FC<MessagesProps> = () => {
    useHeader('Kennisbank');

    const [category, setCategory] = useState<string>();
    const [showMore, setShowMore] = useState(false);

    const { useAllKnowledgeBaseItems } = useKnowledgeBaseItems();
    const { data: knowledgeBaseItems = [] } = useAllKnowledgeBaseItems({
        categoryId: category
    });
    const { useAllKnowledgeBaseCategories } = useKnowledgeBaseCategories();
    const { data: knowledgeBaseCategories = [] } = useAllKnowledgeBaseCategories();

    const categoryOptions = [{ value: '', label: '-' }].concat(knowledgeBaseCategories.map((c) => ({
        value: c.id,
        label: c.name
    })));

    const handleOnItemClick = (id: string) => {
        navigate(`/knowledge-base/${id}/detail`);
    };

    const handleOnCategoryChange = (option: IOption | Options<IOption> | null) => {
        if (option == null || option instanceof Array) {
            return;
        }

        if (typeof option.value === 'number') {
            return;
        }

        setCategory(option.value as string);
        setShowMore(false);
    };

    let sortedKnowledgeBaseItems = orderBy(knowledgeBaseItems, (item) => item.publicationDate, 'desc');

    if (!showMore) {
        sortedKnowledgeBaseItems = sortedKnowledgeBaseItems.slice(0, 6);
    }

    return (
        <>
            <Col xl={6} offset={{ xl: 6 }}>
                <Card fill variant="primary">
                    <CardBody>
                        <Row>
                            <Col style={{ display: 'flex', alignItems: 'center' }} md={6}>
                                <Paragraph variant="white" noMargin>Filter op categorie</Paragraph>
                            </Col>
                            <Col md={6}>
                                <FormSelect options={categoryOptions} onChange={handleOnCategoryChange} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Row>
                {sortedKnowledgeBaseItems.length === 0 &&
                    <H3 center>
                        Geen artikelen gevonden
                    </H3>
                }
                {sortedKnowledgeBaseItems.map((item) => (
                    <Col md={4} key={item.id}>
                        <Card fill>
                            <CardBody>
                                <CardHeader flexDirection="column">
                                    <H5>{item.category?.name ?? 'Geen categorie'}</H5>
                                    <H3>{item.title}</H3>
                                    <span>{format(parseISO(item.publicationDate), 'dd-MM-yyyy')}</span>
                                </CardHeader>
                                <CardContent>
                                    <Paragraph lines={4}>{item.description}</Paragraph>
                                </CardContent>
                                <CardActions align="center">
                                    <Button onClick={() => handleOnItemClick(item.id)}>Lees verder</Button>
                                </CardActions>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            {
                !showMore && knowledgeBaseItems.length > 6 &&
                <Row>
                    <Col>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        <a href="#" onClick={() => setShowMore(true)}>Bekijk oudere artikelen</a>
                    </Col>
                </Row>
            }
        </>
    );
};

export default KnowledgeBase;
