import { useSession } from 'contexts/sessionContext';
import { useApi } from 'hooks/api';
import { BannerSendMailModel } from './types';

const useSendBannerMail = () => {
    const { session } = useSession();
    const { post } = useApi();

    const baseUrl = '/banner/mail';

    const sendBanner = async (values: BannerSendMailModel) => {
        const newValues = {
            ...values,
            customerId: values.customerId ?? session?.customerId
        };

        const response = await post(baseUrl, newValues);
        return response;
    };

    return {
        sendBanner
    };
};

export default useSendBannerMail;
