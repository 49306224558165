import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import { H3, Paragraph } from 'components/atoms/text';
import { useHeader } from 'contexts/headerContext';
import { SendTermsAndConditionsTaskMailInputModel, TermsAndConditionsType } from 'hooks/termsAndConditions/types';
import useTermsAndConditions from 'hooks/termsAndConditions/useTermsAndConditions';
import { FormGroup, FormikFormFile, FormikFormTextarea } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import { toast } from 'react-toastify';
import { Button } from 'components/atoms/button';
import React, { FC } from 'react';

interface ReviewProps extends RouteComponentProps { }

const initialValues: SendTermsAndConditionsTaskMailInputModel = {
    file: undefined,
    comments: '',
    type: TermsAndConditionsType.Update
};

const Update: FC<ReviewProps> = () => {
    useHeader('Algemene Voorwaarden');

    const { sendTermsAndConditionsTaskMail } = useTermsAndConditions();

    const handleMail = async (values: SendTermsAndConditionsTaskMailInputModel, { setErrors, resetForm }: FormikHelpers<SendTermsAndConditionsTaskMailInputModel>) => {
        const response = await sendTermsAndConditionsTaskMail(values);

        if (response.ok) {
            toast.success('Uw aanvraag voor het updaten van de Algemene Voorwaarden is succesvol verstuurd');
            resetForm();
        } else if (response.errors) {
            const errors = toFormikErrors<SendTermsAndConditionsTaskMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de update-aanvraag');
        }
    };

    return (
        <>
            <Card variant="primary">
                <CardBody>
                    <H3 variant="white">Update algemene voorwaarden</H3>
                    <Paragraph variant="white">
                        Door bijvoorbeeld wijziging in wet- en regelgeving of wijzigingen in uw bedrijfsactiviteiten kunnen uw voorwaarden verouderen of niet meer voldoende passend zijn. Heeft u een onderhoudscontract algemene voorwaarden? Dat controleren wij uw voorwaarden periodiek en passen aan wanneer nodig.
                    </Paragraph>
                    <Paragraph variant="white" noMargin>
                        Heeft u twijfels over de actualiteit van uw algemene voorwaarden? Of vraagt u zich af of uw voorwaarden nog wel voldoende passend zijn door wijzigingen in uw bedrijfsactiviteiten? Vul onderstaand formulier in en wij informeren u graag over de actualiteit van uw algemene voorwaarden. Ook wanneer uw voorwaarden niet door Juresta werden vervaardigd kunt u onderstaand formulier invullen.
                    </Paragraph>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <Formik<SendTermsAndConditionsTaskMailInputModel> initialValues={initialValues} onSubmit={handleMail}>
                        {({ isSubmitting }) => (
                            <Form>
                                <FormGroup label="Bijlage toevoegen" required>
                                    <FormikFormFile name="file" />
                                </FormGroup>
                                <FormGroup label="Vraag of opmerking">
                                    <FormikFormTextarea name="comments" placeholder="Opmerking" />
                                </FormGroup>
                                <FormGroup noMargin alignRight>
                                    <Button loading={isSubmitting}>Verzenden</Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    );
};

export default Update;
