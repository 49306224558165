import MenuItem, { MenuItemProps } from 'components/atoms/MenuItem';
import React, { FC } from 'react';
import { Menu as Wrapper } from './styles';

export interface MenuProps {
    items: MenuItemProps[];
}

const Menu: FC<MenuProps> = ({ items }) => {
    // Render.
    return (
        <Wrapper>
            {items.map((item) => {
                return (
                    <MenuItem key={item.title} {...item}>
                        {item.title}
                    </MenuItem>
                );
            })}
        </Wrapper>
    );
};

export default Menu;
