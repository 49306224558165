import styled from '@emotion/styled';

export const Alert = styled.p`
    display: block;
    margin: 0;
    padding: .5rem .75rem;
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.border};
    font-weight: 500;
    font-size: .9rem;
`;