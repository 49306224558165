import React, { FC } from 'react';
import { Button } from 'components/atoms/button';
import { FormControl } from 'components/atoms/form';
import Group from 'components/molecules/Group';

export interface SearchProps {
    filter: string;
    setFilter: (filter: string) => void;
    onAddClick?: () => void;
    onAddClickLabel?: string;
    onSettingsClick?: () => void;
}

const Search: FC<SearchProps> = ({ filter, setFilter, onAddClick, onAddClickLabel, onSettingsClick }) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFilter(value);
    };

    return (
        <Group>
            <FormControl name="search" placeholder="Zoeken" value={filter} onChange={handleOnChange} />
            {onAddClick && (
                <Button endIcon="plus" onClick={onAddClick} size="lg" rounded={false}>
                    {onAddClickLabel ? onAddClickLabel : 'Nieuw'}
                </Button>
            )}
            {onSettingsClick && <Button startIcon="settings" onClick={onSettingsClick} size="lg" rounded={false} />}
        </Group>
    );
};

export default Search;
