import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardBody } from 'components/atoms/card';
import Table from 'components/molecules/table';
import { Column } from 'react-table';
import { useHeader } from 'contexts/headerContext';
import useAgreements from 'hooks/agreement';
import { useSession } from 'contexts/sessionContext';
import { AgreementPlainModel, SendAgreementMailInputModel } from 'hooks/agreement/types';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import useModal from 'hooks/modal';
import Modal from 'components/organisms/Modal';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import { H3, Paragraph } from 'components/atoms/text';
import { format, parseISO } from 'date-fns';

const columns: Column<AgreementPlainModel>[] = [
    {
        Header: 'Naam',
        accessor: (agreement) => agreement.fileName
    },
    {
        Header: 'Datum',
        accessor: (agreement) => parseISO(agreement.date).getTime(),
        Cell: ({ value }: any) => format(value, 'dd-MM-yyyy')
    },
    {
        Header: 'Versie',
        accessor: (agreement) => agreement.version
    }
];

const ClientAgreements: FC<RouteComponentProps> = () => {
    useHeader('Overeenkomsten');

    const { session } = useSession();

    const { useAllAgreements, getAgreementFile, sendAgreementMail } = useAgreements();
    const { data: agreements = [], isLoading: isLoadingAgreements } = useAllAgreements({ customerId: session?.customerId });


    const [agreementToMail, setAgreementToMail] = useState<AgreementPlainModel>();
    const [fileToPrint, setFileToPrint] = useState<string | undefined>();
    const printRef = React.createRef<any>();

    const downloadFile = async (agreement: AgreementPlainModel) => {
        const response = await getAgreementFile(agreement.id);

        if (response.ok) {
            saveAs(await response.blob(), agreement.fileName);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de overeenkomst');
        }
    };

    const printFile = async (agreement: AgreementPlainModel) => {
        const response = await getAgreementFile(agreement.id);

        if (response.ok) {
            const url = window.URL.createObjectURL(await response.blob());
            setFileToPrint(url);
        } else {
            toast.error('Er ging iets fout bij het ophalen van de overeenkomst');
        }
    };

    const handleMail = async (values: SendAgreementMailInputModel, { setErrors }: FormikHelpers<SendAgreementMailInputModel>) => {
        if (!agreementToMail) {
            return;
        }

        const response = await sendAgreementMail(agreementToMail.id, values);

        if (response.ok) {
            toast.success('Overeenkomst is succesvol verstuurd');
            closeMailModal();
        } else if (response.errors) {
            const errors = toFormikErrors<SendAgreementMailInputModel>(response.errors);
            setErrors(errors);
        } else {
            toast.error('Er ging iets fout bij het versturen van de overeenkomst');
        }
    };

    const initialValuesSendMail: SendAgreementMailInputModel = {
        toEmail: ''
    };

    const [showMailModal, hideMailModal] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal inProp={inProp} onExited={onExited} onHide={hideMailModal} size="large" title="Overeenkomst versturen" subtitle="Vul een e-mailadres in om de overeenkomst te versturen">
                <Formik<SendAgreementMailInputModel> initialValues={initialValuesSendMail} onSubmit={handleMail}>
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <FormGroup label="E-mailadres" required>
                                <FormikFormControl name="toEmail" placeholder="E-mailadres" />
                            </FormGroup>
                            <Group right>
                                <Button type="button" onClick={closeMailModal}>Annuleren</Button>
                                <Button type="submit" loading={isSubmitting} disabled={!isValid}>Versturen</Button>
                            </Group>
                        </Form>
                    )}
                </Formik>
            </Modal>
        ),
        [agreementToMail]
    );

    const openMailModal = (agreement: AgreementPlainModel) => {
        setAgreementToMail(agreement);
        showMailModal();
    };

    const closeMailModal = () => {
        setAgreementToMail(undefined);
        hideMailModal();
    };

    // Render.
    return (
        <>
            <Card variant="primary">
                <CardBody>
                    <H3 variant="white">Uw overeenkomsten</H3>
                    <Paragraph variant="white" noMargin>In dit onderdeel kunt u de door Juresta vervaardigde overeenkomsten voor uw onderneming inzien. U kunt de overeenkomst downloaden of direct per mail doorsturen naar een mogelijke opdrachtgever. </Paragraph>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <Table<AgreementPlainModel>
                        data={agreements}
                        columns={columns}
                        isLoading={isLoadingAgreements}
                        noDataMessage="Geen overeenkomsten beschikbaar"
                        actions={[
                            {
                                icon: 'download',
                                onClick: (item) => downloadFile(item),
                                title: 'Download overeenkomst'
                            },
                            {
                                icon: 'print',
                                onClick: (item) => printFile(item),
                                title: 'Print overeenkomst'
                            },
                            {
                                icon: 'mail',
                                onClick: (item) => openMailModal(item),
                                title: 'Mail overeenkomst'
                            }
                        ]}
                    />
                </CardBody>
            </Card>

            {
                fileToPrint &&
                <iframe ref={printRef} style={{ display: 'none' }} title="print" src={fileToPrint} onLoad={() => window.frames[0].print()}></iframe>
            }
        </>
    );
};

export default ClientAgreements;
