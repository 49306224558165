import React, { FC, Suspense, useEffect, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { Wrapper, Content, MainContent } from './styles';
import Navigation from 'components/organisms/Navigation';
import { css, Global } from '@emotion/react';
import { Default as Header } from 'components/organisms/header';
import User from 'components/molecules/User';
import Dropdown from 'components/molecules/dropdown';
import useAccount from 'hooks/account/useAccount';
import { useSession } from 'contexts/sessionContext';
import { useAreaAuthorization } from 'hooks/area';
import { Loading } from 'components/atoms/button/Button/styles';
import colors from 'theme/colors';
import { Role } from 'utils/constants';
import { MenuItemProps } from 'components/atoms/MenuItem';
import { useApi } from 'hooks/api';
import { DropdownItemProps } from 'components/atoms/item/DropdownItem';
import { persistCollectionForeignKey, persistCollectionKey } from 'utils/constants/localstrorageconstants';

const styles = css`
    body {
        background-color: ${colors.light};
    }
`;

interface DefaultProps extends RouteComponentProps {
    role?: Role;
    items: MenuItemProps[];
}

const Default: FC<DefaultProps> = ({ children, role, items }) => {
    const { session, hasRole } = useSession();
    const { refreshSession } = useApi();
    const { logOut } = useAccount();
    const [initialized, setInitialized] = useState(false);
    const { initializedAuth } = useAreaAuthorization(initialized, role);

    useEffect(() => {
        (async () => {
            if (session == null) {
                await refreshSession();
            }
            setInitialized(true);
        })();
    }, []);

    // Methods.
    const handleLogOut = async () => {
        // Remove local storage of forms
        localStorage.removeItem(persistCollectionForeignKey);
        localStorage.removeItem(persistCollectionKey);

        logOut().then(() => navigate('/account'));
    };

    return session && initialized && initializedAuth ? (
        <>
            <Global styles={styles} />
            <Wrapper>
                <Navigation items={items} />
                <MainContent>
                    <Header>
                        <Dropdown
                            items={[
                                !hasRole(Role.Admin) && {
                                    title: 'Bekijk mijn profiel',
                                    icon: 'profile',
                                    to: '/profile'
                                },
                                {
                                    title: 'Uitloggen',
                                    onClick: handleLogOut,
                                    icon: 'sign-out'
                                }
                            ].filter(Boolean) as DropdownItemProps[]}
                        >
                            <User>{session?.fullName ?? 'Onbekend'}</User>
                        </Dropdown>
                    </Header>
                    <Content>
                        <Suspense fallback="loading">{children}</Suspense>
                    </Content>
                </MainContent>
            </Wrapper>
        </>
    ) : (
        <Loading />
    );
};

export default Default;
