import React, { FC, useState } from 'react';
import { Accordion as Wrapper, AccordionHeader } from './styles';
import { Collapse } from 'react-collapse';
import { CardBody } from 'components/atoms/card';


interface AccordionProps {
    title: string;
}

const Accordion: FC<AccordionProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Wrapper>
            <AccordionHeader isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                {title}
            </AccordionHeader>
            <Collapse isOpened={isOpen}>
                <CardBody style={{ paddingTop: 0 }}>
                    {children}
                </CardBody>
            </Collapse>
        </Wrapper>
    );

};

export default Accordion;
